var _templateObject;
function _taggedTemplateLiteral(strings, raw) { if (!raw) {
    raw = strings.slice(0);
} return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import styled from 'styled-components';
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  cursor: ", ";\n\n  display: flex;\n  align-items: center;\n\n  width: ", ";\n  height: ", ";\n"])), function (_ref) {
    var pointer = _ref.pointer;
    return pointer ? 'pointer' : 'unset';
}, function (_ref2) {
    var width = _ref2.width;
    return width && "".concat(width, "px");
}, function (_ref3) {
    var height = _ref3.height;
    return height && "".concat(height, "px");
});
