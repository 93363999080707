import { createSlice } from '@reduxjs/toolkit';
import { logout } from './actions';
import { authApiSlice } from './endpoints';
import { deleteAccessToken } from './utils';
var initialState = {
    isAuth: false,
    role: null
};
export var authSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {},
    extraReducers: function extraReducers(builder) {
        builder.addCase(logout, function (state) {
            state.role = null;
            state.isAuth = false;
            deleteAccessToken();
        });
        builder.addMatcher(authApiSlice.endpoints.logout.matchFulfilled, function (state) {
            state.role = null;
            state.isAuth = false;
            deleteAccessToken();
        });
        builder.addMatcher(authApiSlice.endpoints.getRole.matchFulfilled, function (state, _ref) {
            var role = _ref.payload.data.role;
            state.isAuth = true;
            state.role = role;
        });
    }
});
