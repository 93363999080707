var _GENERATION_STATUS_TI, _GENERATION_STATUS_CO;
function _typeof(obj) {
    "@babel/helpers - typeof";
    return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj);
}
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) {
    Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true });
}
else {
    obj[key] = value;
} return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null)
    return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) {
    var res = prim.call(input, hint || "default");
    if (_typeof(res) !== "object")
        return res;
    throw new TypeError("@@toPrimitive must return a primitive value.");
} return (hint === "string" ? String : Number)(input); }
export var GENERATION_STATUS = {
    COMPLETED: 'finished_QG',
    FINISHED_BACK: 'finished_BACK',
    CREATED: 'created',
    PROCESS: 'in_progress',
    ERROR: 'error_QG',
    ERROR_BACK: 'error_BACK'
};
export var GENERATION_STATUS_NAMES = {
    COMPLETED: 'Завершено',
    PROCESS: 'В процессе',
    CREATED: 'Создано',
    ERROR: 'Ошибка'
};
export var GENERATION_STATUS_TITLES = (_GENERATION_STATUS_TI = {}, _defineProperty(_GENERATION_STATUS_TI, GENERATION_STATUS.COMPLETED, GENERATION_STATUS_NAMES.COMPLETED), _defineProperty(_GENERATION_STATUS_TI, GENERATION_STATUS.FINISHED_BACK, GENERATION_STATUS_NAMES.COMPLETED), _defineProperty(_GENERATION_STATUS_TI, GENERATION_STATUS.PROCESS, GENERATION_STATUS_NAMES.PROCESS), _defineProperty(_GENERATION_STATUS_TI, GENERATION_STATUS.CREATED, GENERATION_STATUS_NAMES.CREATED), _defineProperty(_GENERATION_STATUS_TI, GENERATION_STATUS.ERROR, GENERATION_STATUS_NAMES.ERROR), _defineProperty(_GENERATION_STATUS_TI, GENERATION_STATUS.ERROR_BACK, GENERATION_STATUS_NAMES.ERROR), _GENERATION_STATUS_TI);
export var GENERATION_STATUS_OPTIONS = [{
        id: '',
        title: 'Не выбрано'
    }, {
        id: GENERATION_STATUS.CREATED.toString(),
        title: GENERATION_STATUS_NAMES.CREATED
    }, {
        id: GENERATION_STATUS.COMPLETED.toString(),
        title: GENERATION_STATUS_NAMES.COMPLETED
    }, {
        id: GENERATION_STATUS.PROCESS.toString(),
        title: GENERATION_STATUS_NAMES.PROCESS
    }, {
        id: GENERATION_STATUS.ERROR.toString(),
        title: GENERATION_STATUS_NAMES.ERROR
    }];
export var GENERATION_STATUS_COLORS = (_GENERATION_STATUS_CO = {}, _defineProperty(_GENERATION_STATUS_CO, GENERATION_STATUS.FINISHED_BACK, 'green'), _defineProperty(_GENERATION_STATUS_CO, GENERATION_STATUS.COMPLETED, 'green'), _defineProperty(_GENERATION_STATUS_CO, GENERATION_STATUS.PROCESS, 'yellow'), _defineProperty(_GENERATION_STATUS_CO, GENERATION_STATUS.CREATED, 'default'), _defineProperty(_GENERATION_STATUS_CO, GENERATION_STATUS.ERROR, 'red'), _defineProperty(_GENERATION_STATUS_CO, GENERATION_STATUS.ERROR_BACK, 'red'), _GENERATION_STATUS_CO);
export var GENERATION_TYPE = 'generation';
export var TRAINING_TYPE = 'training';
export var GENERATION_TYPE_TITLE = 'Генерация вопросов';
export var STUDY_TYPE_TITLE = 'Дообучение моделей';
export var GENERATION_STATES = {
    STATE_DEFAULT: 5,
    STATE_APPROVED: 10,
    STATE_REJECTED: 15
};
