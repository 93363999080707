var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;
function _taggedTemplateLiteral(strings, raw) { if (!raw) {
    raw = strings.slice(0);
} return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import styled from 'styled-components';
import { COLORS } from 'constants/colors';
export var FormWrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: grid;\n  grid-auto-flow: row;\n  grid-gap: 16px;\n  align-items: center;\n"])));
export var InlineGroup = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: grid;\n  grid-gap: 16px;\n  grid-template-columns: 2.5fr 1fr;\n"])));
export var FormGroup = styled(FormWrapper)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  padding: 24px;\n  border: 1px solid ", ";\n  border-radius: 8px;\n"])), COLORS.PURPLE_400);
export var ButtonWrapper = styled(FormWrapper)(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  grid-template-columns: 1.3fr 2fr;\n"])));
export var ManagerWrapper = styled(FormWrapper)(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  ", "\n"])), function (_ref) {
    var isEdit = _ref.isEdit;
    return isEdit ? "\n    grid-template-columns: 1fr 48px;\n    align-items: end;\n  " : null;
});
