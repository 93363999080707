var _templateObject, _templateObject2, _templateObject3;
function _taggedTemplateLiteral(strings, raw) { if (!raw) {
    raw = strings.slice(0);
} return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import styled from 'styled-components';
import { Fonts } from 'types/TextProps';
import { COLORS } from 'constants/colors';
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  position: relative;\n\n  display: flex;\n  gap: 10px;\n  align-items: center;\n\n  min-width: 117px;\n  height: 20px;\n\n  font-family: ", ";\n  font-size: 12px;\n  font-weight: 700;\n  line-height: 16px;\n  color: ", ";\n\n  background: ", ";\n  border-radius: 14px;\n\n  ", "\n  ", ";\n"])), Fonts.NotoSans, COLORS.GRAY_600, COLORS.LOGAN, function (_ref) {
    var progress = _ref.progress;
    return !progress ? 'justify-content: center;' : null;
}, function (_ref2) {
    var width = _ref2.width;
    return width ? "width: ".concat(width, ";") : '';
});
export var Text = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  position: absolute;\n  left: 50%;\n  transform: translateX(-50%);\n"])));
export var Scale = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  width: ", ";\n  height: 100%;\n  padding: 2px 8px;\n\n  background: ", ";\n  border-radius: ", ";\n"])), function (_ref3) {
    var progress = _ref3.progress;
    return "".concat(progress !== null && progress !== void 0 ? progress : 0, "%");
}, COLORS.PURPLE_100, function (_ref4) {
    var progress = _ref4.progress;
    return progress === 100 ? '14px' : '14px 0 0 14px';
});
