import api from './exporterApi';
export var exporter = api.injectEndpoints({
    endpoints: function endpoints(build) {
        return {
            exportQuestions: build.query({
                query: function query(params) {
                    return {
                        url: '/admin/export/questions',
                        method: 'GET',
                        params: params
                    };
                }
            }),
            getExportStatus: build.query({
                query: function query(_ref) {
                    var id = _ref.id;
                    return {
                        url: "/status/".concat(id),
                        method: 'GET'
                    };
                }
            })
        };
    }
});
var useLazyExportQuestionsQuery = exporter.useLazyExportQuestionsQuery, useLazyGetExportStatusQuery = exporter.useLazyGetExportStatusQuery;
export { useLazyExportQuestionsQuery, useLazyGetExportStatusQuery };
