export var getDifferenceBetweenDates = function getDifferenceBetweenDates(date1Str, date2Str) {
    var date1 = new Date(date1Str);
    var date2 = new Date(date2Str);
    var diffMs = date2.getTime() - date1.getTime();
    var diffSeconds = Math.floor(diffMs / 1000);
    var diffMinutes = Math.floor(diffSeconds / 60);
    var diffHours = Math.floor(diffMinutes / 60);
    var hours = "0".concat(diffHours % 24).slice(-2);
    var minutes = "0".concat(diffMinutes % 60).slice(-2);
    var seconds = "0".concat(diffSeconds % 60).slice(-2);
    return "".concat(hours, ":").concat(minutes, ":").concat(seconds);
};
