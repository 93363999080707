import * as React from 'react';
import { isSelectedRole, QUESTION_STATUS_TEXT as STATUS_TEXT } from 'modules';
import { useTypedSelector } from 'store/store';
import { QUESTION_STATUS as STATUS } from '../constants';
export var useQuestionStatusOptions = function useQuestionStatusOptions() {
    var role = useTypedSelector(isSelectedRole);
    var options = React.useMemo(function () {
        if (role === 'expert') {
            return [{
                    value: STATUS.rework.toString(),
                    title: STATUS_TEXT[STATUS.rework]
                }, {
                    value: STATUS.approved.toString(),
                    title: STATUS_TEXT[STATUS.approved]
                }];
        }
        return [{
                value: STATUS.approved.toString(),
                title: STATUS_TEXT[STATUS.approved]
            }, {
                value: STATUS.editing.toString(),
                title: STATUS_TEXT[STATUS.editing]
            }, {
                value: STATUS.expertise.toString(),
                title: STATUS_TEXT[STATUS.expertise]
            }, {
                value: STATUS.rejected.toString(),
                title: STATUS_TEXT[STATUS.rejected]
            }, {
                value: STATUS.rework.toString(),
                title: STATUS_TEXT[STATUS.rework]
            }];
    }, [role]);
    return {
        options: options
    };
};
