var _excluded = ["role"], _excluded2 = ["role", "password_confirmation", "password"], _excluded3 = ["role", "uuid"];
function _objectWithoutProperties(source, excluded) { if (source == null)
    return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);
    for (i = 0; i < sourceSymbolKeys.length; i++) {
        key = sourceSymbolKeys[i];
        if (excluded.indexOf(key) >= 0)
            continue;
        if (!Object.prototype.propertyIsEnumerable.call(source, key))
            continue;
        target[key] = source[key];
    }
} return target; }
function _objectWithoutPropertiesLoose(source, excluded) { if (source == null)
    return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0)
        continue;
    target[key] = source[key];
} return target; }
import { api } from './api';
export var profileService = api.injectEndpoints({
    endpoints: function endpoints(build) {
        return {
            getCurrentProfile: build.query({
                query: function query(_ref) {
                    var _ref$role = _ref.role, role = _ref$role === void 0 ? 'admin' : _ref$role, params = _objectWithoutProperties(_ref, _excluded);
                    return {
                        url: "".concat(role, "/self-edit"),
                        method: 'GET',
                        params: params
                    };
                },
                providesTags: function providesTags(result) {
                    return result !== null && result !== void 0 && result.data ? [{
                            type: 'Profile',
                            id: result.data.id
                        }, {
                            type: 'Profile',
                            id: 'LIST'
                        }] : [{
                            type: 'Profile',
                            id: 'LIST'
                        }];
                }
            }),
            changeProfilePassword: build.mutation({
                query: function query(_ref2) {
                    var role = _ref2.role, password_confirmation = _ref2.password_confirmation, password = _ref2.password, params = _objectWithoutProperties(_ref2, _excluded2);
                    return {
                        url: "".concat(role, "/change-password"),
                        method: 'POST',
                        params: params,
                        body: {
                            password: password,
                            password_confirmation: password_confirmation
                        }
                    };
                }
            }),
            dropProfilePhoto: build.mutation({
                query: function query(_ref3) {
                    var role = _ref3.role, uuid = _ref3.uuid, body = _objectWithoutProperties(_ref3, _excluded3);
                    return {
                        url: "".concat(role, "/drop-photo/").concat(uuid),
                        method: 'POST',
                        body: body
                    };
                }
            }),
            editProfile: build.mutation({
                query: function query(_ref4) {
                    var uuid = _ref4.uuid, role = _ref4.role, f_name = _ref4.f_name, email = _ref4.email, description = _ref4.description, i_name = _ref4.i_name, o_name = _ref4.o_name, phone = _ref4.phone, photo_upload = _ref4.photo_upload;
                    var formData = new FormData();
                    var setter = function setter(name, value) {
                        return formData.append(name, value);
                    };
                    if (photo_upload) {
                        formData.append('photo_upload', photo_upload);
                    }
                    if (description) {
                        formData.append('description', description);
                    }
                    setter('f_name', f_name);
                    setter('i_name', i_name);
                    setter('o_name', o_name);
                    setter('phone', phone);
                    setter('email', email);
                    return {
                        url: "".concat(role, "/update/").concat(uuid),
                        method: 'POST',
                        body: formData
                    };
                },
                invalidatesTags: [{
                        type: 'Profile',
                        id: 'LIST'
                    }]
            })
        };
    }
});
var useGetCurrentProfileQuery = profileService.useGetCurrentProfileQuery, useChangeProfilePasswordMutation = profileService.useChangeProfilePasswordMutation, useEditProfileMutation = profileService.useEditProfileMutation, useDropProfilePhotoMutation = profileService.useDropProfilePhotoMutation;
export { useGetCurrentProfileQuery, useChangeProfilePasswordMutation, useEditProfileMutation, useDropProfilePhotoMutation };
