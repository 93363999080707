import * as React from 'react';
import { isSelectedRole } from 'modules';
import { useGetKnowlegesQuery } from 'services/knowledgeField';
import { useTypedSelector } from 'store/store';
export var useKnowledgeOptions = function useKnowledgeOptions(_ref) {
    var directionId = _ref.directionId, indexOptions = _ref.indexOptions;
    var role = useTypedSelector(isSelectedRole);
    var _useGetKnowlegesQuery = useGetKnowlegesQuery({
        // "!" because of skip
        uuid: directionId,
        role: role
    }, {
        skip: !directionId
    }), knowledges = _useGetKnowlegesQuery.data;
    var options = React.useMemo(function () {
        return (knowledges || []).map(function (knowledge, index) {
            return {
                value: knowledge.id,
                title: "".concat(indexOptions ? "".concat(index + 1, ". ") : '').concat(knowledge.title)
            };
        });
    }, [indexOptions, knowledges]);
    return {
        knowledges: knowledges || [],
        options: options
    };
};
