import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    opened: false,
    title: '',
    message: '',
    isConfirmed: false
};
export var infoModalSlice = createSlice({
    name: 'infoModal',
    initialState: initialState,
    reducers: {
        openModal: function openModal(state, _ref) {
            var _ref$payload = _ref.payload, title = _ref$payload.title, message = _ref$payload.message;
            state.opened = true;
            state.title = title;
            state.message = message;
            state.isConfirmed = false;
        },
        closeModal: function closeModal(state) {
            state.opened = false;
            state.title = '';
            state.message = '';
            state.isConfirmed = false;
        }
    }
});
export var isSelectedInfoModal = function isSelectedInfoModal(state) {
    return state.infoModalReducer;
};
var _infoModalSlice$actio = infoModalSlice.actions, openModal = _infoModalSlice$actio.openModal, closeModal = _infoModalSlice$actio.closeModal;
export { openModal, closeModal };
export default infoModalSlice.reducer;
