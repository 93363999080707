import * as React from 'react';
import { COLOR } from 'uikit/vars';
import { getOptionId } from 'uikit/molecules/Select/utils/selectUtils';
var useKeyboardNavigation = function useKeyboardNavigation(_ref) {
    var onSelect = _ref.onSelect, options = _ref.options;
    var focusedOptionIndexRef = React.useRef(0);
    var optionsForFocusRef = React.useRef(options);
    React.useEffect(function () {
        optionsForFocusRef.current = options;
    }, [options]);
    React.useEffect(function () {
        var onKeyDown = function onKeyDown(e) {
            if (['ArrowDown', 'ArrowUp', 'Enter'].includes(e.code)) {
                e.preventDefault();
                e.stopPropagation();
                var currentOption = optionsForFocusRef.current[focusedOptionIndexRef.current];
                if (!Number.isInteger(focusedOptionIndexRef.current)) {
                    focusedOptionIndexRef.current = 0;
                }
                else if (e.code === 'ArrowDown' && optionsForFocusRef.current.length - 1 > focusedOptionIndexRef.current) {
                    focusedOptionIndexRef.current++;
                }
                else if (e.code === 'ArrowUp' && focusedOptionIndexRef.current > 0) {
                    focusedOptionIndexRef.current--;
                }
                var nextOption = optionsForFocusRef.current[focusedOptionIndexRef.current];
                if (['ArrowDown', 'ArrowUp'].includes(e.code)) {
                    var id = getOptionId(nextOption);
                    var nextElement = document.getElementById(id);
                    // "focus" next option
                    if (nextElement) {
                        nextElement.style.backgroundColor = "var(".concat(COLOR.PURPLE_600, ")");
                        nextElement.scrollIntoView({
                            behavior: 'smooth',
                            block: 'nearest'
                        });
                    }
                    // reset bg of prev "focused" option
                    if (currentOption) {
                        var currentId = getOptionId(currentOption);
                        var currentElement = document.getElementById(currentId);
                        if (currentElement && currentId !== id) {
                            currentElement.style.backgroundColor = 'transparent';
                        }
                    }
                }
                // select "focused" option
                if (e.code === 'Enter') {
                    onSelect(nextOption.value);
                }
            }
        };
        document.addEventListener('keydown', onKeyDown);
        return function () {
            document.removeEventListener('keydown', onKeyDown);
        };
    }, [onSelect, options]);
};
export default useKeyboardNavigation;
