var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;
function _taggedTemplateLiteral(strings, raw) { if (!raw) {
    raw = strings.slice(0);
} return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import styled from 'styled-components';
import { Button as _Button } from 'components/UI/atoms';
import { Input as _Input } from 'components/UI/molecules';
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: grid;\n  grid-gap: 16px;\n  align-items: baseline;\n"])));
export var InlineGroup = styled(Wrapper)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  grid-template-columns: 2fr 2fr 1fr;\n"])));
export var InlineGroupFirst = styled(Wrapper)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  grid-template-columns: 1fr 1fr 1fr 1fr;\n"])));
export var InlineGroupSecond = styled(Wrapper)(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  grid-template-columns: ", ";\n  ", "\n"])), function (_ref) {
    var isEdit = _ref.isEdit;
    return isEdit ? '2fr 1fr 1fr' : '1fr 1fr';
}, function (_ref2) {
    var isEdit = _ref2.isEdit;
    return isEdit ? "\n    align-items: end;\n  " : null;
});
export var Button = styled(_Button)(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  align-self: center;\n"])));
export var InputNearWithBtn = styled(_Input)(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  .error-message {\n    position: absolute;\n  }\n"])));
