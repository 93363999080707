export var getNumWord = function getNumWord(value, words) {
    var isShowValue = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
    var absValue = Math.abs(value) % 100;
    var num = absValue % 10;
    if (absValue > 10 && absValue < 20)
        return "".concat(isShowValue ? absValue : '', " ").concat(words[2]);
    if (num > 1 && num < 5)
        return "".concat(isShowValue ? absValue : '', " ").concat(words[1]);
    if (num === 1)
        return "".concat(isShowValue ? absValue : '', " ").concat(words[0]);
    return "".concat(isShowValue ? absValue : '', " ").concat(words[2]);
};
