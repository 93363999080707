export var checkIsSubDomain = function checkIsSubDomain() {
    var host = window.location.host;
    var hostname = window.location.hostname;
    var subdomain = host.substring(0, host.lastIndexOf("".concat(hostname === 'localhost' || hostname === '127.0.0.1' || hostname.substring(hostname.lastIndexOf('.')).slice(1) === 'localhost' ? '.' : '.prof')));
    var isSubdomain = !!subdomain;
    return {
        isSubdomain: isSubdomain,
        subdomain: subdomain
    };
};
