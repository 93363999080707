import * as Yup from 'yup';
import { createFormValidator, getValidationMessages } from 'utils/forms';
var getSchema = function getSchema() {
    var msg = getValidationMessages();
    return Yup.object({
        directionFieldId: Yup.string().required(msg.required),
        knowledgeFieldId: Yup.string().when('allKnowledgesSelected', {
            is: function is(allKnowledgesSelected) {
                return !allKnowledgesSelected;
            },
            then: function then(schema) {
                return schema.required(msg.required);
            }
        }),
        informationSourceId: Yup.object().when('allSourcesSelected', {
            is: function is(allSourcesSelected) {
                return !allSourcesSelected;
            },
            then: function then(schema) {
                return schema.required(msg.required);
            }
        })
    });
};
export default (function (values) {
    return createFormValidator(getSchema())(values);
});
