var _FUNCTIONAL_DIRECTION, _FUNCTIONAL_DIRECTION2;
function _typeof(obj) {
    "@babel/helpers - typeof";
    return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj);
}
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) {
    Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true });
}
else {
    obj[key] = value;
} return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null)
    return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) {
    var res = prim.call(input, hint || "default");
    if (_typeof(res) !== "object")
        return res;
    throw new TypeError("@@toPrimitive must return a primitive value.");
} return (hint === "string" ? String : Number)(input); }
export var FUNCTIONAL_DIRECTION_STATUS = {
    REQUEST: 10,
    APPROVED: 20,
    REJECTED: 30
};
export var FUNCTIONAL_DIRECTION_STATUS_TEXT = (_FUNCTIONAL_DIRECTION = {}, _defineProperty(_FUNCTIONAL_DIRECTION, FUNCTIONAL_DIRECTION_STATUS.REQUEST, 'Запрошено'), _defineProperty(_FUNCTIONAL_DIRECTION, FUNCTIONAL_DIRECTION_STATUS.APPROVED, 'Подтверждено'), _defineProperty(_FUNCTIONAL_DIRECTION, FUNCTIONAL_DIRECTION_STATUS.REJECTED, 'Отклонено'), _FUNCTIONAL_DIRECTION);
export var FUNCTIONAL_DIRECTION_STATUS_STATUS = (_FUNCTIONAL_DIRECTION2 = {}, _defineProperty(_FUNCTIONAL_DIRECTION2, FUNCTIONAL_DIRECTION_STATUS.REQUEST, 'yellow'), _defineProperty(_FUNCTIONAL_DIRECTION2, FUNCTIONAL_DIRECTION_STATUS.APPROVED, 'green'), _defineProperty(_FUNCTIONAL_DIRECTION2, FUNCTIONAL_DIRECTION_STATUS.REJECTED, 'red'), _FUNCTIONAL_DIRECTION2);
