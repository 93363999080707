var _templateObject, _templateObject2, _templateObject3, _templateObject4;
function _taggedTemplateLiteral(strings, raw) { if (!raw) {
    raw = strings.slice(0);
} return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import styled from 'styled-components';
import { Fonts } from 'types/TextProps';
import { COLORS } from 'constants/colors';
import { CheckTag as _CheckTag } from 'components/UI/atoms';
import { MEDIA } from 'uikit/vars';
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  align-items: self-start;\n  gap: 20px;\n  padding: 17px;\n\n  border: 1px solid ", ";\n\n  @media (min-width: ", ") {\n    display: grid;\n    grid-template-columns: 0.5fr 3fr 1.5fr 1.5fr 3fr;\n    align-items: center;\n\n    min-height: 64px;\n    padding: 17px 24px 17px 24px;\n  }\n"])), COLORS.PURPLE_400, MEDIA.DESKTOP_1);
export var Number = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  font-family: ", ";\n  font-size: 14px;\n  font-weight: 500;\n  line-height: 20px;\n  color: ", ";\n"])), Fonts.NotoSans, COLORS.LOGAN);
export var TagWrapper = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  display: flex;\n  gap: 8px;\n  align-items: center;\n  justify-content: space-between;\n\n  div {\n    font-family: ", ";\n    font-size: 14px;\n    font-weight: 500;\n    line-height: 20px;\n    color: ", ";\n  }\n"])), Fonts.NotoSans, COLORS.DARK_PURPLE_100);
export var CheckTag = styled(_CheckTag)(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  margin-left: 20px;\n"])));
