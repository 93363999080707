export var USER_TYPES = {
    TYPE_ADMIN: 10,
    TYPE_EXPERT: 20,
    TYPE_REDACTOR: 30
};
// const USER_TYPES_VALUES = {
//   [USER_TYPES.TYPE_ADMIN]: "Администратор",
//   [USER_TYPES.TYPE_EXPERT]: "Эксперт",
//   [USER_TYPES.TYPE_REDACTOR]: "Редактор",
// };
