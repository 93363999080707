var _templateObject;
function _taggedTemplateLiteral(strings, raw) { if (!raw) {
    raw = strings.slice(0);
} return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import styled from 'styled-components';
export var Wrapper = styled.svg(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  transform: ", ";\n"])), function (_ref) {
    var type = _ref.type;
    var rotateValue = '0';
    if (type === 'right') {
        rotateValue = '180deg';
    }
    else if (type === 'bottom') {
        rotateValue = '270deg';
    }
    return "rotate(".concat(rotateValue, ")");
});
