import { api } from 'services/api';
export var authApiSlice = api.injectEndpoints({
    endpoints: function endpoints(build) {
        return {
            login: build.mutation({
                query: function query(credentials) {
                    return {
                        url: '/login',
                        method: 'POST',
                        body: credentials
                    };
                }
            }),
            logout: build.mutation({
                query: function query() {
                    return {
                        url: 'logout',
                        method: 'POST'
                    };
                }
            }),
            resetPassword: build.mutation({
                query: function query(credentials) {
                    return {
                        url: 'password-reset',
                        method: 'POST',
                        body: credentials
                    };
                }
            }),
            getRole: build.query({
                query: function query() {
                    return {
                        url: 'role',
                        method: 'GET'
                    };
                }
            })
        };
    }
});
