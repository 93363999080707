var _templateObject, _templateObject2;
function _taggedTemplateLiteral(strings, raw) { if (!raw) {
    raw = strings.slice(0);
} return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import styled from 'styled-components';
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  cursor: pointer;\n\n  display: grid;\n  grid-auto-flow: column;\n  grid-gap: 8px;\n  align-items: center;\n  justify-self: end;\n\n  width: fit-content;\n"])));
export var Icon = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  ", "\n"])), function (_ref) {
    var opened = _ref.opened;
    return opened ? "& > img {\n    transform: rotate(180deg);\n  }" : null;
});
