var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7;
function _taggedTemplateLiteral(strings, raw) { if (!raw) {
    raw = strings.slice(0);
} return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import styled from 'styled-components';
import { COLORS } from 'constants/colors';
import { PAGE_WRAPPER } from 'constants/commonStyles';
export var PageWrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: center;\n  gap: 16px;\n\n  width: 100%;\n"])));
export var sidebarClass = "sidebarClass_s1e66ioh";
export var Inner = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  width: 100%;\n\n  overflow: hidden;\n"])));
export var Wrapper = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  ", ";\n"])), PAGE_WRAPPER);
export var HeaderWrapper = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  display: flex;\n  flex-wrap: wrap;\n  align-items: center;\n  justify-content: space-between;\n  gap: 20px;\n\n  width: 100%;\n"])));
export var ButtonWrapper = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  display: grid;\n  grid-gap: 16px;\n  grid-template-columns: 1fr 1fr;\n  justify-self: end;\n\n  @media screen and (max-width: 1360px) {\n    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));\n  }\n"])));
export var FilterWrapper = styled.div(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  display: grid;\n  grid-template-columns: ", ";\n  align-items: center;\n"])), function (_ref) {
    var isHasAdditionalFilters = _ref.isHasAdditionalFilters;
    return isHasAdditionalFilters ? '2.5fr 2fr 2fr 2fr 2fr 1.5fr' : '3fr 2fr 2fr 1.5fr';
});
export var ToggleWrapper = styled.div(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  display: flex;\n  gap: 8px;\n  align-items: center;\n\n  padding: 8px;\n\n  white-space: nowrap;\n\n  border-radius: 4px;\n  outline: 1px solid ", ";\n"])), COLORS.PURPLE_400);
require("./styled.linaria.css!=!../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./styled.ts");
