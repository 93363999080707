var _excluded = ["uuid", "domain_code"], _excluded2 = ["uuid", "domain_code"], _excluded3 = ["assignment_uuid", "question_uuid"];
function _objectWithoutProperties(source, excluded) { if (source == null)
    return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);
    for (i = 0; i < sourceSymbolKeys.length; i++) {
        key = sourceSymbolKeys[i];
        if (excluded.indexOf(key) >= 0)
            continue;
        if (!Object.prototype.propertyIsEnumerable.call(source, key))
            continue;
        target[key] = source[key];
    }
} return target; }
function _objectWithoutPropertiesLoose(source, excluded) { if (source == null)
    return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0)
        continue;
    target[key] = source[key];
} return target; }
import { api } from './api';
var url = 'employee';
export var employeeTesting = api.injectEndpoints({
    endpoints: function endpoints(build) {
        return {
            getEmployeeTestingData: build.query({
                query: function query(_ref) {
                    var code = _ref.code, domain_code = _ref.domain_code;
                    return {
                        url: "".concat(process.env.PROTOCOL, "://").concat(domain_code, ".").concat(process.env.HOST, "/api/employee/").concat(code),
                        method: 'GET'
                    };
                },
                providesTags: [{
                        type: 'EmployeeTesting',
                        id: 'LIST'
                    }]
            }),
            getEmployeeTestInfo: build.query({
                query: function query(_ref2) {
                    var uuid = _ref2.uuid, domain_code = _ref2.domain_code, params = _objectWithoutProperties(_ref2, _excluded);
                    return {
                        url: "".concat(process.env.PROTOCOL, "://").concat(domain_code, ".").concat(process.env.HOST, "/api/employee/test/").concat(uuid),
                        method: 'GET',
                        params: params
                    };
                },
                providesTags: [{
                        type: 'EmployeeTesting',
                        id: 'TEST'
                    }]
            }),
            getEmployeeTestPreview: build.query({
                query: function query(_ref3) {
                    var uuid = _ref3.uuid, domain_code = _ref3.domain_code, params = _objectWithoutProperties(_ref3, _excluded2);
                    return {
                        url: "".concat(process.env.PROTOCOL, "://").concat(domain_code, ".").concat(process.env.HOST, "/api/employee/test/").concat(uuid, "/preview"),
                        method: 'GET',
                        params: params
                    };
                },
                providesTags: [{
                        type: 'EmployeeTesting',
                        id: 'TEST'
                    }]
            }),
            answerQuestion: build.mutation({
                query: function query(_ref4) {
                    var assignment_uuid = _ref4.assignment_uuid, question_uuid = _ref4.question_uuid, body = _objectWithoutProperties(_ref4, _excluded3);
                    return {
                        url: "".concat(url, "/test/").concat(assignment_uuid, "/").concat(question_uuid),
                        method: 'POST',
                        body: body
                    };
                },
                invalidatesTags: [{
                        type: 'EmployeeTesting',
                        id: 'LIST'
                    }, {
                        type: 'EmployeeTesting',
                        id: 'TEST'
                    }]
            }),
            finishTesting: build.mutation({
                query: function query(assignment_uuid) {
                    return {
                        url: "".concat(url, "/test/").concat(assignment_uuid, "/finished"),
                        method: 'GET'
                    };
                },
                invalidatesTags: [{
                        type: 'EmployeeTesting',
                        id: 'LIST'
                    }]
            }),
            getAnsweredQuestionInfo: build.query({
                query: function query(assignment_question_id) {
                    return {
                        url: "".concat(url, "/test/active-question/").concat(assignment_question_id),
                        method: 'GET'
                    };
                }
            })
        };
    }
});
var useGetEmployeeTestingDataQueryState = employeeTesting.endpoints.getEmployeeTestingData.useQueryState;
export { useGetEmployeeTestingDataQueryState };
var useGetEmployeeTestingDataQuery = employeeTesting.useGetEmployeeTestingDataQuery, useGetEmployeeTestInfoQuery = employeeTesting.useGetEmployeeTestInfoQuery, useLazyGetEmployeeTestInfoQuery = employeeTesting.useLazyGetEmployeeTestInfoQuery, useAnswerQuestionMutation = employeeTesting.useAnswerQuestionMutation, useFinishTestingMutation = employeeTesting.useFinishTestingMutation, useGetAnsweredQuestionInfoQuery = employeeTesting.useGetAnsweredQuestionInfoQuery, useGetEmployeeTestPreviewQuery = employeeTesting.useGetEmployeeTestPreviewQuery;
export { useGetEmployeeTestingDataQuery, useGetEmployeeTestInfoQuery, useLazyGetEmployeeTestInfoQuery, useAnswerQuestionMutation, useFinishTestingMutation, useGetAnsweredQuestionInfoQuery, useGetEmployeeTestPreviewQuery };
