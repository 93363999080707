function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o)
    return; if (typeof o === "string")
    return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor)
    n = o.constructor.name; if (n === "Map" || n === "Set")
    return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n))
    return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length)
    len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++)
    arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) {
    var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1;
    try {
        if (_x = (_i = _i.call(arr)).next, 0 === i) {
            if (Object(_i) !== _i)
                return;
            _n = !1;
        }
        else
            for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0)
                ;
    }
    catch (err) {
        _d = !0, _e = err;
    }
    finally {
        try {
            if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r))
                return;
        }
        finally {
            if (_d)
                throw _e;
        }
    }
    return _arr;
} }
function _arrayWithHoles(arr) { if (Array.isArray(arr))
    return arr; }
import { useCallback, useMemo, useState } from 'react';
import debounce from 'lodash.debounce';
import { useSearchParams } from 'react-router-dom';
export var useDeboucedQuery = function useDeboucedQuery() {
    var isSaveInQueryString = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
    var _useSearchParams = useSearchParams(), _useSearchParams2 = _slicedToArray(_useSearchParams, 2), searchParams = _useSearchParams2[0], setSearchParams = _useSearchParams2[1];
    var queryFromSearchParams = isSaveInQueryString ? searchParams.get('query') : '';
    var searchFromSearchParams = isSaveInQueryString ? searchParams.get('query') : '';
    var _useState = useState(queryFromSearchParams !== null && queryFromSearchParams !== void 0 ? queryFromSearchParams : ''), _useState2 = _slicedToArray(_useState, 2), query = _useState2[0], setQuery = _useState2[1];
    var _useState3 = useState(searchFromSearchParams !== null && searchFromSearchParams !== void 0 ? searchFromSearchParams : ''), _useState4 = _slicedToArray(_useState3, 2), search = _useState4[0], setSearch = _useState4[1];
    var handleUpdateQuery = useMemo(function () {
        return debounce(function (str) {
            return setQuery(str);
        }, 800);
    }, [setQuery]);
    var handleUpdateSearch = useCallback(function (e) {
        setSearch(e.target.value);
        handleUpdateQuery(e.target.value);
        if (isSaveInQueryString) {
            searchParams.set('query', e.target.value);
            setSearchParams(searchParams);
        }
    }, [handleUpdateQuery, isSaveInQueryString, searchParams, setSearchParams]);
    return {
        query: query,
        search: search,
        handleUpdateSearch: handleUpdateSearch
    };
};
