import { useDefineSubdomain } from 'hooks/useDefineSubdomain';
import { employeeTestingApiSlice } from '../endpoints';
export var useEmployee = function useEmployee(_ref) {
    var employeeCode = _ref.employeeCode;
    var _useDefineSubdomain = useDefineSubdomain(), isSubdomain = _useDefineSubdomain.isSubdomain, subdomain = _useDefineSubdomain.subdomain;
    var _employeeTestingApiSl = employeeTestingApiSlice.useGetEmployeeTestingDataQuery({
        code: employeeCode,
        domain_code: subdomain
    }, {
        skip: !employeeCode || !isSubdomain
    }), employeeProfile = _employeeTestingApiSl.currentData;
    var employee = employeeProfile === null || employeeProfile === void 0 ? void 0 : employeeProfile[0];
    return {
        employee: employee
    };
};
