import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    opened: false,
    title: '',
    message: '',
    isConfirmed: false
    // callback: () => {},
};
export var modalSlice = createSlice({
    name: 'modal',
    initialState: initialState,
    reducers: {
        openModal: function openModal(state, _ref) {
            var _ref$payload = _ref.payload, title = _ref$payload.title, message = _ref$payload.message;
            state.opened = true;
            state.title = title;
            state.message = message;
            state.isConfirmed = false;
            // state.callback = callback;
        },
        closeModal: function closeModal(state) {
            state.opened = false;
            state.title = '';
            state.message = '';
            state.isConfirmed = false;
            // state.callback = () => {};
        },
        confirmModal: function confirmModal(state, _ref2) {
            var payload = _ref2.payload;
            state.isConfirmed = payload;
            state.opened = false;
            state.title = '';
            state.message = '';
        }
    }
});
export var isSelectedModal = function isSelectedModal(state) {
    return state.modalReducer;
};
var _modalSlice$actions = modalSlice.actions, openModal = _modalSlice$actions.openModal, closeModal = _modalSlice$actions.closeModal, confirmModal = _modalSlice$actions.confirmModal;
export { openModal, closeModal, confirmModal };
export default modalSlice.reducer;
