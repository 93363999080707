var _templateObject, _templateObject2, _templateObject3, _templateObject4;
function _taggedTemplateLiteral(strings, raw) { if (!raw) {
    raw = strings.slice(0);
} return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import styled from 'styled-components';
import { COLORS } from 'constants/colors';
import { Fonts } from 'types/TextProps';
import { CheckTag as _CheckTag } from 'components/UI/atoms';
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n\n  padding: 24px;\n\n  background: ", ";\n  border-radius: 8px;\n"])), COLORS.PURPLE_600);
export var Header = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n"])));
export var Title = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  font-family: ", ";\n  font-size: 14px;\n  font-weight: 500;\n  line-height: 20px;\n  color: ", ";\n"])), Fonts.Montserrat, COLORS.PURPLE_100);
export var CheckTag = styled(_CheckTag)(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  width: max-content;\n"])));
