var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10;
function _taggedTemplateLiteral(strings, raw) { if (!raw) {
    raw = strings.slice(0);
} return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import styled, { css } from 'styled-components';
import { Fonts } from 'types/TextProps';
import { COLORS } from 'constants/colors';
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  position: relative;\n\n  display: flex;\n  flex-direction: column;\n  gap: 2px;\n\n  border-radius: 8px;\n"])));
export var HeaderWrapper = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: grid;\n  grid-column-gap: 20px;\n  grid-template-columns: ", ";\n  align-items: center;\n\n  padding: 8px 24px 8px 24px;\n\n  word-break: break-word;\n\n  background: ", ";\n"])), function (_ref) {
    var colSize = _ref.colSize;
    return colSize;
}, COLORS.PURPLE_100);
export var HeaderIconWrapper = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  display: flex;\n  gap: 3px;\n  align-items: center;\n"])));
export var Header = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  font-family: ", ";\n  font-size: 14px;\n  font-weight: 500;\n  line-height: 20px;\n  color: ", ";\n"])), Fonts.NotoSans, COLORS.WHITE);
export var RowWrapper = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  display: grid;\n  grid-column-gap: 20px;\n  grid-template-columns: ", ";\n  align-items: center;\n\n  height: ", ";\n  padding: 16px 24px 16px 24px;\n\n  border: 1px solid ", ";\n  &:nth-child(odd) {\n    background: ", ";\n  }\n"])), function (_ref2) {
    var rowSize = _ref2.rowSize;
    return rowSize;
}, function (props) {
    return props.rowHeight ? "".concat(props.rowHeight, "px") : 'auto';
}, COLORS.PURPLE_400, function (_ref3) {
    var noBackground = _ref3.noBackground;
    return !noBackground ? COLORS.PURPLE_600 : 'none';
});
export var Row = styled.div(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  cursor: ", ";\n\n  font-family: ", ";\n  font-size: 14px;\n  font-weight: 500;\n  line-height: 20px;\n  hyphens: auto;\n  word-break: break-word;\n"])), function (_ref4) {
    var onClick = _ref4.onClick;
    return onClick ? 'pointer' : 'default';
}, Fonts.NotoSans);
export var ScrollWrapper = styled.div(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  ", "\n"])), function (props) {
    return props.withScroll && css(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n      & > div {\n        min-width: ", ";\n      }\n    "])), props.minItemWidthInScroll ? "".concat(props.minItemWidthInScroll, "px") : '700px');
});
export var TableWrapper = styled.div(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  overflow-x: auto;\n  overflow-y: hidden;\n  display: grid;\n  grid-auto-flow: row;\n  grid-gap: 40px;\n  align-items: center;\n\n  padding-bottom: 20px;\n\n  // &::-webkit-scrollbar {\n  //   height: 8px;\n  // }\n\n  // &::-webkit-scrollbar-thumb {\n  //   background: ", ";\n  //   border-radius: 4px;\n  // }\n"])), COLORS.LOGAN);
export var ElementsWrapper = styled.div(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n  overflow: hidden;\n\n  font-family: ", ";\n  font-size: 14px;\n  font-weight: 500;\n  line-height: 20px;\n  hyphens: auto;\n  color: ", ";\n  word-break: break-word;\n  &:nth-child(odd) {\n    background: ", ";\n  }\n"])), Fonts.NotoSans, COLORS.DARK_PURPLE_100, function (_ref5) {
    var noBackground = _ref5.noBackground;
    return !noBackground ? COLORS.PURPLE_600 : 'none';
});
