var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8;
function _taggedTemplateLiteral(strings, raw) { if (!raw) {
    raw = strings.slice(0);
} return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import styled from 'styled-components';
import { COLORS } from 'constants/colors';
import { PAGE_WRAPPER } from 'constants/commonStyles';
export var PageWrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  width: 100%;\n"])));
export var Wrapper = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  ", ";\n  border-radius: 8px;\n"])), PAGE_WRAPPER);
export var AvatarWrapper = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  position: relative;\n\n  overflow: hidden;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  width: 300px;\n  height: auto;\n\n  background-color: #f0f0f0;\n  border-radius: 4px;\n"])));
export var Avatar = styled.img(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  display: block;\n  width: 100%;\n  height: auto;\n  border-radius: 4px;\n"])));
export var DefaultAvatar = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  width: 100%;\n  height: 200px;\n  background-color: ", ";\n  border-radius: 4px;\n"])), COLORS.GRAY_500);
export var IconsWrapper = styled.div(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  position: absolute;\n  top: 10px;\n  right: 10px;\n\n  display: grid;\n  grid-gap: 10px;\n"])));
export var ButtonWrapper = styled.div(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  cursor: pointer;\n\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  width: 40px;\n  height: 40px;\n\n  background: ", ";\n  border-radius: 50%;\n"])), COLORS.WHITE);
export var FooterButtonsWrapper = styled.div(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  display: grid;\n  grid-gap: 20px;\n  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));\n"])));
