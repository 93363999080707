var labelsCollection = {
    email: 'E-mail',
    password: 'Пароль',
    own_type: 'Форма собственности',
    domain_code: 'Домен клиента',
    address: 'Почтовый адрес',
    inn: 'ИНН',
    bik: 'БИК',
    kpp: 'КПП',
    checking_account: '№ Р/С',
    bank: 'Банк',
    correspondent_account: 'К/С',
    okpo: 'ОКПО',
    okato: 'ОКАТО',
    ogrn: 'ОГРН',
    director_fio: 'Генеральный директор',
    director_phone: 'Телефон',
    director_email: 'Электронная почта',
    director_site: 'Сайт',
    admin_email: 'E-mail Администратора',
    admin_f_name: 'ФИО Администратора',
    admin_i_name: 'ФИО Администратора',
    admin_o_name: 'ФИО Администратора',
    f_name: 'Фамилия',
    i_name: 'Имя',
    o_name: 'Отчество',
    phone: 'Телефон',
    photo_file: 'Фотография',
    birthday_date: 'Дата рождения',
    profession: 'Профессия',
    position: 'Должность',
    description: 'Дополнительная информация',
    functional_direction: 'Функциональное направление',
    title: 'Название',
    functional_direction_id: 'Функциональное направление',
    source_type: 'Тип источника',
    authors_fio_list: 'Авторы',
    publish_year: 'Год издания',
    url_file: 'Ссылка на источник',
    'knowledge_field_ids[]': 'Область знаний',
    pdf_upload: 'pdf-файл',
    doc_upload: 'docx-файл',
    answers: 'Ответы',
    text: 'Текст вопроса',
    question_type: 'Типы вопросов',
    complexity_level: 'Уровень сложности вопроса',
    author_fio: 'Вопрос внес в систему',
    answer_right_text: 'Ответ',
    information_source_id: 'Источник вопроса',
    image_delete: 'Изображение',
    xls_upload: 'xls-файл',
    knowledge_field_ids: 'Область знаний',
    head_id: 'id руководителя',
    fio: 'ФИО',
    personal_number: 'Табельный номер',
    head_fio: 'ФИО руководителя',
    head_position: '',
    head_personal_number: 'Табельный номер руководителя',
    head_email: 'E-mail руководителя',
    position_id: 'ID штатной должности',
    head_position_id: 'ID штатной должности Руководителя',
    department: 'Подразделение',
    deadline_date: 'Дедлайн',
    employee_ids: 'Сотрудники',
    answer_ids: 'Ответы'
};
export var handleErrorMessageByKey = function handleErrorMessageByKey(data) {
    return data.map(function (data) {
        return data.errors.map(function (errorMsg) {
            var searchValue = /[.\d]/g.test(data.name) ? ' ' : '_';
            var dataNameWithSpaces = data.name.replaceAll(searchValue, ' ');
            if (Object.keys(labelsCollection).includes(data.name)) {
                return errorMsg.replace(dataNameWithSpaces, "\"".concat(labelsCollection[data.name], "\""));
            }
            var findedKey = Object.keys(labelsCollection).find(function (key) {
                return !data.name.indexOf(key);
            });
            if (!Object.keys(labelsCollection).includes(data.name) && findedKey) {
                return errorMsg.replace(dataNameWithSpaces, "\"".concat(labelsCollection[findedKey], "\""));
            }
            return errorMsg;
        });
    }).join(' ');
};
