var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;
function _taggedTemplateLiteral(strings, raw) { if (!raw) {
    raw = strings.slice(0);
} return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import styled, { css } from 'styled-components';
import { COLORS } from 'constants/colors';
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  position: relative;\n\n  ", "\n  display: flex;\n  flex-wrap: nowrap;\n  align-items: center;\n\n  min-height: 48px;\n  padding: 5px 0 5px 9px;\n\n  :hover {\n    ", "\n  }\n\n  ", "\n"])), function (_ref) {
    var disabled = _ref.disabled;
    return disabled && css(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n      cursor: default;\n      font-size: 17px;\n      font-weight: 700;\n      color: ", ";\n    "])), COLORS.PURPLE_100);
}, function (_ref2) {
    var disabled = _ref2.disabled;
    return !disabled && css(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n        cursor: pointer;\n        background: #f5f5f5;\n        border-radius: 4px;\n      "])));
}, function (_ref3) {
    var checked = _ref3.checked;
    return checked && css(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n      cursor: pointer;\n\n      font-size: 16px;\n      font-weight: 700;\n      line-height: 24px;\n      color: ", ";\n\n      border-radius: 4px;\n    "])), COLORS.PURPLE_100);
});
export var LabelContainer = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  display: flex;\n  gap: 20px;\n  align-items: center;\n  p {\n    flex: 1 1 auto;\n  }\n"])));
export var Label = styled.div(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  flex: 0 0 18px;\n  width: 18px;\n  height: 18px;\n  background: ", ";\n"])), function (_ref4) {
    var color = _ref4.color;
    return color;
});
