import { MEDIA } from 'uikit/vars';
import { COLORS } from './colors';
export var PAGE_WRAPPER = "\n  display: flex;\n  flex-direction: column;\n  gap: 30px;\n  padding: 20px;\n  width: 100%;\n  min-height: 837px;\n\n  border-radius: 0 0 8px 8px;\n  background: ".concat(COLORS.WHITE, ";\n  box-shadow: 40px 40px 60px rgba(0, 0, 0, 0.1);\n\n  @media (min-width: ").concat(MEDIA.DESKTOP_1, ") {\n    display: grid;\n    grid-auto-rows: min-content;\n    grid-gap: 40px;  \n    padding: 40px 40px 77px 40px;\n  }\n");
export var WRAPPER = {
    display: 'grid',
    gridTemplateColumns: 'minmax(229px, 1fr) 5fr',
    justifyContent: 'center',
    width: '100%',
    gridGap: '16px'
};
export var MAIN_WRAPPER = {
    minHeight: '837px',
    gridAutoRows: 'min-content',
    width: '100%'
};
