var _templateObject, _templateObject2, _templateObject3;
function _taggedTemplateLiteral(strings, raw) { if (!raw) {
    raw = strings.slice(0);
} return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import styled from 'styled-components';
import { Fonts } from 'types/TextProps';
import approve from './presets/approve';
import danger from './presets/danger';
import dismiss from './presets/dismiss';
import primary from './presets/primary';
import progress from './presets/progress';
import reject from './presets/reject';
import error from './presets/error';
import secondary from './presets/secondary';
import tertiaryDark from './presets/tertiaryDark';
import tertiaryLight from './presets/tertiaryLight';
export var Wrapper = styled.button(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n  grid-gap: 10px;\n  align-items: center;\n  justify-content: center;\n\n  width: ", ";\n  height: 48px;\n  padding: 8px 20px;\n\n  font-family: ", ";\n  font-size: 14px;\n  font-weight: 500;\n  line-height: 24px;\n  letter-spacing: 0.02em;\n  white-space: nowrap;\n\n  border-radius: 5px;\n\n  transition: background-color 0.3s;\n\n  &:hover {\n    cursor: pointer;\n  }\n\n  &:disabled {\n    cursor: not-allowed;\n  }\n\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), function (_ref) {
    var width = _ref.width;
    return width !== null && width !== void 0 ? width : 'auto';
}, Fonts.NotoSans, function (_ref2) {
    var variant = _ref2.variant;
    return variant === 'secondary' && secondary;
}, function (_ref3) {
    var variant = _ref3.variant;
    return variant === 'primary' && primary;
}, function (_ref4) {
    var variant = _ref4.variant;
    return variant === 'danger' && danger;
}, function (_ref5) {
    var variant = _ref5.variant;
    return variant === 'tertiaryLight' && tertiaryLight;
}, function (_ref6) {
    var variant = _ref6.variant;
    return variant === 'tertiaryDark' && tertiaryDark;
}, function (_ref7) {
    var variant = _ref7.variant;
    return variant === 'reject' && reject;
}, function (_ref8) {
    var variant = _ref8.variant;
    return variant === 'dismiss' && dismiss;
}, function (_ref9) {
    var variant = _ref9.variant;
    return variant === 'approve' && approve;
}, function (_ref10) {
    var variant = _ref10.variant;
    return variant === 'progress' && progress;
}, function (_ref11) {
    var variant = _ref11.variant;
    return variant === 'error' && error;
});
var Left = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"])));
var Right = styled(Left)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  margin-right: 0;\n"])));
export var Icon = {
    Left: Left,
    Right: Right
};
