var converter = function converter(num) {
    return num < 10 ? "0".concat(num) : num;
};
var parseDate = function parseDate(arg) {
    var date = typeof arg === 'string' ? new Date(new Date(arg.toString()).toISOString().split('T')[0]) : new Date(arg.toString());
    var day = date.getDate().toString().padStart(2, '0');
    var month = (date.getMonth() + 1).toString().padStart(2, '0');
    var year = date.getFullYear().toString();
    var hours = converter(date.getHours());
    var minutes = converter(date.getMinutes());
    return {
        day: day,
        month: month,
        year: year,
        hours: hours,
        minutes: minutes
    };
};
export var getFormattedDate = function getFormattedDate(arg, isFullYear, format) {
    var _parseDate = parseDate(arg), hours = _parseDate.hours, minutes = _parseDate.minutes, year = _parseDate.year, month = _parseDate.month, day = _parseDate.day;
    return {
        time: "".concat(hours, ":").concat(minutes),
        date: format === 'dd.mm.yy' ? "".concat(day, ".").concat(month, ".").concat(isFullYear ? year : year.substring(2)) : "".concat(isFullYear ? year : year.substring(2), "-").concat(month, "-").concat(day)
    };
};
