var _templateObject, _templateObject2, _templateObject3;
function _taggedTemplateLiteral(strings, raw) { if (!raw) {
    raw = strings.slice(0);
} return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import styled from 'styled-components';
import { Fonts } from 'types/TextProps';
import { COLORS } from 'constants/colors';
export var AdditionWrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  position: absolute;\n  left: 100%;\n\n  display: grid;\n  grid-template-columns: 2fr 1fr;\n  align-items: center;\n\n  width: 40%;\n  height: 100%;\n  margin-left: 1px;\n  padding: 0 18px;\n\n  background: ", ";\n"])), COLORS.ALERT_RED);
export var AdditionText = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  font-family: ", ";\n  font-size: 14px;\n  font-weight: 500;\n  line-height: 20px;\n"])), Fonts.NotoSans);
export var ButtonsGroup = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  display: grid;\n  grid-template-columns: minmax(60px, 1fr) minmax(60px, 1fr);\n  gap: 8px;\n  align-items: center;\n"])));
