import * as Yup from 'yup';
export var getValidationMessages = function getValidationMessages() {
    return {
        format: 'Некорректный формат',
        email: 'Некорректный емейл',
        phone: 'Некорректный номер телефона',
        required: 'Поле обязательно'
    };
};
export var getMinSymbolsMsg = function getMinSymbolsMsg(max) {
    return "\u041C\u0438\u043D\u0438\u043C\u0430\u043B\u044C\u043D\u043E ".concat(max, " \u0441\u0438\u043C\u0432.");
};
export var getMaxSymbolsMsg = function getMaxSymbolsMsg(max) {
    return "\u041C\u0430\u043A\u0441\u0438\u043C\u0430\u043B\u044C\u043D\u043E ".concat(max, " \u0441\u0438\u043C\u0432.");
};
export var getCommonEmailSchema = function getCommonEmailSchema() {
    var msg = getValidationMessages();
    return Yup.string().email(msg.email).test({
        message: msg.email,
        test: function test(to) {
            return !to || /^.+@.+\..+$/.test(to);
        }
    }).trim(msg.format);
};
