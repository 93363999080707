import * as React from 'react';
import { useDefineSubdomain } from 'hooks/useDefineSubdomain';
import { profilesApiSlice } from 'modules';
export var useProfilesOptions = function useProfilesOptions() {
    var _useDefineSubdomain = useDefineSubdomain(), subdomain = _useDefineSubdomain.subdomain;
    var _profilesApiSlice$use = profilesApiSlice.useGetProfilesQuery({
        subdomain: subdomain
    }), profilesData = _profilesApiSlice$use.data;
    var options = React.useMemo(function () {
        if (!profilesData)
            return [];
        return profilesData.data.map(function (profile) {
            return {
                value: profile.id,
                title: profile.title
            };
        });
    }, [profilesData]);
    return {
        profiles: (profilesData === null || profilesData === void 0 ? void 0 : profilesData.data) || [],
        options: options
    };
};
