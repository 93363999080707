import * as Yup from 'yup';
import { getValidationMessages } from './validations';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function checkPhoneNumber() {
    return this.test({
        name: 'checkPhoneNumber',
        exclusive: false,
        message: getValidationMessages().phone,
        test: function test(to) {
            return !to || /^\+\d{9,18}$/.test(to);
        }
    });
}
export var initializeValidationMethods = function initializeValidationMethods() {
    Yup.addMethod(Yup.string, 'checkPhoneNumber', checkPhoneNumber);
};
