export var convertStringToTimer = function convertStringToTimer(seconds) {
    var hours = Math.floor(seconds / 3600);
    var minutes = Math.floor(seconds % 3600 / 60);
    var remainingSeconds = seconds % 60;
    return {
        hours: +hours.toString().padStart(2, '0'),
        minutes: +minutes.toString().padStart(2, '0'),
        seconds: +remainingSeconds.toString().padStart(2, '0')
    };
};
