var _templateObject, _templateObject2, _templateObject3, _templateObject4;
function _taggedTemplateLiteral(strings, raw) { if (!raw) {
    raw = strings.slice(0);
} return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import styled from 'styled-components';
import { PAGE_WRAPPER, WRAPPER } from 'constants/commonStyles';
export var PageWrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  ", "\n"])), WRAPPER);
export var Wrapper = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  ", ";\n"])), PAGE_WRAPPER);
export var HeaderWrapper = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  display: grid;\n  grid-gap: 40px;\n  grid-template-columns: 3fr minmax(149px, 0.5fr);\n"])));
export var FilterWrapper = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  display: grid;\n  grid-template-columns: 2fr 1fr 1fr;\n"])));
