var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;
function _taggedTemplateLiteral(strings, raw) { if (!raw) {
    raw = strings.slice(0);
} return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import styled from 'styled-components';
import { PAGE_WRAPPER, WRAPPER } from 'constants/commonStyles';
export var PageWrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  ", "\n  grid-auto-rows: min-content;\n  min-height: 885px;\n"])), function (_ref) {
    var isSubdomain = _ref.isSubdomain;
    return isSubdomain ? WRAPPER : 'width: 100%;';
});
export var Wrapper = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  ", ";\n"])), PAGE_WRAPPER);
export var ButtonWrapper = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  display: flex;\n  flex-wrap: wrap;\n  align-items: center;\n  gap: 16px;\n"])));
export var FilterWrapper = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  display: grid;\n  grid-template-columns: 3fr 1.5fr 1.5fr;\n  grid-template-columns: ", ";\n"])), function (_ref2) {
    var isSubdomain = _ref2.isSubdomain;
    return isSubdomain ? '3fr 1.5fr' : '3fr 1.5fr 1.5fr';
});
export var IconsGroup = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  display: grid;\n  grid-gap: 20px;\n  grid-template-columns: 1fr 1fr 1fr 1fr;\n  align-items: center;\n  justify-self: end;\n\n  & > img {\n    cursor: pointer;\n  }\n"])));
