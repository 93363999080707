import * as Yup from 'yup';
import { createFormValidator, getValidationMessages } from 'utils/forms';
var getSchema = function getSchema() {
    var msg = getValidationMessages();
    return Yup.object({
        specialization: Yup.object().required(msg.required),
        direction: Yup.object().required(msg.required),
        knowledge: Yup.object().required(msg.required)
    });
};
export default (function (values) {
    return createFormValidator(getSchema())(values);
});
