var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8;
function _taggedTemplateLiteral(strings, raw) { if (!raw) {
    raw = strings.slice(0);
} return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import styled from 'styled-components';
import { Fonts } from 'types/TextProps';
import { COLORS } from 'constants/colors';
export var FormWrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: grid;\n  grid-gap: 16px;\n  align-items: center;\n"])));
export var Wrapper = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: grid;\n  grid-auto-flow: row;\n  align-items: center;\n\n  background: ", ";\n  border-radius: 16px;\n"])), COLORS.PURPLE_600);
export var SelectionWrapper = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  display: grid;\n  grid-template-columns: 1fr 2fr;\n  align-items: center;\n\n  padding: 24px;\n\n  border-bottom: 1px solid ", ";\n"])), COLORS.PURPLE_400);
export var EmployeeHeader = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  display: grid;\n  grid-template-columns: 2fr 1fr 1fr;\n  align-items: center;\n\n  padding: 8px 24px;\n\n  border-bottom: 1px solid ", ";\n"])), COLORS.PURPLE_400);
export var EmployeeWrapper = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  display: grid;\n  grid-gap: 20px;\n  padding: 20px 20px 20px 24px;\n"])));
export var Option = styled.div(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  cursor: pointer;\n\n  font-family: ", ";\n  font-size: 16px;\n  font-weight: 400;\n  line-height: 24px;\n  color: ", ";\n"])), Fonts.NotoSans, COLORS.PURPLE_100);
export var Row = styled.div(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  display: grid;\n  grid-template-columns: 2fr 1fr 1fr;\n  align-items: center;\n"])));
export var PositionName = styled.div(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  font-family: ", ";\n  font-size: 16px;\n  font-weight: 400;\n  line-height: 24px;\n  color: ", ";\n"])), Fonts.NotoSans, function (_ref) {
    var isHead = _ref.isHead;
    return isHead ? COLORS.DARK_PURPLE_100 : COLORS.LOGAN;
});
