function _typeof(obj) {
    "@babel/helpers - typeof";
    return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj);
}
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols);
} return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); });
} return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) {
    Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true });
}
else {
    obj[key] = value;
} return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null)
    return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) {
    var res = prim.call(input, hint || "default");
    if (_typeof(res) !== "object")
        return res;
    throw new TypeError("@@toPrimitive must return a primitive value.");
} return (hint === "string" ? String : Number)(input); }
import { useDispatch, useSelector } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { api } from 'services/api';
import exporterApi from 'services/exporter/exporterApi';
import { rtkQueryErrorLogger } from 'services/rtkQueryErrorLogger';
import { authSlice, AUTH_REDUCER_NAME } from 'modules';
import infoModalReducer from './reducers/infoModalSlice';
import modalReducer from './reducers/modalSlice';
export var createStore = function createStore(options) {
    var _reducer;
    return configureStore(_objectSpread({
        reducer: (_reducer = {}, _defineProperty(_reducer, api.reducerPath, api.reducer), _defineProperty(_reducer, exporterApi.reducerPath, exporterApi.reducer), _defineProperty(_reducer, AUTH_REDUCER_NAME, authSlice.reducer), _defineProperty(_reducer, "modalReducer", modalReducer), _defineProperty(_reducer, "infoModalReducer", infoModalReducer), _reducer),
        middleware: function middleware(getDefaultMiddleware) {
            return getDefaultMiddleware().concat(rtkQueryErrorLogger, api.middleware, exporterApi.middleware);
        },
        devTools: true
    }, options));
};
export var store = createStore();
export var useAppDispatch = useDispatch;
export var useTypedSelector = useSelector;
