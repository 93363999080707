var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;
function _taggedTemplateLiteral(strings, raw) { if (!raw) {
    raw = strings.slice(0);
} return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import styled from 'styled-components';
import { Fonts } from 'types/TextProps';
import { COLORS } from 'constants/colors';
import { Count as _Count } from 'components/UI/atoms';
export var StatisticsWrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: grid;\n  grid-gap: 32px;\n  align-items: baseline;\n\n  padding: 32px 101px 32px 32px;\n\n  background: ", ";\n  border-radius: 8px;\n"])), COLORS.PURPLE_400);
export var StatisticsText = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  font-family: ", ";\n  font-size: 24px;\n  font-weight: 500;\n  line-height: 32px;\n  color: ", ";\n"])), Fonts.Montserrat, COLORS.PURPLE_100);
export var StatiscticsData = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  display: grid;\n  grid-column-gap: 40px;\n  grid-row-gap: 8px;\n  grid-template-columns: repeat(3, 1fr);\n  grid-template-rows: repeat(2, 1fr);\n"])));
export var StatiscticsItem = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  display: flex;\n  gap: 35px;\n  align-items: center;\n  justify-content: space-between;\n"])));
export var StatisticsItemText = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  font-family: ", ";\n  font-size: 14px;\n  font-weight: 500;\n  line-height: 20px;\n  color: ", ";\n"])), Fonts.NotoSans, COLORS.WILD_BLUE_YONDER);
export var Count = styled(_Count)(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  font-family: ", ";\n  font-size: 14px;\n  font-weight: 600;\n  line-height: 20px;\n  color: ", ";\n\n  background: ", ";\n\n  &.percent {\n    color: ", ";\n    background: ", ";\n  }\n"])), Fonts.NotoSans, COLORS.DARK_PURPLE_100, COLORS.WHITE, COLORS.WHITE, COLORS.PURPLE_100);
