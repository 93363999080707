import * as React from 'react';
import { managerApiSlice } from 'modules';
export var useManagerDirectionOptions = function useManagerDirectionOptions(_ref) {
    var funcDirectionValue = _ref.funcDirectionValue;
    var _managerApiSlice$useG = managerApiSlice.useGetFunctionalDirectionsQuery(), funcDirectionsData = _managerApiSlice$useG.data;
    var _managerApiSlice$useG2 = managerApiSlice.useGetKnowledgeFieldsByFuncDirectionQuery({
        // "!" because of skip
        functionalDirectionId: funcDirectionValue
    }, {
        skip: !funcDirectionValue
    }), knowledgeFields = _managerApiSlice$useG2.data;
    var funcDirectionsOptions = React.useMemo(function () {
        if (!(funcDirectionsData !== null && funcDirectionsData !== void 0 && funcDirectionsData.data))
            return [];
        return funcDirectionsData === null || funcDirectionsData === void 0 ? void 0 : funcDirectionsData.data.map(function (funcDirection) {
            return {
                value: funcDirection.id,
                title: funcDirection.title
            };
        });
    }, [funcDirectionsData]);
    var knowledgeFieldsOptions = React.useMemo(function () {
        if (!knowledgeFields)
            return [];
        return knowledgeFields.map(function (knowledgeField) {
            return {
                value: knowledgeField.id,
                title: knowledgeField.title
            };
        });
    }, [knowledgeFields]);
    return {
        funcDirectionsOptions: funcDirectionsOptions,
        knowledgeFieldsOptions: knowledgeFieldsOptions
    };
};
