import * as Yup from 'yup';
import { createFormValidator, getCommonEmailSchema, getValidationMessages } from 'utils/forms';
var getSchema = function getSchema() {
    var msg = getValidationMessages();
    return Yup.object({
        name: Yup.string().required(msg.required),
        companyName: Yup.string().required(msg.required),
        email: getCommonEmailSchema().required(msg.required),
        phone: Yup.string().required(msg.required)
    });
};
export default (function (values) {
    return createFormValidator(getSchema())(values);
});
