var _templateObject, _templateObject2;
function _taggedTemplateLiteral(strings, raw) { if (!raw) {
    raw = strings.slice(0);
} return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import styled from 'styled-components';
import { Fonts } from 'types/TextProps';
import { MEDIA } from 'uikit/vars';
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  position: relative;\n\n  display: flex;\n  align-items: flex-start;\n  justify-content: space-between;\n\n  box-sizing: border-box;\n  padding: 16px 16px 0;\n\n  @media (min-width: ", ") {\n    padding: 32px 32px 0;\n  }\n"])), MEDIA.TABLET_1);
export var Title = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  font-family: ", ";\n  font-size: 24px;\n  font-weight: 600;\n  line-height: 30px;\n  text-align: ", ";\n\n  @media (min-width: ", ") {\n    font-size: 32px;\n    line-height: 40px;\n  }\n"])), Fonts.Montserrat, function (_ref) {
    var isTitleCenter = _ref.isTitleCenter;
    return isTitleCenter && 'center';
}, MEDIA.TABLET_1);
