import * as Yup from 'yup';
import { createFormValidator, getMinMsg, getValidationMessages } from 'utils/forms';
var getSchema = function getSchema() {
    var msg = getValidationMessages();
    return Yup.object({
        title: Yup.string().required(msg.required),
        maxQuestionsAmount: Yup.number().required(msg.required).min(5, getMinMsg(5)),
        maxMinutesAmount: Yup.number(),
        maxAttemptsAmount: Yup.number(),
        beginDate: Yup.date().required(msg.required),
        deadlineDate: Yup.date().required(msg.required)
    });
};
export default (function (values) {
    return createFormValidator(getSchema())(values);
});
