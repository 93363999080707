import { isRouteErrorResponse } from 'react-router-dom';
export var ERROR_CODES = {
    EMPLOYEE_NOT_FOUND: 'Employee not found'
};
export var getErrorTextByCode = function getErrorTextByCode(code) {
    switch (code) {
        case ERROR_CODES.EMPLOYEE_NOT_FOUND:
            return 'Работник не найден';
        default:
            return 'Произошла ошибка, попробуйте позже.';
    }
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export var getError = function getError(err) {
    var code = err.data.code;
    return {
        msg: getErrorTextByCode(code)
    };
};
export var getRenderError = function getRenderError(err) {
    if (isRouteErrorResponse(err) && err !== null && err !== void 0 && err.status && ![401].includes(err.status)) {
        var _err$data;
        throw new Response((_err$data = err.data) === null || _err$data === void 0 ? void 0 : _err$data.message, {
            status: err.status
        });
    }
};
