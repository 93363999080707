import { AUTH_REDUCER_NAME as NAME, ROLE } from './constants';
export var selectIsAuth = function selectIsAuth(state) {
    return state[NAME].isAuth;
};
// Role will always present if the user is authorized
export var isSelectedRole = function isSelectedRole(state) {
    return state[NAME].role;
};
export var isSelectedRedactor = function isSelectedRedactor(state) {
    return state[NAME].role === ROLE.redactor;
};
export var isSelectedExpert = function isSelectedExpert(state) {
    return state[NAME].role === ROLE.expert;
};
export var isSelectedEmployee = function isSelectedEmployee(state) {
    return state[NAME].role === ROLE.employee;
};
export var isSelectedAdmin = function isSelectedAdmin(state) {
    return state[NAME].role === ROLE.admin;
};
