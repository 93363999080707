import { VARIANTS as PROGRESS_UI_VARIANTS } from 'uikit/atoms/Progress/styled';
import { ANSWER_RESULT_PERCENTAGES_BORDERS, ANSWER_RESULT_TYPE } from './constants';
export var getAnswerResultType = function getAnswerResultType(percentage) {
    if (percentage < ANSWER_RESULT_PERCENTAGES_BORDERS[ANSWER_RESULT_TYPE.CRITICAL]) {
        return ANSWER_RESULT_TYPE.CRITICAL;
    }
    if (percentage < ANSWER_RESULT_PERCENTAGES_BORDERS[ANSWER_RESULT_TYPE.INSUFFICIENT]) {
        return ANSWER_RESULT_TYPE.INSUFFICIENT;
    }
    return ANSWER_RESULT_TYPE.APPROPRIATE;
};
export var getProgressStatusByAnswerResultType = function getProgressStatusByAnswerResultType(type) {
    if (type === ANSWER_RESULT_TYPE.CRITICAL) {
        return PROGRESS_UI_VARIANTS.error;
    }
    if (type === ANSWER_RESULT_TYPE.INSUFFICIENT) {
        return PROGRESS_UI_VARIANTS.warning;
    }
    return PROGRESS_UI_VARIANTS.success;
};
export var getAssignmentAccordance = function getAssignmentAccordance(assignments) {
    var questionsInfo = {
        correct: 0,
        total: 0
    };
    assignments.forEach(function (assignment) {
        var results = assignment.assignment_results;
        if (!results) {
            return;
        }
        questionsInfo.correct += results.right_answers_count;
        questionsInfo.total += results.question_count;
    }, 0);
    return Math.round(questionsInfo.correct * 100 / questionsInfo.total);
};
