var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8;
function _taggedTemplateLiteral(strings, raw) { if (!raw) {
    raw = strings.slice(0);
} return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import styled from 'styled-components';
import { Fonts } from 'types/TextProps';
import { COLORS } from 'constants/colors';
import { SelectList as _SelectList } from '../Select/SelectList/SelectList';
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  user-select: none;\n\n  position: relative;\n\n  display: flex;\n  flex-wrap: wrap;\n  align-items: center;\n\n  width: fit-content;\n  min-width: 0;\n\n  font-family: ", ";\n  font-size: 14px;\n  font-weight: 500;\n  line-height: 20px;\n"])), Fonts.NotoSans);
export var Text = styled.span(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: block;\n  color: ", ";\n  white-space: nowrap;\n"])), function (_ref) {
    var opened = _ref.opened;
    return opened ? COLORS.WILD_BLUE_YONDER : COLORS.PURPLE_200;
});
export var ItemsText = styled.span(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  color: ", ";\n"])), COLORS.DARK_PURPLE_100);
export var OverflowWrapper = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  display: flex;\n  flex-wrap: wrap;\n  gap: 24px;\n  align-items: center;\n"])));
export var Button = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  cursor: pointer;\n\n  display: flex;\n  gap: 6px;\n  align-items: center;\n\n  padding: 2px;\n\n  ", ";\n"])), function (_ref2) {
    var opened = _ref2.opened;
    return opened ? "\n    background: ".concat(COLORS.PURPLE_500, ";\n    border: 2px solid ").concat(COLORS.PURPLE_500, ";\n    border-radius: 2px;\n  ") : '';
});
export var ItemWrapper = styled.div(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  display: flex;\n  gap: 4px;\n  align-items: center;\n\n  svg {\n    cursor: pointer;\n  }\n"])));
export var OptionWrapper = styled.div(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  display: flex;\n  flex-wrap: wrap;\n  gap: 24px;\n  align-items: center;\n"])));
export var SelectList = styled(_SelectList)(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  top: 40px;\n  min-width: 308px;\n"])));
