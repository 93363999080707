var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;
function _taggedTemplateLiteral(strings, raw) { if (!raw) {
    raw = strings.slice(0);
} return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import styled from 'styled-components';
import { Fonts } from 'types/TextProps';
import { COLORS } from 'constants/colors';
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  cursor: ", ";\n\n  position: relative;\n  ", ";\n\n  display: grid;\n  grid-column-gap: 20px;\n  grid-template-columns: ", ";\n  align-items: center;\n\n  min-height: 64px;\n  padding: 17px 24px 17px 24px;\n  white-space: break-spaces;\n  &:nth-child(odd) {\n    background: ", ";\n  }\n\n  border: 1px solid ", ";\n\n  transition: transform 0.6s ease-in-out;\n"])), function (_ref) {
    var isAdmin = _ref.isAdmin, isRequested = _ref.isRequested;
    return isAdmin && !isRequested ? 'pointer' : 'default';
}, function (_ref2) {
    var isShow = _ref2.isShow;
    return isShow ? 'transform: translateX(-40%);' : '';
}, function (_ref3) {
    var isRequested = _ref3.isRequested;
    return isRequested ? 'minmax(54px,0.5fr) minmax(306px,3fr) minmax(150px,1fr) minmax(200px,2fr)' : '0.5fr 3fr 1fr 1fr 1fr 1fr';
}, COLORS.PURPLE_600, COLORS.PURPLE_400);
export var IconsGroup = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: grid;\n  grid-gap: 20px;\n  grid-template-columns: 1fr 1fr;\n  align-items: center;\n  justify-items: center;\n  justify-self: end;\n\n  & > img {\n    cursor: pointer;\n  }\n"])));
export var Number = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  font-family: ", ";\n  font-size: 14px;\n  font-weight: 500;\n  line-height: 20px;\n  color: ", ";\n"])), Fonts.NotoSans, COLORS.LOGAN);
export var ButtonsWrapper = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  display: grid;\n  grid-gap: 20px;\n  grid-template-columns: 1fr 1fr;\n"])));
export var ItemWrapper = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  display: flex;\n  gap: 5px;\n"])));
export var SubsectionTitle = styled.div(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  font-family: ", ";\n  font-size: 14px;\n  font-weight: 500;\n  line-height: 20px;\n  color: ", ";\n  white-space: nowrap;\n"])), Fonts.NotoSans, COLORS.DARK_PURPLE_100);
