export var UPDATES_TYPES = {
    create: 'Создан',
    update: 'Изменён',
    delete: 'Удалён'
};
export var UPDATES_COLORS = {
    create: 'green',
    update: 'yellow',
    delete: 'red'
};
