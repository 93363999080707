var _QUESTION_TYPE_TEXT;
function _typeof(obj) {
    "@babel/helpers - typeof";
    return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj);
}
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) {
    Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true });
}
else {
    obj[key] = value;
} return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null)
    return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) {
    var res = prim.call(input, hint || "default");
    if (_typeof(res) !== "object")
        return res;
    throw new TypeError("@@toPrimitive must return a primitive value.");
} return (hint === "string" ? String : Number)(input); }
export var QUESTION_TYPE = {
    /** Одиночный выбор */
    single: 10,
    /** Множественный выбор */
    multiple: 20,
    /** Ввод свободного варианта ответа */
    free: 30,
    /** Ранжирование */
    ranging: 40,
    /** Заполнение пропусков */
    filling: 50
};
export var QUESTION_TYPE_TEXT = (_QUESTION_TYPE_TEXT = {}, _defineProperty(_QUESTION_TYPE_TEXT, QUESTION_TYPE.single, 'Одиночный выбор'), _defineProperty(_QUESTION_TYPE_TEXT, QUESTION_TYPE.multiple, 'Множественный выбор'), _defineProperty(_QUESTION_TYPE_TEXT, QUESTION_TYPE.free, 'Ввод свободного варианта ответа'), _defineProperty(_QUESTION_TYPE_TEXT, QUESTION_TYPE.ranging, 'Ранжирование'), _defineProperty(_QUESTION_TYPE_TEXT, QUESTION_TYPE.filling, 'Заполнение пропусков'), _QUESTION_TYPE_TEXT);
export var QUESTION_TYPE_OPTIONS = [{
        id: QUESTION_TYPE.single.toString(),
        title: QUESTION_TYPE_TEXT[QUESTION_TYPE.single]
    }, {
        id: QUESTION_TYPE.multiple.toString(),
        title: QUESTION_TYPE_TEXT[QUESTION_TYPE.multiple]
    }, {
        id: QUESTION_TYPE.free.toString(),
        title: QUESTION_TYPE_TEXT[QUESTION_TYPE.free]
    }, {
        id: QUESTION_TYPE.ranging.toString(),
        title: QUESTION_TYPE_TEXT[QUESTION_TYPE.ranging]
    }, {
        id: QUESTION_TYPE.filling.toString(),
        title: QUESTION_TYPE_TEXT[QUESTION_TYPE.filling]
    }];
/**
 * TODO: временная сущность, удалить после внедрения новых типов вопросов на бэкенде
 *
 * https://git.univertus.ru/prof-evaluation1/web-system-development/-/issues/419
 */
export var QUESTION_TYPE_OPTIONS_TEMP = [].concat(QUESTION_TYPE_OPTIONS, [{
        id: '__temp-1',
        title: 'Одиночный выбор. Вопрос - утверждение'
    }, {
        id: '__temp-2',
        title: 'Одиночный - Какое утверждение верно?'
    }, {
        id: '__temp-3',
        title: 'Сопоставление'
    }]);
