export var TAG_TYPES = {
    assignments: 'ASSIGNMENTS',
    functionalDirection: 'FUNCTIONAL_DIRECTION',
    functionalDirections: 'FUNCTIONAL_DIRECTIONS',
    employeeFunctionalDirections: 'EMPLOYEE_FUNCTIONAL_DIRECTIONS',
    employeeKnowledgeField: 'EMPLOYEE_KNOWLEDGE_FIELD',
    requestedFunctionalDirection: 'REQUESTED_FUNCTIONAL_DIRECTION',
    teamResults: 'TEAM_RESULTS',
    teamResultsForEveryEmployee: 'TEAM_RESULTS_FOR_EVERY_EMPLOYEE',
    teamAssignments: 'TEAM_ASSIGNMENTS'
};
