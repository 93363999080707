function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o)
    return; if (typeof o === "string")
    return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor)
    n = o.constructor.name; if (n === "Map" || n === "Set")
    return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n))
    return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length)
    len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++)
    arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) {
    var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1;
    try {
        if (_x = (_i = _i.call(arr)).next, 0 === i) {
            if (Object(_i) !== _i)
                return;
            _n = !1;
        }
        else
            for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0)
                ;
    }
    catch (err) {
        _d = !0, _e = err;
    }
    finally {
        try {
            if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r))
                return;
        }
        finally {
            if (_d)
                throw _e;
        }
    }
    return _arr;
} }
function _arrayWithHoles(arr) { if (Array.isArray(arr))
    return arr; }
import * as React from 'react';
import debounce from 'debounce';
import { useSearchParams } from 'react-router-dom';
var debounced = debounce(function (f) {
    f();
}, 300);
export var useUrlSearch = function useUrlSearch(_ref) {
    var _ref$paramName = _ref.paramName, paramName = _ref$paramName === void 0 ? 'query' : _ref$paramName, withPagination = _ref.withPagination;
    var _useSearchParams = useSearchParams(), _useSearchParams2 = _slicedToArray(_useSearchParams, 2), searchParams = _useSearchParams2[0], setSearchParams = _useSearchParams2[1];
    var _React$useState = React.useState(searchParams.get(paramName) || ''), _React$useState2 = _slicedToArray(_React$useState, 2), search = _React$useState2[0], setSearch = _React$useState2[1];
    /** force change search value */
    var onForceChangeSearch = React.useCallback(function (newValue) {
        setSearch(newValue);
    }, []);
    var onSearch = React.useCallback(function (newSearch) {
        if (newSearch.length || searchParams.get(paramName)) {
            setSearchParams(function (params) {
                if (withPagination) {
                    params.set('page', '1');
                }
                if (newSearch) {
                    params.set(paramName, newSearch);
                }
                else {
                    params.delete(paramName);
                }
                return params;
            });
        }
    }, [searchParams, paramName, setSearchParams, withPagination]);
    var onChangeSearch = React.useCallback(function (newValue) {
        setSearch(newValue);
        if (newValue.length >= 3 || newValue.length === 0) {
            debounced(function () {
                onSearch(newValue);
            });
        }
    }, [onSearch]);
    return {
        query: searchParams.get(paramName) || undefined,
        search: search,
        onChangeSearch: onChangeSearch,
        onForceChangeSearch: onForceChangeSearch
    };
};
