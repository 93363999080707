var _QUESTION_ERRORS_VALU;
function _typeof(obj) {
    "@babel/helpers - typeof";
    return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj);
}
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) {
    Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true });
}
else {
    obj[key] = value;
} return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null)
    return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) {
    var res = prim.call(input, hint || "default");
    if (_typeof(res) !== "object")
        return res;
    throw new TypeError("@@toPrimitive must return a primitive value.");
} return (hint === "string" ? String : Number)(input); }
export var QUESTION_ERRORS = {
    MORPHOLOGIC: 'morphological',
    SYNTAX: 'syntax',
    PUNCTUATION: 'punctuation'
};
export var QUESTION_ERRORS_VALUES = (_QUESTION_ERRORS_VALU = {}, _defineProperty(_QUESTION_ERRORS_VALU, QUESTION_ERRORS.MORPHOLOGIC, 'Морфологические/орфографические'), _defineProperty(_QUESTION_ERRORS_VALU, QUESTION_ERRORS.SYNTAX, 'Синтаксические'), _defineProperty(_QUESTION_ERRORS_VALU, QUESTION_ERRORS.PUNCTUATION, 'Пунктуационные'), _QUESTION_ERRORS_VALU);
export var QUESTION_ERRORS_OPTIONS = [{
        id: QUESTION_ERRORS.MORPHOLOGIC,
        title: 'Морфологические/орфографические'
    }, {
        id: QUESTION_ERRORS.SYNTAX,
        title: 'Синтаксические'
    }, {
        id: QUESTION_ERRORS.PUNCTUATION,
        title: 'Пунктуационные'
    }];
