import * as Yup from 'yup';
import { createFormValidator, getCommonEmailSchema, getValidationMessages } from 'utils/forms';
import { NEW_MANAGER_OPTION_VALUE } from './constants';
var getSchema = function getSchema() {
    var msg = getValidationMessages();
    return Yup.object({
        fio: Yup.string().required(msg.required),
        personal_number: Yup.string().min(8).max(8).nullable(),
        position: Yup.string().required(msg.required),
        position_id: Yup.string().min(8).max(8).nullable(),
        department: Yup.string().required(msg.required),
        email: getCommonEmailSchema().required(msg.required),
        // head
        head_id: Yup.string().required(msg.required),
        // fields below are optional if user selected already created manager (not creating a new one)
        head_fio: Yup.string().when('head_id', {
            is: function is(headId) {
                return headId && headId === NEW_MANAGER_OPTION_VALUE;
            },
            then: function then(schema) {
                return schema.required(msg.required);
            },
            otherwise: function otherwise(schema) {
                return schema.optional();
            }
        }),
        head_personal_number: Yup.string().when('head_id', {
            is: function is(headId) {
                return headId && headId === NEW_MANAGER_OPTION_VALUE;
            },
            then: function then(schema) {
                return schema.required(msg.required).min(8).max(8);
            },
            otherwise: function otherwise(schema) {
                return schema.optional();
            }
        }),
        head_position: Yup.string().when('head_id', {
            is: function is(headId) {
                return headId && headId === NEW_MANAGER_OPTION_VALUE;
            },
            then: function then(schema) {
                return schema.required(msg.required);
            },
            otherwise: function otherwise(schema) {
                return schema.optional();
            }
        }),
        head_email: Yup.string().when('head_id', {
            is: function is(headId) {
                return headId && headId === NEW_MANAGER_OPTION_VALUE;
            },
            then: function then(schema) {
                return schema.required(msg.required)
                    // from "getCommonEmailSchema"
                    .email(msg.email).test({
                    message: msg.email,
                    test: function test(to) {
                        return !to || /^.+@.+\..+$/.test(to);
                    }
                }).trim(msg.format);
            },
            otherwise: function otherwise(schema) {
                return schema.optional();
            }
        })
    });
};
export default (function (values) {
    return createFormValidator(getSchema())(values);
});
