var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11;
function _taggedTemplateLiteral(strings, raw) { if (!raw) {
    raw = strings.slice(0);
} return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import styled from 'styled-components';
import { Fonts } from 'types/TextProps';
import { COLORS } from 'constants/colors';
import { Count as _Count } from 'components/UI/atoms';
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: grid;\n  grid-gap: 32px;\n\n  padding: 20px 46px 20px 20px;\n\n  background: ", ";\n  border-radius: 10px;\n"])), COLORS.PURPLE_400);
export var InnerWrapper = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: grid;\n  grid-gap: 54px;\n  grid-template-columns: 2fr 1fr 1fr 1fr;\n"])));
export var ItemWrapper = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  display: grid;\n  grid-auto-rows: min-content;\n  grid-gap: 8px;\n"])));
export var ItemLabel = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  font-family: ", ";\n  font-size: 14px;\n  font-weight: 500;\n  line-height: 20px;\n  color: ", ";\n"])), Fonts.NotoSans, COLORS.DARK_PURPLE_100);
export var ItemData = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  font-family: ", ";\n  font-size: 24px;\n  font-weight: 500;\n  line-height: 32px;\n  color: ", ";\n"])), Fonts.Montserrat, COLORS.BLACK);
export var IconWrapper = styled.div(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  display: flex;\n  gap: 1px;\n  align-items: center;\n"])));
export var Count = styled(_Count)(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  font-family: ", ";\n  font-size: 14px;\n  font-weight: 500;\n  line-height: 20px;\n  color: ", ";\n\n  background: ", ";\n"])), Fonts.NotoSans, COLORS.DARK_PURPLE_100, COLORS.WHITE);
export var RowsWrapper = styled.div(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  display: grid;\n  grid-gap: 32px;\n  grid-template-columns: max-content max-content;\n  align-items: center;\n"])));
export var Row = styled.div(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  display: flex;\n  gap: 8px;\n  align-items: center;\n"])));
export var EmployeeData = styled.div(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n  display: flex;\n  gap: 34px;\n  align-items: center;\n"])));
export var EmployeeText = styled.div(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n  font-family: ", ";\n  font-size: 14px;\n  font-weight: 500;\n  line-height: 20px;\n  color: ", ";\n"])), Fonts.NotoSans, COLORS.WILD_BLUE_YONDER);
