var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;
function _taggedTemplateLiteral(strings, raw) { if (!raw) {
    raw = strings.slice(0);
} return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import styled from 'styled-components';
import { PAGE_WRAPPER } from 'constants/commonStyles';
export var PageWrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  width: 100%;\n"])));
export var Wrapper = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  ", ";\n"])), PAGE_WRAPPER);
export var HeaderWrapper = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  display: grid;\n  grid-gap: 40px;\n  grid-template-columns: 4fr 1fr;\n"])));
export var ButtonWrapper = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  display: grid;\n  grid-gap: 16px;\n  grid-template-columns: 1fr 1fr 1fr;\n  align-items: center;\n"])));
export var FilterWrapper = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  display: grid;\n  grid-template-columns: 2fr 2fr 2fr 1fr 1fr;\n"])));
