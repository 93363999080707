var _excluded = ["clientId"], _excluded2 = ["client_uuid"];
function _objectWithoutProperties(source, excluded) { if (source == null)
    return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);
    for (i = 0; i < sourceSymbolKeys.length; i++) {
        key = sourceSymbolKeys[i];
        if (excluded.indexOf(key) >= 0)
            continue;
        if (!Object.prototype.propertyIsEnumerable.call(source, key))
            continue;
        target[key] = source[key];
    }
} return target; }
function _objectWithoutPropertiesLoose(source, excluded) { if (source == null)
    return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0)
        continue;
    target[key] = source[key];
} return target; }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o)
    return; if (typeof o === "string")
    return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor)
    n = o.constructor.name; if (n === "Map" || n === "Set")
    return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n))
    return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null)
    return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr))
    return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length)
    len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++)
    arr2[i] = arr[i]; return arr2; }
import { api } from './api';
var url = 'admin/test/assignment';
export var testAssignment = api.injectEndpoints({
    endpoints: function endpoints(build) {
        return {
            getAssignmentEmployees: build.query({
                query: function query(params) {
                    return {
                        url: url,
                        method: 'GET',
                        params: params
                    };
                },
                providesTags: function providesTags(result) {
                    return result ? [].concat(_toConsumableArray(result.data.map(function (_ref) {
                        var id = _ref.id;
                        return {
                            type: 'TestAssignment',
                            id: id
                        };
                    })), [{
                            type: 'TestAssignment',
                            id: 'LIST'
                        }]) : [{
                            type: 'TestAssignment',
                            id: 'LIST'
                        }];
                }
            }),
            getAssignmentAdminEmployees: build.query({
                query: function query(_ref2) {
                    var clientId = _ref2.clientId, params = _objectWithoutProperties(_ref2, _excluded);
                    return {
                        url: "/admin/client/".concat(clientId, "/test/assignment"),
                        method: 'GET',
                        params: params
                    };
                },
                providesTags: function providesTags(result) {
                    return result ? [].concat(_toConsumableArray(result.data.map(function (_ref3) {
                        var id = _ref3.id;
                        return {
                            type: 'TestAssignment',
                            id: id
                        };
                    })), [{
                            type: 'TestAssignment',
                            id: 'LIST'
                        }]) : [{
                            type: 'TestAssignment',
                            id: 'LIST'
                        }];
                }
            }),
            assignEmployee: build.mutation({
                query: function query(body) {
                    return {
                        url: url,
                        method: 'POST',
                        body: body
                    };
                },
                invalidatesTags: [{
                        type: 'TestAssignment',
                        id: 'LIST'
                    }]
            }),
            assignAdminEmployee: build.mutation({
                query: function query(_ref4) {
                    var client_uuid = _ref4.client_uuid, body = _objectWithoutProperties(_ref4, _excluded2);
                    return {
                        url: "admin/client/".concat(client_uuid, "/employee/test/assignment"),
                        method: 'POST',
                        body: body
                    };
                },
                invalidatesTags: [{
                        type: 'TestAssignment',
                        id: 'LIST'
                    }]
            })
        };
    }
});
var useGetAssignmentEmployeesQuery = testAssignment.useGetAssignmentEmployeesQuery, useGetAssignmentAdminEmployeesQuery = testAssignment.useGetAssignmentAdminEmployeesQuery, useAssignEmployeeMutation = testAssignment.useAssignEmployeeMutation, useAssignAdminEmployeeMutation = testAssignment.useAssignAdminEmployeeMutation;
export { useGetAssignmentEmployeesQuery, useGetAssignmentAdminEmployeesQuery, useAssignEmployeeMutation, useAssignAdminEmployeeMutation };
