var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;
function _taggedTemplateLiteral(strings, raw) { if (!raw) {
    raw = strings.slice(0);
} return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import styled from 'styled-components';
import { Fonts } from 'types/TextProps';
import { COLORS } from 'constants/colors';
import { DatePicker as _DatePicker } from '../../DatePicker';
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: grid;\n  grid-gap: 16px;\n"])));
export var Row = styled(Wrapper)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  grid-template-columns: ", ";\n  align-items: ", ";\n"])), function (_ref) {
    var cols = _ref.cols;
    return "repeat(".concat(cols, ", 1fr);");
}, function (_ref2) {
    var align = _ref2.align;
    return align ? "".concat(align) : 'start';
});
export var DatePicker = styled(_DatePicker)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral([""])));
export var Label = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  display: block;\n\n  margin-right: 10px;\n  margin-bottom: 10px;\n\n  font-family: ", ";\n  font-size: 16px;\n  font-weight: 400;\n  font-style: normal;\n  line-height: 24px;\n  color: ", ";\n\n  background: none;\n"])), Fonts.NotoSans, COLORS.DARK_PURPLE_100);
export var FnWrapper = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral([""])));
export var FnList = styled.div(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  margin-right: 10px;\n  margin-left: 10px;\n  font-family: ", ";\n  color: ", ";\n"])), Fonts.NotoSans, COLORS.DARK_PURPLE_100);
