var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;
function _taggedTemplateLiteral(strings, raw) { if (!raw) {
    raw = strings.slice(0);
} return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import styled from 'styled-components';
import { COLORS } from 'constants/colors';
import { Fonts } from 'types/TextProps';
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  cursor: pointer;\n\n  display: flex;\n  flex-direction: column;\n  gap: 32px;\n  justify-content: space-between;\n\n  width: 100%;\n  min-height: 176px;\n  padding: 32px;\n  white-space: break-spaces;\n\n  background: #f9f7fc;\n  border-radius: 8px;\n\n  &:hover {\n    background: ", ";\n  }\n"])), COLORS.PURPLE_400);
export var ItemsWrapper = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: grid;\n  grid-column-gap: 50px;\n  grid-row-gap: 8px;\n  grid-template-columns: 150px 50px;\n  align-items: center;\n\n  @media screen and (max-width: 1125px) {\n    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));\n  }\n"])));
export var ItemWrapper = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  display: grid;\n  grid-gap: 5px;\n  grid-template-columns: 3fr auto;\n"])));
export var NameWrapper = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  display: flex;\n  gap: 10px;\n  align-items: baseline;\n  justify-content: space-between;\n\n  width: 100%;\n"])));
export var IconsWrapper = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  display: grid;\n  grid-gap: 20px;\n  grid-template-columns: 20px 20px;\n  align-items: center;\n"])));
export var SubsectionTitle = styled.div(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  font-family: ", ";\n  font-size: 14px;\n  font-weight: 500;\n  line-height: 20px;\n  color: ", ";\n  white-space: nowrap;\n\n  &:hover {\n    color: ", ";\n  }\n"])), Fonts.NotoSans, COLORS.DARK_PURPLE_100, COLORS.PURPLE_100);
