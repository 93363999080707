export var getGroupedKnowledges = function getGroupedKnowledges(_ref) {
    var initKnowledges = _ref.initKnowledges, currentKnowledges = _ref.currentKnowledges;
    var initIds = new Set(initKnowledges.map(function (knowledge) {
        return knowledge.id;
    }));
    var currentIds = new Set(currentKnowledges.map(function (knowledge) {
        return knowledge.id;
    }));
    var attached = currentKnowledges.filter(function (knowledge) {
        return !initIds.has(knowledge.id);
    });
    var dettached = initKnowledges.filter(function (knowledge) {
        return !currentIds.has(knowledge.id);
    });
    return {
        attached: attached,
        dettached: dettached
    };
};
