export var parseUrlParam = function parseUrlParam(_ref) {
    var value = _ref.value;
    return !value || value === 'null' ? undefined : value;
};
export var getSearchParamFromUrl = function getSearchParamFromUrl(_ref2) {
    var requestUrl = _ref2.requestUrl, name = _ref2.name;
    var extractedValue = new URL(requestUrl).searchParams.get(name);
    return parseUrlParam({
        value: extractedValue
    });
};
