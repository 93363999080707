var _excluded = ["uuid"], _excluded2 = ["client_uuid"], _excluded3 = ["client_uuid"], _excluded4 = ["uuid", "client_uuid"], _excluded5 = ["uuid"];
function _objectWithoutProperties(source, excluded) { if (source == null)
    return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);
    for (i = 0; i < sourceSymbolKeys.length; i++) {
        key = sourceSymbolKeys[i];
        if (excluded.indexOf(key) >= 0)
            continue;
        if (!Object.prototype.propertyIsEnumerable.call(source, key))
            continue;
        target[key] = source[key];
    }
} return target; }
function _objectWithoutPropertiesLoose(source, excluded) { if (source == null)
    return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0)
        continue;
    target[key] = source[key];
} return target; }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o)
    return; if (typeof o === "string")
    return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor)
    n = o.constructor.name; if (n === "Map" || n === "Set")
    return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n))
    return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null)
    return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr))
    return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length)
    len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++)
    arr2[i] = arr[i]; return arr2; }
import { api } from './api';
var url = 'admin/client';
export var client = api.injectEndpoints({
    endpoints: function endpoints(build) {
        return {
            getClients: build.query({
                query: function query(params) {
                    return {
                        url: url,
                        method: 'GET',
                        params: params
                    };
                },
                providesTags: function providesTags(result) {
                    return result ? [].concat(_toConsumableArray(result.data.map(function (_ref) {
                        var id = _ref.id;
                        return {
                            type: 'Client',
                            id: id
                        };
                    })), [{
                            type: 'Client',
                            id: 'LIST'
                        }]) : [{
                            type: 'Client',
                            id: 'LIST'
                        }];
                }
            }),
            createClient: build.mutation({
                query: function query(body) {
                    return {
                        url: url,
                        method: 'POST',
                        body: body
                    };
                },
                invalidatesTags: [{
                        type: 'Client',
                        id: 'LIST'
                    }]
            }),
            getClient: build.query({
                query: function query(uuid) {
                    return {
                        url: "".concat(url, "/").concat(uuid, "/edit"),
                        method: 'GET'
                    };
                },
                providesTags: function providesTags(result) {
                    return [{
                            type: 'Client',
                            id: result === null || result === void 0 ? void 0 : result.data.id
                        }];
                }
            }),
            editClient: build.mutation({
                query: function query(_ref2) {
                    var uuid = _ref2.uuid, body = _objectWithoutProperties(_ref2, _excluded);
                    return {
                        url: "".concat(url, "/").concat(uuid),
                        method: 'PUT',
                        body: body
                    };
                },
                invalidatesTags: function invalidatesTags(result) {
                    return [{
                            type: 'Client',
                            id: result === null || result === void 0 ? void 0 : result.data.id
                        }];
                }
            }),
            removeClient: build.mutation({
                query: function query(uuid) {
                    return {
                        url: "".concat(url, "/").concat(uuid),
                        method: 'DELETE'
                    };
                },
                invalidatesTags: [{
                        type: 'Client',
                        id: 'LIST'
                    }]
            }),
            getClientEmployees: build.query({
                query: function query(_ref3) {
                    var client_uuid = _ref3.client_uuid, params = _objectWithoutProperties(_ref3, _excluded2);
                    return {
                        url: "".concat(url, "/").concat(client_uuid, "/employee"),
                        method: 'GET',
                        params: params
                    };
                },
                providesTags: function providesTags(result) {
                    return result ? [].concat(_toConsumableArray(result.data.map(function (_ref4) {
                        var id = _ref4.id;
                        return {
                            type: 'ClientEmployee',
                            id: id
                        };
                    })), [{
                            type: 'ClientEmployee',
                            id: 'LIST'
                        }]) : [{
                            type: 'ClientEmployee',
                            id: 'LIST'
                        }];
                }
            }),
            createClientEmployee: build.mutation({
                query: function query(_ref5) {
                    var client_uuid = _ref5.client_uuid, body = _objectWithoutProperties(_ref5, _excluded3);
                    return {
                        url: "".concat(url, "/").concat(client_uuid, "/employee"),
                        method: 'POST',
                        body: body
                    };
                },
                invalidatesTags: [{
                        type: 'ClientEmployee',
                        id: 'LIST'
                    }]
            }),
            getClientEmployee: build.query({
                query: function query(_ref6) {
                    var client_uuid = _ref6.client_uuid, uuid = _ref6.uuid;
                    return {
                        url: "".concat(url, "/").concat(client_uuid, "/employee/").concat(uuid, "/edit"),
                        method: 'GET'
                    };
                },
                providesTags: function providesTags(result) {
                    return [{
                            type: 'ClientEmployee',
                            id: result === null || result === void 0 ? void 0 : result.data.id
                        }];
                }
            }),
            getClientHeadEmployees: build.query({
                query: function query(client_uuid) {
                    return {
                        url: "".concat(url, "/").concat(client_uuid, "/employee/head/list"),
                        method: 'GET'
                    };
                }
            }),
            editClientEmployee: build.mutation({
                query: function query(_ref7) {
                    var uuid = _ref7.uuid, client_uuid = _ref7.client_uuid, body = _objectWithoutProperties(_ref7, _excluded4);
                    return {
                        url: "".concat(url, "/").concat(client_uuid, "/employee/").concat(uuid),
                        method: 'PUT',
                        body: body
                    };
                },
                invalidatesTags: function invalidatesTags(result) {
                    return [{
                            type: 'ClientEmployee',
                            id: result === null || result === void 0 ? void 0 : result.data.id
                        }];
                }
            }),
            removeClientEmployee: build.mutation({
                query: function query(_ref8) {
                    var uuid = _ref8.uuid, client_uuid = _ref8.client_uuid;
                    return {
                        url: "".concat(url, "/").concat(client_uuid, "/employee/").concat(uuid),
                        method: 'DELETE'
                    };
                },
                invalidatesTags: [{
                        type: 'ClientEmployee',
                        id: 'LIST'
                    }]
            }),
            importClientEmployees: build.mutation({
                query: function query(_ref9) {
                    var file = _ref9.file, client_uuid = _ref9.client_uuid;
                    var formData = new FormData();
                    formData.append('xls_upload', file);
                    return {
                        url: "".concat(url, "/").concat(client_uuid, "/employee/import"),
                        method: 'POST',
                        body: formData
                    };
                },
                invalidatesTags: [{
                        type: 'ClientEmployee',
                        id: 'LIST'
                    }]
            }),
            getClientKnowledgeField: build.query({
                query: function query(_ref10) {
                    var uuid = _ref10.uuid, params = _objectWithoutProperties(_ref10, _excluded5);
                    return {
                        url: "".concat(url, "/").concat(uuid, "/knowledge-field"),
                        method: 'GET',
                        params: params
                    };
                }
            }),
            resetClientPassword: build.mutation({
                query: function query(uuid) {
                    return {
                        url: "".concat(url, "/").concat(uuid, "/reset-password"),
                        method: 'GET'
                    };
                }
            })
        };
    }
});
var useCreateClientMutation = client.useCreateClientMutation, useEditClientMutation = client.useEditClientMutation, useGetClientQuery = client.useGetClientQuery, useGetClientsQuery = client.useGetClientsQuery, useRemoveClientMutation = client.useRemoveClientMutation, useGetClientEmployeesQuery = client.useGetClientEmployeesQuery, useLazyGetClientEmployeesQuery = client.useLazyGetClientEmployeesQuery, useCreateClientEmployeeMutation = client.useCreateClientEmployeeMutation, useGetClientEmployeeQuery = client.useGetClientEmployeeQuery, useGetClientHeadEmployeesQuery = client.useGetClientHeadEmployeesQuery, useEditClientEmployeeMutation = client.useEditClientEmployeeMutation, useRemoveClientEmployeeMutation = client.useRemoveClientEmployeeMutation, useImportClientEmployeesMutation = client.useImportClientEmployeesMutation, useGetClientKnowledgeFieldQuery = client.useGetClientKnowledgeFieldQuery, useResetClientPasswordMutation = client.useResetClientPasswordMutation;
export { useCreateClientMutation, useEditClientMutation, useGetClientQuery, useGetClientsQuery, useRemoveClientMutation, useGetClientEmployeesQuery, useLazyGetClientEmployeesQuery, useCreateClientEmployeeMutation, useGetClientEmployeeQuery, useGetClientHeadEmployeesQuery, useEditClientEmployeeMutation, useRemoveClientEmployeeMutation, useImportClientEmployeesMutation, useGetClientKnowledgeFieldQuery, useResetClientPasswordMutation };
