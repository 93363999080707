var _templateObject, _templateObject2, _templateObject3, _templateObject4;
function _taggedTemplateLiteral(strings, raw) { if (!raw) {
    raw = strings.slice(0);
} return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import styled from 'styled-components';
import { Fonts } from 'types/TextProps';
import { COLORS } from 'constants/colors';
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n  flex-wrap: nowrap;\n  align-items: center;\n"])));
export var Text = styled.p(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  cursor: pointer;\n\n  font-family: ", ";\n  font-size: 16px;\n  font-weight: 400;\n  line-height: 24px;\n  color: ", ";\n"])), Fonts.NotoSans, function (_ref) {
    var isRightAnswer = _ref.isRightAnswer, isWrongAnswer = _ref.isWrongAnswer;
    if (isRightAnswer) {
        return COLORS.GREEN;
    }
    if (isWrongAnswer) {
        return COLORS.ALERT_RED;
    }
    return COLORS.DARK_PURPLE_100;
});
export var CustomCheckbox = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  cursor: pointer;\n\n  position: relative;\n\n  min-width: 24px;\n  height: 24px;\n  margin-right: 8px;\n\n  opacity: ", ";\n  background-color: ", ";\n  border: ", ";\n  border-radius: 2px;\n"])), function (_ref2) {
    var disabled = _ref2.disabled;
    return disabled ? 0.4 : 1;
}, function (_ref3) {
    var checked = _ref3.checked, isRightAnswer = _ref3.isRightAnswer, isWrongAnswer = _ref3.isWrongAnswer;
    if (checked && isRightAnswer) {
        return COLORS.GREEN;
    }
    if (checked && isWrongAnswer) {
        return COLORS.ALERT_RED;
    }
    if (checked && !isRightAnswer) {
        return COLORS.PURPLE_100;
    }
    return COLORS.WHITE;
}, function (_ref4) {
    var checked = _ref4.checked, error = _ref4.error;
    var color = COLORS.GRAY_100;
    if (checked) {
        color = COLORS.WHITE;
    }
    else if (error) {
        color = COLORS.RED_100;
    }
    return "1px solid ".concat(color);
});
export var Icon = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  position: absolute;\n  z-index: 2;\n\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  width: 100%;\n  height: 100%;\n\n  /* path {\n    stroke: ", ";\n  } */\n"])), function (_ref5) {
    var checked = _ref5.checked;
    return checked ? '#FFFFFF' : '#fff';
});
