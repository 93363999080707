var _templateObject, _templateObject2, _templateObject3;
function _taggedTemplateLiteral(strings, raw) { if (!raw) {
    raw = strings.slice(0);
} return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import styled from 'styled-components';
import { Fonts } from 'types/TextProps';
import { COLORS } from 'constants/colors';
import { COLOR } from 'uikit/vars';
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n  gap: 8px;\n  align-items: center;\n"])));
export var Label = styled.label(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  cursor: pointer;\n\n  font-family: ", ";\n  font-size: 16px;\n  font-weight: 400;\n  line-height: 24px;\n  color: ", ";\n\n  ", "\n"])), Fonts.NotoSans, function (_ref) {
    var isRightAnswer = _ref.isRightAnswer, isWrongAnswer = _ref.isWrongAnswer;
    if (isRightAnswer) {
        return "var(".concat(COLOR.SUCCESS, ")");
    }
    if (isWrongAnswer) {
        return COLORS.ALERT_RED;
    }
    return COLORS.DARK_PURPLE_100;
}, function (_ref2) {
    var disabled = _ref2.disabled;
    return disabled && "\n    cursor: not-allowed;\n  ";
});
export var Radio = styled.input(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  cursor: pointer;\n\n  position: relative;\n\n  min-width: 24px;\n  height: 24px;\n  margin: 0;\n\n  -webkit-appearance: none;\n  appearance: none;\n  border: ", ";\n  border-radius: 50%;\n\n  transition: all 0.1s ease-in-out;\n  ::after {\n    content: '';\n\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n\n    display: block;\n\n    width: 14px;\n    height: 14px;\n\n    border-radius: 50%;\n  }\n  :checked {\n    border: 3px solid ", ";\n    ::after {\n      background-color: ", ";\n    }\n  }\n  :disabled {\n    cursor: not-allowed;\n    background-color: ", ";\n    :checked {\n      border: ", ";\n      ::after {\n        background-color: ", ";\n      }\n    }\n  }\n"])), function (_ref3) {
    var error = _ref3.error;
    return error ? "1px solid ".concat(COLORS.RED_100) : "1px solid ".concat(COLORS.GRAY_50);
}, COLORS.PURPLE_100, COLORS.PURPLE_100, COLORS.WHITE, function (_ref4) {
    var isRightAnswer = _ref4.isRightAnswer, isWrongAnswer = _ref4.isWrongAnswer;
    var color = COLORS.LOGAN;
    if (isRightAnswer) {
        color = "var(".concat(COLOR.SUCCESS, ")");
    }
    else if (isWrongAnswer) {
        color = COLORS.ALERT_RED;
    }
    return "3px solid ".concat(color);
}, function (_ref5) {
    var isRightAnswer = _ref5.isRightAnswer, isWrongAnswer = _ref5.isWrongAnswer;
    if (isRightAnswer) {
        return "var(".concat(COLOR.SUCCESS, ")");
    }
    if (isWrongAnswer) {
        return COLORS.ALERT_RED;
    }
    return COLORS.LOGAN;
});
