var _QUESTION_STATUS_TEXT, _QUESTION_USERS_STATU, _QUESTION_ADMIN_STATU, _QUESTION_USERS_STATU2;
function _typeof(obj) {
    "@babel/helpers - typeof";
    return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj);
}
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) {
    Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true });
}
else {
    obj[key] = value;
} return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null)
    return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) {
    var res = prim.call(input, hint || "default");
    if (_typeof(res) !== "object")
        return res;
    throw new TypeError("@@toPrimitive must return a primitive value.");
} return (hint === "string" ? String : Number)(input); }
export var QUESTION_STATUSES = {
    EDITING: 10,
    REWORK: 15,
    EXPERTISE: 20,
    APPROVED: 30,
    REJECTED: 40
};
export var QUESTION_STATUS_TEXT = (_QUESTION_STATUS_TEXT = {}, _defineProperty(_QUESTION_STATUS_TEXT, QUESTION_STATUSES.APPROVED, 'Да'), _defineProperty(_QUESTION_STATUS_TEXT, QUESTION_STATUSES.REWORK, 'Нет'), _defineProperty(_QUESTION_STATUS_TEXT, QUESTION_STATUSES.EXPERTISE, 'Нет'), _defineProperty(_QUESTION_STATUS_TEXT, QUESTION_STATUSES.EDITING, 'Нет'), _defineProperty(_QUESTION_STATUS_TEXT, QUESTION_STATUSES.REJECTED, 'Нет'), _QUESTION_STATUS_TEXT);
export var QUESTION_USERS_STATUS_TEXT = (_QUESTION_USERS_STATU = {}, _defineProperty(_QUESTION_USERS_STATU, QUESTION_STATUSES.APPROVED, 'Утвержден'), _defineProperty(_QUESTION_USERS_STATU, QUESTION_STATUSES.REWORK, 'На редактуре'), _defineProperty(_QUESTION_USERS_STATU, QUESTION_STATUSES.EXPERTISE, 'На экспертизе'), _defineProperty(_QUESTION_USERS_STATU, QUESTION_STATUSES.EDITING, 'Не проверен'), _defineProperty(_QUESTION_USERS_STATU, QUESTION_STATUSES.REJECTED, 'Отклонен'), _QUESTION_USERS_STATU);
export var QUESTION_ADMIN_STATUS_TEXT = (_QUESTION_ADMIN_STATU = {}, _defineProperty(_QUESTION_ADMIN_STATU, QUESTION_STATUSES.APPROVED, 'Утвержден экспертом'), _defineProperty(_QUESTION_ADMIN_STATU, QUESTION_STATUSES.REWORK, 'На редактуре'), _defineProperty(_QUESTION_ADMIN_STATU, QUESTION_STATUSES.EXPERTISE, 'На экспертизе'), _defineProperty(_QUESTION_ADMIN_STATU, QUESTION_STATUSES.EDITING, 'Не проверен'), _defineProperty(_QUESTION_ADMIN_STATU, QUESTION_STATUSES.REJECTED, 'Отклонен'), _QUESTION_ADMIN_STATU);
export var QUESTION_USERS_STATUS_OPTIONS = [{
        id: '',
        title: 'Не выбрано'
    }, {
        id: QUESTION_STATUSES.APPROVED.toString(),
        title: QUESTION_USERS_STATUS_TEXT[QUESTION_STATUSES.APPROVED]
    }, {
        id: QUESTION_STATUSES.EDITING.toString(),
        title: QUESTION_USERS_STATUS_TEXT[QUESTION_STATUSES.EDITING]
    }, {
        id: QUESTION_STATUSES.EXPERTISE.toString(),
        title: QUESTION_USERS_STATUS_TEXT[QUESTION_STATUSES.EXPERTISE]
    }, {
        id: QUESTION_STATUSES.REJECTED.toString(),
        title: QUESTION_USERS_STATUS_TEXT[QUESTION_STATUSES.REJECTED]
    }, {
        id: QUESTION_STATUSES.REWORK.toString(),
        title: QUESTION_USERS_STATUS_TEXT[QUESTION_STATUSES.REWORK]
    }];
export var QUESTION_USERS_STATUS_COLORS = (_QUESTION_USERS_STATU2 = {}, _defineProperty(_QUESTION_USERS_STATU2, QUESTION_STATUSES.APPROVED, 'green'), _defineProperty(_QUESTION_USERS_STATU2, QUESTION_STATUSES.REWORK, 'yellow'), _defineProperty(_QUESTION_USERS_STATU2, QUESTION_STATUSES.EXPERTISE, 'red'), _defineProperty(_QUESTION_USERS_STATU2, QUESTION_STATUSES.EDITING, 'default'), _defineProperty(_QUESTION_USERS_STATU2, QUESTION_STATUSES.REJECTED, 'red'), _QUESTION_USERS_STATU2);
export var QUESTION_USERS_STATUS_OPTIONS_FOR_EXPERTISE = [{
        id: '',
        title: 'Не выбрано'
    }, {
        id: '1',
        title: 'Корректный'
    }, {
        id: '0',
        title: 'Некорректный'
    }];
export var QUESTION_USERS_STATUS_OPTIONS_FOR_APPROVE = [{
        id: '',
        title: 'Не выбрано'
    }, {
        id: 'rework',
        title: 'На доработку'
    }, {
        id: 'approved',
        title: 'Утверждён'
    }];
