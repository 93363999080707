var _templateObject, _templateObject2;
function _taggedTemplateLiteral(strings, raw) { if (!raw) {
    raw = strings.slice(0);
} return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import styled from 'styled-components';
import { COLORS } from 'constants/colors';
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n  gap: 8px;\n"])));
export var TextWrapper = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  cursor: ", ";\n\n  font-family: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  line-height: ", ";\n  color: ", ";\n"])), function (_ref) {
    var pointer = _ref.pointer;
    return pointer && 'pointer';
}, function (_ref2) {
    var fontFamily = _ref2.fontFamily;
    return fontFamily;
}, function (_ref3) {
    var fontSize = _ref3.fontSize;
    return "".concat(fontSize, "px");
}, function (_ref4) {
    var fontWeight = _ref4.fontWeight;
    return fontWeight;
}, function (_ref5) {
    var lineHeight = _ref5.lineHeight;
    return "".concat(lineHeight, "px");
}, function (_ref6) {
    var color = _ref6.color;
    return color !== null && color !== void 0 ? color : COLORS.BLACK;
});
