var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;
function _taggedTemplateLiteral(strings, raw) { if (!raw) {
    raw = strings.slice(0);
} return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import styled from 'styled-components';
import { MEDIA } from 'uikit/vars';
import { COLORS } from 'constants/colors';
import { Fonts } from 'types/TextProps';
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  cursor: pointer;\n\n  display: grid;\n  grid-gap: 32px;\n  justify-items: center;\n\n  height: 100%;\n  min-height: 300px;\n  padding: 16px;\n\n  background-color: ", ";\n  border-radius: 8px;\n\n  @media (min-width: ", ") {\n    padding: 32px;\n  }\n"])), function (_ref) {
    var active = _ref.active;
    return active ? COLORS.PURPLE_400 : COLORS.PURPLE_600;
}, MEDIA.TABLET_1);
export var InnerWrapper = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: grid;\n  grid-gap: 37px;\n  align-items: center;\n  justify-items: center;\n\n  width: 100%;\n"])));
export var NameWrapper = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  display: flex;\n  gap: 10px;\n  align-items: baseline;\n  justify-content: space-between;\n\n  width: 100%;\n"])));
export var CountWrapper = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  display: grid;\n  grid-auto-flow: column;\n  grid-gap: 1px;\n  align-items: center;\n  align-self: end;\n"])));
export var Number = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  font-family: ", ";\n  font-size: 14px;\n  font-weight: 500;\n  line-height: 20px;\n  color: ", ";\n"])), Fonts.NotoSans, COLORS.WILD_BLUE_YONDER);
export var IconsWrapper = styled.div(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  display: grid;\n  grid-gap: 20px;\n  grid-template-columns: 20px;\n  align-items: center;\n"])));
