import { MEDIA } from './index';
var FONTS = {
    NOTO_SANS: 'NotoSans',
    MONTSERRAT: 'Montserrat',
    PANTON: 'Panton'
};
var PRIMARY_FONT = "\n  font-family: ".concat(FONTS.NOTO_SANS, ";\n");
var SECONDARY_FONT = "\n  font-family: ".concat(FONTS.MONTSERRAT, ";\n");
var H1 = "\n  font-family: ".concat(FONTS.MONTSERRAT, ";\n  font-weight: 500;\n  font-size: 48px;\n  line-height: 64px;\n");
var H2 = "\n  font-family: ".concat(FONTS.MONTSERRAT, ";\n  font-style: normal;\n  font-weight: 500;\n  font-size: 28px;\n  line-height: 28px; \n  \n  @media (min-width: ").concat(MEDIA.TABLET_1, ") {\n    font-size: 30px;\n    line-height: 30px;\n  }\n  \n  @media (min-width: ").concat(MEDIA.DESKTOP_1, ") {\n    font-size: 32px;\n    line-height: 32px;\n  }\n");
var H3 = "\n  font-family: ".concat(FONTS.MONTSERRAT, ";\n  font-style: normal;\n  font-weight: 500;\n  font-size: 20px;\n  line-height: 28px;\n\n\n  @media (min-width: ").concat(MEDIA.TABLET_1, ") {\n    font-size: 22px;\n    line-height: 30px;\n  }\n  \n  @media (min-width: ").concat(MEDIA.DESKTOP_1, ") {\n    font-size: 24px;\n    line-height: 32px;\n  }\n");
var H4 = "\n  font-family: ".concat(FONTS.MONTSERRAT, ";\n  font-style: normal;\n  font-weight: 500;\n  font-size: 20px;\n  line-height: 32px;\n");
var H5 = "\n  font-family: ".concat(FONTS.MONTSERRAT, ";\n  font-style: normal;\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 24px;\n");
var H6 = "\n  font-family: ".concat(FONTS.MONTSERRAT, ";\n  font-style: normal;\n  font-weight: 600;\n  font-size: 14px;\n  line-height: 20px;\n");
var LARGE1 = "\n  font-family: ".concat(FONTS.NOTO_SANS, ";\n  font-weight: 400;\n  font-size: 20px;\n  line-height: 28px;\n");
var LARGE2 = "\n  font-family: ".concat(FONTS.NOTO_SANS, ";\n  font-weight: 400;\n  font-size: 18px;\n  line-height: 26px;\n");
var MEDIUM1 = "\n  font-family: ".concat(FONTS.NOTO_SANS, ";\n  font-weight: 400;\n  font-size: 16px;\n  line-height: 24px;\n");
var MEDIUM2 = "\n  font-family: ".concat(FONTS.NOTO_SANS, ";\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 24px;\n");
var SMALL1 = "\n  font-family: ".concat(FONTS.NOTO_SANS, ";\n  font-weight: 500;\n  font-size: 14px;\n  line-height: 20px;\n");
var SMALL2 = "\n  font-family: ".concat(FONTS.MONTSERRAT, ";\n  font-weight: 700;\n  font-size: 13px;\n  line-height: 20px;\n");
var SMALL3 = "\n  font-family: ".concat(FONTS.NOTO_SANS, ";\n  font-weight: 500;\n  font-size: 12px;\n  line-height: 20px;\n");
var SMALL4 = "\n  font-family: ".concat(FONTS.NOTO_SANS, ";\n  font-weight: 400;\n  font-size: 11px;\n  line-height: 11px;\n");
var SMALL5 = "\n  font-family: ".concat(FONTS.NOTO_SANS, ";\n  font-weight: 400;\n  font-size: 9px;\n  line-height: 9px;\n");
var TH = "\n  font-family: ".concat(FONTS.NOTO_SANS, ";\n  font-weight: 600;\n  font-size: 14px;\n  line-height: 15px;\n\n  letter-spacing: 0.1em;\n  text-transform: uppercase;\n");
export default {
    H1: H1,
    H2: H2,
    H3: H3,
    H4: H4,
    H5: H5,
    H6: H6,
    LARGE1: LARGE1,
    LARGE2: LARGE2,
    MEDIUM1: MEDIUM1,
    MEDIUM2: MEDIUM2,
    SMALL1: SMALL1,
    SMALL2: SMALL2,
    SMALL3: SMALL3,
    SMALL4: SMALL4,
    SMALL5: SMALL5,
    TH: TH,
    PRIMARY_FONT: PRIMARY_FONT,
    SECONDARY_FONT: SECONDARY_FONT
};
