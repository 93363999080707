export { Functions } from './Functions/Functions';
export { SysAdminInstruction } from './SysAdminInstruction/SysAdminInstruction';
export { NewsPage } from './NewsPage';
export { EmployeesPage } from './EmployeesPage';
export { SourcesPage } from './SourcesPage';
export { ClientsPage } from './ClientsPage';
export { ClientPage } from './ClientPage';
export { TestingAndResultsPage } from './TestingAndResultsPage';
export { default as TestingResultsReportPage } from './TestingResultsReportPage/TestingResultsReportPage';
export { QuestionsPage } from './QuestionsPage';
export { KnowledgeBasesPage } from './KnowledgeBasesPage';
export { AllKnowledgeFieldsPage } from './AllKnowledgeFieldsPage';
export { ExpertsPage } from './ExpertsPage';
export { RedactorsPage } from './RedactorsPage';
export { ResultPage } from './ResultPage';
export { UsersQuestionsPage } from './UsersQuestionsPage';
export { StatisticsPage } from './StatisticsPage';
export { ReportsPage } from './ReportsPage';
export { default as EmployeeReportPreview } from './EmployeeReportPreview/EmployeeReportPreview';
export { UserProfilePage } from './UserProfilePage';
export { TestingTasksPage } from './TestingTasksPage';
export { default as TestingAssignPage } from './TestingAssignPage/TestingAssignPage';
export { AdminProfile } from './AdminProfile';
export { GenerationPage } from './GenerationPage';
export { RequestedFunctionalDirectionsPage } from './RequestedFunctionalDirectionsPage';
export { UserGuide } from './UserGuide/UserGuide';
export { Oferta } from './Oferta/Oferta';
export { default as Lending } from './Lending/Lending';
