import { useEffect, useRef } from 'react';
export var useEffectOnce = function useEffectOnce(callback, when) {
    var hasRunOnce = useRef(false);
    useEffect(function () {
        if (when && !hasRunOnce.current) {
            callback();
            hasRunOnce.current = true;
        }
    }, [when, callback]);
};
