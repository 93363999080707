export var COLORS = {
    DARK_PURPLE_100: '#383838',
    DARK_PURPLE_200: '#56447F',
    DARK_PURPLE_300: '#8E82A9',
    PURPLE_600: '#F9F7FC',
    WHITE: '#FFFFFF',
    PURPLE_100: '#573DF9',
    PURPLE_200: '#4C17BF',
    PURPLE_300: '#331080',
    PURPLE_400: '#ECEDFF',
    PURPLE_500: '#F0EDF7',
    BLACK: '#1B1817',
    GRAY_25: '#2E2A36',
    GRAY_50: '#5A5761',
    GRAY_100: '#99969E',
    GRAY_200: '#BAB9BD',
    GRAY_300: '#DEDEE0',
    GRAY_400: '#ECECEE',
    GRAY_500: '#F3F3F5',
    GRAY_600: '#FAFAFA',
    ALERT_RED: '#FDAAAC',
    RED_100: '#F34335',
    RED_200: '#B63228',
    RED_300: '#7A221B',
    CONCRETE: '#f2f2f2',
    SCARPA_FLOW: '#57545F',
    ZIRCON: '#F5F6FF',
    LOGAN: '#B1B3CC',
    MAGNOLIA: '#FAF8FF',
    DARK_BLACK: '#000000',
    GREEN: '#ADEED6',
    WILD_BLUE_YONDER: '#8587AF',
    ALERT_YELLOW: '#FDF4AE',
    CARD_BG: '#ECEFF1',
    MOUNTAIN_MEADOW: '#20DD71'
};
