export var QUESTION_TABS = {
    all: 'all',
    right: 'right',
    wrong: 'wrong',
    feedback: 'feedback'
};
export var columns = [{
        name: '№',
        colSize: '0.5fr'
    }, {
        name: 'Вопросы',
        colSize: '3fr'
    }, {
        colSize: '2fr'
    }, {
        colSize: '3fr'
    }];
