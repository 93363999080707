var _templateObject;
function _taggedTemplateLiteral(strings, raw) { if (!raw) {
    raw = strings.slice(0);
} return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import styled from 'styled-components';
import { COLORS } from 'constants/colors';
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  cursor: pointer;\n\n  display: flex;\n  gap: 10px;\n  align-items: center;\n  justify-content: space-between;\n\n  width: 100%;\n  padding: 14px 8px 14px 16px;\n\n  background: ", ";\n  border-radius: 6px;\n\n  ", "\n\n  &:hover {\n    background: ", ";\n\n    & > div > div {\n      color: ", ";\n    }\n\n    div + div {\n      color: ", ";\n      background: ", ";\n    }\n  }\n"])), COLORS.ZIRCON, function (_ref) {
    var active = _ref.active;
    return active ? "\n    background: ".concat(COLORS.PURPLE_100, ";\n    & > div > div {\n      color: ").concat(COLORS.PURPLE_400, ";\n    }\n    div + div {\n      background: ").concat(COLORS.PURPLE_400, ";\n      color: ").concat(COLORS.DARK_PURPLE_100, ";\n    }\n  ") : null;
}, COLORS.PURPLE_100, COLORS.PURPLE_400, COLORS.DARK_PURPLE_100, COLORS.PURPLE_400);
