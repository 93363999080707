import { api } from 'services/api';
import { TAG_TYPES as TAG } from 'services/constants';
import { sanitizeClientUrl } from 'utils/apiUtils';
export var assignmentsApiSlice = api.injectEndpoints({
    endpoints: function endpoints(build) {
        return {
            // for admin and superadmins
            getEmployeeAssignmentsAdmin: build.query({
                query: function query(_ref) {
                    var clientId = _ref.clientId, employeeId = _ref.employeeId;
                    return {
                        url: "admin/client/".concat(clientId, "/employee/").concat(employeeId, "/assignment/result"),
                        method: 'GET'
                    };
                },
                providesTags: function providesTags(_data, _error, _ref2) {
                    var clientId = _ref2.clientId, employeeId = _ref2.employeeId;
                    return [{
                            type: TAG.employeeAssignments,
                            id: "".concat(clientId, "_").concat(employeeId)
                        }];
                }
            }),
            // for manager and employee himself
            getEmployeeAssignments: build.query({
                query: function query(_ref3) {
                    var employeeId = _ref3.employeeId;
                    return {
                        url: "employee/".concat(employeeId, "/assignment/result"),
                        method: 'GET'
                    };
                },
                providesTags: function providesTags(_data, _error, _ref4) {
                    var employeeId = _ref4.employeeId;
                    return [{
                            type: TAG.employeeAssignments,
                            id: employeeId
                        }];
                }
            }),
            getAssignments: build.query({
                query: function query(_ref5) {
                    var clientId = _ref5.clientId, params = _ref5.params;
                    return {
                        url: sanitizeClientUrl({
                            url: "admin/client/".concat(clientId, "/test/assignment"),
                            clientId: clientId
                        }),
                        method: 'GET',
                        params: params
                    };
                },
                providesTags: [TAG.assignments]
            }),
            deleteAssignmentAdmin: build.mutation({
                query: function query(_ref6) {
                    var assignmentId = _ref6.assignmentId;
                    return {
                        url: "admin/employee/test/".concat(assignmentId),
                        method: 'DELETE'
                    };
                },
                invalidatesTags: [TAG.assignments, TAG.employeeAssignments]
            }),
            assignEmployees: build.mutation({
                query: function query(_ref7) {
                    var subdomain = _ref7.subdomain, data = _ref7.data;
                    return {
                        url: "".concat(process.env.PROTOCOL, "://").concat(subdomain, ".").concat(process.env.HOST, "/api/admin/v2/test/assignment"),
                        method: 'POST',
                        body: data
                    };
                },
                invalidatesTags: [TAG.assignments, TAG.employeeAssignments]
            }),
            assignEmployeesAdmin: build.mutation({
                query: function query(_ref8) {
                    var clientId = _ref8.clientId, data = _ref8.data;
                    return {
                        url: "v2/client/".concat(clientId, "/employee/test/assignment"),
                        method: 'POST',
                        body: data
                    };
                },
                invalidatesTags: [TAG.assignments, TAG.employeeAssignments]
            }),
            getAssignmentsWeekGroups: build.query({
                query: function query() {
                    return {
                        url: 'admin/test/assignment/week-group',
                        method: 'GET'
                    };
                },
                providesTags: [TAG.assignmentsWeekGroups]
            })
        };
    }
});
