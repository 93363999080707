var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7;
function _taggedTemplateLiteral(strings, raw) { if (!raw) {
    raw = strings.slice(0);
} return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import styled from 'styled-components';
import { Fonts } from 'types/TextProps';
import { COLORS } from 'constants/colors';
import { Textarea as _Textarea } from 'components/UI/molecules';
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: grid;\n  grid-column: 1/5;\n  grid-template-columns: 2fr 1.5fr;\n\n  margin-top: 16px;\n  white-space: break-spaces;\n\n  background: ", ";\n  border-radius: 8px;\n"])), COLORS.PURPLE_600);
export var Answers = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: grid;\n  grid-auto-rows: max-content;\n  padding: 32px 96px 32px 55px;\n"])));
export var ButtonWrapper = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-top: 44px;\n"])));
export var Textarea = styled(_Textarea)(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  margin-top: 32px;\n\n  textarea {\n    resize: none;\n    min-height: 300px;\n    background: ", ";\n  }\n"])), COLORS.WHITE);
export var QuestionTextWrapper = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  display: grid;\n  margin-bottom: 32px;\n"])));
export var QuestionText = styled.div(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  margin-top: 20px;\n\n  font-family: ", ";\n  font-size: 16px;\n  font-weight: 400;\n  line-height: 24px;\n  color: #263238;\n"])), Fonts.NotoSans);
export var Errors = styled.div(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  display: grid;\n  grid-gap: 6px;\n"])));
