import * as React from 'react';
import { format } from 'date-fns';
export var useAttempts = function useAttempts(_ref) {
    var attemptsInfo = _ref.attemptsInfo, passed = _ref.passed;
    var attemptsLeft = attemptsInfo ? attemptsInfo.max_attempts - attemptsInfo.current_attempt : 0;
    var text = React.useMemo(function () {
        if (!attemptsInfo)
            return '';
        if (passed) {
            return 'Спасибо за отличный результат. Так держать!';
        }
        if (attemptsLeft) {
            var current_attempt = attemptsInfo.current_attempt, deadline_date = attemptsInfo.deadline_date;
            var nextAttemptNumber = current_attempt + 1;
            var dateText = deadline_date ? "\u041F\u0440\u043E\u0439\u0434\u0438\u0442\u0435 \u043E\u0446\u0435\u043D\u043A\u0443 \u0434\u043E ".concat(format(new Date(deadline_date), 'dd.MM.yyyy'), ". ") : '';
            return "\u0423 \u0432\u0430\u0441 \u0435\u0441\u0442\u044C \u041F\u043E\u043F\u044B\u0442\u043A\u0430 ".concat(nextAttemptNumber, " \u0434\u043B\u044F \u043F\u0440\u043E\u0445\u043E\u0436\u0434\u0435\u043D\u0438\u044F \u043E\u0446\u0435\u043D\u043A\u0438. ").concat(dateText, "\u0420\u0435\u0437\u0443\u043B\u044C\u0442\u0430\u0442 \u0431\u0443\u0434\u0435\u0442 \u043E\u0446\u0435\u043D\u0435\u043D \u043F\u043E \u041F\u043E\u043F\u044B\u0442\u043A\u0435 ").concat(nextAttemptNumber);
        }
        return 'Попыток больше нет';
    }, [attemptsInfo, attemptsLeft, passed]);
    return {
        text: text,
        hasAttempts: !!attemptsLeft
    };
};
