var _templateObject, _templateObject2, _templateObject3, _templateObject4;
function _taggedTemplateLiteral(strings, raw) { if (!raw) {
    raw = strings.slice(0);
} return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import styled from 'styled-components';
import { Fonts } from 'types/TextProps';
import { COLORS } from 'constants/colors';
import { Count as _Count } from 'components/UI/atoms';
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: grid;\n  grid-gap: 10px;\n  grid-template-columns: minmax(307px, max-content) minmax(192px, max-content) max-content;\n  align-items: center;\n\n  padding: 20px;\n\n  background: ", ";\n  border-radius: 10px;\n"])), COLORS.PURPLE_400);
export var Items = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: flex;\n  gap: 8px;\n  align-items: center;\n"])));
export var ItemText = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  font-family: ", ";\n  font-size: 14px;\n  font-weight: 500;\n  line-height: 20px;\n  color: ", ";\n"])), Fonts.NotoSans, COLORS.DARK_PURPLE_100);
var colors = {
    white: COLORS.WHITE,
    alert: COLORS.ALERT_RED,
    approve: COLORS.GREEN
};
export var Count = styled(_Count)(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  color: ", ";\n  background: ", ";\n"])), COLORS.DARK_PURPLE_100, function (_ref) {
    var type = _ref.type;
    return type ? colors[type] : '';
});
