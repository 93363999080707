import * as React from 'react';
import { useDefineSubdomain } from 'hooks/useDefineSubdomain';
import { profilesApiSlice } from 'modules';
export var Chunk = function Chunk(props) {
    var _useDefineSubdomain = useDefineSubdomain(), subdomain = _useDefineSubdomain.subdomain;
    // additional required props are passed from ChunksLoader
    var _ref = props, filter = _ref.filter, chunkIndex = _ref.chunkIndex, onChunkDataCallback = _ref.onChunkDataCallback, limit = _ref.limit;
    var params = React.useMemo(function () {
        var offset = limit * chunkIndex;
        return {
            page: Math.floor(offset / limit) + 1,
            query: filter
        };
    }, [chunkIndex, filter, limit]);
    var _profilesApiSlice$use = profilesApiSlice.useGetProfilesQuery({
        subdomain: subdomain,
        params: params
    }), profilesData = _profilesApiSlice$use.data;
    React.useEffect(function () {
        onChunkDataCallback({
            data: profilesData === null || profilesData === void 0 ? void 0 : profilesData.data,
            chunkIndex: chunkIndex
        });
    }, [chunkIndex, onChunkDataCallback, profilesData === null || profilesData === void 0 ? void 0 : profilesData.data]);
    return null;
};
