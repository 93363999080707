var _templateObject, _templateObject2;
function _taggedTemplateLiteral(strings, raw) { if (!raw) {
    raw = strings.slice(0);
} return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import styled from 'styled-components';
import { MEDIA } from 'uikit/vars';
var positions = {
    left: 'flex-start',
    center: 'space-around',
    default: 'flex-end'
};
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  gap: 16px;\n  align-items: center;\n  justify-content: ", ";\n\n  button {\n    width: 100%;\n  }\n\n  @media (min-width: ", ") {\n    flex-direction: row;\n\n    button {\n      width: initial;\n    }\n  }\n"])), function (props) {
    return !props.actionPosition ? positions.default : positions[props.actionPosition];
}, MEDIA.TABLET_1);
export var ButtonsWrapper = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: flex;\n  gap: 16px;\n  align-items: center;\n  justify-content: ", ";\n\n  padding: 16px 17px 16px 16px;\n"])), function (_ref) {
    var isThreeButtons = _ref.isThreeButtons;
    return isThreeButtons ? 'space-between' : 'flex-end';
});
