export var endpoints = {
    adminClients: {
        url: '/admin/clients'
    },
    redactorQuestions: {
        url: '/redactor/questions'
    },
    redactorProfile: {
        url: '/redactor/profile'
    },
    redactorDirections: {
        url: '/redactor/directions'
    },
    redactorSources: {
        url: '/redactor/sources'
    },
    redactorKnowledges: {
        url: '/redactor/knowledges'
    },
    redactorGeneration: {
        url: '/redactor/generation'
    },
    expertQuestions: {
        url: '/expert/questions'
    },
    expertProfile: {
        url: '/expert/profile'
    },
    adminRequestedFunctionalDirections: {
        url: '/admin/clients/:clientId/directions',
        name: 'directions'
    },
    adminClient: {
        url: '/admin/clients/:clientId',
        name: 'client'
    },
    adminExperts: {
        url: '/admin/experts'
    },
    adminRedactors: {
        url: '/admin/redactors'
    },
    adminResults: {
        url: '/admin/clients/:clientId/results',
        name: 'results'
    },
    adminResultsAssign: {
        url: '/admin/clients/:clientId/results/assign',
        name: 'results-assign'
    },
    knowledgeBase: '/admin/knowledge',
    generation: {
        url: '/admin/generation',
        name: 'generation'
    },
    // Client's admin
    clientSpecializations: '/admin/specializations',
    clientDirections: '/admin/specializations/:specializationId/directions',
    clientKnowledges: '/admin/specializations/:specializationId/directions/:directionId/knowledges',
    clientQuestions: '/admin/questions',
    clientResults: '/admin/results',
    clientResultsAssignmentReport: '/admin/results/report/:subordinateCode/:assignmentId',
    clientEmployees: '/admin/employees',
    clientSources: '/admin/sources',
    clientProfiles: '/admin/profiles',
    clientReports: '/admin/reports',
    clientNews: {
        url: '/admin/directions/:directionId/knowledge-fields/:knowledgeFieldsId'
    },
    adminDirections: {
        url: '/admin/knowledge/directions'
    },
    adminAllKnowledgeFields: {
        url: '/admin/knowledge/knowledges',
        name: 'knowledge-fields'
    },
    adminClientEmployees: '/admin/clients/:clientId/employees',
    adminStatisctics: {
        url: '/admin/clients/:clientId/statisctics',
        name: 'statisctics'
    },
    clientStatisctics: {
        url: '/admin/statisctics',
        name: 'statisctics'
    },
    adminClientQuestionsEmployees: {
        url: '/admin/clients/:clientId/questions',
        name: 'questions'
    },
    adminClientResultsAssign: {
        url: '/admin/results/assign',
        name: 'results-assign'
    },
    adminSources: {
        url: '/admin/knowledge/sources',
        name: 'sources'
    },
    adminQuestions: {
        url: '/admin/knowledge/questions',
        name: 'questions'
    },
    // Employee
    employeeAssignedTesting: '/employee/:employeeCode/test',
    employeeAssignedTestingResult: '/employee/:employeeCode/test/:testId/result',
    employeeAssignedTest: '/employee/:employeeCode/test/:testId',
    // Manager routes
    team: '/employee/:employeeCode/team',
    teamReport: '/employee/:employeeCode/team/report',
    subordinateAssignmentReport: '/employee/:employeeCode/team/report/:subordinateCode/:assignmentId',
    // admin
    adminSpecializations: '/admin/knowledge/specializations',
    functions: {
        url: '/functions'
    },
    sysAdminInstruction: {
        url: '/sys-admin-instruction'
    },
    userGuide: {
        url: '/user_guide'
    },
    oferta: {
        url: '/oferta'
    }
};
