var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;
function _taggedTemplateLiteral(strings, raw) { if (!raw) {
    raw = strings.slice(0);
} return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import styled from 'styled-components';
import { Fonts } from 'types/TextProps';
import { COLORS } from 'constants/colors';
import { SvgWrapper as _SvgWrapper } from 'components/UI/atoms';
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  position: relative;\n\n  display: grid;\n  grid-column-gap: 20px;\n  grid-template-columns:\n    minmax(54px, 0.5fr) minmax(248px, 2fr) minmax(200px, 2fr)\n    minmax(117px, 1fr) minmax(195px, 2fr) 1fr;\n  align-items: center;\n\n  min-height: 64px;\n  padding: 17px 24px 17px 24px;\n\n  background: ", ";\n  border: 1px solid ", ";\n\n  transition: transform 0.6s ease-in-out;\n"])), COLORS.WHITE, COLORS.PURPLE_400);
export var DateWrapper = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: flex;\n  gap: 16px;\n  align-items: center;\n"])));
export var Duration = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  font-family: ", ";\n  font-size: 14px;\n  font-weight: 500;\n  line-height: 20px;\n  color: ", ";\n"])), Fonts.NotoSans, COLORS.WILD_BLUE_YONDER);
export var Number = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  font-family: ", ";\n  font-size: 14px;\n  font-weight: 500;\n  line-height: 20px;\n  color: ", ";\n"])), Fonts.NotoSans, COLORS.LOGAN);
export var SvgWrapper = styled(_SvgWrapper)(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  justify-self: center;\n"])));
