var _templateObject, _templateObject2, _templateObject3, _templateObject4;
function _taggedTemplateLiteral(strings, raw) { if (!raw) {
    raw = strings.slice(0);
} return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import styled from 'styled-components';
import { COLORS } from 'constants/colors';
export var Container = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  position: ", ";\n  z-index: 100;\n  top: 0;\n  left: 0;\n\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n\n  width: 100%;\n  height: 100%;\n"])), function (_ref) {
    var isBlocker = _ref.isBlocker;
    return isBlocker ? 'absolute' : 'relative';
});
export var Circular = styled.svg(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  transform: rotate(-90deg);\n\n  width: ", ";\n  height: ", ";\n\n  background: transparent;\n\n  fill: none;\n  stroke-linecap: square;\n  stroke-width: 4;\n"])), function (_ref2) {
    var size = _ref2.size;
    return "".concat(size !== null && size !== void 0 ? size : 64, "px");
}, function (_ref3) {
    var size = _ref3.size;
    return "".concat(size !== null && size !== void 0 ? size : 64, "px");
});
export var OuterCircle = styled.circle(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  stroke: ", ";\n  stroke-width: 13px;\n"])), COLORS.PURPLE_400);
export var InnerCircle = styled.circle(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  transform-origin: center;\n\n  opacity: 0.9;\n\n  stroke: ", ";\n  stroke-dasharray: 312;\n  stroke-dashoffset: 0;\n  stroke-width: 13px;\n\n  animation: circleRotate 1.7s linear infinite;\n\n  @keyframes circleRotate {\n    0% {\n      transform: rotate(70deg);\n      stroke-dashoffset: 312;\n    }\n    60% {\n      stroke-dashoffset: -312;\n    }\n    100% {\n      transform: rotate(450deg);\n      stroke-dashoffset: -312;\n    }\n  }\n"])), COLORS.PURPLE_100);
