var _templateObject, _templateObject2, _templateObject3;
function _taggedTemplateLiteral(strings, raw) { if (!raw) {
    raw = strings.slice(0);
} return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import styled from 'styled-components';
import { Fonts } from 'types/TextProps';
import { COLORS } from 'constants/colors';
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: grid;\n  grid-column-gap: 20px;\n  grid-template-columns: 0.5fr 4fr 2.5fr 1fr;\n  align-items: center;\n\n  min-height: 64px;\n  padding: 17px 24px 17px 24px;\n\n  border: 1px solid ", ";\n"])), COLORS.PURPLE_400);
export var Number = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  font-family: ", ";\n  font-size: 14px;\n  font-weight: 500;\n  line-height: 20px;\n  color: ", ";\n"])), Fonts.NotoSans, COLORS.LOGAN);
export var ButtonWrapper = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  display: grid;\n  grid-gap: 8px;\n  grid-template-columns: 1fr 1fr;\n  align-items: center;\n"])));
