var OWN_TITLES = {
    OWN_TYPE_PRIVATE: 'Частная',
    // OWN_TYPE_COLLECTIVE: "Коллективная",
    OWN_TYPE_STATE: 'Государственная',
    OWN_TYPE_MUNICIPAL: 'Муниципальная',
    OWN_TYPE_OTHER: 'Иная'
};
var OWN_NUMBERS = {
    OWN_TYPE_PRIVATE: '10',
    // OWN_TYPE_COLLECTIVE: "20",
    OWN_TYPE_STATE: '30',
    OWN_TYPE_MUNICIPAL: '40',
    OWN_TYPE_OTHER: '50'
};
export var OWN_TYPES_OPTIONS = [{
        id: OWN_NUMBERS.OWN_TYPE_PRIVATE,
        title: OWN_TITLES.OWN_TYPE_PRIVATE
    },
    // {
    //   id: OWN_NUMBERS.OWN_TYPE_COLLECTIVE,
    //   title: OWN_TITLES.OWN_TYPE_COLLECTIVE,
    // },
    {
        id: OWN_NUMBERS.OWN_TYPE_STATE,
        title: OWN_TITLES.OWN_TYPE_STATE
    }, {
        id: OWN_NUMBERS.OWN_TYPE_MUNICIPAL,
        title: OWN_TITLES.OWN_TYPE_MUNICIPAL
    }, {
        id: OWN_NUMBERS.OWN_TYPE_OTHER,
        title: OWN_TITLES.OWN_TYPE_OTHER
    }];
