export default [{
        id: '62dc3269-32e1-4e5d-ba06-184435ed5c47',
        employee_id: 'd8df7071-d3cd-491e-a34b-86dc7a5d6aeb',
        question_count: 7,
        knowledge_field_id: 'a0abb578-be3a-4b0e-8c01-5cf5737296c0',
        status: {
            status: 20,
            status_name: 'Завершено'
        },
        passing_test_time: '7 min',
        left_time: 0,
        dead_line: '2023-10-31',
        created_at: '2023-10-08T17:19:12.000000Z',
        start_date: '2023-10-09 07:39:50',
        end_date: '2023-10-09 07:48:23',
        knowledgeField: {
            id: 'a0abb578-be3a-4b0e-8c01-5cf5737296c0',
            title: 'Правила производства электромонтажных работ и охрана труда',
            active_status: true,
            functional_direction_id: '1aa9ceb4-74c6-4173-8029-781c189363c0',
            functional_direction_title: 'Электромонтажные работы',
            question_cnt: 0,
            information_source_cnt: 9,
            employee_cnt: 8,
            test_result_cnt: 17,
            created_at: '02.10.2023',
            base_id: '5a6ead49-569a-4d18-ad97-99457c92ab38',
            functionalDirection: {
                id: '1aa9ceb4-74c6-4173-8029-781c189363c0',
                title: 'Электромонтажные работы',
                base_id: '69f12fd3-87ba-4b17-a2ac-7c4cdcf01887',
                status: 20,
                created_at: '2023-10-02T14:19:37.000000Z',
                updated_at: '2023-10-02T14:25:38.000000Z',
                deleted_at: null
            }
        },
        employee: {
            id: 'd8df7071-d3cd-491e-a34b-86dc7a5d6aeb',
            fio: 'Жигулева Марина Юрьевна',
            email: 'm.zhiguleva@univertus.com',
            position: 'Главный бухгалтер',
            position_id: '10865709',
            personal_number: '28100400',
            code: 'uiazFpZQYKG2YClIFnw6tW5TS9OBra2Q',
            department: null,
            head_id: null,
            is_head: true,
            head_email: 'm.zhiguleva@univertus.com',
            head_position: 'Главный бухгалтер',
            head_position_id: '10865709'
        },
        assignmentQuestions: [{
                assignmentQuestion: {
                    id: '05596df5-91c9-4c5c-832d-7ebad06e4a63',
                    assignment_id: '62dc3269-32e1-4e5d-ba06-184435ed5c47',
                    question_id: 'ec3527d1-d9d4-4000-b7ee-336a1d361fec',
                    is_correct: false,
                    answer_text: 'Регистр',
                    answer_date: '2023-10-09',
                    feedback: [],
                    employee_answer: ['cacc2d1c-cb19-42c8-a294-631235a42a7c'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '0f6b4120-54d4-49d8-91b7-ecb8fba96291',
                    assignment_id: '62dc3269-32e1-4e5d-ba06-184435ed5c47',
                    question_id: '0763f5c8-00a0-449a-a703-f469d13cf2d7',
                    is_correct: false,
                    answer_text: 'Человеческие силы',
                    answer_date: '2023-10-09',
                    feedback: [],
                    employee_answer: ['37fc180e-d3c3-49be-be93-9650fa44aeb8'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '38624df6-bf72-4186-baf0-8f3d38796139',
                    assignment_id: '62dc3269-32e1-4e5d-ba06-184435ed5c47',
                    question_id: '314b2f52-fc65-4cad-bd6c-7597ae7bfc86',
                    is_correct: true,
                    answer_text: 'Резонансные кривые',
                    answer_date: '2023-10-09',
                    feedback: [],
                    employee_answer: ['fbc307b9-9232-44ef-afc5-1a98f564a5e4'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '67d2ba07-6726-4ce4-9d7c-48666a0253c2',
                    assignment_id: '62dc3269-32e1-4e5d-ba06-184435ed5c47',
                    question_id: '4e8e87a5-b3f8-457e-b6da-3759f56f1c25',
                    is_correct: false,
                    answer_text: 'Кг/м3',
                    answer_date: '2023-10-09',
                    feedback: [],
                    employee_answer: ['1963a00b-4247-4f5c-a03a-9add5de22764'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '87af6dcb-2833-47a1-8318-1c0862f11f52',
                    assignment_id: '62dc3269-32e1-4e5d-ba06-184435ed5c47',
                    question_id: '8a631fc8-96e5-4537-a4f9-0ba372e13a0e',
                    is_correct: true,
                    answer_text: 'Мультиплексор',
                    answer_date: '2023-10-09',
                    feedback: [],
                    employee_answer: ['637e7eea-0f44-473a-b72d-0ed563579ccd'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'c1279727-8ce7-4aa1-9f1d-8cfed4206b55',
                    assignment_id: '62dc3269-32e1-4e5d-ba06-184435ed5c47',
                    question_id: '9b108fed-b6e6-4363-b591-8631e6385941',
                    is_correct: false,
                    answer_text: 'Операнд',
                    answer_date: '2023-10-09',
                    feedback: [],
                    employee_answer: ['767b4c8d-a492-476d-9f50-a35d461f1b00'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'f8371e3b-1a03-4730-b039-fdec2a0ada14',
                    assignment_id: '62dc3269-32e1-4e5d-ba06-184435ed5c47',
                    question_id: '20ffcf06-780a-4db9-a93c-25f394d0b14b',
                    is_correct: false,
                    answer_text: 'Поляризация',
                    answer_date: '2023-10-09',
                    feedback: [],
                    employee_answer: ['c27734ec-9288-47b1-b56d-00b9751c55a2'],
                    question: []
                }
            }],
        assignmentResults: {
            id: 'ad65c544-0b51-4dae-8b54-a50df7791045',
            assignment_id: '62dc3269-32e1-4e5d-ba06-184435ed5c47',
            is_success: false,
            question_count: 7,
            right_answers_count: 7,
            wrong_answers_count: 5,
            created_at: '2023-10-09T07:48:23.000000Z',
            updated_at: '2023-10-09T07:48:23.000000Z'
        }
    }, {
        id: '05c9ba8e-9ce6-426e-b4c5-e4455f558e44',
        employee_id: '66b5e430-4593-41d3-9fe4-66dc0bc1f103',
        question_count: 7,
        knowledge_field_id: 'a0abb578-be3a-4b0e-8c01-5cf5737296c0',
        status: {
            status: 20,
            status_name: 'Завершено'
        },
        passing_test_time: '7 min',
        left_time: 0,
        dead_line: '2023-10-31',
        created_at: '2023-10-08T17:19:12.000000Z',
        start_date: '2023-10-09 08:43:15',
        end_date: '2023-10-09 08:50:17',
        knowledgeField: {
            id: 'a0abb578-be3a-4b0e-8c01-5cf5737296c0',
            title: 'Правила производства электромонтажных работ и охрана труда',
            active_status: true,
            functional_direction_id: '1aa9ceb4-74c6-4173-8029-781c189363c0',
            functional_direction_title: 'Электромонтажные работы',
            question_cnt: 0,
            information_source_cnt: 9,
            employee_cnt: 8,
            test_result_cnt: 17,
            created_at: '02.10.2023',
            base_id: '5a6ead49-569a-4d18-ad97-99457c92ab38',
            functionalDirection: {
                id: '1aa9ceb4-74c6-4173-8029-781c189363c0',
                title: 'Электромонтажные работы',
                base_id: '69f12fd3-87ba-4b17-a2ac-7c4cdcf01887',
                status: 20,
                created_at: '2023-10-02T14:19:37.000000Z',
                updated_at: '2023-10-02T14:25:38.000000Z',
                deleted_at: null
            }
        },
        employee: {
            id: '66b5e430-4593-41d3-9fe4-66dc0bc1f103',
            fio: 'Варнаев Игорь Вячеславович',
            email: 'i.varnaev@univertus.com',
            position: 'Начальник отдела охраны труда',
            position_id: '10847233',
            personal_number: '20329018',
            code: 'bAAq49EoC5DD2W0DHsyOOCNr4IGjP69x',
            department: null,
            head_id: null,
            is_head: true,
            head_email: 'i.varnaev@univertus.com',
            head_position: 'Начальник отдела охраны труда',
            head_position_id: '10847233'
        },
        assignmentQuestions: [{
                assignmentQuestion: {
                    id: '0c6f8662-1fe6-4ae4-be1b-e347c8264b28',
                    assignment_id: '05c9ba8e-9ce6-426e-b4c5-e4455f558e44',
                    question_id: '8a631fc8-96e5-4537-a4f9-0ba372e13a0e',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '260ffe33-9362-4fba-8ab4-6e13a557ec9e',
                    assignment_id: '05c9ba8e-9ce6-426e-b4c5-e4455f558e44',
                    question_id: '314b2f52-fc65-4cad-bd6c-7597ae7bfc86',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '44947820-90ff-493c-b5d5-b0298ec70613',
                    assignment_id: '05c9ba8e-9ce6-426e-b4c5-e4455f558e44',
                    question_id: '9b108fed-b6e6-4363-b591-8631e6385941',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'a0fbfe58-04f2-47c9-b484-333e1c992990',
                    assignment_id: '05c9ba8e-9ce6-426e-b4c5-e4455f558e44',
                    question_id: '20ffcf06-780a-4db9-a93c-25f394d0b14b',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'a663cb13-5ff3-4041-b643-f496bac6b637',
                    assignment_id: '05c9ba8e-9ce6-426e-b4c5-e4455f558e44',
                    question_id: '0763f5c8-00a0-449a-a703-f469d13cf2d7',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'ebdce460-a6e1-4fdf-951c-95855aa9b566',
                    assignment_id: '05c9ba8e-9ce6-426e-b4c5-e4455f558e44',
                    question_id: 'ec3527d1-d9d4-4000-b7ee-336a1d361fec',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'fffb0894-33bd-421f-8b03-02a3a1d6efbe',
                    assignment_id: '05c9ba8e-9ce6-426e-b4c5-e4455f558e44',
                    question_id: '4e8e87a5-b3f8-457e-b6da-3759f56f1c25',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }],
        assignmentResults: {
            id: '7fa0adcc-fc1b-4d7d-b46d-e4f8e7ac507b',
            assignment_id: '05c9ba8e-9ce6-426e-b4c5-e4455f558e44',
            is_success: false,
            question_count: 7,
            right_answers_count: 7,
            wrong_answers_count: 7,
            created_at: '2023-10-09T08:50:17.000000Z',
            updated_at: '2023-10-09T08:50:17.000000Z'
        }
    }, {
        id: '76ae3405-74ec-4b04-96d9-bb529fba13da',
        employee_id: '2f5c37b1-25a7-4fc3-9d3a-46d27c99fb68',
        question_count: 6,
        knowledge_field_id: 'a0abb578-be3a-4b0e-8c01-5cf5737296c0',
        status: {
            status: 20,
            status_name: 'Завершено'
        },
        passing_test_time: '6 min',
        left_time: 0,
        dead_line: '2023-10-31',
        created_at: '2023-10-08T17:27:36.000000Z',
        start_date: '2023-10-09 09:37:58',
        end_date: '2023-10-09 09:38:41',
        knowledgeField: {
            id: 'a0abb578-be3a-4b0e-8c01-5cf5737296c0',
            title: 'Правила производства электромонтажных работ и охрана труда',
            active_status: true,
            functional_direction_id: '1aa9ceb4-74c6-4173-8029-781c189363c0',
            functional_direction_title: 'Электромонтажные работы',
            question_cnt: 0,
            information_source_cnt: 9,
            employee_cnt: 8,
            test_result_cnt: 17,
            created_at: '02.10.2023',
            base_id: '5a6ead49-569a-4d18-ad97-99457c92ab38',
            functionalDirection: {
                id: '1aa9ceb4-74c6-4173-8029-781c189363c0',
                title: 'Электромонтажные работы',
                base_id: '69f12fd3-87ba-4b17-a2ac-7c4cdcf01887',
                status: 20,
                created_at: '2023-10-02T14:19:37.000000Z',
                updated_at: '2023-10-02T14:25:38.000000Z',
                deleted_at: null
            }
        },
        employee: {
            id: '2f5c37b1-25a7-4fc3-9d3a-46d27c99fb68',
            fio: 'Абдылов \tДмитрий \tСергеевич',
            email: 'd.abdylov@univertus.com',
            position: 'Специалист по безопасности и охране труда',
            position_id: '10859310',
            personal_number: '20203004',
            code: '5jC4vnfQyVuHIErHZPxDQP1XZvaPuttu',
            department: 'Охрана труда',
            head_id: '66b5e430-4593-41d3-9fe4-66dc0bc1f103',
            is_head: false,
            head_email: 'd.abdylov@univertus.com',
            head_position: 'Специалист по безопасности и охране труда',
            head_position_id: '10859310'
        },
        assignmentQuestions: [{
                assignmentQuestion: {
                    id: '376013c4-099f-4439-a947-2efee376b112',
                    assignment_id: '76ae3405-74ec-4b04-96d9-bb529fba13da',
                    question_id: '314b2f52-fc65-4cad-bd6c-7597ae7bfc86',
                    is_correct: false,
                    answer_text: 'Линейные кривые',
                    answer_date: '2023-10-09',
                    feedback: [],
                    employee_answer: ['9842cb90-1987-4a87-bc5f-d190753e4b59'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '447bc965-3f29-4678-bc2b-7e445bb2c860',
                    assignment_id: '76ae3405-74ec-4b04-96d9-bb529fba13da',
                    question_id: '20ffcf06-780a-4db9-a93c-25f394d0b14b',
                    is_correct: false,
                    answer_text: 'Поляризация',
                    answer_date: '2023-10-09',
                    feedback: [],
                    employee_answer: ['c27734ec-9288-47b1-b56d-00b9751c55a2'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '63177846-b68c-48a4-a548-ae7d761f7d24',
                    assignment_id: '76ae3405-74ec-4b04-96d9-bb529fba13da',
                    question_id: 'ec3527d1-d9d4-4000-b7ee-336a1d361fec',
                    is_correct: false,
                    answer_text: 'Операнд',
                    answer_date: '2023-10-09',
                    feedback: [],
                    employee_answer: ['2e352c9b-fec6-475d-abab-d9f05fe91b9c'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '761fb9b0-e7b8-469b-b3f5-6281ca7342aa',
                    assignment_id: '76ae3405-74ec-4b04-96d9-bb529fba13da',
                    question_id: '8a631fc8-96e5-4537-a4f9-0ba372e13a0e',
                    is_correct: false,
                    answer_text: 'Резистор',
                    answer_date: '2023-10-09',
                    feedback: [],
                    employee_answer: ['c7ef3da0-901b-4688-be4a-c01ec83c9efa'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'abd37aae-027d-4f18-9350-b9395a651072',
                    assignment_id: '76ae3405-74ec-4b04-96d9-bb529fba13da',
                    question_id: '9b108fed-b6e6-4363-b591-8631e6385941',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'cc5bd464-08b6-4ae6-ba49-712c6dbde697',
                    assignment_id: '76ae3405-74ec-4b04-96d9-bb529fba13da',
                    question_id: '4e8e87a5-b3f8-457e-b6da-3759f56f1c25',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }],
        assignmentResults: {
            id: 'e1fed920-2b02-4027-a56a-5602d72389dd',
            assignment_id: '76ae3405-74ec-4b04-96d9-bb529fba13da',
            is_success: false,
            question_count: 6,
            right_answers_count: 4,
            wrong_answers_count: 6,
            created_at: '2023-10-09T09:38:41.000000Z',
            updated_at: '2023-10-09T09:38:41.000000Z'
        }
    }, {
        id: '7c43aca9-82e5-4993-aa67-937dcbf16620',
        employee_id: '44fa4892-bfa5-4483-9b34-e3dd0f8e0dfa',
        question_count: 11,
        knowledge_field_id: 'a0abb578-be3a-4b0e-8c01-5cf5737296c0',
        status: {
            status: 20,
            status_name: 'Завершено'
        },
        passing_test_time: '11 min',
        left_time: 0,
        dead_line: '2023-10-31',
        created_at: '2023-10-08T17:27:53.000000Z',
        start_date: '2023-10-09 14:23:35',
        end_date: '2023-10-09 14:27:57',
        knowledgeField: {
            id: 'a0abb578-be3a-4b0e-8c01-5cf5737296c0',
            title: 'Правила производства электромонтажных работ и охрана труда',
            active_status: true,
            functional_direction_id: '1aa9ceb4-74c6-4173-8029-781c189363c0',
            functional_direction_title: 'Электромонтажные работы',
            question_cnt: 0,
            information_source_cnt: 9,
            employee_cnt: 8,
            test_result_cnt: 17,
            created_at: '02.10.2023',
            base_id: '5a6ead49-569a-4d18-ad97-99457c92ab38',
            functionalDirection: {
                id: '1aa9ceb4-74c6-4173-8029-781c189363c0',
                title: 'Электромонтажные работы',
                base_id: '69f12fd3-87ba-4b17-a2ac-7c4cdcf01887',
                status: 20,
                created_at: '2023-10-02T14:19:37.000000Z',
                updated_at: '2023-10-02T14:25:38.000000Z',
                deleted_at: null
            }
        },
        employee: {
            id: '44fa4892-bfa5-4483-9b34-e3dd0f8e0dfa',
            fio: 'Кельманов \tРуслан \tАлексеевич',
            email: 'r.kelmanov@univertus.com',
            position: 'Специалист по безопасности и охране труда',
            position_id: '50345059',
            personal_number: '20426882',
            code: 'd3oWaJnsk3e1v4aX1XHczal4bdk3c7Vk',
            department: 'Охрана труда',
            head_id: '66b5e430-4593-41d3-9fe4-66dc0bc1f103',
            is_head: false,
            head_email: 'r.kelmanov@univertus.com',
            head_position: 'Специалист по безопасности и охране труда',
            head_position_id: '50345059'
        },
        assignmentQuestions: [{
                assignmentQuestion: {
                    id: '1fb15ecb-f4bb-44f3-8735-b2d7969bbdec',
                    assignment_id: '7c43aca9-82e5-4993-aa67-937dcbf16620',
                    question_id: '314b2f52-fc65-4cad-bd6c-7597ae7bfc86',
                    is_correct: true,
                    answer_text: 'Резонансные кривые',
                    answer_date: '2023-10-09',
                    feedback: [],
                    employee_answer: ['fbc307b9-9232-44ef-afc5-1a98f564a5e4'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '394f1988-2620-4423-afd5-7bca82d55ce8',
                    assignment_id: '7c43aca9-82e5-4993-aa67-937dcbf16620',
                    question_id: '96b5761e-0ae8-4e3b-bf09-ca2c638e8750',
                    is_correct: false,
                    answer_text: 'Способность',
                    answer_date: '2023-10-09',
                    feedback: [],
                    employee_answer: ['f2b005e2-e906-4dd4-a8cb-3a9a26d1ef18'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '3ce7982b-e14e-488b-b4e0-e97da7239314',
                    assignment_id: '7c43aca9-82e5-4993-aa67-937dcbf16620',
                    question_id: '0763f5c8-00a0-449a-a703-f469d13cf2d7',
                    is_correct: false,
                    answer_text: 'Человеческие силы',
                    answer_date: '2023-10-09',
                    feedback: [],
                    employee_answer: ['37fc180e-d3c3-49be-be93-9650fa44aeb8'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '478f7868-e435-45c2-b18e-87c8f47e20b6',
                    assignment_id: '7c43aca9-82e5-4993-aa67-937dcbf16620',
                    question_id: '20ffcf06-780a-4db9-a93c-25f394d0b14b',
                    is_correct: false,
                    answer_text: 'Поляризация',
                    answer_date: '2023-10-09',
                    feedback: [],
                    employee_answer: ['c27734ec-9288-47b1-b56d-00b9751c55a2'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '54d5870d-3843-49b4-9f01-5bc3028de85f',
                    assignment_id: '7c43aca9-82e5-4993-aa67-937dcbf16620',
                    question_id: '2a40a92e-26c9-4961-a0a2-2b085688e790',
                    is_correct: false,
                    answer_text: 'Полупроводниковый компаратор',
                    answer_date: '2023-10-09',
                    feedback: [],
                    employee_answer: ['ef665321-196c-4809-9aab-f64da71c7faf'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '5ab1966b-21b3-46ef-82db-efd9df1845ae',
                    assignment_id: '7c43aca9-82e5-4993-aa67-937dcbf16620',
                    question_id: '5569c13e-f57e-49c8-9f4b-0c293f9e197e',
                    is_correct: false,
                    answer_text: 'Перераспределения',
                    answer_date: '2023-10-09',
                    feedback: [],
                    employee_answer: ['d73bb64a-ff58-4f52-9394-6f75be295b12'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '65ceaf70-298b-4084-8f6e-f54538d2b499',
                    assignment_id: '7c43aca9-82e5-4993-aa67-937dcbf16620',
                    question_id: 'ec3527d1-d9d4-4000-b7ee-336a1d361fec',
                    is_correct: false,
                    answer_text: 'Операнд',
                    answer_date: '2023-10-09',
                    feedback: [],
                    employee_answer: ['2e352c9b-fec6-475d-abab-d9f05fe91b9c'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '7c03274c-af2f-41f1-9351-9e37fa44e6de',
                    assignment_id: '7c43aca9-82e5-4993-aa67-937dcbf16620',
                    question_id: '8a631fc8-96e5-4537-a4f9-0ba372e13a0e',
                    is_correct: false,
                    answer_text: 'Резистор',
                    answer_date: '2023-10-09',
                    feedback: [],
                    employee_answer: ['c7ef3da0-901b-4688-be4a-c01ec83c9efa'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'b9fee6f8-a4dc-42be-92b9-5189eb0f6deb',
                    assignment_id: '7c43aca9-82e5-4993-aa67-937dcbf16620',
                    question_id: '6fc5952d-e30f-44a6-923d-ab6bd1d2fb8b',
                    is_correct: false,
                    answer_text: 'Электрическое поле',
                    answer_date: '2023-10-09',
                    feedback: [],
                    employee_answer: ['0e130774-d26f-4a19-b305-501344923161'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'e7217190-e25d-49e6-b8c6-b444749642c6',
                    assignment_id: '7c43aca9-82e5-4993-aa67-937dcbf16620',
                    question_id: '9b108fed-b6e6-4363-b591-8631e6385941',
                    is_correct: false,
                    answer_text: 'Выпрямители',
                    answer_date: '2023-10-09',
                    feedback: [],
                    employee_answer: ['b21a01ad-ca6d-4350-994a-80a81443e73b'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'e8b47bfd-652a-4a22-8c94-c7971e5d8903',
                    assignment_id: '7c43aca9-82e5-4993-aa67-937dcbf16620',
                    question_id: '4e8e87a5-b3f8-457e-b6da-3759f56f1c25',
                    is_correct: false,
                    answer_text: 'Мг/см',
                    answer_date: '2023-10-09',
                    feedback: [],
                    employee_answer: ['86e0c4ae-c680-40a5-a0ab-6ff5fa7eb229'],
                    question: []
                }
            }],
        assignmentResults: {
            id: 'fda5818b-9e06-4c67-adce-12bf23ee6ce1',
            assignment_id: '7c43aca9-82e5-4993-aa67-937dcbf16620',
            is_success: false,
            question_count: 11,
            right_answers_count: 1,
            wrong_answers_count: 10,
            created_at: '2023-10-09T14:27:57.000000Z',
            updated_at: '2023-10-09T14:27:57.000000Z'
        }
    }, {
        id: '858f1388-8717-41a1-b99f-3391ab5906a4',
        employee_id: '173e512f-52e5-4b87-b291-751dbbc2d6d2',
        question_count: 13,
        knowledge_field_id: 'a0abb578-be3a-4b0e-8c01-5cf5737296c0',
        status: {
            status: 20,
            status_name: 'Завершено'
        },
        passing_test_time: '13 min',
        left_time: 0,
        dead_line: '2023-10-31',
        created_at: '2023-10-08T17:27:53.000000Z',
        start_date: '2023-10-09 09:51:16',
        end_date: '2023-10-09 09:54:36',
        knowledgeField: {
            id: 'a0abb578-be3a-4b0e-8c01-5cf5737296c0',
            title: 'Правила производства электромонтажных работ и охрана труда',
            active_status: true,
            functional_direction_id: '1aa9ceb4-74c6-4173-8029-781c189363c0',
            functional_direction_title: 'Электромонтажные работы',
            question_cnt: 0,
            information_source_cnt: 9,
            employee_cnt: 8,
            test_result_cnt: 17,
            created_at: '02.10.2023',
            base_id: '5a6ead49-569a-4d18-ad97-99457c92ab38',
            functionalDirection: {
                id: '1aa9ceb4-74c6-4173-8029-781c189363c0',
                title: 'Электромонтажные работы',
                base_id: '69f12fd3-87ba-4b17-a2ac-7c4cdcf01887',
                status: 20,
                created_at: '2023-10-02T14:19:37.000000Z',
                updated_at: '2023-10-02T14:25:38.000000Z',
                deleted_at: null
            }
        },
        employee: {
            id: '173e512f-52e5-4b87-b291-751dbbc2d6d2',
            fio: 'Мерцаев \tСергей \tГеннадьевич',
            email: 's.mertsaev@univertus.com',
            position: 'Бухгалтер по учету расч. с контрагентами',
            position_id: '50369736',
            personal_number: '16001854',
            code: 'mQv5LheHyD9QuKLX0owXzcIIs6sJoAnT',
            department: 'Бухгалтерия',
            head_id: 'd8df7071-d3cd-491e-a34b-86dc7a5d6aeb',
            is_head: false,
            head_email: 's.mertsaev@univertus.com',
            head_position: 'Бухгалтер по учету расч. с контрагентами',
            head_position_id: '50369736'
        },
        assignmentQuestions: [{
                assignmentQuestion: {
                    id: '01d61b96-5e34-4238-acb2-d56093f3db92',
                    assignment_id: '858f1388-8717-41a1-b99f-3391ab5906a4',
                    question_id: '20ffcf06-780a-4db9-a93c-25f394d0b14b',
                    is_correct: false,
                    answer_text: 'Дисперсия',
                    answer_date: '2023-10-09',
                    feedback: [],
                    employee_answer: ['d36b21f9-701a-435d-9912-38981749d28e'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '07244848-e85c-4a3b-9c7f-a02a462860b3',
                    assignment_id: '858f1388-8717-41a1-b99f-3391ab5906a4',
                    question_id: 'ec3527d1-d9d4-4000-b7ee-336a1d361fec',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '0d1e7e8d-bc01-41bb-ae95-52dbbc2a2608',
                    assignment_id: '858f1388-8717-41a1-b99f-3391ab5906a4',
                    question_id: '8a631fc8-96e5-4537-a4f9-0ba372e13a0e',
                    is_correct: false,
                    answer_text: 'Разъём',
                    answer_date: '2023-10-09',
                    feedback: [],
                    employee_answer: ['4506800e-6429-4d68-946c-a52399057a37'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '160174d7-5096-4ea3-b8b0-63a525a2f320',
                    assignment_id: '858f1388-8717-41a1-b99f-3391ab5906a4',
                    question_id: 'b2ef7918-7334-4dd2-ad5f-2edd6f4b723b',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '1c235998-e37a-4c4d-8232-0ed648a45a3e',
                    assignment_id: '858f1388-8717-41a1-b99f-3391ab5906a4',
                    question_id: '96b5761e-0ae8-4e3b-bf09-ca2c638e8750',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '1ca974a2-1ad5-48b6-823b-23fa0ce38c5f',
                    assignment_id: '858f1388-8717-41a1-b99f-3391ab5906a4',
                    question_id: '20791567-063b-4ab9-bfb6-41b34e48a0c7',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '5f33431e-b88d-4cb3-afc1-557bff2c64d1',
                    assignment_id: '858f1388-8717-41a1-b99f-3391ab5906a4',
                    question_id: '314b2f52-fc65-4cad-bd6c-7597ae7bfc86',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '699ba01f-521e-4d88-888b-8a9810a97761',
                    assignment_id: '858f1388-8717-41a1-b99f-3391ab5906a4',
                    question_id: '5569c13e-f57e-49c8-9f4b-0c293f9e197e',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '84cef901-1b2b-4ab6-8faa-f386ef083098',
                    assignment_id: '858f1388-8717-41a1-b99f-3391ab5906a4',
                    question_id: '2a40a92e-26c9-4961-a0a2-2b085688e790',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '87407243-1639-4653-8ba7-9262d00428de',
                    assignment_id: '858f1388-8717-41a1-b99f-3391ab5906a4',
                    question_id: '0763f5c8-00a0-449a-a703-f469d13cf2d7',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'c04c143f-fea5-4cd0-99cd-70d722d07f1f',
                    assignment_id: '858f1388-8717-41a1-b99f-3391ab5906a4',
                    question_id: '6fc5952d-e30f-44a6-923d-ab6bd1d2fb8b',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'e48e5034-c011-4582-8047-34d6e3363390',
                    assignment_id: '858f1388-8717-41a1-b99f-3391ab5906a4',
                    question_id: '4e8e87a5-b3f8-457e-b6da-3759f56f1c25',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'ee82335b-6879-41b6-a286-fb01e783496d',
                    assignment_id: '858f1388-8717-41a1-b99f-3391ab5906a4',
                    question_id: '9b108fed-b6e6-4363-b591-8631e6385941',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }],
        assignmentResults: {
            id: '62482369-d3be-489a-8285-6654961d30ec',
            assignment_id: '858f1388-8717-41a1-b99f-3391ab5906a4',
            is_success: false,
            question_count: 13,
            right_answers_count: 0,
            wrong_answers_count: 13,
            created_at: '2023-10-09T09:54:36.000000Z',
            updated_at: '2023-10-09T09:54:36.000000Z'
        }
    }, {
        id: 'a95ff12f-f7b7-46d7-b4c1-a1bdeba4c69d',
        employee_id: '3a414350-ea6d-4284-96f0-f0866bd97f3d',
        question_count: 17,
        knowledge_field_id: 'a0abb578-be3a-4b0e-8c01-5cf5737296c0',
        status: {
            status: 20,
            status_name: 'Завершено'
        },
        passing_test_time: '17 min',
        left_time: 0,
        dead_line: '2023-10-31',
        created_at: '2023-10-08T17:27:53.000000Z',
        start_date: '2023-10-09 14:48:34',
        end_date: '2023-10-09 15:10:01',
        knowledgeField: {
            id: 'a0abb578-be3a-4b0e-8c01-5cf5737296c0',
            title: 'Правила производства электромонтажных работ и охрана труда',
            active_status: true,
            functional_direction_id: '1aa9ceb4-74c6-4173-8029-781c189363c0',
            functional_direction_title: 'Электромонтажные работы',
            question_cnt: 0,
            information_source_cnt: 9,
            employee_cnt: 8,
            test_result_cnt: 17,
            created_at: '02.10.2023',
            base_id: '5a6ead49-569a-4d18-ad97-99457c92ab38',
            functionalDirection: {
                id: '1aa9ceb4-74c6-4173-8029-781c189363c0',
                title: 'Электромонтажные работы',
                base_id: '69f12fd3-87ba-4b17-a2ac-7c4cdcf01887',
                status: 20,
                created_at: '2023-10-02T14:19:37.000000Z',
                updated_at: '2023-10-02T14:25:38.000000Z',
                deleted_at: null
            }
        },
        employee: {
            id: '3a414350-ea6d-4284-96f0-f0866bd97f3d',
            fio: 'Красикова \tДарья \tСергеевна',
            email: 'd.krasikova@univertus.com',
            position: 'Специалист по расчету зараб платы',
            position_id: '50368737',
            personal_number: '14000284',
            code: 'XyMS5F8hPokMX5OgzIVvXafZjHn1T3mN',
            department: 'Бухгалтерия',
            head_id: 'd8df7071-d3cd-491e-a34b-86dc7a5d6aeb',
            is_head: false,
            head_email: 'd.krasikova@univertus.com',
            head_position: 'Специалист по расчету зараб платы',
            head_position_id: '50368737'
        },
        assignmentQuestions: [{
                assignmentQuestion: {
                    id: '026b0c75-9d5c-4035-b3af-3e53e925adec',
                    assignment_id: 'a95ff12f-f7b7-46d7-b4c1-a1bdeba4c69d',
                    question_id: '9b108fed-b6e6-4363-b591-8631e6385941',
                    is_correct: false,
                    answer_text: 'Операнд',
                    answer_date: '2023-10-09',
                    feedback: [],
                    employee_answer: ['767b4c8d-a492-476d-9f50-a35d461f1b00'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '0a8ee06c-c0d8-42b3-bc36-b2a66ef0ed54',
                    assignment_id: 'a95ff12f-f7b7-46d7-b4c1-a1bdeba4c69d',
                    question_id: '2a40a92e-26c9-4961-a0a2-2b085688e790',
                    is_correct: true,
                    answer_text: 'Полупроводниковый диод',
                    answer_date: '2023-10-09',
                    feedback: [],
                    employee_answer: ['aac261a2-e899-4bb8-95f6-1da813b0fc2e'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '119e1672-b054-4274-bd9c-137c81c5b265',
                    assignment_id: 'a95ff12f-f7b7-46d7-b4c1-a1bdeba4c69d',
                    question_id: '8a631fc8-96e5-4537-a4f9-0ba372e13a0e',
                    is_correct: false,
                    answer_text: 'Резистор',
                    answer_date: '2023-10-09',
                    feedback: [],
                    employee_answer: ['c7ef3da0-901b-4688-be4a-c01ec83c9efa'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '2d130807-5ba1-4220-877e-6c17a8e91cbc',
                    assignment_id: 'a95ff12f-f7b7-46d7-b4c1-a1bdeba4c69d',
                    question_id: '96b5761e-0ae8-4e3b-bf09-ca2c638e8750',
                    is_correct: true,
                    answer_text: 'Энергия',
                    answer_date: '2023-10-09',
                    feedback: [],
                    employee_answer: ['90681e48-4e38-4b45-8a3d-6994429ce7c0'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '2f95307d-f201-4cd5-8129-2b6070d7660b',
                    assignment_id: 'a95ff12f-f7b7-46d7-b4c1-a1bdeba4c69d',
                    question_id: '80801bfc-a80c-40ff-90f7-cd14abfe0cee',
                    is_correct: false,
                    answer_text: 'Распознавание информации',
                    answer_date: '2023-10-09',
                    feedback: [],
                    employee_answer: ['e9a5b3ed-fe26-4570-b19f-4a035b10c3e1'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '467dae2e-293d-4f09-87e4-438c7d803a4f',
                    assignment_id: 'a95ff12f-f7b7-46d7-b4c1-a1bdeba4c69d',
                    question_id: 'ec3527d1-d9d4-4000-b7ee-336a1d361fec',
                    is_correct: true,
                    answer_text: 'Выпрямители',
                    answer_date: '2023-10-09',
                    feedback: [],
                    employee_answer: ['e7a550a7-588c-458d-87cd-e936f17db96d'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '591986d4-d3f0-453b-b82c-cb7afdd83dda',
                    assignment_id: 'a95ff12f-f7b7-46d7-b4c1-a1bdeba4c69d',
                    question_id: '1af57b14-629a-4bbb-b7ba-e766a736f8d7',
                    is_correct: false,
                    answer_text: 'Динисторы',
                    answer_date: '2023-10-09',
                    feedback: [],
                    employee_answer: ['3c3f6e3c-9e8f-49ab-a330-e6e80db66c58'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '653dd31a-41c9-4edf-a9dd-a8111c85bead',
                    assignment_id: 'a95ff12f-f7b7-46d7-b4c1-a1bdeba4c69d',
                    question_id: 'b2ef7918-7334-4dd2-ad5f-2edd6f4b723b',
                    is_correct: false,
                    answer_text: 'Фоторезисторы',
                    answer_date: '2023-10-09',
                    feedback: [],
                    employee_answer: ['54a0ee78-7193-4745-b414-70f9925217df'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '8db738fe-937e-4ea1-91b6-ec9dd1f4fe30',
                    assignment_id: 'a95ff12f-f7b7-46d7-b4c1-a1bdeba4c69d',
                    question_id: '47617597-9b1b-407c-b14d-691807f9d861',
                    is_correct: false,
                    answer_text: 'Схема триггера',
                    answer_date: '2023-10-09',
                    feedback: [],
                    employee_answer: ['468d333a-6505-4691-b39f-63cbe26f1e2e'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '96bb1b1f-9977-4fa1-be21-6db22e5b3edb',
                    assignment_id: 'a95ff12f-f7b7-46d7-b4c1-a1bdeba4c69d',
                    question_id: '314b2f52-fc65-4cad-bd6c-7597ae7bfc86',
                    is_correct: true,
                    answer_text: 'Резонансные кривые',
                    answer_date: '2023-10-09',
                    feedback: [],
                    employee_answer: ['fbc307b9-9232-44ef-afc5-1a98f564a5e4'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '9a0d4030-cafe-47f3-b92d-d86a27a4a0f1',
                    assignment_id: 'a95ff12f-f7b7-46d7-b4c1-a1bdeba4c69d',
                    question_id: '5569c13e-f57e-49c8-9f4b-0c293f9e197e',
                    is_correct: false,
                    answer_text: 'Разделения',
                    answer_date: '2023-10-09',
                    feedback: [],
                    employee_answer: ['0c066db1-6b70-49fc-9b79-26a3599e03cb'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'a0b5c6c4-1045-445d-860f-7628ce82383e',
                    assignment_id: 'a95ff12f-f7b7-46d7-b4c1-a1bdeba4c69d',
                    question_id: '0763f5c8-00a0-449a-a703-f469d13cf2d7',
                    is_correct: false,
                    answer_text: 'Человеческие силы',
                    answer_date: '2023-10-09',
                    feedback: [],
                    employee_answer: ['37fc180e-d3c3-49be-be93-9650fa44aeb8'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'bd315d93-49b3-440a-bc3f-849c269704ad',
                    assignment_id: 'a95ff12f-f7b7-46d7-b4c1-a1bdeba4c69d',
                    question_id: '4e8e87a5-b3f8-457e-b6da-3759f56f1c25',
                    is_correct: false,
                    answer_text: 'Мг/см',
                    answer_date: '2023-10-09',
                    feedback: [],
                    employee_answer: ['86e0c4ae-c680-40a5-a0ab-6ff5fa7eb229'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'ccc512f8-8603-4f15-a87b-1b3ffc1c9dfb',
                    assignment_id: 'a95ff12f-f7b7-46d7-b4c1-a1bdeba4c69d',
                    question_id: '20ffcf06-780a-4db9-a93c-25f394d0b14b',
                    is_correct: false,
                    answer_text: 'Дисперсия',
                    answer_date: '2023-10-09',
                    feedback: [],
                    employee_answer: ['d36b21f9-701a-435d-9912-38981749d28e'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'd14ac1e0-1653-4ca3-bd63-b6adcb593212',
                    assignment_id: 'a95ff12f-f7b7-46d7-b4c1-a1bdeba4c69d',
                    question_id: '6fc5952d-e30f-44a6-923d-ab6bd1d2fb8b',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'd6a9cd37-e36d-42a9-b899-b954a093303c',
                    assignment_id: 'a95ff12f-f7b7-46d7-b4c1-a1bdeba4c69d',
                    question_id: '20791567-063b-4ab9-bfb6-41b34e48a0c7',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'e3c4ed49-889d-4242-8319-f476872667af',
                    assignment_id: 'a95ff12f-f7b7-46d7-b4c1-a1bdeba4c69d',
                    question_id: '895eea62-1612-480d-8dc0-03859ba30d83',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }],
        assignmentResults: null
    }, {
        id: '828f29e1-3651-4693-ad7a-eb65bd1170dc',
        employee_id: 'b477a83f-7fbf-4872-ba0d-cae7fd2cefa6',
        question_count: 11,
        knowledge_field_id: 'a0abb578-be3a-4b0e-8c01-5cf5737296c0',
        status: {
            status: 25,
            status_name: 'Пропущено'
        },
        passing_test_time: '11 min',
        left_time: 0,
        dead_line: '2023-10-11',
        created_at: '2023-10-10T08:17:00.000000Z',
        start_date: '2023-10-10 08:17:39',
        end_date: '2023-10-10 08:18:53',
        knowledgeField: {
            id: 'a0abb578-be3a-4b0e-8c01-5cf5737296c0',
            title: 'Правила производства электромонтажных работ и охрана труда',
            active_status: true,
            functional_direction_id: '1aa9ceb4-74c6-4173-8029-781c189363c0',
            functional_direction_title: 'Электромонтажные работы',
            question_cnt: 0,
            information_source_cnt: 9,
            employee_cnt: 8,
            test_result_cnt: 17,
            created_at: '02.10.2023',
            base_id: '5a6ead49-569a-4d18-ad97-99457c92ab38',
            functionalDirection: {
                id: '1aa9ceb4-74c6-4173-8029-781c189363c0',
                title: 'Электромонтажные работы',
                base_id: '69f12fd3-87ba-4b17-a2ac-7c4cdcf01887',
                status: 20,
                created_at: '2023-10-02T14:19:37.000000Z',
                updated_at: '2023-10-02T14:25:38.000000Z',
                deleted_at: null
            }
        },
        employee: {
            id: 'b477a83f-7fbf-4872-ba0d-cae7fd2cefa6',
            fio: 'Alex',
            email: 'zepedro@yandex.ru',
            position: 'Alex',
            position_id: '12345678',
            personal_number: '12345678',
            code: 'foROw4YrlCgrmrw7jabOL9cwyWlIV9Lp',
            department: 'Alex',
            head_id: '66b5e430-4593-41d3-9fe4-66dc0bc1f103',
            is_head: false,
            head_email: 'zepedro@yandex.ru',
            head_position: 'Alex',
            head_position_id: '12345678'
        },
        assignmentQuestions: [{
                assignmentQuestion: {
                    id: '0963a25c-c788-4ea4-9405-64fffba24bd4',
                    assignment_id: '828f29e1-3651-4693-ad7a-eb65bd1170dc',
                    question_id: 'c1564b85-a513-4384-8582-1b6c0ac8bf2b',
                    is_correct: false,
                    answer_text: 'Многогабаритный троллейный токопровод',
                    answer_date: '2023-10-10',
                    feedback: [],
                    employee_answer: ['2133b76e-4e9a-40d0-9c97-2b5e3c0a9b20'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '3c00a926-b517-43d0-9ec5-7d4a73d772b3',
                    assignment_id: '828f29e1-3651-4693-ad7a-eb65bd1170dc',
                    question_id: '9e733656-84f1-490d-a4df-9395ca84c0eb',
                    is_correct: true,
                    answer_text: 'Температура тления',
                    answer_date: '2023-10-10',
                    feedback: [],
                    employee_answer: ['dd1c1551-9c9f-46d1-b223-06d8728fb67d'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '43115904-64b6-48f2-bd03-4e7eb9159db9',
                    assignment_id: '828f29e1-3651-4693-ad7a-eb65bd1170dc',
                    question_id: 'e319bdec-6ee8-4572-984e-42c32acb7aee',
                    is_correct: false,
                    answer_text: 'Сцена',
                    answer_date: '2023-10-10',
                    feedback: [],
                    employee_answer: ['052df522-3177-4d4c-bea2-ebfea5a06dc0'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '600b99ae-bb4c-408a-af98-2f4813cf0b18',
                    assignment_id: '828f29e1-3651-4693-ad7a-eb65bd1170dc',
                    question_id: '0763f5c8-00a0-449a-a703-f469d13cf2d7',
                    is_correct: false,
                    answer_text: 'Человеческие силы',
                    answer_date: '2023-10-10',
                    feedback: [],
                    employee_answer: ['37fc180e-d3c3-49be-be93-9650fa44aeb8'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '6f141c5a-76ea-4638-b05e-8715d07e1787',
                    assignment_id: '828f29e1-3651-4693-ad7a-eb65bd1170dc',
                    question_id: '80801bfc-a80c-40ff-90f7-cd14abfe0cee',
                    is_correct: true,
                    answer_text: 'Кодирование информации',
                    answer_date: '2023-10-10',
                    feedback: [],
                    employee_answer: ['981277f6-1e3b-4e03-931f-4e1c09919fd8'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '92f96d85-6f2d-4531-aa03-b935937c9107',
                    assignment_id: '828f29e1-3651-4693-ad7a-eb65bd1170dc',
                    question_id: '314b2f52-fc65-4cad-bd6c-7597ae7bfc86',
                    is_correct: false,
                    answer_text: 'Графические кривые',
                    answer_date: '2023-10-10',
                    feedback: [],
                    employee_answer: ['883fcf9d-2e43-48f7-80fa-39055c5b04a4'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '939c8baf-9d4b-4cd1-9170-2f83dafe738a',
                    assignment_id: '828f29e1-3651-4693-ad7a-eb65bd1170dc',
                    question_id: '96b5761e-0ae8-4e3b-bf09-ca2c638e8750',
                    is_correct: false,
                    answer_text: 'Масса',
                    answer_date: '2023-10-10',
                    feedback: [],
                    employee_answer: ['e42528d2-8373-4ff3-b80a-a1ce36658b27'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'b5e62e49-8899-47b7-8023-99d582bb7928',
                    assignment_id: '828f29e1-3651-4693-ad7a-eb65bd1170dc',
                    question_id: 'd4e38ac5-816f-49d1-8924-b97fe3d3e17c',
                    is_correct: false,
                    answer_text: 'Химическая',
                    answer_date: '2023-10-10',
                    feedback: [],
                    employee_answer: ['8a492950-329b-478a-88bd-340c6d9bc493'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'e224019a-f3be-4c42-8545-558ca857fa87',
                    assignment_id: '828f29e1-3651-4693-ad7a-eb65bd1170dc',
                    question_id: '7b6519de-cf39-493e-84df-bd206fd9b6bf',
                    is_correct: false,
                    answer_text: 'Запас',
                    answer_date: '2023-10-10',
                    feedback: [],
                    employee_answer: ['7c05b1ad-98f0-4aae-807f-6ac1c8eaaa0f'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'f21000c1-0f69-4320-9852-59a47a3b786c',
                    assignment_id: '828f29e1-3651-4693-ad7a-eb65bd1170dc',
                    question_id: '2a40a92e-26c9-4961-a0a2-2b085688e790',
                    is_correct: true,
                    answer_text: 'Полупроводниковый диод',
                    answer_date: '2023-10-10',
                    feedback: [],
                    employee_answer: ['aac261a2-e899-4bb8-95f6-1da813b0fc2e'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'fe620de4-11d1-4000-8299-04e80b60b4f8',
                    assignment_id: '828f29e1-3651-4693-ad7a-eb65bd1170dc',
                    question_id: '8a631fc8-96e5-4537-a4f9-0ba372e13a0e',
                    is_correct: false,
                    answer_text: 'Разъём',
                    answer_date: '2023-10-10',
                    feedback: [],
                    employee_answer: ['4506800e-6429-4d68-946c-a52399057a37'],
                    question: []
                }
            }],
        assignmentResults: {
            id: '2ec6588d-c777-443c-ab4c-79efd2680288',
            assignment_id: '828f29e1-3651-4693-ad7a-eb65bd1170dc',
            is_success: false,
            question_count: 11,
            right_answers_count: 3,
            wrong_answers_count: 8,
            created_at: '2023-10-10T08:18:53.000000Z',
            updated_at: '2023-10-10T08:18:53.000000Z'
        }
    }, {
        id: '82fe077e-58b1-4f28-8a45-8d998c7c8ccb',
        employee_id: 'b477a83f-7fbf-4872-ba0d-cae7fd2cefa6',
        question_count: 20,
        knowledge_field_id: 'a0abb578-be3a-4b0e-8c01-5cf5737296c0',
        status: {
            status: 25,
            status_name: 'Пропущено'
        },
        passing_test_time: '20 min',
        left_time: 0,
        dead_line: '2023-10-12',
        created_at: '2023-10-10T08:27:02.000000Z',
        start_date: '2023-10-10 08:27:48',
        end_date: '2023-10-10 08:29:48',
        knowledgeField: {
            id: 'a0abb578-be3a-4b0e-8c01-5cf5737296c0',
            title: 'Правила производства электромонтажных работ и охрана труда',
            active_status: true,
            functional_direction_id: '1aa9ceb4-74c6-4173-8029-781c189363c0',
            functional_direction_title: 'Электромонтажные работы',
            question_cnt: 0,
            information_source_cnt: 9,
            employee_cnt: 8,
            test_result_cnt: 17,
            created_at: '02.10.2023',
            base_id: '5a6ead49-569a-4d18-ad97-99457c92ab38',
            functionalDirection: {
                id: '1aa9ceb4-74c6-4173-8029-781c189363c0',
                title: 'Электромонтажные работы',
                base_id: '69f12fd3-87ba-4b17-a2ac-7c4cdcf01887',
                status: 20,
                created_at: '2023-10-02T14:19:37.000000Z',
                updated_at: '2023-10-02T14:25:38.000000Z',
                deleted_at: null
            }
        },
        employee: {
            id: 'b477a83f-7fbf-4872-ba0d-cae7fd2cefa6',
            fio: 'Alex',
            email: 'zepedro@yandex.ru',
            position: 'Alex',
            position_id: '12345678',
            personal_number: '12345678',
            code: 'foROw4YrlCgrmrw7jabOL9cwyWlIV9Lp',
            department: 'Alex',
            head_id: '66b5e430-4593-41d3-9fe4-66dc0bc1f103',
            is_head: false,
            head_email: 'zepedro@yandex.ru',
            head_position: 'Alex',
            head_position_id: '12345678'
        },
        assignmentQuestions: [{
                assignmentQuestion: {
                    id: '17f4ca27-1605-4f8c-80df-cb029cfb7671',
                    assignment_id: '82fe077e-58b1-4f28-8a45-8d998c7c8ccb',
                    question_id: '6af19c4b-4d17-4891-b885-3bbbb4d79b49',
                    is_correct: true,
                    answer_text: 'Главный распределительный щит',
                    answer_date: '2023-10-10',
                    feedback: [],
                    employee_answer: ['f3aaf5ac-174b-435a-a27c-03e3ec33706e'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '183176ca-d5bb-408a-b329-752376f9602d',
                    assignment_id: '82fe077e-58b1-4f28-8a45-8d998c7c8ccb',
                    question_id: '8a631fc8-96e5-4537-a4f9-0ba372e13a0e',
                    is_correct: false,
                    answer_text: 'Разъём',
                    answer_date: '2023-10-10',
                    feedback: [],
                    employee_answer: ['4506800e-6429-4d68-946c-a52399057a37'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '18a12231-566c-4d8c-928f-b929843d7010',
                    assignment_id: '82fe077e-58b1-4f28-8a45-8d998c7c8ccb',
                    question_id: '0763f5c8-00a0-449a-a703-f469d13cf2d7',
                    is_correct: false,
                    answer_text: 'Мощные силы',
                    answer_date: '2023-10-10',
                    feedback: [],
                    employee_answer: ['ca647f63-1244-44d4-98ec-36bad9e9080d'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '252c3897-c570-4ca4-889a-bafd23c82d2d',
                    assignment_id: '82fe077e-58b1-4f28-8a45-8d998c7c8ccb',
                    question_id: '7b6519de-cf39-493e-84df-bd206fd9b6bf',
                    is_correct: false,
                    answer_text: 'Запас',
                    answer_date: '2023-10-10',
                    feedback: [],
                    employee_answer: ['7c05b1ad-98f0-4aae-807f-6ac1c8eaaa0f'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '277cb8b3-286c-4e40-8993-9fcf84c41d87',
                    assignment_id: '82fe077e-58b1-4f28-8a45-8d998c7c8ccb',
                    question_id: '3802dada-0afe-41ea-bb5c-896b822de134',
                    is_correct: false,
                    answer_text: 'Температура тления',
                    answer_date: '2023-10-10',
                    feedback: [],
                    employee_answer: ['0a7afcdc-ab2c-4490-921f-f8de3059c322'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '49bfa97f-baf5-4015-80e3-b5880bbd716e',
                    assignment_id: '82fe077e-58b1-4f28-8a45-8d998c7c8ccb',
                    question_id: 'd4e38ac5-816f-49d1-8924-b97fe3d3e17c',
                    is_correct: false,
                    answer_text: 'Химическая',
                    answer_date: '2023-10-10',
                    feedback: [],
                    employee_answer: ['8a492950-329b-478a-88bd-340c6d9bc493'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '5179101d-c0d6-49bd-8832-f39d76dfb229',
                    assignment_id: '82fe077e-58b1-4f28-8a45-8d998c7c8ccb',
                    question_id: '1af57b14-629a-4bbb-b7ba-e766a736f8d7',
                    is_correct: false,
                    answer_text: 'Динисторы',
                    answer_date: '2023-10-10',
                    feedback: [],
                    employee_answer: ['3c3f6e3c-9e8f-49ab-a330-e6e80db66c58'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '5c7e37bf-1213-4fd5-8d7f-94334d89a6df',
                    assignment_id: '82fe077e-58b1-4f28-8a45-8d998c7c8ccb',
                    question_id: '053cee07-2210-4307-9a0d-4daa61c66331',
                    is_correct: false,
                    answer_text: 'Гирлянда изоляторов',
                    answer_date: '2023-10-10',
                    feedback: [],
                    employee_answer: ['fe47a779-d9af-4558-944a-85355367d8c2'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '6020c859-5c4e-4ca9-b3ab-d94da2b9cb70',
                    assignment_id: '82fe077e-58b1-4f28-8a45-8d998c7c8ccb',
                    question_id: '80801bfc-a80c-40ff-90f7-cd14abfe0cee',
                    is_correct: true,
                    answer_text: 'Кодирование информации',
                    answer_date: '2023-10-10',
                    feedback: [],
                    employee_answer: ['981277f6-1e3b-4e03-931f-4e1c09919fd8'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '65abb5f7-8a69-47c9-84af-29a32dff66a4',
                    assignment_id: '82fe077e-58b1-4f28-8a45-8d998c7c8ccb',
                    question_id: 'e3416d36-9220-43bd-997e-b3691d2ae049',
                    is_correct: false,
                    answer_text: 'Предельная температура',
                    answer_date: '2023-10-10',
                    feedback: [],
                    employee_answer: ['dd9b6e29-6e97-4992-8b4c-fd5449370408'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '70c0db45-e0eb-4023-83c4-00a916de8c60',
                    assignment_id: '82fe077e-58b1-4f28-8a45-8d998c7c8ccb',
                    question_id: '9e733656-84f1-490d-a4df-9395ca84c0eb',
                    is_correct: false,
                    answer_text: 'Температура вспышки',
                    answer_date: '2023-10-10',
                    feedback: [],
                    employee_answer: ['b4946f18-7d46-4985-88e2-7d31037016f1'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '7eb9c892-4f02-4d26-a4ea-1bf05c38746f',
                    assignment_id: '82fe077e-58b1-4f28-8a45-8d998c7c8ccb',
                    question_id: '13c538d6-a4f3-41bd-bc4a-dbec0a46f619',
                    is_correct: false,
                    answer_text: 'Пульсовый сигнал',
                    answer_date: '2023-10-10',
                    feedback: [],
                    employee_answer: ['44d891d6-46d4-458d-981b-4a4bb1c9c468'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '88227d70-3f62-44ae-9075-404353af5c82',
                    assignment_id: '82fe077e-58b1-4f28-8a45-8d998c7c8ccb',
                    question_id: '2a40a92e-26c9-4961-a0a2-2b085688e790',
                    is_correct: true,
                    answer_text: 'Полупроводниковый диод',
                    answer_date: '2023-10-10',
                    feedback: [],
                    employee_answer: ['aac261a2-e899-4bb8-95f6-1da813b0fc2e'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '94604f5a-ce8f-441a-9a89-5c4f0508e0b1',
                    assignment_id: '82fe077e-58b1-4f28-8a45-8d998c7c8ccb',
                    question_id: 'c1564b85-a513-4384-8582-1b6c0ac8bf2b',
                    is_correct: false,
                    answer_text: 'Негабаритный троллейный токопровод',
                    answer_date: '2023-10-10',
                    feedback: [],
                    employee_answer: ['3aa4db63-32c5-4f57-b84d-527a02d52561'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '9b0ccaa3-6609-418f-9413-6843f1ebb8ff',
                    assignment_id: '82fe077e-58b1-4f28-8a45-8d998c7c8ccb',
                    question_id: '96b5761e-0ae8-4e3b-bf09-ca2c638e8750',
                    is_correct: false,
                    answer_text: 'Способность',
                    answer_date: '2023-10-10',
                    feedback: [],
                    employee_answer: ['f2b005e2-e906-4dd4-a8cb-3a9a26d1ef18'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'aa0f520e-c3cd-414a-bcc8-48ed6f48054c',
                    assignment_id: '82fe077e-58b1-4f28-8a45-8d998c7c8ccb',
                    question_id: '7a278d91-1ad9-4ea1-aa7e-61e930380cc0',
                    is_correct: false,
                    answer_text: 'Температура воспламенения',
                    answer_date: '2023-10-10',
                    feedback: [],
                    employee_answer: ['5344d576-7a7c-4e20-9a79-3582a4f48329'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'b0ab5c38-9699-4dec-8a89-ce1d28b479c7',
                    assignment_id: '82fe077e-58b1-4f28-8a45-8d998c7c8ccb',
                    question_id: '314b2f52-fc65-4cad-bd6c-7597ae7bfc86',
                    is_correct: false,
                    answer_text: 'Арифметические кривые',
                    answer_date: '2023-10-10',
                    feedback: [],
                    employee_answer: ['87a3be3a-723f-433b-bda4-4f049e93cf95'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'b48f3fc1-4545-493c-b1f4-3349006da1d7',
                    assignment_id: '82fe077e-58b1-4f28-8a45-8d998c7c8ccb',
                    question_id: '20791567-063b-4ab9-bfb6-41b34e48a0c7',
                    is_correct: false,
                    answer_text: 'Разрыв',
                    answer_date: '2023-10-10',
                    feedback: [],
                    employee_answer: ['a1e00c58-5103-4f89-86e3-7f3a5ba4bc61'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'f0d4f67b-70bb-4093-b066-4e31f7472078',
                    assignment_id: '82fe077e-58b1-4f28-8a45-8d998c7c8ccb',
                    question_id: '5d269692-31d9-447f-b704-0e54faebee44',
                    is_correct: true,
                    answer_text: 'Сообщение',
                    answer_date: '2023-10-10',
                    feedback: [],
                    employee_answer: ['92da37e9-5f5d-406e-a32c-f1d053ec6e5e'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'fb948efb-45b9-4df9-ad66-424bc918dbc8',
                    assignment_id: '82fe077e-58b1-4f28-8a45-8d998c7c8ccb',
                    question_id: 'e319bdec-6ee8-4572-984e-42c32acb7aee',
                    is_correct: false,
                    answer_text: 'Сцена',
                    answer_date: '2023-10-10',
                    feedback: [],
                    employee_answer: ['052df522-3177-4d4c-bea2-ebfea5a06dc0'],
                    question: []
                }
            }],
        assignmentResults: {
            id: 'ab76bd4f-7f44-4c8f-b832-66363fc666c4',
            assignment_id: '82fe077e-58b1-4f28-8a45-8d998c7c8ccb',
            is_success: false,
            question_count: 20,
            right_answers_count: 4,
            wrong_answers_count: 16,
            created_at: '2023-10-10T08:29:48.000000Z',
            updated_at: '2023-10-10T08:29:48.000000Z'
        }
    }, {
        id: '1f0d2bfa-f7c9-4f31-a563-fb29931c2fb6',
        employee_id: 'b477a83f-7fbf-4872-ba0d-cae7fd2cefa6',
        question_count: 23,
        knowledge_field_id: 'a0abb578-be3a-4b0e-8c01-5cf5737296c0',
        status: {
            status: 25,
            status_name: 'Пропущено'
        },
        passing_test_time: '23 min',
        left_time: 0,
        dead_line: '2023-10-12',
        created_at: '2023-10-10T11:17:32.000000Z',
        start_date: '2023-10-10 11:17:45',
        end_date: '2023-10-10 11:18:53',
        knowledgeField: {
            id: 'a0abb578-be3a-4b0e-8c01-5cf5737296c0',
            title: 'Правила производства электромонтажных работ и охрана труда',
            active_status: true,
            functional_direction_id: '1aa9ceb4-74c6-4173-8029-781c189363c0',
            functional_direction_title: 'Электромонтажные работы',
            question_cnt: 0,
            information_source_cnt: 9,
            employee_cnt: 8,
            test_result_cnt: 17,
            created_at: '02.10.2023',
            base_id: '5a6ead49-569a-4d18-ad97-99457c92ab38',
            functionalDirection: {
                id: '1aa9ceb4-74c6-4173-8029-781c189363c0',
                title: 'Электромонтажные работы',
                base_id: '69f12fd3-87ba-4b17-a2ac-7c4cdcf01887',
                status: 20,
                created_at: '2023-10-02T14:19:37.000000Z',
                updated_at: '2023-10-02T14:25:38.000000Z',
                deleted_at: null
            }
        },
        employee: {
            id: 'b477a83f-7fbf-4872-ba0d-cae7fd2cefa6',
            fio: 'Alex',
            email: 'zepedro@yandex.ru',
            position: 'Alex',
            position_id: '12345678',
            personal_number: '12345678',
            code: 'foROw4YrlCgrmrw7jabOL9cwyWlIV9Lp',
            department: 'Alex',
            head_id: '66b5e430-4593-41d3-9fe4-66dc0bc1f103',
            is_head: false,
            head_email: 'zepedro@yandex.ru',
            head_position: 'Alex',
            head_position_id: '12345678'
        },
        assignmentQuestions: [{
                assignmentQuestion: {
                    id: '0458c84f-113f-49c5-ad86-a1f782df143a',
                    assignment_id: '1f0d2bfa-f7c9-4f31-a563-fb29931c2fb6',
                    question_id: '053cee07-2210-4307-9a0d-4daa61c66331',
                    is_correct: true,
                    answer_text: 'Распределительный пункт',
                    answer_date: '2023-10-10',
                    feedback: [],
                    employee_answer: ['acd76198-b450-4961-bc87-9511e5c5231d'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '05108873-46c3-4c0b-81eb-9218b3ffe0fb',
                    assignment_id: '1f0d2bfa-f7c9-4f31-a563-fb29931c2fb6',
                    question_id: '1af57b14-629a-4bbb-b7ba-e766a736f8d7',
                    is_correct: true,
                    answer_text: 'Фотодиоды',
                    answer_date: '2023-10-10',
                    feedback: [],
                    employee_answer: ['141662c4-ed7b-45aa-ae2a-1afb744a7e3d'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '06d03db0-4816-4d01-aba9-3550ec974024',
                    assignment_id: '1f0d2bfa-f7c9-4f31-a563-fb29931c2fb6',
                    question_id: '314b2f52-fc65-4cad-bd6c-7597ae7bfc86',
                    is_correct: false,
                    answer_text: 'Линейные кривые',
                    answer_date: '2023-10-10',
                    feedback: [],
                    employee_answer: ['9842cb90-1987-4a87-bc5f-d190753e4b59'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '19f1a030-7b22-4696-a226-9cec96740953',
                    assignment_id: '1f0d2bfa-f7c9-4f31-a563-fb29931c2fb6',
                    question_id: 'd4e38ac5-816f-49d1-8924-b97fe3d3e17c',
                    is_correct: true,
                    answer_text: 'Глухозаземленная',
                    answer_date: '2023-10-10',
                    feedback: [],
                    employee_answer: ['6712830f-7e08-42c0-83e0-efc0e1ba573a'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '1e346596-c55f-4b92-b01c-76fbff2c2f14',
                    assignment_id: '1f0d2bfa-f7c9-4f31-a563-fb29931c2fb6',
                    question_id: '20791567-063b-4ab9-bfb6-41b34e48a0c7',
                    is_correct: true,
                    answer_text: 'Сдвиг',
                    answer_date: '2023-10-10',
                    feedback: [],
                    employee_answer: ['1c618850-d967-4b18-8b6e-20131688dabd'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '2efce172-9624-4958-a3d0-f94b1a0c3d24',
                    assignment_id: '1f0d2bfa-f7c9-4f31-a563-fb29931c2fb6',
                    question_id: 'e3416d36-9220-43bd-997e-b3691d2ae049',
                    is_correct: false,
                    answer_text: 'Температура тления',
                    answer_date: '2023-10-10',
                    feedback: [],
                    employee_answer: ['c535190d-056d-4c1b-8e93-6851ae85d9fa'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '32293beb-fb3c-43a1-b24f-3b54983ef337',
                    assignment_id: '1f0d2bfa-f7c9-4f31-a563-fb29931c2fb6',
                    question_id: '13c538d6-a4f3-41bd-bc4a-dbec0a46f619',
                    is_correct: false,
                    answer_text: 'Линейный сигнал',
                    answer_date: '2023-10-10',
                    feedback: [],
                    employee_answer: ['5c4ea5e5-1966-42f1-b90d-be34f37e1121'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '375ccd23-e58e-4457-86b8-602efa4f30cb',
                    assignment_id: '1f0d2bfa-f7c9-4f31-a563-fb29931c2fb6',
                    question_id: 'c1564b85-a513-4384-8582-1b6c0ac8bf2b',
                    is_correct: false,
                    answer_text: 'Негабаритный троллейный токопровод',
                    answer_date: '2023-10-10',
                    feedback: [],
                    employee_answer: ['3aa4db63-32c5-4f57-b84d-527a02d52561'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '45fde9ba-e424-49fa-90c0-fa260c070c69',
                    assignment_id: '1f0d2bfa-f7c9-4f31-a563-fb29931c2fb6',
                    question_id: '8a631fc8-96e5-4537-a4f9-0ba372e13a0e',
                    is_correct: false,
                    answer_text: 'Разъём',
                    answer_date: '2023-10-10',
                    feedback: [],
                    employee_answer: ['4506800e-6429-4d68-946c-a52399057a37'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '70106470-39b6-4277-b904-39a2eb7a3972',
                    assignment_id: '1f0d2bfa-f7c9-4f31-a563-fb29931c2fb6',
                    question_id: 'e319bdec-6ee8-4572-984e-42c32acb7aee',
                    is_correct: false,
                    answer_text: 'Кулиса',
                    answer_date: '2023-10-10',
                    feedback: [],
                    employee_answer: ['558a1208-8bcf-46e2-8ce4-fdd4b899d6a1'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '78c18d8f-170f-4819-b1c4-c87b77e25ddd',
                    assignment_id: '1f0d2bfa-f7c9-4f31-a563-fb29931c2fb6',
                    question_id: '7a278d91-1ad9-4ea1-aa7e-61e930380cc0',
                    is_correct: false,
                    answer_text: 'Температура воспламенения',
                    answer_date: '2023-10-10',
                    feedback: [],
                    employee_answer: ['5344d576-7a7c-4e20-9a79-3582a4f48329'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '7caf87bc-754f-47c1-a258-8f157eeba389',
                    assignment_id: '1f0d2bfa-f7c9-4f31-a563-fb29931c2fb6',
                    question_id: '2a40a92e-26c9-4961-a0a2-2b085688e790',
                    is_correct: false,
                    answer_text: 'Полупроводниковый излучатель',
                    answer_date: '2023-10-10',
                    feedback: [],
                    employee_answer: ['8a90114e-be52-41db-bd1e-90bc4b03870b'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '96d8867b-fad2-4fe6-8716-6ad4fcb48f39',
                    assignment_id: '1f0d2bfa-f7c9-4f31-a563-fb29931c2fb6',
                    question_id: '80801bfc-a80c-40ff-90f7-cd14abfe0cee',
                    is_correct: false,
                    answer_text: 'Квантование информации',
                    answer_date: '2023-10-10',
                    feedback: [],
                    employee_answer: ['7e2bb7f2-54f4-4a35-9917-8a2014ff6fac'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'a0a045e6-4e35-46bb-b18e-7b7c21567a5a',
                    assignment_id: '1f0d2bfa-f7c9-4f31-a563-fb29931c2fb6',
                    question_id: '5d269692-31d9-447f-b704-0e54faebee44',
                    is_correct: true,
                    answer_text: 'Сообщение',
                    answer_date: '2023-10-10',
                    feedback: [],
                    employee_answer: ['92da37e9-5f5d-406e-a32c-f1d053ec6e5e'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'adc510da-977b-426c-90b3-7cdc5fb6acd2',
                    assignment_id: '1f0d2bfa-f7c9-4f31-a563-fb29931c2fb6',
                    question_id: '3802dada-0afe-41ea-bb5c-896b822de134',
                    is_correct: false,
                    answer_text: 'Температура тления',
                    answer_date: '2023-10-10',
                    feedback: [],
                    employee_answer: ['0a7afcdc-ab2c-4490-921f-f8de3059c322'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'bd5a5ab6-9887-4232-a78a-4f36f8a6b07b',
                    assignment_id: '1f0d2bfa-f7c9-4f31-a563-fb29931c2fb6',
                    question_id: '0763f5c8-00a0-449a-a703-f469d13cf2d7',
                    is_correct: false,
                    answer_text: 'Человеческие силы',
                    answer_date: '2023-10-10',
                    feedback: [],
                    employee_answer: ['37fc180e-d3c3-49be-be93-9650fa44aeb8'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'bd8b95b5-eeda-4d4e-9262-a1c47110c396',
                    assignment_id: '1f0d2bfa-f7c9-4f31-a563-fb29931c2fb6',
                    question_id: '25181c74-bb88-4fab-bc25-52a8353fbc0e',
                    is_correct: false,
                    answer_text: 'Рабочий',
                    answer_date: '2023-10-10',
                    feedback: [],
                    employee_answer: ['6944c2d4-e914-45ab-a142-c8e3df888a78'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'c3c7dcb6-b850-44d2-a536-6d584d8540c4',
                    assignment_id: '1f0d2bfa-f7c9-4f31-a563-fb29931c2fb6',
                    question_id: '91db0ce8-4fb8-4ea7-8bbb-b2aac5dc1627',
                    is_correct: false,
                    answer_text: 'Физическая характеристика',
                    answer_date: '2023-10-10',
                    feedback: [],
                    employee_answer: ['d810c307-5398-4ea5-9935-08ae37bc1242'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'd33784fd-6f2c-493b-a694-ee9b1502d922',
                    assignment_id: '1f0d2bfa-f7c9-4f31-a563-fb29931c2fb6',
                    question_id: '6af19c4b-4d17-4891-b885-3bbbb4d79b49',
                    is_correct: false,
                    answer_text: 'Электрогазовый распределительный щит',
                    answer_date: '2023-10-10',
                    feedback: [],
                    employee_answer: ['d332873a-740c-4fe9-b843-d7594a63f3f0'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'da4ab54c-0ea4-4c4a-a808-bb9de2a191f3',
                    assignment_id: '1f0d2bfa-f7c9-4f31-a563-fb29931c2fb6',
                    question_id: '243fa21c-6514-423c-88fd-0fdb91c4f727',
                    is_correct: false,
                    answer_text: 'Фоторезисторы',
                    answer_date: '2023-10-10',
                    feedback: [],
                    employee_answer: ['f00bac31-84d5-467f-bb99-d23a15fb83a1'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'dccbc1fa-2966-4f21-a3fa-5f1cc02b116a',
                    assignment_id: '1f0d2bfa-f7c9-4f31-a563-fb29931c2fb6',
                    question_id: '9e733656-84f1-490d-a4df-9395ca84c0eb',
                    is_correct: false,
                    answer_text: 'Предельная температура',
                    answer_date: '2023-10-10',
                    feedback: [],
                    employee_answer: ['83f80621-fba2-4409-a6fc-6ee249aea8a9'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'ddae908f-193c-45de-a177-4f622107c0fe',
                    assignment_id: '1f0d2bfa-f7c9-4f31-a563-fb29931c2fb6',
                    question_id: '7b6519de-cf39-493e-84df-bd206fd9b6bf',
                    is_correct: true,
                    answer_text: 'Ресурс',
                    answer_date: '2023-10-10',
                    feedback: [],
                    employee_answer: ['4ad54fe3-376d-4fc1-a5ee-1738f49d491f'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'f06f15b9-c3c0-4b1b-a800-140082650bb5',
                    assignment_id: '1f0d2bfa-f7c9-4f31-a563-fb29931c2fb6',
                    question_id: '96b5761e-0ae8-4e3b-bf09-ca2c638e8750',
                    is_correct: false,
                    answer_text: 'Электроэнергия',
                    answer_date: '2023-10-10',
                    feedback: [],
                    employee_answer: ['f8e0404a-e974-416c-adf6-cfa4681baa7f'],
                    question: []
                }
            }],
        assignmentResults: {
            id: 'a79c0711-3007-479f-bbea-75964076485f',
            assignment_id: '1f0d2bfa-f7c9-4f31-a563-fb29931c2fb6',
            is_success: false,
            question_count: 23,
            right_answers_count: 6,
            wrong_answers_count: 17,
            created_at: '2023-10-10T11:18:53.000000Z',
            updated_at: '2023-10-10T11:18:53.000000Z'
        }
    }, {
        id: '7490a52c-8cf8-4bb3-8442-a9a4604a6ec5',
        employee_id: 'd8df7071-d3cd-491e-a34b-86dc7a5d6aeb',
        question_count: 6,
        knowledge_field_id: 'a0abb578-be3a-4b0e-8c01-5cf5737296c0',
        status: {
            status: 10,
            status_name: 'Назначено'
        },
        passing_test_time: '6 min',
        left_time: 6,
        dead_line: '2023-10-29',
        created_at: '2023-10-10T12:43:38.000000Z',
        start_date: null,
        end_date: null,
        knowledgeField: {
            id: 'a0abb578-be3a-4b0e-8c01-5cf5737296c0',
            title: 'Правила производства электромонтажных работ и охрана труда',
            active_status: true,
            functional_direction_id: '1aa9ceb4-74c6-4173-8029-781c189363c0',
            functional_direction_title: 'Электромонтажные работы',
            question_cnt: 0,
            information_source_cnt: 9,
            employee_cnt: 8,
            test_result_cnt: 17,
            created_at: '02.10.2023',
            base_id: '5a6ead49-569a-4d18-ad97-99457c92ab38',
            functionalDirection: {
                id: '1aa9ceb4-74c6-4173-8029-781c189363c0',
                title: 'Электромонтажные работы',
                base_id: '69f12fd3-87ba-4b17-a2ac-7c4cdcf01887',
                status: 20,
                created_at: '2023-10-02T14:19:37.000000Z',
                updated_at: '2023-10-02T14:25:38.000000Z',
                deleted_at: null
            }
        },
        employee: {
            id: 'd8df7071-d3cd-491e-a34b-86dc7a5d6aeb',
            fio: 'Жигулева Марина Юрьевна',
            email: 'm.zhiguleva@univertus.com',
            position: 'Главный бухгалтер',
            position_id: '10865709',
            personal_number: '28100400',
            code: 'uiazFpZQYKG2YClIFnw6tW5TS9OBra2Q',
            department: null,
            head_id: null,
            is_head: true,
            head_email: 'm.zhiguleva@univertus.com',
            head_position: 'Главный бухгалтер',
            head_position_id: '10865709'
        },
        assignmentQuestions: [{
                assignmentQuestion: {
                    id: '0d3f0b93-108d-4955-82e4-b999f881edc0',
                    assignment_id: '7490a52c-8cf8-4bb3-8442-a9a4604a6ec5',
                    question_id: '96b5761e-0ae8-4e3b-bf09-ca2c638e8750',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '3714bbb8-d073-4dda-8656-24893d78815f',
                    assignment_id: '7490a52c-8cf8-4bb3-8442-a9a4604a6ec5',
                    question_id: '7b6519de-cf39-493e-84df-bd206fd9b6bf',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '44f4496a-d02c-4b03-98ce-b38187516e69',
                    assignment_id: '7490a52c-8cf8-4bb3-8442-a9a4604a6ec5',
                    question_id: 'd4e38ac5-816f-49d1-8924-b97fe3d3e17c',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '7c81e46b-f004-4449-bd5f-e500a4b12975',
                    assignment_id: '7490a52c-8cf8-4bb3-8442-a9a4604a6ec5',
                    question_id: 'c1564b85-a513-4384-8582-1b6c0ac8bf2b',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '941ad42a-bc85-44bb-a921-e3d96b8adb70',
                    assignment_id: '7490a52c-8cf8-4bb3-8442-a9a4604a6ec5',
                    question_id: '314b2f52-fc65-4cad-bd6c-7597ae7bfc86',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'bf7681b3-6861-439e-af44-af17169c3a45',
                    assignment_id: '7490a52c-8cf8-4bb3-8442-a9a4604a6ec5',
                    question_id: '8a631fc8-96e5-4537-a4f9-0ba372e13a0e',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }],
        assignmentResults: null
    }, {
        id: '772692ed-3562-416c-a798-31ddfcd511c4',
        employee_id: '2f5c37b1-25a7-4fc3-9d3a-46d27c99fb68',
        question_count: 25,
        knowledge_field_id: 'a0abb578-be3a-4b0e-8c01-5cf5737296c0',
        status: {
            status: 20,
            status_name: 'Завершено'
        },
        passing_test_time: '25 min',
        left_time: 0,
        dead_line: '2023-10-29',
        created_at: '2023-10-10T12:43:38.000000Z',
        start_date: '2024-01-26 13:10:34',
        end_date: '2024-01-26 13:11:43',
        knowledgeField: {
            id: 'a0abb578-be3a-4b0e-8c01-5cf5737296c0',
            title: 'Правила производства электромонтажных работ и охрана труда',
            active_status: true,
            functional_direction_id: '1aa9ceb4-74c6-4173-8029-781c189363c0',
            functional_direction_title: 'Электромонтажные работы',
            question_cnt: 0,
            information_source_cnt: 9,
            employee_cnt: 8,
            test_result_cnt: 17,
            created_at: '02.10.2023',
            base_id: '5a6ead49-569a-4d18-ad97-99457c92ab38',
            functionalDirection: {
                id: '1aa9ceb4-74c6-4173-8029-781c189363c0',
                title: 'Электромонтажные работы',
                base_id: '69f12fd3-87ba-4b17-a2ac-7c4cdcf01887',
                status: 20,
                created_at: '2023-10-02T14:19:37.000000Z',
                updated_at: '2023-10-02T14:25:38.000000Z',
                deleted_at: null
            }
        },
        employee: {
            id: '2f5c37b1-25a7-4fc3-9d3a-46d27c99fb68',
            fio: 'Абдылов \tДмитрий \tСергеевич',
            email: 'd.abdylov@univertus.com',
            position: 'Специалист по безопасности и охране труда',
            position_id: '10859310',
            personal_number: '20203004',
            code: '5jC4vnfQyVuHIErHZPxDQP1XZvaPuttu',
            department: 'Охрана труда',
            head_id: '66b5e430-4593-41d3-9fe4-66dc0bc1f103',
            is_head: false,
            head_email: 'd.abdylov@univertus.com',
            head_position: 'Специалист по безопасности и охране труда',
            head_position_id: '10859310'
        },
        assignmentQuestions: [{
                assignmentQuestion: {
                    id: '02f795c5-305c-4f3e-bad6-ebdab8f21c13',
                    assignment_id: '772692ed-3562-416c-a798-31ddfcd511c4',
                    question_id: '5d269692-31d9-447f-b704-0e54faebee44',
                    is_correct: false,
                    answer_text: 'Отправление',
                    answer_date: '2024-01-26',
                    feedback: [],
                    employee_answer: ['554e1117-2f81-496b-a0e6-e2ab56ce1c68'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '07ed9ebd-732e-45de-b09a-00456a4c4468',
                    assignment_id: '772692ed-3562-416c-a798-31ddfcd511c4',
                    question_id: 'c1564b85-a513-4384-8582-1b6c0ac8bf2b',
                    is_correct: false,
                    answer_text: 'Среднегабаритный троллейный токопровод',
                    answer_date: '2024-01-26',
                    feedback: [],
                    employee_answer: ['e0de4785-baa6-4486-baf2-61b969a318f1'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '223a6c3f-f9e7-4771-87fe-983f2b41954b',
                    assignment_id: '772692ed-3562-416c-a798-31ddfcd511c4',
                    question_id: 'd4e38ac5-816f-49d1-8924-b97fe3d3e17c',
                    is_correct: false,
                    answer_text: 'Чрезвычайная',
                    answer_date: '2024-01-26',
                    feedback: [],
                    employee_answer: ['affe34bd-69d1-4752-9c87-c9525527e32a'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '24217163-834c-48a8-8dee-e4a098fa438e',
                    assignment_id: '772692ed-3562-416c-a798-31ddfcd511c4',
                    question_id: '0763f5c8-00a0-449a-a703-f469d13cf2d7',
                    is_correct: false,
                    answer_text: 'Человеческие силы',
                    answer_date: '2024-01-26',
                    feedback: [],
                    employee_answer: ['11cf130b-b4e1-48c1-af94-09e997826b85'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '26f003b5-0c55-4122-ae9f-479b7c96baed',
                    assignment_id: '772692ed-3562-416c-a798-31ddfcd511c4',
                    question_id: '80801bfc-a80c-40ff-90f7-cd14abfe0cee',
                    is_correct: false,
                    answer_text: 'Квантование информации',
                    answer_date: '2024-01-26',
                    feedback: [],
                    employee_answer: ['dc14b659-e2c1-4817-bc1d-bb354b42ec02'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '3092ade1-d430-4d2e-bd53-1d459fed9faf',
                    assignment_id: '772692ed-3562-416c-a798-31ddfcd511c4',
                    question_id: '314b2f52-fc65-4cad-bd6c-7597ae7bfc86',
                    is_correct: false,
                    answer_text: 'Арифметические кривые',
                    answer_date: '2024-01-26',
                    feedback: [],
                    employee_answer: ['ebb504e6-81c8-4ff7-829c-cc3792a516dd'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '403f3f03-c941-4e3c-8a12-4797314f0c93',
                    assignment_id: '772692ed-3562-416c-a798-31ddfcd511c4',
                    question_id: '7a278d91-1ad9-4ea1-aa7e-61e930380cc0',
                    is_correct: false,
                    answer_text: 'Температура тления',
                    answer_date: '2024-01-26',
                    feedback: [],
                    employee_answer: ['3deeaa80-b456-4fe9-8e1a-6471cd1a56f2'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '4e98c0bc-022f-447a-9f94-6795c7564a37',
                    assignment_id: '772692ed-3562-416c-a798-31ddfcd511c4',
                    question_id: '3802dada-0afe-41ea-bb5c-896b822de134',
                    is_correct: false,
                    answer_text: 'Температура самовоспламенения',
                    answer_date: '2024-01-26',
                    feedback: [],
                    employee_answer: ['a4e86d24-124f-4c0d-966d-414712da6c36'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '55801c60-a987-4a50-9bc7-0f4af8ecf09d',
                    assignment_id: '772692ed-3562-416c-a798-31ddfcd511c4',
                    question_id: '9e733656-84f1-490d-a4df-9395ca84c0eb',
                    is_correct: false,
                    answer_text: 'Температура вспышки',
                    answer_date: '2024-01-26',
                    feedback: [],
                    employee_answer: ['b4946f18-7d46-4985-88e2-7d31037016f1'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '68e0fd61-4c0a-4203-b6e0-99246fd9bddc',
                    assignment_id: '772692ed-3562-416c-a798-31ddfcd511c4',
                    question_id: '96b5761e-0ae8-4e3b-bf09-ca2c638e8750',
                    is_correct: false,
                    answer_text: 'Электроэнергия',
                    answer_date: '2024-01-26',
                    feedback: [],
                    employee_answer: ['03196dc9-2eb2-4d55-bc35-46065abd604c'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '82ea2e16-ddd8-4919-9715-bd20499f36d9',
                    assignment_id: '772692ed-3562-416c-a798-31ddfcd511c4',
                    question_id: '053cee07-2210-4307-9a0d-4daa61c66331',
                    is_correct: false,
                    answer_text: 'Тросовое крепление',
                    answer_date: '2024-01-26',
                    feedback: [],
                    employee_answer: ['02192bec-bc23-4c38-a84e-8f09d174d154'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '908f5184-94c0-44df-bee0-1d9989de2c9e',
                    assignment_id: '772692ed-3562-416c-a798-31ddfcd511c4',
                    question_id: '7b6519de-cf39-493e-84df-bd206fd9b6bf',
                    is_correct: false,
                    answer_text: 'Капитал',
                    answer_date: '2024-01-26',
                    feedback: [],
                    employee_answer: ['f99dbe40-f6be-4615-8c95-6a04d053f62d'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '9a16ebd3-59a7-4fa4-8033-27117edf2549',
                    assignment_id: '772692ed-3562-416c-a798-31ddfcd511c4',
                    question_id: 'e319bdec-6ee8-4572-984e-42c32acb7aee',
                    is_correct: false,
                    answer_text: 'Кулиса',
                    answer_date: '2024-01-26',
                    feedback: [],
                    employee_answer: ['558a1208-8bcf-46e2-8ce4-fdd4b899d6a1'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '9b1f0c1c-62f7-4bbd-a081-1e558f9e2874',
                    assignment_id: '772692ed-3562-416c-a798-31ddfcd511c4',
                    question_id: '20791567-063b-4ab9-bfb6-41b34e48a0c7',
                    is_correct: false,
                    answer_text: 'Разрыв',
                    answer_date: '2024-01-26',
                    feedback: [],
                    employee_answer: ['7df786c4-b6d7-4912-8c04-3f09278a2707'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'a3f764a3-346d-461b-b171-de1509363a44',
                    assignment_id: '772692ed-3562-416c-a798-31ddfcd511c4',
                    question_id: '243fa21c-6514-423c-88fd-0fdb91c4f727',
                    is_correct: false,
                    answer_text: 'Фоторезисторы',
                    answer_date: '2024-01-26',
                    feedback: [],
                    employee_answer: ['46040dff-b94d-41d4-8983-ba606aafe99d'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'a89f00e9-da3e-461b-bd68-4f4066a9deb9',
                    assignment_id: '772692ed-3562-416c-a798-31ddfcd511c4',
                    question_id: 'a9483131-2a8f-49d8-8787-c64bd868a198',
                    is_correct: true,
                    answer_text: 'Послеаварийный',
                    answer_date: '2024-01-26',
                    feedback: [],
                    employee_answer: ['394e7641-5139-4ffb-bda1-43a73d7cc1f0'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'acf2e639-f5d2-4769-976d-16eec1539390',
                    assignment_id: '772692ed-3562-416c-a798-31ddfcd511c4',
                    question_id: '6af19c4b-4d17-4891-b885-3bbbb4d79b49',
                    is_correct: false,
                    answer_text: 'Электрогазовый распределительный щит',
                    answer_date: '2024-01-26',
                    feedback: [],
                    employee_answer: ['d332873a-740c-4fe9-b843-d7594a63f3f0'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'b2ed804a-6d7d-47f2-89a2-2edf9ede4142',
                    assignment_id: '772692ed-3562-416c-a798-31ddfcd511c4',
                    question_id: '8a631fc8-96e5-4537-a4f9-0ba372e13a0e',
                    is_correct: true,
                    answer_text: 'Мультиплексор',
                    answer_date: '2024-01-26',
                    feedback: [],
                    employee_answer: ['0ff2bff5-f0bf-4d3b-ab25-9420756297d2'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'b5a9e8e3-f587-442e-bac0-2d3950304964',
                    assignment_id: '772692ed-3562-416c-a798-31ddfcd511c4',
                    question_id: '13c538d6-a4f3-41bd-bc4a-dbec0a46f619',
                    is_correct: false,
                    answer_text: 'Пульсовый сигнал',
                    answer_date: '2024-01-26',
                    feedback: [],
                    employee_answer: ['aab63fbd-853d-4ab3-bac1-73640cc05a41'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'c15dadb2-2d24-443f-a3da-e9213da5f509',
                    assignment_id: '772692ed-3562-416c-a798-31ddfcd511c4',
                    question_id: '1af57b14-629a-4bbb-b7ba-e766a736f8d7',
                    is_correct: false,
                    answer_text: 'Светодиоды',
                    answer_date: '2024-01-26',
                    feedback: [],
                    employee_answer: ['e65ef2c5-321c-49e0-988b-c8abe7fb1740'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'ce2dbbd4-77d2-43d2-add4-89257dd039d3',
                    assignment_id: '772692ed-3562-416c-a798-31ddfcd511c4',
                    question_id: '2a40a92e-26c9-4961-a0a2-2b085688e790',
                    is_correct: false,
                    answer_text: 'Полупроводниковый компаратор',
                    answer_date: '2024-01-26',
                    feedback: [],
                    employee_answer: ['effbcd47-c11c-46b3-9e5e-20807fd2f9a2'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'd77c959e-348d-46f0-a7b9-c7ac77c6d764',
                    assignment_id: '772692ed-3562-416c-a798-31ddfcd511c4',
                    question_id: '91db0ce8-4fb8-4ea7-8bbb-b2aac5dc1627',
                    is_correct: false,
                    answer_text: 'Физическая характеристика',
                    answer_date: '2024-01-26',
                    feedback: [],
                    employee_answer: ['225105c4-97a2-48da-8053-7034097d59e6'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'f3e374f1-aeb1-4396-867f-4601fc9f02c2',
                    assignment_id: '772692ed-3562-416c-a798-31ddfcd511c4',
                    question_id: '25181c74-bb88-4fab-bc25-52a8353fbc0e',
                    is_correct: false,
                    answer_text: 'Рабочий',
                    answer_date: '2024-01-26',
                    feedback: [],
                    employee_answer: ['6944c2d4-e914-45ab-a142-c8e3df888a78'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'f5865409-d79f-4ca7-bc68-95ef898797f5',
                    assignment_id: '772692ed-3562-416c-a798-31ddfcd511c4',
                    question_id: 'e3416d36-9220-43bd-997e-b3691d2ae049',
                    is_correct: true,
                    answer_text: 'Температура самовоспламенения',
                    answer_date: '2024-01-26',
                    feedback: [],
                    employee_answer: ['f229abb9-7f18-4ddc-afec-0ccd3af14b50'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'f96ed3a1-8275-48d5-a0fe-eac250e50a3f',
                    assignment_id: '772692ed-3562-416c-a798-31ddfcd511c4',
                    question_id: 'cbc9468a-eab1-4277-9ecf-af4e126ab074',
                    is_correct: false,
                    answer_text: 'Дуга пропускания контура',
                    answer_date: '2024-01-26',
                    feedback: [],
                    employee_answer: ['d3628585-dc5c-4480-b40f-1712bb0be3fd'],
                    question: []
                }
            }],
        assignmentResults: {
            id: '0e71d001-d865-476e-a686-2b22603cc4fb',
            assignment_id: '772692ed-3562-416c-a798-31ddfcd511c4',
            is_success: false,
            question_count: 25,
            right_answers_count: 3,
            wrong_answers_count: 22,
            created_at: '2024-01-26T13:11:43.000000Z',
            updated_at: '2024-01-26T13:11:43.000000Z'
        }
    }, {
        id: '3a0721b9-6f35-409b-9d66-29e2dccc19a5',
        employee_id: '66b5e430-4593-41d3-9fe4-66dc0bc1f103',
        question_count: 6,
        knowledge_field_id: 'a0abb578-be3a-4b0e-8c01-5cf5737296c0',
        status: {
            status: 10,
            status_name: 'Назначено'
        },
        passing_test_time: '6 min',
        left_time: 6,
        dead_line: '2023-10-29',
        created_at: '2023-10-10T12:43:38.000000Z',
        start_date: null,
        end_date: null,
        knowledgeField: {
            id: 'a0abb578-be3a-4b0e-8c01-5cf5737296c0',
            title: 'Правила производства электромонтажных работ и охрана труда',
            active_status: true,
            functional_direction_id: '1aa9ceb4-74c6-4173-8029-781c189363c0',
            functional_direction_title: 'Электромонтажные работы',
            question_cnt: 0,
            information_source_cnt: 9,
            employee_cnt: 8,
            test_result_cnt: 17,
            created_at: '02.10.2023',
            base_id: '5a6ead49-569a-4d18-ad97-99457c92ab38',
            functionalDirection: {
                id: '1aa9ceb4-74c6-4173-8029-781c189363c0',
                title: 'Электромонтажные работы',
                base_id: '69f12fd3-87ba-4b17-a2ac-7c4cdcf01887',
                status: 20,
                created_at: '2023-10-02T14:19:37.000000Z',
                updated_at: '2023-10-02T14:25:38.000000Z',
                deleted_at: null
            }
        },
        employee: {
            id: '66b5e430-4593-41d3-9fe4-66dc0bc1f103',
            fio: 'Варнаев Игорь Вячеславович',
            email: 'i.varnaev@univertus.com',
            position: 'Начальник отдела охраны труда',
            position_id: '10847233',
            personal_number: '20329018',
            code: 'bAAq49EoC5DD2W0DHsyOOCNr4IGjP69x',
            department: null,
            head_id: null,
            is_head: true,
            head_email: 'i.varnaev@univertus.com',
            head_position: 'Начальник отдела охраны труда',
            head_position_id: '10847233'
        },
        assignmentQuestions: [{
                assignmentQuestion: {
                    id: '1eb30fee-8af8-423a-9c23-b78527166717',
                    assignment_id: '3a0721b9-6f35-409b-9d66-29e2dccc19a5',
                    question_id: '314b2f52-fc65-4cad-bd6c-7597ae7bfc86',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '42301e68-4eab-4dd5-af25-0b80c6a89e6b',
                    assignment_id: '3a0721b9-6f35-409b-9d66-29e2dccc19a5',
                    question_id: 'd4e38ac5-816f-49d1-8924-b97fe3d3e17c',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'b5281dcb-38bd-407c-9fb3-31e62c181318',
                    assignment_id: '3a0721b9-6f35-409b-9d66-29e2dccc19a5',
                    question_id: 'c1564b85-a513-4384-8582-1b6c0ac8bf2b',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'cc3dd02b-eed2-4f1b-a932-8f1a1eddecbf',
                    assignment_id: '3a0721b9-6f35-409b-9d66-29e2dccc19a5',
                    question_id: '8a631fc8-96e5-4537-a4f9-0ba372e13a0e',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'f483677c-89f2-41f7-9305-1a9c61ee90e5',
                    assignment_id: '3a0721b9-6f35-409b-9d66-29e2dccc19a5',
                    question_id: '96b5761e-0ae8-4e3b-bf09-ca2c638e8750',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'fcbc9e37-77ee-4080-a125-0e05e55338f7',
                    assignment_id: '3a0721b9-6f35-409b-9d66-29e2dccc19a5',
                    question_id: '7b6519de-cf39-493e-84df-bd206fd9b6bf',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }],
        assignmentResults: null
    }, {
        id: '726adfd1-5a00-4f37-bcf5-ee31f99e29db',
        employee_id: '44fa4892-bfa5-4483-9b34-e3dd0f8e0dfa',
        question_count: 17,
        knowledge_field_id: 'a0abb578-be3a-4b0e-8c01-5cf5737296c0',
        status: {
            status: 20,
            status_name: 'Завершено'
        },
        passing_test_time: '17 min',
        left_time: 0,
        dead_line: '2023-10-29',
        created_at: '2023-10-10T12:43:38.000000Z',
        start_date: '2023-10-10 12:51:45',
        end_date: '2023-10-10 13:10:01',
        knowledgeField: {
            id: 'a0abb578-be3a-4b0e-8c01-5cf5737296c0',
            title: 'Правила производства электромонтажных работ и охрана труда',
            active_status: true,
            functional_direction_id: '1aa9ceb4-74c6-4173-8029-781c189363c0',
            functional_direction_title: 'Электромонтажные работы',
            question_cnt: 0,
            information_source_cnt: 9,
            employee_cnt: 8,
            test_result_cnt: 17,
            created_at: '02.10.2023',
            base_id: '5a6ead49-569a-4d18-ad97-99457c92ab38',
            functionalDirection: {
                id: '1aa9ceb4-74c6-4173-8029-781c189363c0',
                title: 'Электромонтажные работы',
                base_id: '69f12fd3-87ba-4b17-a2ac-7c4cdcf01887',
                status: 20,
                created_at: '2023-10-02T14:19:37.000000Z',
                updated_at: '2023-10-02T14:25:38.000000Z',
                deleted_at: null
            }
        },
        employee: {
            id: '44fa4892-bfa5-4483-9b34-e3dd0f8e0dfa',
            fio: 'Кельманов \tРуслан \tАлексеевич',
            email: 'r.kelmanov@univertus.com',
            position: 'Специалист по безопасности и охране труда',
            position_id: '50345059',
            personal_number: '20426882',
            code: 'd3oWaJnsk3e1v4aX1XHczal4bdk3c7Vk',
            department: 'Охрана труда',
            head_id: '66b5e430-4593-41d3-9fe4-66dc0bc1f103',
            is_head: false,
            head_email: 'r.kelmanov@univertus.com',
            head_position: 'Специалист по безопасности и охране труда',
            head_position_id: '50345059'
        },
        assignmentQuestions: [{
                assignmentQuestion: {
                    id: '0d78a496-7628-4ac9-8cac-4bad84f206f9',
                    assignment_id: '726adfd1-5a00-4f37-bcf5-ee31f99e29db',
                    question_id: '2a40a92e-26c9-4961-a0a2-2b085688e790',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '16a9cf8b-6484-4568-827a-bca3757be075',
                    assignment_id: '726adfd1-5a00-4f37-bcf5-ee31f99e29db',
                    question_id: 'd4e38ac5-816f-49d1-8924-b97fe3d3e17c',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '1bea15c9-f299-46dc-8923-457faaf6c79e',
                    assignment_id: '726adfd1-5a00-4f37-bcf5-ee31f99e29db',
                    question_id: '13c538d6-a4f3-41bd-bc4a-dbec0a46f619',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '21d23bff-a30d-44d8-8535-052ec760cd83',
                    assignment_id: '726adfd1-5a00-4f37-bcf5-ee31f99e29db',
                    question_id: '9e733656-84f1-490d-a4df-9395ca84c0eb',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '2f410e7e-354c-49a0-89a7-eb888da35a15',
                    assignment_id: '726adfd1-5a00-4f37-bcf5-ee31f99e29db',
                    question_id: '96b5761e-0ae8-4e3b-bf09-ca2c638e8750',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '3ba4b1d0-bd80-499c-a841-4f46e42a4fca',
                    assignment_id: '726adfd1-5a00-4f37-bcf5-ee31f99e29db',
                    question_id: '8a631fc8-96e5-4537-a4f9-0ba372e13a0e',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '481e78bc-890e-41f4-829f-cf11032cb87f',
                    assignment_id: '726adfd1-5a00-4f37-bcf5-ee31f99e29db',
                    question_id: '7a278d91-1ad9-4ea1-aa7e-61e930380cc0',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '600038b9-33d6-499f-9a0c-a5142d4ef70e',
                    assignment_id: '726adfd1-5a00-4f37-bcf5-ee31f99e29db',
                    question_id: '80801bfc-a80c-40ff-90f7-cd14abfe0cee',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '688b9c13-2742-4f02-a12a-00c508655e55',
                    assignment_id: '726adfd1-5a00-4f37-bcf5-ee31f99e29db',
                    question_id: 'e3416d36-9220-43bd-997e-b3691d2ae049',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '72f0f017-b991-47e5-9f72-57936cc7ff16',
                    assignment_id: '726adfd1-5a00-4f37-bcf5-ee31f99e29db',
                    question_id: '20791567-063b-4ab9-bfb6-41b34e48a0c7',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '9ef6a8e6-f63a-4fef-874d-dfe0e5ae6abb',
                    assignment_id: '726adfd1-5a00-4f37-bcf5-ee31f99e29db',
                    question_id: '7b6519de-cf39-493e-84df-bd206fd9b6bf',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'a4f93d08-e4c2-4fad-81b2-bb14370e7035',
                    assignment_id: '726adfd1-5a00-4f37-bcf5-ee31f99e29db',
                    question_id: '0763f5c8-00a0-449a-a703-f469d13cf2d7',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'aba9ae63-091a-40b9-a03a-b5f163f2df68',
                    assignment_id: '726adfd1-5a00-4f37-bcf5-ee31f99e29db',
                    question_id: 'c1564b85-a513-4384-8582-1b6c0ac8bf2b',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'ac8fdd3e-7dd6-4f70-9280-ed3a35bcb5f0',
                    assignment_id: '726adfd1-5a00-4f37-bcf5-ee31f99e29db',
                    question_id: '3802dada-0afe-41ea-bb5c-896b822de134',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'c06410c3-f6c5-46f6-a2cb-bbe4f2e61002',
                    assignment_id: '726adfd1-5a00-4f37-bcf5-ee31f99e29db',
                    question_id: '1af57b14-629a-4bbb-b7ba-e766a736f8d7',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'c7c7be9f-8eb3-4d42-b777-734c82f60014',
                    assignment_id: '726adfd1-5a00-4f37-bcf5-ee31f99e29db',
                    question_id: 'e319bdec-6ee8-4572-984e-42c32acb7aee',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'd6df1a82-8a11-4872-9dd0-fbe2b272b5a6',
                    assignment_id: '726adfd1-5a00-4f37-bcf5-ee31f99e29db',
                    question_id: '314b2f52-fc65-4cad-bd6c-7597ae7bfc86',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }],
        assignmentResults: null
    }, {
        id: '17339cb0-89d6-482f-b1b3-76ce729b98d7',
        employee_id: '173e512f-52e5-4b87-b291-751dbbc2d6d2',
        question_count: 16,
        knowledge_field_id: 'a0abb578-be3a-4b0e-8c01-5cf5737296c0',
        status: {
            status: 20,
            status_name: 'Завершено'
        },
        passing_test_time: '16 min',
        left_time: 0,
        dead_line: '2023-10-29',
        created_at: '2023-10-10T12:43:38.000000Z',
        start_date: '2023-10-10 17:27:04',
        end_date: '2023-10-10 17:28:00',
        knowledgeField: {
            id: 'a0abb578-be3a-4b0e-8c01-5cf5737296c0',
            title: 'Правила производства электромонтажных работ и охрана труда',
            active_status: true,
            functional_direction_id: '1aa9ceb4-74c6-4173-8029-781c189363c0',
            functional_direction_title: 'Электромонтажные работы',
            question_cnt: 0,
            information_source_cnt: 9,
            employee_cnt: 8,
            test_result_cnt: 17,
            created_at: '02.10.2023',
            base_id: '5a6ead49-569a-4d18-ad97-99457c92ab38',
            functionalDirection: {
                id: '1aa9ceb4-74c6-4173-8029-781c189363c0',
                title: 'Электромонтажные работы',
                base_id: '69f12fd3-87ba-4b17-a2ac-7c4cdcf01887',
                status: 20,
                created_at: '2023-10-02T14:19:37.000000Z',
                updated_at: '2023-10-02T14:25:38.000000Z',
                deleted_at: null
            }
        },
        employee: {
            id: '173e512f-52e5-4b87-b291-751dbbc2d6d2',
            fio: 'Мерцаев \tСергей \tГеннадьевич',
            email: 's.mertsaev@univertus.com',
            position: 'Бухгалтер по учету расч. с контрагентами',
            position_id: '50369736',
            personal_number: '16001854',
            code: 'mQv5LheHyD9QuKLX0owXzcIIs6sJoAnT',
            department: 'Бухгалтерия',
            head_id: 'd8df7071-d3cd-491e-a34b-86dc7a5d6aeb',
            is_head: false,
            head_email: 's.mertsaev@univertus.com',
            head_position: 'Бухгалтер по учету расч. с контрагентами',
            head_position_id: '50369736'
        },
        assignmentQuestions: [{
                assignmentQuestion: {
                    id: '03fe1707-a0eb-41ab-a1c2-aa4c4e9f264d',
                    assignment_id: '17339cb0-89d6-482f-b1b3-76ce729b98d7',
                    question_id: '13c538d6-a4f3-41bd-bc4a-dbec0a46f619',
                    is_correct: false,
                    answer_text: 'Линейный сигнал',
                    answer_date: '2023-10-10',
                    feedback: [],
                    employee_answer: ['5c4ea5e5-1966-42f1-b90d-be34f37e1121'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '55db5889-9bd3-487c-b259-29c915347c82',
                    assignment_id: '17339cb0-89d6-482f-b1b3-76ce729b98d7',
                    question_id: 'e319bdec-6ee8-4572-984e-42c32acb7aee',
                    is_correct: false,
                    answer_text: 'Эстрада',
                    answer_date: '2023-10-10',
                    feedback: [],
                    employee_answer: ['b49ff6db-300a-4546-9585-44b8b7b2b3e4'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '7ce85aa1-8ffe-4840-b7f8-284924684d83',
                    assignment_id: '17339cb0-89d6-482f-b1b3-76ce729b98d7',
                    question_id: '96b5761e-0ae8-4e3b-bf09-ca2c638e8750',
                    is_correct: false,
                    answer_text: 'Способность',
                    answer_date: '2023-10-10',
                    feedback: [],
                    employee_answer: ['f2b005e2-e906-4dd4-a8cb-3a9a26d1ef18'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '8acb4fed-be6a-4344-963f-dc2240493fa7',
                    assignment_id: '17339cb0-89d6-482f-b1b3-76ce729b98d7',
                    question_id: '314b2f52-fc65-4cad-bd6c-7597ae7bfc86',
                    is_correct: false,
                    answer_text: 'Графические кривые',
                    answer_date: '2023-10-10',
                    feedback: [],
                    employee_answer: ['883fcf9d-2e43-48f7-80fa-39055c5b04a4'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '91c5014e-b9ab-4c6d-ab92-354334a29030',
                    assignment_id: '17339cb0-89d6-482f-b1b3-76ce729b98d7',
                    question_id: '7a278d91-1ad9-4ea1-aa7e-61e930380cc0',
                    is_correct: false,
                    answer_text: 'Температура воспламенения',
                    answer_date: '2023-10-10',
                    feedback: [],
                    employee_answer: ['5344d576-7a7c-4e20-9a79-3582a4f48329'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '9c58bc32-6f89-4c7e-a951-5c7a7c3c2ac6',
                    assignment_id: '17339cb0-89d6-482f-b1b3-76ce729b98d7',
                    question_id: 'c1564b85-a513-4384-8582-1b6c0ac8bf2b',
                    is_correct: false,
                    answer_text: 'Негабаритный троллейный токопровод',
                    answer_date: '2023-10-10',
                    feedback: [],
                    employee_answer: ['3aa4db63-32c5-4f57-b84d-527a02d52561'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'b8da1571-6de6-40b4-b6e9-8dd785f31120',
                    assignment_id: '17339cb0-89d6-482f-b1b3-76ce729b98d7',
                    question_id: '3802dada-0afe-41ea-bb5c-896b822de134',
                    is_correct: true,
                    answer_text: 'Температура воспламенения',
                    answer_date: '2023-10-10',
                    feedback: [],
                    employee_answer: ['2caf1609-024c-4879-b020-8bdc187b4c21'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'c356b3b1-e68f-4918-bdcc-006093af0ee0',
                    assignment_id: '17339cb0-89d6-482f-b1b3-76ce729b98d7',
                    question_id: '9e733656-84f1-490d-a4df-9395ca84c0eb',
                    is_correct: false,
                    answer_text: 'Температура вспышки',
                    answer_date: '2023-10-10',
                    feedback: [],
                    employee_answer: ['b4946f18-7d46-4985-88e2-7d31037016f1'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'c712cbe6-5949-4f17-a06b-43cad32e36b3',
                    assignment_id: '17339cb0-89d6-482f-b1b3-76ce729b98d7',
                    question_id: '0763f5c8-00a0-449a-a703-f469d13cf2d7',
                    is_correct: false,
                    answer_text: 'Мощные силы',
                    answer_date: '2023-10-10',
                    feedback: [],
                    employee_answer: ['ca647f63-1244-44d4-98ec-36bad9e9080d'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'ce6f2ffd-d6a9-4086-b1b7-b69aed557408',
                    assignment_id: '17339cb0-89d6-482f-b1b3-76ce729b98d7',
                    question_id: '80801bfc-a80c-40ff-90f7-cd14abfe0cee',
                    is_correct: false,
                    answer_text: 'Квантование информации',
                    answer_date: '2023-10-10',
                    feedback: [],
                    employee_answer: ['7e2bb7f2-54f4-4a35-9917-8a2014ff6fac'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'd5e1fe46-619e-4510-9114-6e723431b039',
                    assignment_id: '17339cb0-89d6-482f-b1b3-76ce729b98d7',
                    question_id: 'd4e38ac5-816f-49d1-8924-b97fe3d3e17c',
                    is_correct: false,
                    answer_text: 'Чрезвычайная',
                    answer_date: '2023-10-10',
                    feedback: [],
                    employee_answer: ['affe34bd-69d1-4752-9c87-c9525527e32a'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'e2310b1b-2ca4-4c36-99d7-58f4e98743db',
                    assignment_id: '17339cb0-89d6-482f-b1b3-76ce729b98d7',
                    question_id: '20791567-063b-4ab9-bfb6-41b34e48a0c7',
                    is_correct: false,
                    answer_text: 'Разрыв',
                    answer_date: '2023-10-10',
                    feedback: [],
                    employee_answer: ['a1e00c58-5103-4f89-86e3-7f3a5ba4bc61'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'ec4fa1f9-cd18-4754-97dd-e4007acd7c3d',
                    assignment_id: '17339cb0-89d6-482f-b1b3-76ce729b98d7',
                    question_id: '2a40a92e-26c9-4961-a0a2-2b085688e790',
                    is_correct: true,
                    answer_text: 'Полупроводниковый диод',
                    answer_date: '2023-10-10',
                    feedback: [],
                    employee_answer: ['aac261a2-e899-4bb8-95f6-1da813b0fc2e'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'efa982f1-a092-4aee-a7fd-551ce5b38f47',
                    assignment_id: '17339cb0-89d6-482f-b1b3-76ce729b98d7',
                    question_id: '7b6519de-cf39-493e-84df-bd206fd9b6bf',
                    is_correct: false,
                    answer_text: 'Капитал',
                    answer_date: '2023-10-10',
                    feedback: [],
                    employee_answer: ['5792541a-6463-4012-b750-47d8257f6a2d'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'f5131378-7151-4296-9514-c7589db6e05e',
                    assignment_id: '17339cb0-89d6-482f-b1b3-76ce729b98d7',
                    question_id: 'e3416d36-9220-43bd-997e-b3691d2ae049',
                    is_correct: false,
                    answer_text: 'Температура тления',
                    answer_date: '2023-10-10',
                    feedback: [],
                    employee_answer: ['c535190d-056d-4c1b-8e93-6851ae85d9fa'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'f76df1be-e327-45ea-b3ee-0fdcce48d0db',
                    assignment_id: '17339cb0-89d6-482f-b1b3-76ce729b98d7',
                    question_id: '8a631fc8-96e5-4537-a4f9-0ba372e13a0e',
                    is_correct: false,
                    answer_text: 'Разъём',
                    answer_date: '2023-10-10',
                    feedback: [],
                    employee_answer: ['4506800e-6429-4d68-946c-a52399057a37'],
                    question: []
                }
            }],
        assignmentResults: {
            id: '0899f195-cde3-4fb6-b5e5-fcea147ee862',
            assignment_id: '17339cb0-89d6-482f-b1b3-76ce729b98d7',
            is_success: false,
            question_count: 16,
            right_answers_count: 2,
            wrong_answers_count: 14,
            created_at: '2023-10-10T17:28:00.000000Z',
            updated_at: '2023-10-10T17:28:00.000000Z'
        }
    }, {
        id: 'd2b521c0-533b-4b40-a92c-33fe371f7c95',
        employee_id: 'b477a83f-7fbf-4872-ba0d-cae7fd2cefa6',
        question_count: 24,
        knowledge_field_id: 'a0abb578-be3a-4b0e-8c01-5cf5737296c0',
        status: {
            status: 25,
            status_name: 'Пропущено'
        },
        passing_test_time: '24 min',
        left_time: 24,
        dead_line: '2023-10-29',
        created_at: '2023-10-10T12:43:39.000000Z',
        start_date: null,
        end_date: null,
        knowledgeField: {
            id: 'a0abb578-be3a-4b0e-8c01-5cf5737296c0',
            title: 'Правила производства электромонтажных работ и охрана труда',
            active_status: true,
            functional_direction_id: '1aa9ceb4-74c6-4173-8029-781c189363c0',
            functional_direction_title: 'Электромонтажные работы',
            question_cnt: 0,
            information_source_cnt: 9,
            employee_cnt: 8,
            test_result_cnt: 17,
            created_at: '02.10.2023',
            base_id: '5a6ead49-569a-4d18-ad97-99457c92ab38',
            functionalDirection: {
                id: '1aa9ceb4-74c6-4173-8029-781c189363c0',
                title: 'Электромонтажные работы',
                base_id: '69f12fd3-87ba-4b17-a2ac-7c4cdcf01887',
                status: 20,
                created_at: '2023-10-02T14:19:37.000000Z',
                updated_at: '2023-10-02T14:25:38.000000Z',
                deleted_at: null
            }
        },
        employee: {
            id: 'b477a83f-7fbf-4872-ba0d-cae7fd2cefa6',
            fio: 'Alex',
            email: 'zepedro@yandex.ru',
            position: 'Alex',
            position_id: '12345678',
            personal_number: '12345678',
            code: 'foROw4YrlCgrmrw7jabOL9cwyWlIV9Lp',
            department: 'Alex',
            head_id: '66b5e430-4593-41d3-9fe4-66dc0bc1f103',
            is_head: false,
            head_email: 'zepedro@yandex.ru',
            head_position: 'Alex',
            head_position_id: '12345678'
        },
        assignmentQuestions: [{
                assignmentQuestion: {
                    id: '092f882b-6d6e-49a9-97ee-d8b6eb3de7e0',
                    assignment_id: 'd2b521c0-533b-4b40-a92c-33fe371f7c95',
                    question_id: '13c538d6-a4f3-41bd-bc4a-dbec0a46f619',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '135374d8-56e0-49ee-b461-5b27bf6cf5da',
                    assignment_id: 'd2b521c0-533b-4b40-a92c-33fe371f7c95',
                    question_id: '0763f5c8-00a0-449a-a703-f469d13cf2d7',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '1e5acd69-09b8-4dd2-9bce-2839e57ef926',
                    assignment_id: 'd2b521c0-533b-4b40-a92c-33fe371f7c95',
                    question_id: '25181c74-bb88-4fab-bc25-52a8353fbc0e',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '56433cff-61cb-405e-a496-357f002e0fc5',
                    assignment_id: 'd2b521c0-533b-4b40-a92c-33fe371f7c95',
                    question_id: '5d269692-31d9-447f-b704-0e54faebee44',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '57088e46-8748-4c8e-96b8-343019a87b96',
                    assignment_id: 'd2b521c0-533b-4b40-a92c-33fe371f7c95',
                    question_id: '7b6519de-cf39-493e-84df-bd206fd9b6bf',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '57988cbc-4e20-4486-8fe4-b6c86ebcc284',
                    assignment_id: 'd2b521c0-533b-4b40-a92c-33fe371f7c95',
                    question_id: '314b2f52-fc65-4cad-bd6c-7597ae7bfc86',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '65d196db-cdb1-4db9-b5e0-754a877d5ce3',
                    assignment_id: 'd2b521c0-533b-4b40-a92c-33fe371f7c95',
                    question_id: '243fa21c-6514-423c-88fd-0fdb91c4f727',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '6767dc7e-d257-41b3-a851-b02d57934783',
                    assignment_id: 'd2b521c0-533b-4b40-a92c-33fe371f7c95',
                    question_id: '7a278d91-1ad9-4ea1-aa7e-61e930380cc0',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '6a20613f-ed3a-4cdb-af98-040bce0f9716',
                    assignment_id: 'd2b521c0-533b-4b40-a92c-33fe371f7c95',
                    question_id: '91db0ce8-4fb8-4ea7-8bbb-b2aac5dc1627',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '742b4d70-4a76-42f5-bbb3-bdff66fe3b47',
                    assignment_id: 'd2b521c0-533b-4b40-a92c-33fe371f7c95',
                    question_id: '8a631fc8-96e5-4537-a4f9-0ba372e13a0e',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '80fdc266-f1b4-463e-aa03-ce7f135cf49e',
                    assignment_id: 'd2b521c0-533b-4b40-a92c-33fe371f7c95',
                    question_id: '20791567-063b-4ab9-bfb6-41b34e48a0c7',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '8597501d-322f-4a1e-91a6-9b6aa9e21141',
                    assignment_id: 'd2b521c0-533b-4b40-a92c-33fe371f7c95',
                    question_id: 'a9483131-2a8f-49d8-8787-c64bd868a198',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '965dc4e2-df3c-484d-8bf9-7038196f4d56',
                    assignment_id: 'd2b521c0-533b-4b40-a92c-33fe371f7c95',
                    question_id: '1af57b14-629a-4bbb-b7ba-e766a736f8d7',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'a60f2ad4-df2e-4f24-a975-32538a7f0865',
                    assignment_id: 'd2b521c0-533b-4b40-a92c-33fe371f7c95',
                    question_id: 'e319bdec-6ee8-4572-984e-42c32acb7aee',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'a7bcfe4a-3a97-4b07-a29f-b80b8fe70928',
                    assignment_id: 'd2b521c0-533b-4b40-a92c-33fe371f7c95',
                    question_id: 'e3416d36-9220-43bd-997e-b3691d2ae049',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'aae64b16-3163-4b8d-a1d4-51017312d2dc',
                    assignment_id: 'd2b521c0-533b-4b40-a92c-33fe371f7c95',
                    question_id: '2a40a92e-26c9-4961-a0a2-2b085688e790',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'c37237e9-9fb5-4775-bffc-29664835dccc',
                    assignment_id: 'd2b521c0-533b-4b40-a92c-33fe371f7c95',
                    question_id: '9e733656-84f1-490d-a4df-9395ca84c0eb',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'cdae8b34-ee64-46ae-b556-bed85f26817c',
                    assignment_id: 'd2b521c0-533b-4b40-a92c-33fe371f7c95',
                    question_id: '6af19c4b-4d17-4891-b885-3bbbb4d79b49',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'e3e1614d-28bf-44a7-ada1-7cef20178ba6',
                    assignment_id: 'd2b521c0-533b-4b40-a92c-33fe371f7c95',
                    question_id: '80801bfc-a80c-40ff-90f7-cd14abfe0cee',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'e4233bec-a55e-442f-a7dd-a1750f12732e',
                    assignment_id: 'd2b521c0-533b-4b40-a92c-33fe371f7c95',
                    question_id: '3802dada-0afe-41ea-bb5c-896b822de134',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'ef5f7ef9-9fd5-4210-84ca-b238da3a4ab6',
                    assignment_id: 'd2b521c0-533b-4b40-a92c-33fe371f7c95',
                    question_id: 'd4e38ac5-816f-49d1-8924-b97fe3d3e17c',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'ef9d2e2d-1059-4c47-9ff3-51174c3c3159',
                    assignment_id: 'd2b521c0-533b-4b40-a92c-33fe371f7c95',
                    question_id: '053cee07-2210-4307-9a0d-4daa61c66331',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'f81cb3e3-ef19-4a9e-ab46-a59b47a806ae',
                    assignment_id: 'd2b521c0-533b-4b40-a92c-33fe371f7c95',
                    question_id: 'c1564b85-a513-4384-8582-1b6c0ac8bf2b',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'ffa4d33d-15f1-407f-89ce-62297adfd53d',
                    assignment_id: 'd2b521c0-533b-4b40-a92c-33fe371f7c95',
                    question_id: '96b5761e-0ae8-4e3b-bf09-ca2c638e8750',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }],
        assignmentResults: null
    }, {
        id: '12734813-ae9e-4597-8266-d5021edc1c86',
        employee_id: '74f419c2-f5ba-4d99-8dba-c59e8f151a84',
        question_count: 10,
        knowledge_field_id: 'a0abb578-be3a-4b0e-8c01-5cf5737296c0',
        status: {
            status: 10,
            status_name: 'Назначено'
        },
        passing_test_time: '10 min',
        left_time: 10,
        dead_line: '2023-10-29',
        created_at: '2023-10-10T12:43:39.000000Z',
        start_date: null,
        end_date: null,
        knowledgeField: {
            id: 'a0abb578-be3a-4b0e-8c01-5cf5737296c0',
            title: 'Правила производства электромонтажных работ и охрана труда',
            active_status: true,
            functional_direction_id: '1aa9ceb4-74c6-4173-8029-781c189363c0',
            functional_direction_title: 'Электромонтажные работы',
            question_cnt: 0,
            information_source_cnt: 9,
            employee_cnt: 8,
            test_result_cnt: 17,
            created_at: '02.10.2023',
            base_id: '5a6ead49-569a-4d18-ad97-99457c92ab38',
            functionalDirection: {
                id: '1aa9ceb4-74c6-4173-8029-781c189363c0',
                title: 'Электромонтажные работы',
                base_id: '69f12fd3-87ba-4b17-a2ac-7c4cdcf01887',
                status: 20,
                created_at: '2023-10-02T14:19:37.000000Z',
                updated_at: '2023-10-02T14:25:38.000000Z',
                deleted_at: null
            }
        },
        employee: {
            id: '74f419c2-f5ba-4d99-8dba-c59e8f151a84',
            fio: 'Абашкина Елена Валерьевна',
            email: 'e.abashkina@univertus.com',
            position: 'Специалист по безопасности и охране труда',
            position_id: '50360095',
            personal_number: '15010560',
            code: 'pX3hubiynQTUV3BfkzWL3MtcfrsFAh48',
            department: 'Охрана труда',
            head_id: '66b5e430-4593-41d3-9fe4-66dc0bc1f103',
            is_head: false,
            head_email: 'e.abashkina@univertus.com',
            head_position: 'Специалист по безопасности и охране труда',
            head_position_id: '50360095'
        },
        assignmentQuestions: [{
                assignmentQuestion: {
                    id: '08d279fa-955e-4a60-95b1-76abb08cd52f',
                    assignment_id: '12734813-ae9e-4597-8266-d5021edc1c86',
                    question_id: '0763f5c8-00a0-449a-a703-f469d13cf2d7',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '217b183c-7a77-4aed-b559-a745c048cf26',
                    assignment_id: '12734813-ae9e-4597-8266-d5021edc1c86',
                    question_id: '8a631fc8-96e5-4537-a4f9-0ba372e13a0e',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '268c38f7-7cba-4bcd-8a17-6ef5dece95dc',
                    assignment_id: '12734813-ae9e-4597-8266-d5021edc1c86',
                    question_id: 'c1564b85-a513-4384-8582-1b6c0ac8bf2b',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '2fddb804-dc52-4498-833a-65df68be9ee2',
                    assignment_id: '12734813-ae9e-4597-8266-d5021edc1c86',
                    question_id: '7b6519de-cf39-493e-84df-bd206fd9b6bf',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '74909c1c-f78a-4078-808f-26cdf2bd385a',
                    assignment_id: '12734813-ae9e-4597-8266-d5021edc1c86',
                    question_id: '2a40a92e-26c9-4961-a0a2-2b085688e790',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '8f25be80-4ba3-4a6a-bbd4-058d348d6619',
                    assignment_id: '12734813-ae9e-4597-8266-d5021edc1c86',
                    question_id: 'd4e38ac5-816f-49d1-8924-b97fe3d3e17c',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '936b24b6-90cd-45aa-8453-397dbac8512a',
                    assignment_id: '12734813-ae9e-4597-8266-d5021edc1c86',
                    question_id: '314b2f52-fc65-4cad-bd6c-7597ae7bfc86',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'a972d0ef-e0b2-42bc-838c-9f9c7e3fc971',
                    assignment_id: '12734813-ae9e-4597-8266-d5021edc1c86',
                    question_id: 'e319bdec-6ee8-4572-984e-42c32acb7aee',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'b50524e6-569a-4f14-a5b1-45bfe7c27682',
                    assignment_id: '12734813-ae9e-4597-8266-d5021edc1c86',
                    question_id: '96b5761e-0ae8-4e3b-bf09-ca2c638e8750',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'faaf3060-800f-467b-a369-6eff73777a41',
                    assignment_id: '12734813-ae9e-4597-8266-d5021edc1c86',
                    question_id: '9e733656-84f1-490d-a4df-9395ca84c0eb',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }],
        assignmentResults: null
    }, {
        id: 'abdbf096-0ffe-41f5-a0f1-97c83481a67c',
        employee_id: '3a414350-ea6d-4284-96f0-f0866bd97f3d',
        question_count: 12,
        knowledge_field_id: 'a0abb578-be3a-4b0e-8c01-5cf5737296c0',
        status: {
            status: 20,
            status_name: 'Завершено'
        },
        passing_test_time: '12 min',
        left_time: 0,
        dead_line: '2023-10-29',
        created_at: '2023-10-10T12:43:39.000000Z',
        start_date: '2023-10-11 06:56:47',
        end_date: '2023-10-11 07:07:44',
        knowledgeField: {
            id: 'a0abb578-be3a-4b0e-8c01-5cf5737296c0',
            title: 'Правила производства электромонтажных работ и охрана труда',
            active_status: true,
            functional_direction_id: '1aa9ceb4-74c6-4173-8029-781c189363c0',
            functional_direction_title: 'Электромонтажные работы',
            question_cnt: 0,
            information_source_cnt: 9,
            employee_cnt: 8,
            test_result_cnt: 17,
            created_at: '02.10.2023',
            base_id: '5a6ead49-569a-4d18-ad97-99457c92ab38',
            functionalDirection: {
                id: '1aa9ceb4-74c6-4173-8029-781c189363c0',
                title: 'Электромонтажные работы',
                base_id: '69f12fd3-87ba-4b17-a2ac-7c4cdcf01887',
                status: 20,
                created_at: '2023-10-02T14:19:37.000000Z',
                updated_at: '2023-10-02T14:25:38.000000Z',
                deleted_at: null
            }
        },
        employee: {
            id: '3a414350-ea6d-4284-96f0-f0866bd97f3d',
            fio: 'Красикова \tДарья \tСергеевна',
            email: 'd.krasikova@univertus.com',
            position: 'Специалист по расчету зараб платы',
            position_id: '50368737',
            personal_number: '14000284',
            code: 'XyMS5F8hPokMX5OgzIVvXafZjHn1T3mN',
            department: 'Бухгалтерия',
            head_id: 'd8df7071-d3cd-491e-a34b-86dc7a5d6aeb',
            is_head: false,
            head_email: 'd.krasikova@univertus.com',
            head_position: 'Специалист по расчету зараб платы',
            head_position_id: '50368737'
        },
        assignmentQuestions: [{
                assignmentQuestion: {
                    id: '117e1b93-0fee-4f6f-be68-bb5f519f8686',
                    assignment_id: 'abdbf096-0ffe-41f5-a0f1-97c83481a67c',
                    question_id: '7b6519de-cf39-493e-84df-bd206fd9b6bf',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '502cea9f-dfbb-42ce-9437-a9150d5f9917',
                    assignment_id: 'abdbf096-0ffe-41f5-a0f1-97c83481a67c',
                    question_id: 'c1564b85-a513-4384-8582-1b6c0ac8bf2b',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '74b0f21d-b49f-4ca9-8965-6a91a6411d29',
                    assignment_id: 'abdbf096-0ffe-41f5-a0f1-97c83481a67c',
                    question_id: '0763f5c8-00a0-449a-a703-f469d13cf2d7',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '78ef524c-1aa9-4775-a19d-4cfb3123dc30',
                    assignment_id: 'abdbf096-0ffe-41f5-a0f1-97c83481a67c',
                    question_id: '9e733656-84f1-490d-a4df-9395ca84c0eb',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '840758cb-a60f-4bb2-9789-e26bdc2ec326',
                    assignment_id: 'abdbf096-0ffe-41f5-a0f1-97c83481a67c',
                    question_id: '314b2f52-fc65-4cad-bd6c-7597ae7bfc86',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '84087e98-0321-4e5a-889b-da911dd8bdb7',
                    assignment_id: 'abdbf096-0ffe-41f5-a0f1-97c83481a67c',
                    question_id: '8a631fc8-96e5-4537-a4f9-0ba372e13a0e',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '8cae4bf5-5956-462e-94bc-8901eaf7c0ef',
                    assignment_id: 'abdbf096-0ffe-41f5-a0f1-97c83481a67c',
                    question_id: '96b5761e-0ae8-4e3b-bf09-ca2c638e8750',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '9ddeb37c-95f5-469c-97f5-43602f07cb24',
                    assignment_id: 'abdbf096-0ffe-41f5-a0f1-97c83481a67c',
                    question_id: 'e3416d36-9220-43bd-997e-b3691d2ae049',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'b34d5a31-8337-4e27-85a2-62aed2235512',
                    assignment_id: 'abdbf096-0ffe-41f5-a0f1-97c83481a67c',
                    question_id: 'e319bdec-6ee8-4572-984e-42c32acb7aee',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'b535790d-642c-4d32-bda0-d68949ba8c07',
                    assignment_id: 'abdbf096-0ffe-41f5-a0f1-97c83481a67c',
                    question_id: '2a40a92e-26c9-4961-a0a2-2b085688e790',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'ceabbbb4-fd50-4e8f-b6b6-e86adeb19db9',
                    assignment_id: 'abdbf096-0ffe-41f5-a0f1-97c83481a67c',
                    question_id: '80801bfc-a80c-40ff-90f7-cd14abfe0cee',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'f2105e64-004b-4e41-b281-4060950c8c71',
                    assignment_id: 'abdbf096-0ffe-41f5-a0f1-97c83481a67c',
                    question_id: 'd4e38ac5-816f-49d1-8924-b97fe3d3e17c',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }],
        assignmentResults: {
            id: '15c9693e-f70b-48e9-8aad-16f6a223fbac',
            assignment_id: 'abdbf096-0ffe-41f5-a0f1-97c83481a67c',
            is_success: false,
            question_count: 12,
            right_answers_count: 0,
            wrong_answers_count: 12,
            created_at: '2023-10-11T07:07:44.000000Z',
            updated_at: '2023-10-11T07:07:44.000000Z'
        }
    }, {
        id: '61c7e882-cb77-4268-aae0-f211ea9024a5',
        employee_id: 'd8df7071-d3cd-491e-a34b-86dc7a5d6aeb',
        question_count: 15,
        knowledge_field_id: 'f93ad003-11af-48a1-b93b-a5f8d20ed846',
        status: {
            status: 10,
            status_name: 'Назначено'
        },
        passing_test_time: '15 min',
        left_time: 15,
        dead_line: '2024-02-29',
        created_at: '2024-01-30T07:03:38.000000Z',
        start_date: null,
        end_date: null,
        knowledgeField: {
            id: 'f93ad003-11af-48a1-b93b-a5f8d20ed846',
            title: 'Организация системы управления охраной труда на предприятии (СУОТ)',
            active_status: true,
            functional_direction_id: 'ff3807af-0296-44ae-b5d2-5cee778cf069',
            functional_direction_title: 'Охрана труда и промышленная безопасность',
            question_cnt: 0,
            information_source_cnt: 15,
            employee_cnt: 8,
            test_result_cnt: 8,
            created_at: '02.10.2023',
            base_id: '691969f2-1a5c-48fb-84a1-4b8354b7f638',
            functionalDirection: {
                id: 'ff3807af-0296-44ae-b5d2-5cee778cf069',
                title: 'Охрана труда и промышленная безопасность',
                base_id: 'f74b379c-48aa-49cf-aa34-daea7e1f1d1a',
                status: 20,
                created_at: '2023-10-02T14:19:28.000000Z',
                updated_at: '2023-10-02T14:25:35.000000Z',
                deleted_at: null
            }
        },
        employee: {
            id: 'd8df7071-d3cd-491e-a34b-86dc7a5d6aeb',
            fio: 'Жигулева Марина Юрьевна',
            email: 'm.zhiguleva@univertus.com',
            position: 'Главный бухгалтер',
            position_id: '10865709',
            personal_number: '28100400',
            code: 'uiazFpZQYKG2YClIFnw6tW5TS9OBra2Q',
            department: null,
            head_id: null,
            is_head: true,
            head_email: 'm.zhiguleva@univertus.com',
            head_position: 'Главный бухгалтер',
            head_position_id: '10865709'
        },
        assignmentQuestions: [{
                assignmentQuestion: {
                    id: '0543cea0-7e4c-49b7-b8a5-1906b632d274',
                    assignment_id: '61c7e882-cb77-4268-aae0-f211ea9024a5',
                    question_id: '012c93f3-62c6-40c0-9636-822954c14b39',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '1540ad2e-bbb2-4128-83bd-15406b7ce845',
                    assignment_id: '61c7e882-cb77-4268-aae0-f211ea9024a5',
                    question_id: 'a78a0c66-5f7b-430f-a6b6-a4476b69c80b',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '17585beb-290e-4af0-a62e-76e75e11fccd',
                    assignment_id: '61c7e882-cb77-4268-aae0-f211ea9024a5',
                    question_id: '252f658b-4ae0-4205-91e1-a7a38a4b2f84',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '3bc02a61-c17a-4a4f-9f95-02d171c75186',
                    assignment_id: '61c7e882-cb77-4268-aae0-f211ea9024a5',
                    question_id: '1b1cb05b-f6da-4934-9071-fd78d9628325',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '408f0ad9-4542-4e52-b9e9-3156861d9c05',
                    assignment_id: '61c7e882-cb77-4268-aae0-f211ea9024a5',
                    question_id: '089df980-5995-4173-af11-ae9595384a17',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '603e9ddf-c2b1-46fd-b1cc-1d41c38d0eb2',
                    assignment_id: '61c7e882-cb77-4268-aae0-f211ea9024a5',
                    question_id: 'fceff740-5bf7-4f98-94b5-b455de612dc9',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'a4441d9f-9323-4e68-9d3b-c0f1a7484f6c',
                    assignment_id: '61c7e882-cb77-4268-aae0-f211ea9024a5',
                    question_id: '7179c0d0-b2e7-4907-a90b-b50b9e453777',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'a7fdbef9-3132-455b-9202-56d82395d6ed',
                    assignment_id: '61c7e882-cb77-4268-aae0-f211ea9024a5',
                    question_id: '96ccaa0d-d3e5-43fa-9589-995fda276522',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'afa47f8b-d033-4832-8aab-16a803ce9ff8',
                    assignment_id: '61c7e882-cb77-4268-aae0-f211ea9024a5',
                    question_id: 'fbeae805-b8a4-48ff-a562-c088d660cc03',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'da0e1578-c8e9-448a-b431-13d44a99db91',
                    assignment_id: '61c7e882-cb77-4268-aae0-f211ea9024a5',
                    question_id: '05613963-5508-4df1-9012-4f26657cce32',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'e279b297-b132-41ed-8447-50981d0e1e82',
                    assignment_id: '61c7e882-cb77-4268-aae0-f211ea9024a5',
                    question_id: '5fcd0c53-a549-407c-8951-8003373ed8c6',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'ea3e56cb-2d61-4a77-96d2-dcd2b4b84f8f',
                    assignment_id: '61c7e882-cb77-4268-aae0-f211ea9024a5',
                    question_id: '51d944fd-5c2d-4017-bd15-d6589a4a1604',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'f20ff9e2-58a0-4c1b-9f8c-abb4f560bf6e',
                    assignment_id: '61c7e882-cb77-4268-aae0-f211ea9024a5',
                    question_id: '71b081ad-fdb7-4f08-aa3b-eb6ac52c8e4b',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'fba60638-3737-4b75-a3d8-920f5cf14f00',
                    assignment_id: '61c7e882-cb77-4268-aae0-f211ea9024a5',
                    question_id: '82439213-59f2-48ed-a529-684cdea52cc0',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'ff710a3b-5d2f-4692-9c86-b71fbdea169c',
                    assignment_id: '61c7e882-cb77-4268-aae0-f211ea9024a5',
                    question_id: 'df95c139-41d1-4c22-825e-0dab17500162',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }],
        assignmentResults: null
    }, {
        id: '946178a4-62d2-48cc-ab22-cbfeb9852f03',
        employee_id: '66b5e430-4593-41d3-9fe4-66dc0bc1f103',
        question_count: 25,
        knowledge_field_id: 'f93ad003-11af-48a1-b93b-a5f8d20ed846',
        status: {
            status: 10,
            status_name: 'Назначено'
        },
        passing_test_time: '25 min',
        left_time: 25,
        dead_line: '2024-02-29',
        created_at: '2024-01-30T07:03:38.000000Z',
        start_date: null,
        end_date: null,
        knowledgeField: {
            id: 'f93ad003-11af-48a1-b93b-a5f8d20ed846',
            title: 'Организация системы управления охраной труда на предприятии (СУОТ)',
            active_status: true,
            functional_direction_id: 'ff3807af-0296-44ae-b5d2-5cee778cf069',
            functional_direction_title: 'Охрана труда и промышленная безопасность',
            question_cnt: 0,
            information_source_cnt: 15,
            employee_cnt: 8,
            test_result_cnt: 8,
            created_at: '02.10.2023',
            base_id: '691969f2-1a5c-48fb-84a1-4b8354b7f638',
            functionalDirection: {
                id: 'ff3807af-0296-44ae-b5d2-5cee778cf069',
                title: 'Охрана труда и промышленная безопасность',
                base_id: 'f74b379c-48aa-49cf-aa34-daea7e1f1d1a',
                status: 20,
                created_at: '2023-10-02T14:19:28.000000Z',
                updated_at: '2023-10-02T14:25:35.000000Z',
                deleted_at: null
            }
        },
        employee: {
            id: '66b5e430-4593-41d3-9fe4-66dc0bc1f103',
            fio: 'Варнаев Игорь Вячеславович',
            email: 'i.varnaev@univertus.com',
            position: 'Начальник отдела охраны труда',
            position_id: '10847233',
            personal_number: '20329018',
            code: 'bAAq49EoC5DD2W0DHsyOOCNr4IGjP69x',
            department: null,
            head_id: null,
            is_head: true,
            head_email: 'i.varnaev@univertus.com',
            head_position: 'Начальник отдела охраны труда',
            head_position_id: '10847233'
        },
        assignmentQuestions: [{
                assignmentQuestion: {
                    id: '0adb1c1f-8c5e-4b79-b7fe-8f6f87c5502d',
                    assignment_id: '946178a4-62d2-48cc-ab22-cbfeb9852f03',
                    question_id: 'd63cbc63-e23c-44a9-80a3-f52945873267',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '13d6e6a4-9c8d-4388-b735-16a97486798b',
                    assignment_id: '946178a4-62d2-48cc-ab22-cbfeb9852f03',
                    question_id: '45d589d2-4300-4adc-a1e3-9c3aac741b1c',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '18bd0e22-6118-4516-a9d6-a4ae6d0fb849',
                    assignment_id: '946178a4-62d2-48cc-ab22-cbfeb9852f03',
                    question_id: '82439213-59f2-48ed-a529-684cdea52cc0',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '26a95571-1a4d-43d7-a295-6f3be8328535',
                    assignment_id: '946178a4-62d2-48cc-ab22-cbfeb9852f03',
                    question_id: 'a1d01ccc-1bc3-498b-a53f-cdcdc73d5f59',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '281dbb4e-620a-46b1-9f57-bea36ec7c5d1',
                    assignment_id: '946178a4-62d2-48cc-ab22-cbfeb9852f03',
                    question_id: 'a78a0c66-5f7b-430f-a6b6-a4476b69c80b',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '2966d4b6-0255-4547-949a-18757083a98f',
                    assignment_id: '946178a4-62d2-48cc-ab22-cbfeb9852f03',
                    question_id: '012c93f3-62c6-40c0-9636-822954c14b39',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '2caece2a-1249-40f6-872f-5b304e73f974',
                    assignment_id: '946178a4-62d2-48cc-ab22-cbfeb9852f03',
                    question_id: '5fcd0c53-a549-407c-8951-8003373ed8c6',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '2ec94e7c-84fb-4c64-ae35-ee122d8d7f36',
                    assignment_id: '946178a4-62d2-48cc-ab22-cbfeb9852f03',
                    question_id: '51d944fd-5c2d-4017-bd15-d6589a4a1604',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '37f35c83-9c0f-4340-a389-e97b239299e7',
                    assignment_id: '946178a4-62d2-48cc-ab22-cbfeb9852f03',
                    question_id: '96ccaa0d-d3e5-43fa-9589-995fda276522',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '41f4bbc8-6a21-4acb-b8b5-553d206b1c12',
                    assignment_id: '946178a4-62d2-48cc-ab22-cbfeb9852f03',
                    question_id: '252f658b-4ae0-4205-91e1-a7a38a4b2f84',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '4874f23f-27d0-4cdb-9828-a68c2d4f212a',
                    assignment_id: '946178a4-62d2-48cc-ab22-cbfeb9852f03',
                    question_id: 'fceff740-5bf7-4f98-94b5-b455de612dc9',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '554cfb20-5783-4d61-b819-1bc4443728a9',
                    assignment_id: '946178a4-62d2-48cc-ab22-cbfeb9852f03',
                    question_id: 'a9f9f874-28db-43ae-a5a5-45af19078dc7',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '5b94eefe-8869-4fd6-b3f7-7bade2b76ccc',
                    assignment_id: '946178a4-62d2-48cc-ab22-cbfeb9852f03',
                    question_id: '730d0e00-f49c-4a1d-b0d9-7d0b0cd4acef',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '5c0865f5-d1c0-4f2c-a305-fec156f3a281',
                    assignment_id: '946178a4-62d2-48cc-ab22-cbfeb9852f03',
                    question_id: '415fd193-6842-48ba-aa38-0388e59e6f8d',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '70775343-561d-4490-80bb-fcdebb071d12',
                    assignment_id: '946178a4-62d2-48cc-ab22-cbfeb9852f03',
                    question_id: 'fbeae805-b8a4-48ff-a562-c088d660cc03',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '7c3b54e7-1688-4d35-bb07-40242374b528',
                    assignment_id: '946178a4-62d2-48cc-ab22-cbfeb9852f03',
                    question_id: 'df95c139-41d1-4c22-825e-0dab17500162',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '808cd67d-dd4a-4f15-8842-24c2e6e7c005',
                    assignment_id: '946178a4-62d2-48cc-ab22-cbfeb9852f03',
                    question_id: '7179c0d0-b2e7-4907-a90b-b50b9e453777',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '88b9647a-55c3-4bcc-bdea-03716d99ecc2',
                    assignment_id: '946178a4-62d2-48cc-ab22-cbfeb9852f03',
                    question_id: '1b1cb05b-f6da-4934-9071-fd78d9628325',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '8944b8c5-bbf4-43e9-a081-1bdd742c8c3e',
                    assignment_id: '946178a4-62d2-48cc-ab22-cbfeb9852f03',
                    question_id: '71b081ad-fdb7-4f08-aa3b-eb6ac52c8e4b',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '90b79b94-7188-489f-ae37-aea4f7730380',
                    assignment_id: '946178a4-62d2-48cc-ab22-cbfeb9852f03',
                    question_id: 'e90dd478-363a-4bf2-b908-de998c21f993',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '98d65129-429c-4dea-a4e0-37de6390f5f4',
                    assignment_id: '946178a4-62d2-48cc-ab22-cbfeb9852f03',
                    question_id: 'bf40da34-d882-4e5f-947d-3a9cbabcd62c',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '9a86f418-8751-4cd4-a038-7c537566745e',
                    assignment_id: '946178a4-62d2-48cc-ab22-cbfeb9852f03',
                    question_id: '8edecaf5-d2e6-4387-90dc-4aa31d40c017',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'b3b956d8-c13a-4aba-8a9c-c3b3b5f65173',
                    assignment_id: '946178a4-62d2-48cc-ab22-cbfeb9852f03',
                    question_id: '05613963-5508-4df1-9012-4f26657cce32',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'e07c8ca0-76a8-4dfe-877a-cf76ce8e548c',
                    assignment_id: '946178a4-62d2-48cc-ab22-cbfeb9852f03',
                    question_id: '089df980-5995-4173-af11-ae9595384a17',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'ebcc1875-5b98-47f5-97bc-f4145dfadaaa',
                    assignment_id: '946178a4-62d2-48cc-ab22-cbfeb9852f03',
                    question_id: '225f9f83-425b-490a-a66a-3a812321761d',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }],
        assignmentResults: null
    }, {
        id: 'fbc96708-0680-4ce6-9fc0-0961130eebae',
        employee_id: '3a414350-ea6d-4284-96f0-f0866bd97f3d',
        question_count: 12,
        knowledge_field_id: 'f93ad003-11af-48a1-b93b-a5f8d20ed846',
        status: {
            status: 10,
            status_name: 'Назначено'
        },
        passing_test_time: '12 min',
        left_time: 12,
        dead_line: '2024-02-29',
        created_at: '2024-01-30T07:03:38.000000Z',
        start_date: null,
        end_date: null,
        knowledgeField: {
            id: 'f93ad003-11af-48a1-b93b-a5f8d20ed846',
            title: 'Организация системы управления охраной труда на предприятии (СУОТ)',
            active_status: true,
            functional_direction_id: 'ff3807af-0296-44ae-b5d2-5cee778cf069',
            functional_direction_title: 'Охрана труда и промышленная безопасность',
            question_cnt: 0,
            information_source_cnt: 15,
            employee_cnt: 8,
            test_result_cnt: 8,
            created_at: '02.10.2023',
            base_id: '691969f2-1a5c-48fb-84a1-4b8354b7f638',
            functionalDirection: {
                id: 'ff3807af-0296-44ae-b5d2-5cee778cf069',
                title: 'Охрана труда и промышленная безопасность',
                base_id: 'f74b379c-48aa-49cf-aa34-daea7e1f1d1a',
                status: 20,
                created_at: '2023-10-02T14:19:28.000000Z',
                updated_at: '2023-10-02T14:25:35.000000Z',
                deleted_at: null
            }
        },
        employee: {
            id: '3a414350-ea6d-4284-96f0-f0866bd97f3d',
            fio: 'Красикова \tДарья \tСергеевна',
            email: 'd.krasikova@univertus.com',
            position: 'Специалист по расчету зараб платы',
            position_id: '50368737',
            personal_number: '14000284',
            code: 'XyMS5F8hPokMX5OgzIVvXafZjHn1T3mN',
            department: 'Бухгалтерия',
            head_id: 'd8df7071-d3cd-491e-a34b-86dc7a5d6aeb',
            is_head: false,
            head_email: 'd.krasikova@univertus.com',
            head_position: 'Специалист по расчету зараб платы',
            head_position_id: '50368737'
        },
        assignmentQuestions: [{
                assignmentQuestion: {
                    id: '0d333e51-3757-4277-8bfc-0a3ae9914be4',
                    assignment_id: 'fbc96708-0680-4ce6-9fc0-0961130eebae',
                    question_id: '5fcd0c53-a549-407c-8951-8003373ed8c6',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '0d4e85fe-9d0d-40de-8905-79cdbcc1c557',
                    assignment_id: 'fbc96708-0680-4ce6-9fc0-0961130eebae',
                    question_id: 'df95c139-41d1-4c22-825e-0dab17500162',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '0db03e3b-4fa2-41d1-92bb-aad1cc49280f',
                    assignment_id: 'fbc96708-0680-4ce6-9fc0-0961130eebae',
                    question_id: '05613963-5508-4df1-9012-4f26657cce32',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '1542fe10-be67-4d00-8bbc-dc6ada9b6f6b',
                    assignment_id: 'fbc96708-0680-4ce6-9fc0-0961130eebae',
                    question_id: '71b081ad-fdb7-4f08-aa3b-eb6ac52c8e4b',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '290b8358-9848-45c7-8a3d-12e1d51f6948',
                    assignment_id: 'fbc96708-0680-4ce6-9fc0-0961130eebae',
                    question_id: '96ccaa0d-d3e5-43fa-9589-995fda276522',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '4e10276d-b1de-4511-b905-0f19a15ca02c',
                    assignment_id: 'fbc96708-0680-4ce6-9fc0-0961130eebae',
                    question_id: '51d944fd-5c2d-4017-bd15-d6589a4a1604',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '7efb639e-a263-493a-87b0-e88abd5c201a',
                    assignment_id: 'fbc96708-0680-4ce6-9fc0-0961130eebae',
                    question_id: '089df980-5995-4173-af11-ae9595384a17',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'bf639c68-bebc-4816-acf2-1571c15a9a2d',
                    assignment_id: 'fbc96708-0680-4ce6-9fc0-0961130eebae',
                    question_id: '252f658b-4ae0-4205-91e1-a7a38a4b2f84',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'c964c828-c953-4683-8a4f-5d3b944d62af',
                    assignment_id: 'fbc96708-0680-4ce6-9fc0-0961130eebae',
                    question_id: '1b1cb05b-f6da-4934-9071-fd78d9628325',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'd77234b1-157e-4363-80ae-11050c4822cd',
                    assignment_id: 'fbc96708-0680-4ce6-9fc0-0961130eebae',
                    question_id: 'a78a0c66-5f7b-430f-a6b6-a4476b69c80b',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'de01c553-34b6-42aa-808b-237a7a0b9ab7',
                    assignment_id: 'fbc96708-0680-4ce6-9fc0-0961130eebae',
                    question_id: '012c93f3-62c6-40c0-9636-822954c14b39',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'ed63aa3d-0fa0-422c-b25e-fe90f42ac162',
                    assignment_id: 'fbc96708-0680-4ce6-9fc0-0961130eebae',
                    question_id: '82439213-59f2-48ed-a529-684cdea52cc0',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }],
        assignmentResults: null
    }, {
        id: 'c94177b9-b042-4e8d-a0d7-f7b5361a7457',
        employee_id: '2f5c37b1-25a7-4fc3-9d3a-46d27c99fb68',
        question_count: 11,
        knowledge_field_id: 'f93ad003-11af-48a1-b93b-a5f8d20ed846',
        status: {
            status: 10,
            status_name: 'Назначено'
        },
        passing_test_time: '11 min',
        left_time: 11,
        dead_line: '2024-02-29',
        created_at: '2024-01-30T07:03:38.000000Z',
        start_date: null,
        end_date: null,
        knowledgeField: {
            id: 'f93ad003-11af-48a1-b93b-a5f8d20ed846',
            title: 'Организация системы управления охраной труда на предприятии (СУОТ)',
            active_status: true,
            functional_direction_id: 'ff3807af-0296-44ae-b5d2-5cee778cf069',
            functional_direction_title: 'Охрана труда и промышленная безопасность',
            question_cnt: 0,
            information_source_cnt: 15,
            employee_cnt: 8,
            test_result_cnt: 8,
            created_at: '02.10.2023',
            base_id: '691969f2-1a5c-48fb-84a1-4b8354b7f638',
            functionalDirection: {
                id: 'ff3807af-0296-44ae-b5d2-5cee778cf069',
                title: 'Охрана труда и промышленная безопасность',
                base_id: 'f74b379c-48aa-49cf-aa34-daea7e1f1d1a',
                status: 20,
                created_at: '2023-10-02T14:19:28.000000Z',
                updated_at: '2023-10-02T14:25:35.000000Z',
                deleted_at: null
            }
        },
        employee: {
            id: '2f5c37b1-25a7-4fc3-9d3a-46d27c99fb68',
            fio: 'Абдылов \tДмитрий \tСергеевич',
            email: 'd.abdylov@univertus.com',
            position: 'Специалист по безопасности и охране труда',
            position_id: '10859310',
            personal_number: '20203004',
            code: '5jC4vnfQyVuHIErHZPxDQP1XZvaPuttu',
            department: 'Охрана труда',
            head_id: '66b5e430-4593-41d3-9fe4-66dc0bc1f103',
            is_head: false,
            head_email: 'd.abdylov@univertus.com',
            head_position: 'Специалист по безопасности и охране труда',
            head_position_id: '10859310'
        },
        assignmentQuestions: [{
                assignmentQuestion: {
                    id: '0bcb747e-8da2-4c0c-8e50-1b9afcaf57ff',
                    assignment_id: 'c94177b9-b042-4e8d-a0d7-f7b5361a7457',
                    question_id: '252f658b-4ae0-4205-91e1-a7a38a4b2f84',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '0c5cd9b7-6190-4908-bdea-e72be7af977b',
                    assignment_id: 'c94177b9-b042-4e8d-a0d7-f7b5361a7457',
                    question_id: '05613963-5508-4df1-9012-4f26657cce32',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '26be9c07-185b-4a17-a006-2fe62c4dd686',
                    assignment_id: 'c94177b9-b042-4e8d-a0d7-f7b5361a7457',
                    question_id: '012c93f3-62c6-40c0-9636-822954c14b39',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '57ba5e27-e4be-470a-9c29-ac0a2b8e8b71',
                    assignment_id: 'c94177b9-b042-4e8d-a0d7-f7b5361a7457',
                    question_id: '1b1cb05b-f6da-4934-9071-fd78d9628325',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '6120cc1d-00bb-405c-b8e1-e977443ab468',
                    assignment_id: 'c94177b9-b042-4e8d-a0d7-f7b5361a7457',
                    question_id: '51d944fd-5c2d-4017-bd15-d6589a4a1604',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '77a97bbc-30e9-4a7b-8e47-c7a696e3a8cc',
                    assignment_id: 'c94177b9-b042-4e8d-a0d7-f7b5361a7457',
                    question_id: 'a78a0c66-5f7b-430f-a6b6-a4476b69c80b',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '83c0219d-0c92-45e2-843f-261663a9b537',
                    assignment_id: 'c94177b9-b042-4e8d-a0d7-f7b5361a7457',
                    question_id: '5fcd0c53-a549-407c-8951-8003373ed8c6',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '8543fc5b-5b9c-4d3c-9c9f-e35f5688eeee',
                    assignment_id: 'c94177b9-b042-4e8d-a0d7-f7b5361a7457',
                    question_id: 'df95c139-41d1-4c22-825e-0dab17500162',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '9402c1e3-af20-43b6-ac07-6119d2085cae',
                    assignment_id: 'c94177b9-b042-4e8d-a0d7-f7b5361a7457',
                    question_id: '089df980-5995-4173-af11-ae9595384a17',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'a2f3f20d-4e69-4a88-ab15-793381a7d757',
                    assignment_id: 'c94177b9-b042-4e8d-a0d7-f7b5361a7457',
                    question_id: '96ccaa0d-d3e5-43fa-9589-995fda276522',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'ab2e06a1-6e94-4c66-9d62-5ad943834823',
                    assignment_id: 'c94177b9-b042-4e8d-a0d7-f7b5361a7457',
                    question_id: '82439213-59f2-48ed-a529-684cdea52cc0',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }],
        assignmentResults: null
    }, {
        id: '624c631e-2490-4e26-b27c-e842fd81910e',
        employee_id: '173e512f-52e5-4b87-b291-751dbbc2d6d2',
        question_count: 9,
        knowledge_field_id: 'f93ad003-11af-48a1-b93b-a5f8d20ed846',
        status: {
            status: 10,
            status_name: 'Назначено'
        },
        passing_test_time: '9 min',
        left_time: 9,
        dead_line: '2024-02-29',
        created_at: '2024-01-30T07:03:38.000000Z',
        start_date: null,
        end_date: null,
        knowledgeField: {
            id: 'f93ad003-11af-48a1-b93b-a5f8d20ed846',
            title: 'Организация системы управления охраной труда на предприятии (СУОТ)',
            active_status: true,
            functional_direction_id: 'ff3807af-0296-44ae-b5d2-5cee778cf069',
            functional_direction_title: 'Охрана труда и промышленная безопасность',
            question_cnt: 0,
            information_source_cnt: 15,
            employee_cnt: 8,
            test_result_cnt: 8,
            created_at: '02.10.2023',
            base_id: '691969f2-1a5c-48fb-84a1-4b8354b7f638',
            functionalDirection: {
                id: 'ff3807af-0296-44ae-b5d2-5cee778cf069',
                title: 'Охрана труда и промышленная безопасность',
                base_id: 'f74b379c-48aa-49cf-aa34-daea7e1f1d1a',
                status: 20,
                created_at: '2023-10-02T14:19:28.000000Z',
                updated_at: '2023-10-02T14:25:35.000000Z',
                deleted_at: null
            }
        },
        employee: {
            id: '173e512f-52e5-4b87-b291-751dbbc2d6d2',
            fio: 'Мерцаев \tСергей \tГеннадьевич',
            email: 's.mertsaev@univertus.com',
            position: 'Бухгалтер по учету расч. с контрагентами',
            position_id: '50369736',
            personal_number: '16001854',
            code: 'mQv5LheHyD9QuKLX0owXzcIIs6sJoAnT',
            department: 'Бухгалтерия',
            head_id: 'd8df7071-d3cd-491e-a34b-86dc7a5d6aeb',
            is_head: false,
            head_email: 's.mertsaev@univertus.com',
            head_position: 'Бухгалтер по учету расч. с контрагентами',
            head_position_id: '50369736'
        },
        assignmentQuestions: [{
                assignmentQuestion: {
                    id: '0a7751e7-c726-4e66-be09-d3bfb66de46b',
                    assignment_id: '624c631e-2490-4e26-b27c-e842fd81910e',
                    question_id: '252f658b-4ae0-4205-91e1-a7a38a4b2f84',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '26d08f51-4874-4044-a313-102d1f95a0e4',
                    assignment_id: '624c631e-2490-4e26-b27c-e842fd81910e',
                    question_id: 'df95c139-41d1-4c22-825e-0dab17500162',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '4395330f-2bb0-465a-b38c-741d1aadd1a4',
                    assignment_id: '624c631e-2490-4e26-b27c-e842fd81910e',
                    question_id: '82439213-59f2-48ed-a529-684cdea52cc0',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '4762c274-a17d-43c8-87dc-128f40ab9d5f',
                    assignment_id: '624c631e-2490-4e26-b27c-e842fd81910e',
                    question_id: '51d944fd-5c2d-4017-bd15-d6589a4a1604',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '4f5cb5a4-88c5-46e7-a0c5-6c562e2921e6',
                    assignment_id: '624c631e-2490-4e26-b27c-e842fd81910e',
                    question_id: '05613963-5508-4df1-9012-4f26657cce32',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '788cd39c-fe5f-4f70-bd20-2ea3fdb31f71',
                    assignment_id: '624c631e-2490-4e26-b27c-e842fd81910e',
                    question_id: '1b1cb05b-f6da-4934-9071-fd78d9628325',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '844773cc-832d-4284-ae44-cf3e6e6b0af6',
                    assignment_id: '624c631e-2490-4e26-b27c-e842fd81910e',
                    question_id: '5fcd0c53-a549-407c-8951-8003373ed8c6',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'df068d9b-49e0-4815-a033-b03101fcf3a1',
                    assignment_id: '624c631e-2490-4e26-b27c-e842fd81910e',
                    question_id: 'a78a0c66-5f7b-430f-a6b6-a4476b69c80b',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'e28c80aa-170a-4266-9e9e-cf4bb5344885',
                    assignment_id: '624c631e-2490-4e26-b27c-e842fd81910e',
                    question_id: '012c93f3-62c6-40c0-9636-822954c14b39',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }],
        assignmentResults: null
    }, {
        id: '94b6229a-d32e-4588-aa76-e3a9065ce60e',
        employee_id: '44fa4892-bfa5-4483-9b34-e3dd0f8e0dfa',
        question_count: 23,
        knowledge_field_id: 'f93ad003-11af-48a1-b93b-a5f8d20ed846',
        status: {
            status: 10,
            status_name: 'Назначено'
        },
        passing_test_time: '23 min',
        left_time: 23,
        dead_line: '2024-02-29',
        created_at: '2024-01-30T07:03:38.000000Z',
        start_date: null,
        end_date: null,
        knowledgeField: {
            id: 'f93ad003-11af-48a1-b93b-a5f8d20ed846',
            title: 'Организация системы управления охраной труда на предприятии (СУОТ)',
            active_status: true,
            functional_direction_id: 'ff3807af-0296-44ae-b5d2-5cee778cf069',
            functional_direction_title: 'Охрана труда и промышленная безопасность',
            question_cnt: 0,
            information_source_cnt: 15,
            employee_cnt: 8,
            test_result_cnt: 8,
            created_at: '02.10.2023',
            base_id: '691969f2-1a5c-48fb-84a1-4b8354b7f638',
            functionalDirection: {
                id: 'ff3807af-0296-44ae-b5d2-5cee778cf069',
                title: 'Охрана труда и промышленная безопасность',
                base_id: 'f74b379c-48aa-49cf-aa34-daea7e1f1d1a',
                status: 20,
                created_at: '2023-10-02T14:19:28.000000Z',
                updated_at: '2023-10-02T14:25:35.000000Z',
                deleted_at: null
            }
        },
        employee: {
            id: '44fa4892-bfa5-4483-9b34-e3dd0f8e0dfa',
            fio: 'Кельманов \tРуслан \tАлексеевич',
            email: 'r.kelmanov@univertus.com',
            position: 'Специалист по безопасности и охране труда',
            position_id: '50345059',
            personal_number: '20426882',
            code: 'd3oWaJnsk3e1v4aX1XHczal4bdk3c7Vk',
            department: 'Охрана труда',
            head_id: '66b5e430-4593-41d3-9fe4-66dc0bc1f103',
            is_head: false,
            head_email: 'r.kelmanov@univertus.com',
            head_position: 'Специалист по безопасности и охране труда',
            head_position_id: '50345059'
        },
        assignmentQuestions: [{
                assignmentQuestion: {
                    id: '0a20fe86-0a1e-45a7-9ce4-5c30f9181ff0',
                    assignment_id: '94b6229a-d32e-4588-aa76-e3a9065ce60e',
                    question_id: 'df95c139-41d1-4c22-825e-0dab17500162',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '1f1009f7-9a91-4c3f-b9a3-f9e47a93d93d',
                    assignment_id: '94b6229a-d32e-4588-aa76-e3a9065ce60e',
                    question_id: '96ccaa0d-d3e5-43fa-9589-995fda276522',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '2e1a9957-5f20-4906-960b-e147d0f6a46d',
                    assignment_id: '94b6229a-d32e-4588-aa76-e3a9065ce60e',
                    question_id: '225f9f83-425b-490a-a66a-3a812321761d',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '318aa261-f070-46b8-b906-830d856774b2',
                    assignment_id: '94b6229a-d32e-4588-aa76-e3a9065ce60e',
                    question_id: 'a78a0c66-5f7b-430f-a6b6-a4476b69c80b',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '3eba5b2b-233e-49cc-a7e3-64eaf548610e',
                    assignment_id: '94b6229a-d32e-4588-aa76-e3a9065ce60e',
                    question_id: '5fcd0c53-a549-407c-8951-8003373ed8c6',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '44b03e0b-ea78-4b89-8ff6-bf0fb020adeb',
                    assignment_id: '94b6229a-d32e-4588-aa76-e3a9065ce60e',
                    question_id: '012c93f3-62c6-40c0-9636-822954c14b39',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '4db5c265-3211-40b2-9964-cd5a304ff764',
                    assignment_id: '94b6229a-d32e-4588-aa76-e3a9065ce60e',
                    question_id: '252f658b-4ae0-4205-91e1-a7a38a4b2f84',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '574a2be2-d193-43cd-b600-b36fdd0eaf40',
                    assignment_id: '94b6229a-d32e-4588-aa76-e3a9065ce60e',
                    question_id: 'fbeae805-b8a4-48ff-a562-c088d660cc03',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '5d718392-3c29-4127-b264-0f8978c576bd',
                    assignment_id: '94b6229a-d32e-4588-aa76-e3a9065ce60e',
                    question_id: '730d0e00-f49c-4a1d-b0d9-7d0b0cd4acef',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '75d9fddf-6408-44ca-8007-f6c6faabcf5e',
                    assignment_id: '94b6229a-d32e-4588-aa76-e3a9065ce60e',
                    question_id: '8edecaf5-d2e6-4387-90dc-4aa31d40c017',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '77b3ecbd-4b0a-4819-bb4b-97cd550f9d15',
                    assignment_id: '94b6229a-d32e-4588-aa76-e3a9065ce60e',
                    question_id: '51d944fd-5c2d-4017-bd15-d6589a4a1604',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '9feb473d-da00-4043-a12e-94bd529ca131',
                    assignment_id: '94b6229a-d32e-4588-aa76-e3a9065ce60e',
                    question_id: 'e90dd478-363a-4bf2-b908-de998c21f993',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'a0450de9-1c64-4477-81eb-db4b0923391e',
                    assignment_id: '94b6229a-d32e-4588-aa76-e3a9065ce60e',
                    question_id: '45d589d2-4300-4adc-a1e3-9c3aac741b1c',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'a61038e4-65a9-42c3-817b-aae12f9e52b2',
                    assignment_id: '94b6229a-d32e-4588-aa76-e3a9065ce60e',
                    question_id: '1b1cb05b-f6da-4934-9071-fd78d9628325',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'af5d652a-4ce1-44c1-9828-09e6319eae0d',
                    assignment_id: '94b6229a-d32e-4588-aa76-e3a9065ce60e',
                    question_id: 'd63cbc63-e23c-44a9-80a3-f52945873267',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'b74fb401-d370-4ede-94e1-01ff77d7b066',
                    assignment_id: '94b6229a-d32e-4588-aa76-e3a9065ce60e',
                    question_id: '71b081ad-fdb7-4f08-aa3b-eb6ac52c8e4b',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'cd7cdca3-5519-43b2-b81f-818b5b89f820',
                    assignment_id: '94b6229a-d32e-4588-aa76-e3a9065ce60e',
                    question_id: 'fceff740-5bf7-4f98-94b5-b455de612dc9',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'cf4e5b06-edad-4928-9f92-526cff3acbd5',
                    assignment_id: '94b6229a-d32e-4588-aa76-e3a9065ce60e',
                    question_id: 'a1d01ccc-1bc3-498b-a53f-cdcdc73d5f59',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'd9de7023-76f8-49e7-92b8-95fbdee0eefc',
                    assignment_id: '94b6229a-d32e-4588-aa76-e3a9065ce60e',
                    question_id: '7179c0d0-b2e7-4907-a90b-b50b9e453777',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'e0aa9372-0530-4260-9e97-bdfffd5ff599',
                    assignment_id: '94b6229a-d32e-4588-aa76-e3a9065ce60e',
                    question_id: 'bf40da34-d882-4e5f-947d-3a9cbabcd62c',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'e252bbe5-53da-40b8-86df-bb5a674dfad6',
                    assignment_id: '94b6229a-d32e-4588-aa76-e3a9065ce60e',
                    question_id: '05613963-5508-4df1-9012-4f26657cce32',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'e67188ce-45d8-41d1-9311-e026e2b53a87',
                    assignment_id: '94b6229a-d32e-4588-aa76-e3a9065ce60e',
                    question_id: '089df980-5995-4173-af11-ae9595384a17',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'e95736e3-5c30-47c4-b365-643d5c8af347',
                    assignment_id: '94b6229a-d32e-4588-aa76-e3a9065ce60e',
                    question_id: '82439213-59f2-48ed-a529-684cdea52cc0',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }],
        assignmentResults: null
    }, {
        id: '3e2d782e-bb2f-429d-bc20-3f644baaedcf',
        employee_id: 'b477a83f-7fbf-4872-ba0d-cae7fd2cefa6',
        question_count: 20,
        knowledge_field_id: 'f93ad003-11af-48a1-b93b-a5f8d20ed846',
        status: {
            status: 10,
            status_name: 'Назначено'
        },
        passing_test_time: '20 min',
        left_time: 20,
        dead_line: '2024-02-29',
        created_at: '2024-01-30T07:03:38.000000Z',
        start_date: null,
        end_date: null,
        knowledgeField: {
            id: 'f93ad003-11af-48a1-b93b-a5f8d20ed846',
            title: 'Организация системы управления охраной труда на предприятии (СУОТ)',
            active_status: true,
            functional_direction_id: 'ff3807af-0296-44ae-b5d2-5cee778cf069',
            functional_direction_title: 'Охрана труда и промышленная безопасность',
            question_cnt: 0,
            information_source_cnt: 15,
            employee_cnt: 8,
            test_result_cnt: 8,
            created_at: '02.10.2023',
            base_id: '691969f2-1a5c-48fb-84a1-4b8354b7f638',
            functionalDirection: {
                id: 'ff3807af-0296-44ae-b5d2-5cee778cf069',
                title: 'Охрана труда и промышленная безопасность',
                base_id: 'f74b379c-48aa-49cf-aa34-daea7e1f1d1a',
                status: 20,
                created_at: '2023-10-02T14:19:28.000000Z',
                updated_at: '2023-10-02T14:25:35.000000Z',
                deleted_at: null
            }
        },
        employee: {
            id: 'b477a83f-7fbf-4872-ba0d-cae7fd2cefa6',
            fio: 'Alex',
            email: 'zepedro@yandex.ru',
            position: 'Alex',
            position_id: '12345678',
            personal_number: '12345678',
            code: 'foROw4YrlCgrmrw7jabOL9cwyWlIV9Lp',
            department: 'Alex',
            head_id: '66b5e430-4593-41d3-9fe4-66dc0bc1f103',
            is_head: false,
            head_email: 'zepedro@yandex.ru',
            head_position: 'Alex',
            head_position_id: '12345678'
        },
        assignmentQuestions: [{
                assignmentQuestion: {
                    id: '09ca0aa6-81fe-42e0-934e-3cac6bfb486f',
                    assignment_id: '3e2d782e-bb2f-429d-bc20-3f644baaedcf',
                    question_id: 'df95c139-41d1-4c22-825e-0dab17500162',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '3781afc3-90d2-4510-a220-6ee32f43454b',
                    assignment_id: '3e2d782e-bb2f-429d-bc20-3f644baaedcf',
                    question_id: '5fcd0c53-a549-407c-8951-8003373ed8c6',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '4cc77eac-6f97-473f-bced-fe108e45a1e5',
                    assignment_id: '3e2d782e-bb2f-429d-bc20-3f644baaedcf',
                    question_id: '089df980-5995-4173-af11-ae9595384a17',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '5043163a-84b7-4ce8-ac07-2e800f70e4f8',
                    assignment_id: '3e2d782e-bb2f-429d-bc20-3f644baaedcf',
                    question_id: 'a78a0c66-5f7b-430f-a6b6-a4476b69c80b',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '76357ae4-8961-4e8f-a71a-50aab4f47215',
                    assignment_id: '3e2d782e-bb2f-429d-bc20-3f644baaedcf',
                    question_id: 'a1d01ccc-1bc3-498b-a53f-cdcdc73d5f59',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '830452b5-ef0f-47d7-88b2-d83a506ca4aa',
                    assignment_id: '3e2d782e-bb2f-429d-bc20-3f644baaedcf',
                    question_id: '7179c0d0-b2e7-4907-a90b-b50b9e453777',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '84342a65-a60b-4c4e-a1bc-7c0b1ac23fcf',
                    assignment_id: '3e2d782e-bb2f-429d-bc20-3f644baaedcf',
                    question_id: '82439213-59f2-48ed-a529-684cdea52cc0',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '8983171b-a28a-40fe-b345-da39c61f67b0',
                    assignment_id: '3e2d782e-bb2f-429d-bc20-3f644baaedcf',
                    question_id: '96ccaa0d-d3e5-43fa-9589-995fda276522',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '8b7eb6d4-1bfe-451b-b4ca-ad19ba9c914b',
                    assignment_id: '3e2d782e-bb2f-429d-bc20-3f644baaedcf',
                    question_id: 'e90dd478-363a-4bf2-b908-de998c21f993',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '8c43b6fa-7108-4b09-b54e-02597feede17',
                    assignment_id: '3e2d782e-bb2f-429d-bc20-3f644baaedcf',
                    question_id: 'bf40da34-d882-4e5f-947d-3a9cbabcd62c',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '8e189030-7f02-487d-a7c7-6a1d3a3b68b0',
                    assignment_id: '3e2d782e-bb2f-429d-bc20-3f644baaedcf',
                    question_id: 'fbeae805-b8a4-48ff-a562-c088d660cc03',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '9bbd5c52-c9d8-4533-a38d-8fcae919f70c',
                    assignment_id: '3e2d782e-bb2f-429d-bc20-3f644baaedcf',
                    question_id: '51d944fd-5c2d-4017-bd15-d6589a4a1604',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'b408fdbc-dc3f-43f6-bc99-3cc6e96f3118',
                    assignment_id: '3e2d782e-bb2f-429d-bc20-3f644baaedcf',
                    question_id: '05613963-5508-4df1-9012-4f26657cce32',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'b8fba9bb-0e41-41ce-b0ac-ee61ad87a25f',
                    assignment_id: '3e2d782e-bb2f-429d-bc20-3f644baaedcf',
                    question_id: '71b081ad-fdb7-4f08-aa3b-eb6ac52c8e4b',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'bb1cdcd9-349a-4015-bae0-4a7ec4e77035',
                    assignment_id: '3e2d782e-bb2f-429d-bc20-3f644baaedcf',
                    question_id: '012c93f3-62c6-40c0-9636-822954c14b39',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'c9911876-7e01-4f57-9925-9b0c90047c1d',
                    assignment_id: '3e2d782e-bb2f-429d-bc20-3f644baaedcf',
                    question_id: 'fceff740-5bf7-4f98-94b5-b455de612dc9',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'd8deafdf-f43b-4eed-94b3-9c79ba314601',
                    assignment_id: '3e2d782e-bb2f-429d-bc20-3f644baaedcf',
                    question_id: '225f9f83-425b-490a-a66a-3a812321761d',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'e97e06c3-4587-4a99-bd24-c81ec6524882',
                    assignment_id: '3e2d782e-bb2f-429d-bc20-3f644baaedcf',
                    question_id: '252f658b-4ae0-4205-91e1-a7a38a4b2f84',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'ec54740b-93c4-47c7-a31f-456df41f9053',
                    assignment_id: '3e2d782e-bb2f-429d-bc20-3f644baaedcf',
                    question_id: '1b1cb05b-f6da-4934-9071-fd78d9628325',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'f9775e68-d67d-4e41-a7d6-ccc6a868d679',
                    assignment_id: '3e2d782e-bb2f-429d-bc20-3f644baaedcf',
                    question_id: '730d0e00-f49c-4a1d-b0d9-7d0b0cd4acef',
                    is_correct: null,
                    answer_text: null,
                    answer_date: null,
                    feedback: [],
                    employee_answer: null,
                    question: []
                }
            }],
        assignmentResults: null
    }, {
        id: 'fa86e63c-f1b6-44a5-9df5-e747aea3b262',
        employee_id: '74f419c2-f5ba-4d99-8dba-c59e8f151a84',
        question_count: 7,
        knowledge_field_id: 'f93ad003-11af-48a1-b93b-a5f8d20ed846',
        status: {
            status: 20,
            status_name: 'Завершено'
        },
        passing_test_time: '7 min',
        left_time: 0,
        dead_line: '2024-02-29',
        created_at: '2024-01-30T07:03:38.000000Z',
        start_date: '2024-02-01 18:08:26',
        end_date: '2024-02-01 18:09:54',
        knowledgeField: {
            id: 'f93ad003-11af-48a1-b93b-a5f8d20ed846',
            title: 'Организация системы управления охраной труда на предприятии (СУОТ)',
            active_status: true,
            functional_direction_id: 'ff3807af-0296-44ae-b5d2-5cee778cf069',
            functional_direction_title: 'Охрана труда и промышленная безопасность',
            question_cnt: 0,
            information_source_cnt: 15,
            employee_cnt: 8,
            test_result_cnt: 8,
            created_at: '02.10.2023',
            base_id: '691969f2-1a5c-48fb-84a1-4b8354b7f638',
            functionalDirection: {
                id: 'ff3807af-0296-44ae-b5d2-5cee778cf069',
                title: 'Охрана труда и промышленная безопасность',
                base_id: 'f74b379c-48aa-49cf-aa34-daea7e1f1d1a',
                status: 20,
                created_at: '2023-10-02T14:19:28.000000Z',
                updated_at: '2023-10-02T14:25:35.000000Z',
                deleted_at: null
            }
        },
        employee: {
            id: '74f419c2-f5ba-4d99-8dba-c59e8f151a84',
            fio: 'Абашкина Елена Валерьевна',
            email: 'e.abashkina@univertus.com',
            position: 'Специалист по безопасности и охране труда',
            position_id: '50360095',
            personal_number: '15010560',
            code: 'pX3hubiynQTUV3BfkzWL3MtcfrsFAh48',
            department: 'Охрана труда',
            head_id: '66b5e430-4593-41d3-9fe4-66dc0bc1f103',
            is_head: false,
            head_email: 'e.abashkina@univertus.com',
            head_position: 'Специалист по безопасности и охране труда',
            head_position_id: '50360095'
        },
        assignmentQuestions: [{
                assignmentQuestion: {
                    id: '1c285a35-adab-4558-8338-8f68fed1beb0',
                    assignment_id: 'fa86e63c-f1b6-44a5-9df5-e747aea3b262',
                    question_id: '1b1cb05b-f6da-4934-9071-fd78d9628325',
                    is_correct: false,
                    answer_text: 'Тарифный разряд',
                    answer_date: '2024-02-01',
                    feedback: [],
                    employee_answer: ['db7d1639-1fcf-4887-9b1d-6977494da0a5'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '45899073-ebe8-4b7d-bce4-2ac6192068bf',
                    assignment_id: 'fa86e63c-f1b6-44a5-9df5-e747aea3b262',
                    question_id: '5fcd0c53-a549-407c-8951-8003373ed8c6',
                    is_correct: false,
                    answer_text: 'Первоочередный этап',
                    answer_date: '2024-02-01',
                    feedback: [],
                    employee_answer: ['1cfa9a99-020a-49a5-88ff-5842cec1e436'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '602e7769-be54-4cd1-a4e2-723ab2064477',
                    assignment_id: 'fa86e63c-f1b6-44a5-9df5-e747aea3b262',
                    question_id: 'df95c139-41d1-4c22-825e-0dab17500162',
                    is_correct: false,
                    answer_text: 'Тарифная сетка',
                    answer_date: '2024-02-01',
                    feedback: [],
                    employee_answer: ['897e09e2-c4d0-4343-8200-e8c4f6b9b0c5'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '6b8f9ea4-6c7b-48ca-a9cc-14adff733988',
                    assignment_id: 'fa86e63c-f1b6-44a5-9df5-e747aea3b262',
                    question_id: '252f658b-4ae0-4205-91e1-a7a38a4b2f84',
                    is_correct: true,
                    answer_text: 'Быть направленными на непрерывное совершенствование защитных мероприятий охраны труда работников для достижения наилучшей результативности деятельности по охране труда ',
                    answer_date: '2024-02-01',
                    feedback: [],
                    employee_answer: ['2aab9d6b-5ee8-480a-bb59-74be181901cf'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: '9dece69a-8399-46d9-9b78-ece639a3fd06',
                    assignment_id: 'fa86e63c-f1b6-44a5-9df5-e747aea3b262',
                    question_id: 'a78a0c66-5f7b-430f-a6b6-a4476b69c80b',
                    is_correct: false,
                    answer_text: 'Быть совместимыми и сравнимыми, а также не позволять осуществлять их межсистемное использование ',
                    answer_date: '2024-02-01',
                    feedback: [],
                    employee_answer: ['a03cfd37-1a59-4e07-88e6-284b30017fe7'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'a2079631-0c88-421a-bfa3-020fe8654293',
                    assignment_id: 'fa86e63c-f1b6-44a5-9df5-e747aea3b262',
                    question_id: '012c93f3-62c6-40c0-9636-822954c14b39',
                    is_correct: false,
                    answer_text: 'Совместительство',
                    answer_date: '2024-02-01',
                    feedback: [],
                    employee_answer: ['6bb61d9a-164a-40aa-9cc9-54741886eef1'],
                    question: []
                }
            }, {
                assignmentQuestion: {
                    id: 'faef7136-8d91-4569-8b3e-81a1f47d7741',
                    assignment_id: 'fa86e63c-f1b6-44a5-9df5-e747aea3b262',
                    question_id: '51d944fd-5c2d-4017-bd15-d6589a4a1604',
                    is_correct: false,
                    answer_text: 'При спорах арбитражных заседателей',
                    answer_date: '2024-02-01',
                    feedback: [],
                    employee_answer: ['4ca506d8-94a2-4ff6-b2c6-741806a65444'],
                    question: []
                }
            }],
        assignmentResults: {
            id: 'cb68c321-4d34-4553-9f98-240b558e9785',
            assignment_id: 'fa86e63c-f1b6-44a5-9df5-e747aea3b262',
            is_success: false,
            question_count: 7,
            right_answers_count: 1,
            wrong_answers_count: 6,
            created_at: '2024-02-01T18:09:54.000000Z',
            updated_at: '2024-02-01T18:09:54.000000Z'
        }
    }];
