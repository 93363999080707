import { api } from 'services/api';
import { TAG_TYPES as TAG } from 'services/constants';
var getAssignmentsEndpoints = api.injectEndpoints({
    endpoints: function endpoints(build) {
        return {
            // for admin and superadmins
            getAdminAssignments: build.query({
                query: function query(_ref) {
                    var clientId = _ref.clientId, employeeId = _ref.employeeId;
                    return {
                        url: "admin/client/".concat(clientId, "/employee/").concat(employeeId, "/assignment/result"),
                        method: 'GET'
                    };
                },
                providesTags: function providesTags(_data, _error, _ref2) {
                    var clientId = _ref2.clientId, employeeId = _ref2.employeeId;
                    return [{
                            type: TAG.assignments,
                            id: "".concat(clientId, "_").concat(employeeId)
                        }];
                }
            }),
            // for manager and employee himself
            getEmployeeAssignments: build.query({
                query: function query(_ref3) {
                    var employeeId = _ref3.employeeId;
                    return {
                        url: "employee/".concat(employeeId, "/assignment/result"),
                        method: 'GET'
                    };
                },
                providesTags: function providesTags(_data, _error, _ref4) {
                    var employeeId = _ref4.employeeId;
                    return [{
                            type: TAG.assignments,
                            id: employeeId
                        }];
                }
            })
        };
    }
});
var useGetAdminAssignmentsQuery = getAssignmentsEndpoints.useGetAdminAssignmentsQuery, useGetEmployeeAssignmentsQuery = getAssignmentsEndpoints.useGetEmployeeAssignmentsQuery;
export { useGetAdminAssignmentsQuery, useGetEmployeeAssignmentsQuery };
