var SOURCE_NUMBERS = {
    TYPE_REGULATION: '10',
    TYPE_POLOZHENIE: '20',
    TYPE_TECHNICAL_MAP: '30',
    TYPE_STO: '40',
    TYPE_TECHNICAL_LIST: '50',
    TYPE_PPR: '60',
    TYPE_GOST: '70',
    TYPE_SNIP: '80',
    TYPE_ISO: '90',
    TYPE_ORDER: '110',
    TYPE_DECREE: '120',
    TYPE_LAW: '130',
    TYPE_OTHER: '140'
};
var SOURCE_TITLES = {
    // Локальный нормативный акт
    TYPE_POLOZHENIE: '- Положение',
    TYPE_REGULATION: '- Регламент',
    TYPE_TECHNICAL_MAP: '- Технологическая карта',
    TYPE_STO: '- Cтандарт организации (СТО)',
    TYPE_TECHNICAL_LIST: '- Технологическая записка',
    TYPE_PPR: '- Проект производства работ (ППР)',
    // Федеральные/государственные нормативные акты
    TYPE_GOST: '- ГОСТ',
    TYPE_SNIP: '- СНиП',
    TYPE_ISO: '- ИСО',
    TYPE_ORDER: '- Приказ',
    TYPE_DECREE: '- Постановление',
    TYPE_LAW: '- Федеральный закон',
    TYPE_OTHER: '- Другое'
};
export var SOURCE_TYPES_OPTIONS = [{
        id: 'local.acts',
        title: 'Локальный нормативный акт',
        disabled: true
    }, {
        id: SOURCE_NUMBERS.TYPE_POLOZHENIE,
        title: SOURCE_TITLES.TYPE_POLOZHENIE
    }, {
        id: SOURCE_NUMBERS.TYPE_REGULATION,
        title: SOURCE_TITLES.TYPE_REGULATION
    }, {
        id: SOURCE_NUMBERS.TYPE_TECHNICAL_MAP,
        title: SOURCE_TITLES.TYPE_TECHNICAL_MAP
    }, {
        id: SOURCE_NUMBERS.TYPE_STO,
        title: SOURCE_TITLES.TYPE_STO
    }, {
        id: SOURCE_NUMBERS.TYPE_TECHNICAL_LIST,
        title: SOURCE_TITLES.TYPE_TECHNICAL_LIST
    }, {
        id: SOURCE_NUMBERS.TYPE_PPR,
        title: SOURCE_TITLES.TYPE_PPR
    }, {
        id: 'federal.acts',
        title: 'Федеральные/государственные нормативные акты',
        disabled: true
    }, {
        id: SOURCE_NUMBERS.TYPE_GOST,
        title: SOURCE_TITLES.TYPE_GOST
    }, {
        id: SOURCE_NUMBERS.TYPE_SNIP,
        title: SOURCE_TITLES.TYPE_SNIP
    }, {
        id: SOURCE_NUMBERS.TYPE_ISO,
        title: SOURCE_TITLES.TYPE_ISO
    }, {
        id: SOURCE_NUMBERS.TYPE_ORDER,
        title: SOURCE_TITLES.TYPE_ORDER
    }, {
        id: SOURCE_NUMBERS.TYPE_DECREE,
        title: SOURCE_TITLES.TYPE_DECREE
    }, {
        id: SOURCE_NUMBERS.TYPE_LAW,
        title: SOURCE_TITLES.TYPE_LAW
    }, {
        id: SOURCE_NUMBERS.TYPE_OTHER,
        title: SOURCE_TITLES.TYPE_OTHER
    }];
