var _templateObject;
function _taggedTemplateLiteral(strings, raw) { if (!raw) {
    raw = strings.slice(0);
} return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import styled from 'styled-components';
import { Fonts } from 'types/TextProps';
import { COLORS } from 'constants/colors';
var colors = {
    yellow: COLORS.ALERT_YELLOW,
    red: COLORS.ALERT_RED,
    purple: COLORS.PURPLE_100,
    white: COLORS.WHITE,
    green: COLORS.GREEN,
    default: COLORS.PURPLE_400
};
export var Wrappper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  justify-self: flex-start;\n\n  min-width: 40px;\n  height: 28px;\n  padding: 4px 10px 4px 10px;\n\n  font-family: ", ";\n  font-size: 14px;\n  font-weight: 500;\n  line-height: 20px;\n  white-space: nowrap;\n\n  background: ", ";\n  border-radius: 4px;\n"])), Fonts.NotoSans, function (_ref) {
    var color = _ref.color;
    return colors[color];
});
