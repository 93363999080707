function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o)
    return; if (typeof o === "string")
    return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor)
    n = o.constructor.name; if (n === "Map" || n === "Set")
    return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n))
    return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null)
    return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr))
    return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length)
    len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++)
    arr2[i] = arr[i]; return arr2; }
import * as React from 'react';
import { useField } from 'react-final-form';
export var useFormData = function useFormData(_ref) {
    var onClose = _ref.onClose;
    var _useField = useField('knowledges'), _useField$input = _useField.input, relativeKnowledges = _useField$input.value, onChangeRelativeKnowledges = _useField$input.onChange;
    var onSubmit = React.useCallback(function (v) {
        // "!" due to validation
        var specialization = v.specialization;
        var direction = v.direction;
        var knowledge = v.knowledge;
        var data = {
            id: knowledge.id,
            title: knowledge.title,
            functional_direction: {
                id: direction.id,
                title: direction.title,
                specialization: {
                    id: specialization.id,
                    title: specialization.title
                }
            }
        };
        onChangeRelativeKnowledges([].concat(_toConsumableArray(relativeKnowledges), [data]));
        if (onClose) {
            onClose();
        }
    }, [onChangeRelativeKnowledges, onClose, relativeKnowledges]);
    return {
        initialValues: {},
        onSubmit: onSubmit
    };
};
