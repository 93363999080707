var _excluded = ["role"], _excluded2 = ["role"], _excluded3 = ["knowledge_field_ids"], _excluded4 = ["uuid", "role"], _excluded5 = ["knowledge_field_ids", "doc_delete", "pdf_delete"];
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o)
    return; if (typeof o === "string")
    return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor)
    n = o.constructor.name; if (n === "Map" || n === "Set")
    return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n))
    return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null)
    return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr))
    return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length)
    len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++)
    arr2[i] = arr[i]; return arr2; }
function _objectWithoutProperties(source, excluded) { if (source == null)
    return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);
    for (i = 0; i < sourceSymbolKeys.length; i++) {
        key = sourceSymbolKeys[i];
        if (excluded.indexOf(key) >= 0)
            continue;
        if (!Object.prototype.propertyIsEnumerable.call(source, key))
            continue;
        target[key] = source[key];
    }
} return target; }
function _objectWithoutPropertiesLoose(source, excluded) { if (source == null)
    return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0)
        continue;
    target[key] = source[key];
} return target; }
import { api } from './api';
var url = 'information-source';
export var informationSource = api.injectEndpoints({
    endpoints: function endpoints(build) {
        return {
            getSources: build.query({
                query: function query(_ref) {
                    var _ref$role = _ref.role, role = _ref$role === void 0 ? 'admin' : _ref$role, params = _objectWithoutProperties(_ref, _excluded);
                    return {
                        url: "".concat(role, "/").concat(url),
                        method: 'GET',
                        params: params
                    };
                },
                providesTags: function providesTags(result) {
                    return result ? [].concat(_toConsumableArray(result.data.map(function (_ref2) {
                        var id = _ref2.id;
                        return {
                            type: 'InformationSource',
                            id: id
                        };
                    })), [{
                            type: 'InformationSource',
                            id: 'LIST'
                        }]) : [{
                            type: 'InformationSource',
                            id: 'LIST'
                        }];
                }
            }),
            getSourcesUpdates: build.query({
                query: function query(_ref3) {
                    var uuid = _ref3.uuid, page = _ref3.page;
                    return {
                        url: "admin/updates/".concat(url, "/").concat(uuid),
                        method: 'GET',
                        params: {
                            page: page
                        }
                    };
                }
            }),
            createSource: build.mutation({
                query: function query(_ref4) {
                    var _ref4$role = _ref4.role, role = _ref4$role === void 0 ? 'admin' : _ref4$role, body = _objectWithoutProperties(_ref4, _excluded2);
                    var formData = new FormData();
                    var knowledge_field_ids = body.knowledge_field_ids, restBody = _objectWithoutProperties(body, _excluded3);
                    Object.keys(body).forEach(function (key) {
                        var _restBody$restTypedKe;
                        var typedKey = key;
                        var restTypedKey = key;
                        if (knowledge_field_ids.length && typedKey === 'knowledge_field_ids') {
                            return knowledge_field_ids.forEach(function (id) {
                                return formData.append('knowledge_field_ids[]', id);
                            });
                        }
                        return formData.append(typedKey, (_restBody$restTypedKe = restBody[restTypedKey]) !== null && _restBody$restTypedKe !== void 0 ? _restBody$restTypedKe : '');
                    });
                    return {
                        url: "".concat(role, "/").concat(url),
                        method: 'POST',
                        body: formData
                    };
                },
                invalidatesTags: [{
                        type: 'InformationSource',
                        id: 'LIST'
                    }]
            }),
            getSource: build.query({
                query: function query(_ref5) {
                    var uuid = _ref5.uuid, _ref5$role = _ref5.role, role = _ref5$role === void 0 ? 'admin' : _ref5$role;
                    return {
                        url: "".concat(role, "/").concat(url, "/").concat(uuid, "/edit"),
                        method: 'GET'
                    };
                },
                providesTags: function providesTags(result) {
                    return [{
                            type: 'InformationSource',
                            id: result === null || result === void 0 ? void 0 : result.data.id
                        }];
                }
            }),
            editSource: build.mutation({
                query: function query(_ref6) {
                    var uuid = _ref6.uuid, _ref6$role = _ref6.role, role = _ref6$role === void 0 ? 'admin' : _ref6$role, body = _objectWithoutProperties(_ref6, _excluded4);
                    var formData = new FormData();
                    var knowledge_field_ids = body.knowledge_field_ids, doc_delete = body.doc_delete, pdf_delete = body.pdf_delete, restBody = _objectWithoutProperties(body, _excluded5);
                    Object.keys(body).forEach(function (key) {
                        var _restBody$restTypedKe2;
                        var typedKey = key;
                        var restTypedKey = key;
                        if (knowledge_field_ids.length && typedKey === 'knowledge_field_ids') {
                            return knowledge_field_ids.forEach(function (id) {
                                return formData.append('knowledge_field_ids[]', id);
                            });
                        }
                        if (doc_delete && typedKey === 'doc_delete') {
                            return formData.append('doc_delete', "".concat(doc_delete));
                        }
                        if (pdf_delete && typedKey === 'pdf_delete') {
                            return formData.append('pdf_delete', "".concat(pdf_delete));
                        }
                        return formData.append(typedKey, (_restBody$restTypedKe2 = restBody[restTypedKey]) !== null && _restBody$restTypedKe2 !== void 0 ? _restBody$restTypedKe2 : '');
                    });
                    return {
                        url: "".concat(role, "/").concat(url, "/").concat(uuid),
                        method: 'POST',
                        body: formData
                    };
                },
                invalidatesTags: function invalidatesTags(result) {
                    return [{
                            type: 'InformationSource',
                            id: result === null || result === void 0 ? void 0 : result.data.id
                        }];
                }
            }),
            removeSource: build.mutation({
                query: function query(_ref7) {
                    var uuid = _ref7.uuid, _ref7$role = _ref7.role, role = _ref7$role === void 0 ? 'admin' : _ref7$role;
                    return {
                        url: "".concat(role, "/").concat(url, "/").concat(uuid),
                        method: 'DELETE'
                    };
                },
                invalidatesTags: [{
                        type: 'InformationSource',
                        id: 'LIST'
                    }]
            }),
            getAvailableSourcesForImport: build.mutation({
                query: function query() {
                    return {
                        url: "admin/".concat(url, "/import/available"),
                        method: 'GET'
                    };
                }
            }),
            getAvailableSourcesForPreview: build.mutation({
                query: function query(information_source_ids) {
                    return {
                        url: "admin/".concat(url, "/import/list"),
                        method: 'POST',
                        body: {
                            information_source_ids: information_source_ids
                        }
                    };
                }
            }),
            copyAvailableSourcesForPreview: build.mutation({
                query: function query(information_source_ids) {
                    return {
                        url: "admin/".concat(url, "/import/copy"),
                        method: 'POST',
                        body: {
                            information_source_ids: information_source_ids
                        }
                    };
                },
                invalidatesTags: [{
                        type: 'InformationSource',
                        id: 'LIST'
                    }]
            }),
            downloadGarant: build.mutation({
                query: function query(body) {
                    return {
                        url: '/admin/information-source/save-garant',
                        method: 'POST',
                        body: body
                    };
                }
            }),
            searchGarantDocs: build.mutation({
                query: function query(body) {
                    return {
                        url: '/admin/information-source/search-garant',
                        method: 'POST',
                        body: body
                    };
                }
            }),
            getGarantList: build.query({
                query: function query(tree_id) {
                    return {
                        url: "/admin/information-source/garant-list/".concat(tree_id),
                        method: 'GET'
                    };
                }
            })
        };
    }
});
var useCreateSourceMutation = informationSource.useCreateSourceMutation, useEditSourceMutation = informationSource.useEditSourceMutation, useGetSourceQuery = informationSource.useGetSourceQuery, useGetSourcesQuery = informationSource.useGetSourcesQuery, useRemoveSourceMutation = informationSource.useRemoveSourceMutation, useGetAvailableSourcesForImportMutation = informationSource.useGetAvailableSourcesForImportMutation, useGetAvailableSourcesForPreviewMutation = informationSource.useGetAvailableSourcesForPreviewMutation, useCopyAvailableSourcesForPreviewMutation = informationSource.useCopyAvailableSourcesForPreviewMutation, useDownloadGarantMutation = informationSource.useDownloadGarantMutation, useSearchGarantDocsMutation = informationSource.useSearchGarantDocsMutation, useGetGarantListQuery = informationSource.useGetGarantListQuery, useGetSourcesUpdatesQuery = informationSource.useGetSourcesUpdatesQuery;
export { useCreateSourceMutation, useEditSourceMutation, useGetSourceQuery, useGetSourcesQuery, useRemoveSourceMutation, useGetAvailableSourcesForImportMutation, useGetAvailableSourcesForPreviewMutation, useCopyAvailableSourcesForPreviewMutation, useDownloadGarantMutation, useSearchGarantDocsMutation, useGetGarantListQuery, useGetSourcesUpdatesQuery };
