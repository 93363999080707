import url from 'url';
export var downloadFile = function downloadFile(blob, fileName) {
    var ext = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'xlsx';
    var link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.target = '_blank';
    link.download = "".concat(fileName, ".").concat(ext);
    link.dispatchEvent(new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window
    }));
    return {
        data: null
    };
};
export var getResultGeneratorFileLink = function getResultGeneratorFileLink(file) {
    return url.format({
        host: process.env.HOST,
        protocol: process.env.PROTOCOL,
        pathname: "/exporter/".concat(file)
        // host: '127.0.0.1:3333',
        // protocol: 'http',
        // pathname: file,
    });
};
export var downloadByLink = function downloadByLink(_ref) {
    var href = _ref.href, fileName = _ref.fileName, id = _ref.id;
    var link = document.createElement('a');
    link.id = "link-for-download-".concat(id || fileName);
    link.href = href;
    link.download = fileName;
    link.dispatchEvent(new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window
    }));
};
