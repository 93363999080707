var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;
function _taggedTemplateLiteral(strings, raw) { if (!raw) {
    raw = strings.slice(0);
} return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import styled from 'styled-components';
import { Fonts } from 'types/TextProps';
import { COLORS } from 'constants/colors';
import { Button as _Button } from 'components/UI/atoms';
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: grid;\n  grid-gap: 16px;\n  align-items: center;\n"])));
export var NameWrapper = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: grid;\n  grid-gap: 8px;\n  grid-template-columns: 24px 1fr;\n  align-items: center;\n\n  padding: 20px 20px 20px 28px;\n\n  background: ", ";\n  border-radius: 8px;\n"])), COLORS.PURPLE_400);
export var Text = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  font-family: ", ";\n  font-size: 16px;\n  font-weight: 400;\n  line-height: 24px;\n  color: ", ";\n"])), Fonts.NotoSans, COLORS.DARK_PURPLE_100);
export var LinkWrapper = styled(NameWrapper)(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  grid-gap: 16px;\n  grid-template-columns: 2fr 1fr;\n  padding: 16px;\n"])));
export var Button = styled(_Button)(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  background: ", ";\n"])), COLORS.WHITE);
export var EmailText = styled.span(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  color: ", ";\n"])), COLORS.LOGAN);
