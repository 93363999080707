export var TAG_TYPES = {
    assignments: 'ASSIGNMENTS',
    assignmentsWeekGroups: 'ASSIGNMENTS_WEEK_GROUPS',
    employeeAssignments: 'EMPLOYEE_ASSIGNMENTS',
    employeeDepartmentGroups: 'EMPLOYEE_DEPARTMENT_GROUPS',
    employeeFunctionalDirections: 'EMPLOYEE_FUNCTIONAL_DIRECTIONS',
    employeeKnowledgeField: 'EMPLOYEE_KNOWLEDGE_FIELD',
    functionalDirection: 'FUNCTIONAL_DIRECTION',
    functionalDirections: 'FUNCTIONAL_DIRECTIONS',
    profile: 'PROFILE',
    profiles: 'PROFILES',
    profileEmployee: 'PROFILE_EMPLOYEE',
    requestedFunctionalDirection: 'REQUESTED_FUNCTIONAL_DIRECTION',
    specialization: 'SPECIALIZATION',
    specializations: 'SPECIALIZATIONS',
    specializationsWithMeta: 'SPECIALIZATIONS_WITH_META',
    staffAssignments: 'STAFF_ASSIGNMENTS',
    staffEmployee: 'STAFF_EMPLOYEE',
    staffEmployees: 'STAFF_EMPLOYEES',
    staffEmployeesHead: 'STAFF_EMPLOYEES_HEAD',
    teamResults: 'TEAM_RESULTS',
    teamResultsForEveryEmployee: 'TEAM_RESULTS_FOR_EVERY_EMPLOYEE',
    teamAssignments: 'TEAM_ASSIGNMENTS',
    teamAssignmentsReportData: 'TEAM_ASSIGNMENTS_REPORT_DATA'
};
