var _templateObject, _templateObject2, _templateObject3, _templateObject4;
function _taggedTemplateLiteral(strings, raw) { if (!raw) {
    raw = strings.slice(0);
} return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import styled from 'styled-components';
import { Fonts } from 'types/TextProps';
import { COLORS } from 'constants/colors';
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: ", ";\n  flex-flow: row nowrap;\n  gap: 4px;\n  align-items: center;\n"])), function (_ref) {
    var isPaginationVisible = _ref.isPaginationVisible;
    return isPaginationVisible ? 'flex' : 'none';
});
export var Button = styled.button(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  cursor: ", ";\n\n  width: 48px;\n  height: 48px;\n\n  background: ", ";\n  border: none;\n  border-radius: 24px;\n"])), function (_ref2) {
    var disabled = _ref2.disabled;
    return disabled ? 'not-allowed' : 'pointer';
}, function (_ref3) {
    var active = _ref3.active;
    return active ? COLORS.PURPLE_400 : 'none';
});
export var Graphics = styled.svg(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  width: 100%;\n  height: 100%;\n"])));
export var Text = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  font-family: ", ";\n  font-size: 14px;\n  font-weight: 600;\n  line-height: 20px;\n  color: ", ";\n"])), Fonts.Montserrat, function (_ref4) {
    var active = _ref4.active;
    return active ? COLORS.DARK_PURPLE_100 : COLORS.PURPLE_100;
});
