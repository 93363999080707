var _QUESTION_COMPLEXITY_;
function _typeof(obj) {
    "@babel/helpers - typeof";
    return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj);
}
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) {
    Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true });
}
else {
    obj[key] = value;
} return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null)
    return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) {
    var res = prim.call(input, hint || "default");
    if (_typeof(res) !== "object")
        return res;
    throw new TypeError("@@toPrimitive must return a primitive value.");
} return (hint === "string" ? String : Number)(input); }
export var QUESTION_COMPLEXITY_LEVELS = {
    LEVEL_SIMPLE: 10,
    LEVEL_NORMAL: 20,
    LEVEL_DIFFICULT: 30
};
export var QUESTION_COMPLEXITY_LEVELS_TEXT = (_QUESTION_COMPLEXITY_ = {}, _defineProperty(_QUESTION_COMPLEXITY_, QUESTION_COMPLEXITY_LEVELS.LEVEL_SIMPLE, 'Простой'), _defineProperty(_QUESTION_COMPLEXITY_, QUESTION_COMPLEXITY_LEVELS.LEVEL_NORMAL, 'Средний'), _defineProperty(_QUESTION_COMPLEXITY_, QUESTION_COMPLEXITY_LEVELS.LEVEL_DIFFICULT, 'Сложный'), _QUESTION_COMPLEXITY_);
export var QUESTION_COMPLEXITY_LEVELS_OPTIONS = [{
        id: QUESTION_COMPLEXITY_LEVELS.LEVEL_SIMPLE.toString(),
        title: QUESTION_COMPLEXITY_LEVELS_TEXT[QUESTION_COMPLEXITY_LEVELS.LEVEL_SIMPLE]
    }, {
        id: QUESTION_COMPLEXITY_LEVELS.LEVEL_NORMAL.toString(),
        title: QUESTION_COMPLEXITY_LEVELS_TEXT[QUESTION_COMPLEXITY_LEVELS.LEVEL_NORMAL]
    }, {
        id: QUESTION_COMPLEXITY_LEVELS.LEVEL_DIFFICULT.toString(),
        title: QUESTION_COMPLEXITY_LEVELS_TEXT[QUESTION_COMPLEXITY_LEVELS.LEVEL_DIFFICULT]
    }];
