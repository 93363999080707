export { default as Access } from './Access/Access';
export { default as Advantages } from './Advantages/Advantages';
export { default as Cases } from './Cases/Cases';
export { default as Cost } from './Cost/Cost';
export { default as Footer } from './Footer/Footer';
export { default as ForWhom } from './ForWhom/ForWhom';
export { default as Header } from './Header/Header';
export { default as HowWorks } from './HowWorks/HowWorks';
export { default as Hero } from './Hero/Hero';
export { default as Possibilities } from './Possibilities/Possibilities';
export { default as What } from './What/What';
