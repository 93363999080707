var _templateObject, _templateObject2;
function _taggedTemplateLiteral(strings, raw) { if (!raw) {
    raw = strings.slice(0);
} return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import styled from 'styled-components';
export var Overlay = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  position: fixed;\n  z-index: 100;\n  top: 0;\n  left: 0;\n\n  overflow: auto;\n  display: flex;\n  flex-direction: column;\n\n  width: 100%;\n  height: 100%;\n\n  background: rgba(56, 56, 56, 0.7);\n"])));
export var Wrapper = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  width: 100%;\n  max-width: ", ";\n  margin: auto;\n"])), function (_ref) {
    var maxWidth = _ref.maxWidth;
    return maxWidth;
});
