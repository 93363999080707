var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7;
function _taggedTemplateLiteral(strings, raw) { if (!raw) {
    raw = strings.slice(0);
} return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import styled from 'styled-components';
import { Fonts } from 'types/TextProps';
import { COLORS } from 'constants/colors';
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: grid;\n  grid-column-gap: 20px;\n  grid-template-columns: ", ";\n  align-items: center;\n\n  min-height: 64px;\n  padding: 17px 24px 17px 24px;\n  /* &:nth-child(odd) {\n    background: ", ";\n  } */\n  border: 1px solid ", ";\n"])), function (_ref) {
    var isImported = _ref.isImported;
    return isImported ? '0.5fr 4fr 2fr auto' : 'minmax(54px,0.5fr) minmax(320px,4fr) minmax(130px,3fr) minmax(119px,2fr) minmax(70px,1fr) minmax(104px,1.5fr) minmax(180px, 2fr) 120px';
}, COLORS.PURPLE_600, COLORS.PURPLE_400);
export var IconsGroup = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: grid;\n  grid-gap: 20px;\n  grid-template-columns: 1fr 1fr;\n  align-items: center;\n  justify-items: center;\n  justify-self: end;\n\n  & > img {\n    cursor: pointer;\n  }\n"])));
export var Number = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  font-family: ", ";\n  font-size: 14px;\n  font-weight: 500;\n  line-height: 20px;\n  color: ", ";\n"])), Fonts.NotoSans, COLORS.LOGAN);
export var ButtonWrapper = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  display: grid;\n  grid-gap: 8px;\n  grid-template-columns: 1fr 1fr;\n  align-items: center;\n"])));
export var Statistic = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  display: grid;\n  grid-gap: 8px;\n  grid-template-columns: 1fr 1fr;\n  align-items: center;\n"])));
export var Views = styled.div(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  display: grid;\n  grid-gap: 3px;\n  grid-template-columns: 1fr 1fr;\n  align-items: center;\n"])));
export var ViewText = styled.div(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  font-family: ", ";\n  font-size: 14px;\n  font-weight: 500;\n  line-height: 20px;\n  color: ", ";\n"])), Fonts.NotoSans, COLORS.DARK_PURPLE_100);
