export default {
    GRAY_25: '--color-gray-25',
    LIGHT_GREY: '--color-light-grey',
    GRAY_100: '--color-gray-100',
    GRAY_200: '--color-gray-200',
    GRAY_300: '--color-gray-300',
    GRAY_600: '--color-gray-600',
    PURPLE_100: '--color-purple-100',
    PURPLE_200: '--color-purple-200',
    PURPLE_300: '--color-purple-300',
    PURPLE_400: '--color-purple-400',
    PURPLE_500: '--color-purple-500',
    PURPLE_600: '--color-purple-600',
    DARK_PURPLE_100: '--color-dark-purple-100',
    DARK_PURPLE_200: '--color-dark-purple-200',
    DARK_PURPLE_300: '--color-dark-purple-300',
    ADDITIONAL_PURPLE_300: '--color-additional-purple-300',
    WHITE: '--color-white',
    BLACK: '--color-black',
    SECONDARY_BLACK: '--color-secondary-black',
    SECONDARY_RGB: '--color-secondary-rgb',
    BG_RGB: '--color-bg-rgb',
    INACTIVE: '--color-inactive',
    ICON: '--color-icon',
    WILD_BLUE_YONDER: '--color-wild-blue-yonder',
    NEW: '--color-new',
    TEXT: '--color-text',
    MAGNOLIA: '--color-magnolia',
    BORDER: '--color-border',
    INDEX: '--color-index',
    INFO: '--color-info',
    ALERT: '--color-alert',
    SUCCESS: '--color-success',
    SUCCESS_LIGHT: '--color-success-light',
    SUCCESS_PALE: '--color-success-pale',
    ERROR: '--color-error',
    ERROR_LIGHT: '--color-error-light',
    ERROR_DARK: '--color-error-dark'
};
