var _templateObject, _templateObject2, _templateObject3;
function _taggedTemplateLiteral(strings, raw) { if (!raw) {
    raw = strings.slice(0);
} return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import styled from 'styled-components';
import { Fonts } from 'types/TextProps';
import { COLORS } from 'constants/colors';
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  cursor: pointer;\n\n  display: grid;\n  grid-gap: 8px;\n  grid-template-columns: 40px 1fr;\n  align-items: center;\n\n  padding-top: 10px;\n  padding-bottom: 10px;\n  ", "\n"])), function (_ref) {
    var noBorder = _ref.noBorder;
    return noBorder ? null : 'border-bottom: 1px solid #D8D9F7;';
});
export var Text = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  font-family: ", ";\n  font-size: 16px;\n  font-weight: 400;\n  line-height: 24px;\n  color: ", ";\n"])), Fonts.NotoSans, COLORS.DARK_PURPLE_100);
export var Errors = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  display: grid;\n  grid-column: 1/3;\n  grid-gap: 6px;\n  margin-left: 43px;\n"])));
