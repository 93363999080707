import { exporterApi } from './exporterApi';
export var exporterApiSlice = exporterApi.injectEndpoints({
    endpoints: function endpoints(build) {
        return {
            // Admin export
            exportQuestions: build.query({
                query: function query(params) {
                    return {
                        url: '/admin/export/questions',
                        method: 'GET',
                        params: params
                    };
                }
            }),
            exportStaffEmployees: build.query({
                query: function query(_ref) {
                    var params = _ref.params;
                    return {
                        url: '/admin/export/staff-employees',
                        method: 'GET',
                        params: params
                    };
                }
            }),
            getExportStatus: build.query({
                query: function query(_ref2) {
                    var id = _ref2.id;
                    return {
                        url: "/status/".concat(id),
                        method: 'GET'
                    };
                }
            }),
            exportManagerTeamResults: build.query({
                query: function query(_ref3) {
                    var managerCode = _ref3.managerCode, params = _ref3.params;
                    return {
                        url: "/manager/".concat(managerCode, "/team/results"),
                        method: 'GET',
                        params: params
                    };
                }
            })
        };
    }
});
