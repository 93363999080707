var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7;
function _taggedTemplateLiteral(strings, raw) { if (!raw) {
    raw = strings.slice(0);
} return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import styled from 'styled-components';
import { PAGE_WRAPPER, WRAPPER } from 'constants/commonStyles';
export var PageWrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  ", "\n"])), function (_ref) {
    var isSubdomain = _ref.isSubdomain;
    return isSubdomain ? WRAPPER : 'width: 100%;';
});
export var Wrapper = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  ", ";\n"])), PAGE_WRAPPER);
export var HeaderWrapper = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  display: grid;\n  grid-gap: 40px;\n  grid-template-columns: 2fr 1fr;\n"])));
export var ButtonWrap = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  display: grid;\n  grid-gap: 16px;\n  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;\n  align-items: center;\n\n  @media screen and (max-width: 1360px) {\n    grid-template-columns: 1fr 1fr;\n  }\n"])));
export var ButtonSubdomainWrap = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  display: flex;\n  gap: 16px;\n  align-items: center;\n  justify-content: flex-end;\n"])));
export var FilterWrapper = styled.div(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  display: grid;\n  grid-template-columns: ", ";\n"])), function (_ref2) {
    var isSubdomain = _ref2.isSubdomain;
    return isSubdomain ? '2fr 1fr 1fr' : '2fr 2fr 1fr 1fr';
});
export var InputSearchIconWrapper = styled.div(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  margin-left: 10px;\n"])));
