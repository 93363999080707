export { default as Logo } from './logo.svg';
export { default as Home } from './home.svg';
export { default as Arrow } from './arrow.svg';
export { default as Search } from './search.svg';
export { default as SelectArrow } from './selectArrow.svg';
export { default as Download } from './download.svg';
export { default as Pencil } from './pencil.svg';
export { default as Trash } from './trash.svg';
export { default as WhitePencil } from './whitePencil.svg';
export { default as Doc } from './doc.svg';
export { default as Check } from './check.svg';
export { default as Dismiss } from './dismiss.svg';
export { default as Approve } from './approve.svg';
export { default as ClientsActive } from './clientsActive.svg';
export { default as Clients } from './clients.svg';
export { default as Profile } from './profile.svg';
export { default as BlackPencil } from './blackPencil.svg';
export { default as Login } from './login.svg';
export { default as Employyes } from './employyes.svg';
export { default as Results } from './results.svg';
export { default as Statistics } from './statistics.svg';
export { default as Generate } from './generate.svg';
export { default as BlueArrow } from './blueArrow.svg';
export { default as Alert } from './alert.svg';
export { default as Eye } from './eye.svg';
export { default as BlackTrash } from './blackTrash.svg';
export { default as WhiteTrash } from './whiteTrash.svg';
export { default as Calendar } from './calendar.svg';
export { default as DarkLink } from './darkLink.svg';
export { default as SmallPerson } from './smallPerson.svg';
export { default as Analytics } from './analytics.svg';
export { default as Send } from './send.svg';
export { default as Start } from './start.svg';
export { default as AnalyticsWhite } from './analyticsWhite.svg';
export { default as Watch } from './watch.svg';
export { default as Close } from './close.svg';
export { default as LongArrow } from './longArrow.svg';
