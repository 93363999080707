import { useEffect, useRef } from 'react';
export var useComponentDidUpdate = function useComponentDidUpdate(callback, deps) {
    var mount = useRef(false);
    useEffect(function () {
        if (mount.current)
            callback();
        else
            mount.current = true;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
};
