import * as React from 'react';
import { staffApiSlice } from 'modules';
export var useEmployeeDepartmentGroupsOptions = function useEmployeeDepartmentGroupsOptions() {
    var _staffApiSlice$useGet = staffApiSlice.useGetEmployeeDepartmentGroupsQuery(), departmentGroups = _staffApiSlice$useGet.data;
    var options = React.useMemo(function () {
        if (!departmentGroups)
            return [];
        return departmentGroups.data.map(function (group) {
            return {
                value: group,
                title: group
            };
        });
    }, [departmentGroups]);
    return {
        options: options,
        departmentGroups: departmentGroups
    };
};
