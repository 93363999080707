export var PAGE_FILTERS = {
    specializationId: 'specializationId',
    functionalDirectionId: 'functionalDirectionId',
    knowledgeFieldId: 'knowledgeFieldId',
    type: 'type',
    date: 'date',
    status: 'status',
    query: 'query',
    activity: 'activity'
};
export var QUESTION_ACTIVITY_TYPES = {
    active: 'active',
    notActive: 'notActive',
    withFeedback: 'withFeedback'
};
