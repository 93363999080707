var _ANSWER_RESULT_PERCEN, _ANSWER_RESULT_TYPE_L, _ANSWER_RESULT_TYPE_T, _ANSWER_RESULT_TYPE_C, _ASSIGNMENT_STATUS_TI, _ASSIGNMENT_STATUS_CO;
function _typeof(obj) {
    "@babel/helpers - typeof";
    return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj);
}
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) {
    Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true });
}
else {
    obj[key] = value;
} return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null)
    return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) {
    var res = prim.call(input, hint || "default");
    if (_typeof(res) !== "object")
        return res;
    throw new TypeError("@@toPrimitive must return a primitive value.");
} return (hint === "string" ? String : Number)(input); }
import { COLOR } from 'uikit/vars';
export var ANSWER_RESULT_TYPE = {
    CRITICAL: 'critical',
    INSUFFICIENT: 'insufficient',
    APPROPRIATE: 'appropriate'
};
export var ANSWER_RESULT_PERCENTAGES_BORDERS = (_ANSWER_RESULT_PERCEN = {}, _defineProperty(_ANSWER_RESULT_PERCEN, ANSWER_RESULT_TYPE.CRITICAL, 50), _defineProperty(_ANSWER_RESULT_PERCEN, ANSWER_RESULT_TYPE.INSUFFICIENT, 80), _defineProperty(_ANSWER_RESULT_PERCEN, ANSWER_RESULT_TYPE.APPROPRIATE, 100), _ANSWER_RESULT_PERCEN);
export var ANSWER_RESULT_TYPE_LABEL = (_ANSWER_RESULT_TYPE_L = {}, _defineProperty(_ANSWER_RESULT_TYPE_L, ANSWER_RESULT_TYPE.CRITICAL, 'Низкий уровень знаний'), _defineProperty(_ANSWER_RESULT_TYPE_L, ANSWER_RESULT_TYPE.INSUFFICIENT, 'Недостаточный уровень знаний'), _defineProperty(_ANSWER_RESULT_TYPE_L, ANSWER_RESULT_TYPE.APPROPRIATE, 'Достаточный уровень знаний'), _ANSWER_RESULT_TYPE_L);
export var ANSWER_RESULT_TYPE_TEXT_SHORTED = (_ANSWER_RESULT_TYPE_T = {}, _defineProperty(_ANSWER_RESULT_TYPE_T, ANSWER_RESULT_TYPE.CRITICAL, 'Низкий'), _defineProperty(_ANSWER_RESULT_TYPE_T, ANSWER_RESULT_TYPE.INSUFFICIENT, 'Недостаточный'), _defineProperty(_ANSWER_RESULT_TYPE_T, ANSWER_RESULT_TYPE.APPROPRIATE, 'Достаточный'), _ANSWER_RESULT_TYPE_T);
export var ANSWER_RESULT_TYPE_COLOR = (_ANSWER_RESULT_TYPE_C = {}, _defineProperty(_ANSWER_RESULT_TYPE_C, ANSWER_RESULT_TYPE.CRITICAL, COLOR.ERROR), _defineProperty(_ANSWER_RESULT_TYPE_C, ANSWER_RESULT_TYPE.INSUFFICIENT, COLOR.ALERT), _defineProperty(_ANSWER_RESULT_TYPE_C, ANSWER_RESULT_TYPE.APPROPRIATE, COLOR.SUCCESS), _ANSWER_RESULT_TYPE_C);
export var ASSIGNMENT_STATUS = {
    /**
     * Новое тестирование
     */
    NEW: 10,
    /**
     * На оценке
     */
    TESTING: 15,
    /**
     * Тестирование завершено
     */
    TESTED: 20,
    /**
     * Тестирование пропущено
     */
    MISSED: 25
};
export var ASSIGNMENT_STATUS_TITLE = (_ASSIGNMENT_STATUS_TI = {}, _defineProperty(_ASSIGNMENT_STATUS_TI, ASSIGNMENT_STATUS.NEW, 'Новое'), _defineProperty(_ASSIGNMENT_STATUS_TI, ASSIGNMENT_STATUS.TESTING, 'На оценке'), _defineProperty(_ASSIGNMENT_STATUS_TI, ASSIGNMENT_STATUS.TESTED, 'Пройдено'), _defineProperty(_ASSIGNMENT_STATUS_TI, ASSIGNMENT_STATUS.MISSED, 'Пропущено'), _ASSIGNMENT_STATUS_TI);
export var ASSIGNMENT_STATUS_OPTIONS = [{
        value: ASSIGNMENT_STATUS.NEW,
        title: 'Новое'
    }, {
        value: ASSIGNMENT_STATUS.TESTED,
        title: 'Пройдено'
    }, {
        value: ASSIGNMENT_STATUS.TESTING,
        title: 'На оценке'
    }, {
        value: ASSIGNMENT_STATUS.MISSED,
        title: 'Пропущено'
    }];
export var ASSIGNMENT_STATUS_COLOR = (_ASSIGNMENT_STATUS_CO = {}, _defineProperty(_ASSIGNMENT_STATUS_CO, ASSIGNMENT_STATUS.NEW, COLOR.PURPLE_400), _defineProperty(_ASSIGNMENT_STATUS_CO, ASSIGNMENT_STATUS.TESTING, COLOR.PURPLE_300), _defineProperty(_ASSIGNMENT_STATUS_CO, ASSIGNMENT_STATUS.TESTED, COLOR.SUCCESS_SECONDARY), _defineProperty(_ASSIGNMENT_STATUS_CO, ASSIGNMENT_STATUS.MISSED, COLOR.ALERT), _ASSIGNMENT_STATUS_CO);
