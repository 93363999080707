var modifiers = [{
        enabled: true,
        name: 'minWidth',
        phase: 'write',
        fn: function fn(data) {
            var width = data.state.rects.reference.width;
            // eslint-disable-next-line
            data.state.styles.popper.minWidth = width;
        }
    }];
export default modifiers;
