var _templateObject;
function _taggedTemplateLiteral(strings, raw) { if (!raw) {
    raw = strings.slice(0);
} return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import styled from 'styled-components';
import { COLORS } from 'constants/colors';
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  position: ", ";\n  ", ";\n  ", ";\n  ", ";\n  z-index: 98;\n\n  overflow-y: auto;\n\n  max-height: ", ";\n  padding: 4px;\n\n  background: ", ";\n  border-radius: 4px;\n  outline: 1px solid ", ";\n\n  //min-width: 100%;\n"])), function (_ref) {
    var top = _ref.top, width = _ref.width, left = _ref.left;
    return top !== undefined && width !== undefined && left !== undefined ? 'fixed' : 'absolute';
}, function (_ref2) {
    var top = _ref2.top;
    return top !== undefined ? "top: ".concat(top + 5, "px") : null;
}, function (_ref3) {
    var width = _ref3.width;
    return width !== undefined ? "width: ".concat(width, "px") : null;
}, function (_ref4) {
    var left = _ref4.left;
    return left !== undefined ? "left: ".concat(left, "px") : null;
}, function (_ref5) {
    var height = _ref5.height;
    return height ? "".concat(height, "px;") : '360px';
}, COLORS.WHITE, COLORS.PURPLE_400);
