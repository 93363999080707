var _templateObject, _templateObject2, _templateObject3;
function _taggedTemplateLiteral(strings, raw) { if (!raw) {
    raw = strings.slice(0);
} return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import styled from 'styled-components';
import { Fonts } from 'types/TextProps';
import { COLORS } from 'constants/colors';
export var StyleToggle = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n  gap: 8px;\n  align-items: center;\n\n  .slider {\n    cursor: pointer;\n\n    position: absolute;\n    top: 0;\n    right: 0;\n    bottom: 0;\n    left: 0;\n\n    background-color: ", ";\n    border: 2px solid rgba(11, 96, 141, 0.05);\n\n    -webkit-transition: 0.5s;\n    transition: 0.5s;\n  }\n\n  .slider:before {\n    content: '';\n\n    position: absolute;\n    bottom: 2px;\n    left: 2px;\n\n    min-width: 24px;\n    height: 24px;\n\n    background-color: ", ";\n\n    -webkit-transition: 0.5s;\n    transition: 0.5s;\n  }\n  input:checked + .slider {\n    background-color: ", ";\n    border: 2px solid rgba(11, 96, 141, 0.16);\n  }\n\n  input:focus + .slider {\n    box-shadow: 0 0 1px #20dd71;\n  }\n\n  input:checked + .slider:before {\n    -webkit-transform: translateX(24px);\n    -ms-transform: translateX(24px);\n    transform: translateX(24px);\n  }\n\n  .slider.round {\n    border-radius: 20px;\n  }\n\n  .slider.round:before {\n    border-radius: 50%;\n  }\n"])), COLORS.LOGAN, COLORS.WHITE, COLORS.PURPLE_100);
export var Label = styled.label(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  position: relative;\n\n  display: inline-block;\n\n  min-width: 56px;\n  height: 32px;\n\n  opacity: ", ";\n\n  input {\n    display: none;\n  }\n"])), function (_ref) {
    var _ref$disabled = _ref.disabled, disabled = _ref$disabled === void 0 ? false : _ref$disabled;
    return disabled ? 0.6 : 1;
});
export var Text = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  font-family: ", ";\n  font-size: 14px;\n  font-weight: 500;\n  line-height: 20px;\n  white-space: nowrap;\n"])), Fonts.NotoSans);
