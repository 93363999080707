var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;
function _taggedTemplateLiteral(strings, raw) { if (!raw) {
    raw = strings.slice(0);
} return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import styled from 'styled-components';
import { PAGE_WRAPPER } from 'constants/commonStyles';
import { Text as _Text } from 'components/UI/atoms';
import { COLOR, MEDIA, TEXT } from 'uikit/vars';
export var PageWrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  ", ";\n  border-radius: 8px;\n"])), PAGE_WRAPPER);
export var Wrapper = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: grid;\n  grid-gap: 16px;\n"])));
export var Text = styled(_Text)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  .text {\n    flex-shrink: 10;\n  }\n  .count {\n    flex-basis: auto;\n    text-transform: uppercase;\n    white-space: nowrap;\n    background: #3ccd97;\n  }\n"])));
export var Inner = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n\n  @media (min-width: ", ") {\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n  }\n"])), MEDIA.TABLET_1);
export var Info = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n\n  p {\n    ", ";\n    color: var(", ");\n\n    span {\n      font-weight: 700;\n    }\n  }\n"])), TEXT.MEDIUM1, COLOR.BLACK);
