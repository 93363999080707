var _templateObject, _templateObject2, _templateObject3;
function _taggedTemplateLiteral(strings, raw) { if (!raw) {
    raw = strings.slice(0);
} return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import styled from 'styled-components';
import { Fonts } from 'types/TextProps';
import { COLORS } from 'constants/colors';
export var StyledTextarea = styled.textarea(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  resize: vertical;\n\n  width: 100%;\n  height: 84px;\n  padding: 8px 2px 46px 12px;\n\n  font-family: ", ";\n  font-size: 16px;\n  font-weight: 400;\n  line-height: 24px;\n  color: ", ";\n\n  background-color: ", ";\n  border-radius: 5px;\n  outline: ", ";\n"])), Fonts.NotoSans, function (_ref) {
    var disabled = _ref.disabled, error = _ref.error;
    if (disabled) {
        return COLORS.GRAY_200;
    }
    if (error) {
        return COLORS.RED_100;
    }
    return COLORS.DARK_PURPLE_100;
}, function (_ref2) {
    var disabled = _ref2.disabled, error = _ref2.error;
    if (disabled) {
        return COLORS.GRAY_500;
    }
    if (error) {
        return COLORS.PURPLE_500;
    }
    return COLORS.PURPLE_600;
}, function (_ref3) {
    var inFocus = _ref3.inFocus, error = _ref3.error;
    if (inFocus && !error) {
        return "1px solid ".concat(COLORS.PURPLE_400);
    }
    if (error) {
        return "2px solid ".concat(COLORS.RED_100);
    }
    return null;
});
export var Label = styled.label(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: block;\n\n  margin-right: 10px;\n  margin-bottom: 4px;\n\n  font-family: ", ";\n  font-size: 16px;\n  font-weight: 400;\n  font-style: normal;\n  line-height: 24px;\n  color: ", ";\n\n  background: none;\n"])), Fonts.NotoSans, COLORS.DARK_PURPLE_100);
export var ErrorMessage = styled.span(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  padding: 4px 0;\n\n  font-family: ", ";\n  font-size: 12px;\n  font-weight: 700;\n  line-height: 16px;\n  color: ", ";\n"])), Fonts.NotoSans, COLORS.RED_100);
