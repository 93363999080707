var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;
function _taggedTemplateLiteral(strings, raw) { if (!raw) {
    raw = strings.slice(0);
} return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import styled from 'styled-components';
import { COLORS } from 'constants/colors';
import { Table as _Table } from 'components/organisms';
import { Arrow as _Arrow, Count as _Count } from 'components/UI/atoms';
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  position: relative;\n\n  display: grid;\n  grid-column-gap: 20px;\n  grid-template-columns:\n    minmax(125px, 1fr) minmax(95px, 1fr) minmax(221px, 2fr) minmax(210px, 2fr)\n    minmax(111px, 1fr) minmax(65px, 1fr) 1fr;\n  align-items: center;\n\n  min-height: 64px;\n  padding: 17px 24px 17px 24px;\n\n  border: 1px solid ", ";\n\n  transition: transform 0.6s ease-in-out;\n  &:nth-child(odd) {\n    background: ", ";\n  }\n"])), COLORS.PURPLE_400, COLORS.PURPLE_600);
export var EmployeesCount = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: flex;\n  gap: 2px;\n  align-items: center;\n"])));
export var Arrow = styled(_Arrow)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  cursor: pointer;\n  ", ";\n  path {\n    fill: ", ";\n  }\n"])), function (_ref) {
    var isOpened = _ref.isOpened;
    return isOpened ? "\n    transform: rotate(450deg);\n  " : '';
}, COLORS.PURPLE_100);
export var IconsGroup = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  display: grid;\n  grid-gap: 20px;\n  grid-template-columns: 24px 24px;\n  align-items: center;\n  justify-self: end;\n"])));
export var Count = styled(_Count)(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  width: 43px;\n"])));
export var Table = styled(_Table)(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  grid-column: 1/8;\n  margin-top: 16px;\n\n  .table__header-wrapper {\n    background-color: ", ";\n  }\n\n  .table__header-text {\n    color: ", ";\n  }\n"])), function (_ref2) {
    var index = _ref2.index;
    return index % 2 !== 0 ? COLORS.WHITE : COLORS.PURPLE_600;
}, COLORS.WILD_BLUE_YONDER);
