var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8;
function _taggedTemplateLiteral(strings, raw) { if (!raw) {
    raw = strings.slice(0);
} return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import styled, { css } from 'styled-components';
import { Fonts } from 'types/TextProps';
import { COLORS } from 'constants/colors';
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  position: relative;\n  width: 100%;\n  margin: 0;\n"])));
export var Container = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  position: relative;\n\n  display: flex;\n  gap: 4px;\n  align-items: center;\n\n  width: 100%;\n  height: 48px;\n  padding: 12px;\n\n  background-color: ", ";\n  border-radius: 5px;\n  outline: ", ";\n\n  ", ";\n  ", ";\n\n  input {\n    position: relative;\n\n    width: 100%;\n\n    font-family: ", ";\n    font-size: 16px;\n    font-weight: 400;\n    font-style: normal;\n    line-height: 24px;\n    color: ", ";\n\n    background: none;\n  }\n\n  input:-webkit-autofill,\n  input:-webkit-autofill:hover,\n  input:-webkit-autofill:focus,\n  input:-webkit-autofill:active {\n    transition: background-color 5000s ease-in-out 0s;\n  }\n\n  input::placeholder {\n    color: ", ";\n  }\n\n  input::-webkit-outer-spin-button,\n  input::-webkit-inner-spin-button {\n    margin: 0;\n    -webkit-appearance: none;\n  }\n\n  /* Firefox */\n  input[type='number'] {\n    appearance: textfield;\n  }\n"])), function (_ref) {
    var variant = _ref.variant, disabled = _ref.disabled, error = _ref.error;
    if (variant === 'secondary') {
        if (disabled) {
            return COLORS.GRAY_500;
        }
        if (error) {
            return COLORS.PURPLE_500;
        }
        return COLORS.WHITE;
    }
    if (error) {
        return COLORS.PURPLE_500;
    }
    return COLORS.PURPLE_600;
}, function (_ref2) {
    var inFocus = _ref2.inFocus, error = _ref2.error;
    return inFocus && !error ? '1px solid #ECEDFF' : error && "2px solid ".concat(COLORS.RED_100);
}, function (_ref3) {
    var iconLeft = _ref3.iconLeft;
    return iconLeft ? 'flex-direction: row-reverse;' : null;
}, function (_ref4) {
    var variant = _ref4.variant;
    return variant === 'secondary' ? "outline: 1px solid ".concat(COLORS.PURPLE_400, ";") : null;
}, Fonts.NotoSans, function (_ref5) {
    var error = _ref5.error, disabled = _ref5.disabled;
    if (error) {
        return COLORS.RED_100;
    }
    if (disabled) {
        return COLORS.DARK_PURPLE_300;
    }
    return COLORS.DARK_PURPLE_100;
}, COLORS.LOGAN);
export var IconContainer = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  z-index: 2;\n\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  min-width: 20px;\n\n  ", "\n\n  ", "\n"])), function (_ref6) {
    var pointerEventsNone = _ref6.pointerEventsNone;
    return pointerEventsNone && css(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n      pointer-events: none;\n    "])));
}, function (_ref7) {
    var lowerIconOpacity = _ref7.lowerIconOpacity;
    return lowerIconOpacity && css(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n      svg {\n        opacity: 0.2;\n      }\n    "])));
});
export var Label = styled.label(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  display: block;\n\n  min-width: ", ";\n  margin-right: 10px;\n  margin-bottom: 4px;\n\n  font-family: ", ";\n  font-size: 16px;\n  font-weight: 400;\n  font-style: normal;\n  line-height: 24px;\n  color: ", ";\n  white-space: nowrap;\n\n  background: none;\n"])), function (_ref8) {
    var width = _ref8.width;
    return width && "".concat(width, "px");
}, Fonts.NotoSans, function (_ref9) {
    var disabled = _ref9.disabled;
    return disabled ? COLORS.DARK_PURPLE_300 : COLORS.DARK_PURPLE_100;
});
export var ErrorMessage = styled.span(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  padding: 4px 0;\n\n  font-family: ", ";\n  font-size: 12px;\n  font-weight: 700;\n  line-height: 16px;\n  color: ", ";\n"])), Fonts.NotoSans, COLORS.RED_100);
export var LabelWrapper = styled.div(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  display: flex;\n  gap: 10px;\n  align-items: center;\n"])));
