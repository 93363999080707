function _typeof(obj) {
    "@babel/helpers - typeof";
    return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj);
}
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols);
} return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); });
} return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) {
    Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true });
}
else {
    obj[key] = value;
} return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null)
    return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) {
    var res = prim.call(input, hint || "default");
    if (_typeof(res) !== "object")
        return res;
    throw new TypeError("@@toPrimitive must return a primitive value.");
} return (hint === "string" ? String : Number)(input); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o)
    return; if (typeof o === "string")
    return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor)
    n = o.constructor.name; if (n === "Map" || n === "Set")
    return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n))
    return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length)
    len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++)
    arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) {
    var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1;
    try {
        if (_x = (_i = _i.call(arr)).next, 0 === i) {
            if (Object(_i) !== _i)
                return;
            _n = !1;
        }
        else
            for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0)
                ;
    }
    catch (err) {
        _d = !0, _e = err;
    }
    finally {
        try {
            if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r))
                return;
        }
        finally {
            if (_d)
                throw _e;
        }
    }
    return _arr;
} }
function _arrayWithHoles(arr) { if (Array.isArray(arr))
    return arr; }
import { useCallback, useMemo, useState } from 'react';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export var useFormValidation = function useFormValidation(initialValues, validationRules) {
    var _useState = useState(initialValues), _useState2 = _slicedToArray(_useState, 2), values = _useState2[0], setValues = _useState2[1];
    var _useState3 = useState({}), _useState4 = _slicedToArray(_useState3, 2), validationErrors = _useState4[0], setValidationErrors = _useState4[1];
    var validateField = useCallback(function (fieldName, value) {
        var rules = validationRules[fieldName];
        if (rules) {
            if (rules.required && !(value !== null && value !== void 0 && value.toString().trim())) {
                return 'Поле обязательно для заполнения';
            }
            if (rules.minLength && value.length < rules.minLength) {
                return "\u041C\u0438\u043D\u0438\u043C\u0430\u043B\u044C\u043D\u0430\u044F \u0434\u043B\u0438\u043D\u0430 ".concat(rules.minLength, " \u0441\u0438\u043C\u0432\u043E\u043B\u043E\u0432");
            }
            if (rules.maxLength && value.length > rules.maxLength) {
                return "\u041C\u0430\u043A\u0441\u0438\u043C\u0430\u043B\u044C\u043D\u0430\u044F \u0434\u043B\u0438\u043D\u0430 ".concat(rules.maxLength, " \u0441\u0438\u043C\u0432\u043E\u043B\u043E\u0432");
            }
            if (rules.pattern && !rules.pattern.test(value)) {
                return 'Неверный формат';
            }
        }
        return null;
    }, [validationRules]);
    var validateAllFields = useCallback(function () {
        var errors = {};
        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (var _fieldName in validationRules) {
            var error = validateField(_fieldName, values[_fieldName]);
            if (error) {
                errors[_fieldName] = error;
            }
        }
        return errors;
    }, [validateField, validationRules, values]);
    var validateOnSubmit = useCallback(function () {
        var errors = validateAllFields();
        setValidationErrors(errors);
        return errors;
    }, [validateAllFields]);
    var validateOnChange = useCallback(function (fieldName, value) {
        var error = validateField(fieldName, value);
        setValidationErrors(function (prevErrors) {
            return _objectSpread(_objectSpread({}, prevErrors), {}, _defineProperty({}, fieldName, error));
        });
        setValues(function (prevValues) {
            return _objectSpread(_objectSpread({}, prevValues), {}, _defineProperty({}, fieldName, value));
        });
    }, [validateField, setValidationErrors, setValues]);
    var resetValidation = function resetValidation() {
        setValidationErrors({});
    };
    var isFormValid = Object.values(validationErrors).filter(Boolean).length === 0;
    var validationResult = useMemo(function () {
        return validateOnSubmit();
    }, [validateOnSubmit]);
    return {
        values: values,
        validationRules: validationRules,
        validationResult: validationResult,
        validateOnChange: validateOnChange,
        resetValidation: resetValidation,
        isFormValid: isFormValid,
        validationErrors: validationErrors
    };
};
