import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import prepareHeaders from 'services/prepareHeaders';
export var exporterApi = createApi({
    reducerPath: 'exporter',
    baseQuery: fetchBaseQuery({
        baseUrl: "".concat(window.location.origin, "/exporter"),
        // baseUrl: 'http://127.0.0.1:3333',
        prepareHeaders: prepareHeaders
    }),
    refetchOnFocus: true,
    tagTypes: [],
    endpoints: function endpoints() {
        return {};
    }
});
