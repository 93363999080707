var _templateObject, _templateObject2, _templateObject3, _templateObject4;
function _taggedTemplateLiteral(strings, raw) { if (!raw) {
    raw = strings.slice(0);
} return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import styled from 'styled-components';
import { Fonts } from 'types/TextProps';
import { COLORS } from 'constants/colors';
export var Fieldset = styled.fieldset(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  border: none;\n"])));
export var Legend = styled.legend(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  font-family: ", ";\n  font-size: 24px;\n  font-weight: 500;\n  line-height: 32px;\n  color: ", ";\n"])), Fonts.Montserrat, COLORS.DARK_BLACK);
export var Wrapper = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  display: grid;\n  grid-gap: 24px;\n  margin-top: 32px;\n"])));
export var Errors = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  display: grid;\n  grid-gap: 6px;\n  margin-left: 26px;\n"])));
