import { api } from 'services/api';
export var lendingApiSlice = api.injectEndpoints({
    endpoints: function endpoints(build) {
        return {
            sendLendingFeedback: build.mutation({
                query: function query(_ref) {
                    var data = _ref.data;
                    return {
                        url: '/feedback',
                        method: 'POST',
                        body: data
                    };
                }
            })
        };
    }
});
