var _templateObject, _templateObject2;
function _taggedTemplateLiteral(strings, raw) { if (!raw) {
    raw = strings.slice(0);
} return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import styled from 'styled-components';
import { COLORS } from 'constants/colors';
import { MEDIA } from 'uikit/vars';
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  cursor: pointer;\n\n  display: grid;\n  grid-gap: 32px;\n  align-items: center;\n\n  height: 100%;\n  min-height: 220px;\n  padding: 16px;\n\n  background-color: ", ";\n  border-radius: 8px;\n\n  ", "\n\n  @media (min-width: ", ") {\n    padding: 32px 32px 48px 32px;\n  }\n"])), COLORS.PURPLE_600, function (_ref) {
    var isHasCount = _ref.isHasCount;
    return isHasCount ? "\n      & > div:first-child  > div:last-child  {\n        background-color: ".concat(COLORS.PURPLE_100, ";\n      }\n  ") : null;
}, MEDIA.TABLET_1);
export var Description = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: grid;\n  grid-template-columns: 2fr 1fr;\n  align-items: center;\n  justify-content: space-between;\n  justify-items: end;\n"])));
