function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o)
    return; if (typeof o === "string")
    return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor)
    n = o.constructor.name; if (n === "Map" || n === "Set")
    return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n))
    return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null)
    return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr))
    return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length)
    len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++)
    arr2[i] = arr[i]; return arr2; }
export var extractNumbers = function extractNumbers(raw) {
    return Number(raw.replace(/\D+/gm, ''));
};
export var getNounDeclension = function getNounDeclension(_ref) {
    var number = _ref.number, one = _ref.one, few = _ref.few, many = _ref.many;
    var value = Math.abs(number) % 100;
    var num = value % 10;
    if (value > 10 && value < 20) {
        return many;
    }
    if (num > 1 && num < 5) {
        return few;
    }
    if (num === 1) {
        return one;
    }
    return many;
};
export var withEmptyOption = function withEmptyOption(options) {
    var emptyText = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'Не выбрано';
    return [{
            value: null,
            title: emptyText
        }].concat(_toConsumableArray(options));
};
export var getPercentages = function getPercentages(_ref2) {
    var total = _ref2.total, target = _ref2.target;
    return Math.round(target / total * 100);
};
