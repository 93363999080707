import { isValid, parse, format } from 'date-fns';
var DATE_FORMAT = 'yyyy-MM-dd';
export var formatDateFormat = function formatDateFormat(value) {
    var dateFormat = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : DATE_FORMAT;
    var parsedDate = parse(value, dateFormat, new Date());
    if (isValid(parsedDate)) {
        return parsedDate;
    }
    return undefined;
};
export var parseDate = function parseDate(value) {
    var dateFormat = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : DATE_FORMAT;
    var date = value && new Date(value);
    if (isValid(date)) {
        return format(date, dateFormat);
    }
    return undefined;
};
