var TOKEN_KEY = 'access_token';
export var getAccessToken = function getAccessToken() {
    return localStorage.getItem(TOKEN_KEY);
};
export var saveAccessToken = function saveAccessToken(token) {
    localStorage.setItem(TOKEN_KEY, token);
};
export var deleteAccessToken = function deleteAccessToken() {
    localStorage.removeItem(TOKEN_KEY);
};
