export var ACTIVE_OPTIONS = [{
        id: '',
        title: 'Не выбрано'
    }, {
        id: 'isActive',
        title: 'Активен'
    }, {
        id: 'isNoActive',
        title: 'Не активен'
    }, {
        id: 'withFeedback',
        title: 'С обратной связью'
    }];
