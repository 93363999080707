import { api } from './api';
var url = 'admin/change-password';
export var clientAdminProfile = api.injectEndpoints({
    endpoints: function endpoints(build) {
        return {
            changePassword: build.mutation({
                query: function query(body) {
                    return {
                        url: url,
                        method: 'POST',
                        body: body
                    };
                }
            })
        };
    }
});
var useChangePasswordMutation = clientAdminProfile.useChangePasswordMutation;
export { useChangePasswordMutation };
