var _templateObject, _templateObject2;
function _taggedTemplateLiteral(strings, raw) { if (!raw) {
    raw = strings.slice(0);
} return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import styled from 'styled-components';
import { Fonts } from 'types/TextProps';
import { COLORS } from 'constants/colors';
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: grid;\n  grid-template-columns: ", ";\n  align-items: center;\n  min-width: ", ";\n"])), function (_ref) {
    var isNotFeedbackCount = _ref.isNotFeedbackCount;
    return isNotFeedbackCount ? '1fr 1fr' : '1fr 1fr 1fr';
}, function (_ref2) {
    var minWidth = _ref2.minWidth;
    return minWidth ? "".concat(minWidth, "px") : '64px';
});
export var Mark = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  font-family: ", ";\n  font-size: 14px;\n  font-weight: 600;\n  line-height: 20px;\n  color: ", ";\n  text-align: center;\n\n  background-color: #ff7376;\n\n  &:first-child {\n    background-color: #3ccd97;\n    border-radius: 10px 0 0 10px;\n  }\n\n  &:last-child {\n    background-color: ", ";\n    border-radius: 0 10px 10px 0;\n  }\n"])), Fonts.NotoSans, COLORS.WHITE, function (_ref3) {
    var isYellow = _ref3.isYellow;
    return isYellow ? COLORS.ALERT_YELLOW : '#ff7376';
});
