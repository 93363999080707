var _templateObject, _templateObject2, _templateObject3, _templateObject4;
function _taggedTemplateLiteral(strings, raw) { if (!raw) {
    raw = strings.slice(0);
} return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import styled from 'styled-components';
import { Fonts } from 'types/TextProps';
import { COLORS } from 'constants/colors';
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  position: relative;\n\n  ", ";\n  display: grid;\n  grid-column-gap: 20px;\n  grid-template-columns: ", ";\n  align-items: center;\n\n  min-height: 64px;\n  padding: 17px 24px 17px 24px;\n  white-space: break-spaces;\n\n  &:nth-child(odd) {\n    background: ", ";\n  }\n\n  border: ", ";\n\n  transition: transform 0.6s ease-in-out;\n"])), function (_ref) {
    var isShow = _ref.isShow;
    return isShow ? 'transform: translateX(-40%);' : '';
}, function (_ref2) {
    var isImported = _ref2.isImported;
    return isImported ? 'minmax(54px,0.5fr) minmax(123px,2fr) minmax(179px,3fr) minmax(40px,1fr) minmax(228px,2fr) minmax(119px,2fr) minmax(64px,2fr) 5fr' : 'minmax(24px, 0.5fr) minmax(78px,2fr) minmax(179px,3fr) minmax(33px,1fr) minmax(72px,2fr) minmax(228px,2fr) minmax(119px,2fr) minmax(70px,1fr) 168px';
}, COLORS.PURPLE_600, function (_ref3) {
    var isFirstCreate = _ref3.isFirstCreate;
    return isFirstCreate ? "2px solid ".concat(COLORS.GREEN) : "1px solid ".concat(COLORS.PURPLE_400);
});
export var IconsGroup = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: grid;\n  grid-gap: ", ";\n  grid-template-columns: ", ";\n  align-items: center;\n  justify-self: end;\n  ", ";\n\n  & > img {\n    cursor: pointer;\n  }\n"])), function (_ref4) {
    var isImported = _ref4.isImported;
    return isImported ? '10px' : '20px';
}, function (_ref5) {
    var isImported = _ref5.isImported;
    return isImported ? 'auto auto 118px 118px' : '32px 32px 20px 20px';
}, function (_ref6) {
    var isImported = _ref6.isImported;
    return !isImported && 'justify-items: center;';
});
export var Number = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  font-family: ", ";\n  font-size: 14px;\n  font-weight: 500;\n  line-height: 20px;\n  color: ", ";\n"])), Fonts.NotoSans, COLORS.LOGAN);
export var QuestionCount = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  justify-self: center;\n"])));
