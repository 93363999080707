import { api } from 'services/api';
import { TAG_TYPES as TAG } from 'services/constants';
export var profilesApiSlice = api.injectEndpoints({
    endpoints: function endpoints(build) {
        return {
            getProfiles: build.query({
                query: function query(_ref) {
                    var subdomain = _ref.subdomain, params = _ref.params;
                    return {
                        url: "".concat(process.env.PROTOCOL, "://").concat(subdomain, ".").concat(process.env.HOST, "/api/admin/profiles"),
                        method: 'GET',
                        params: params
                    };
                },
                providesTags: [TAG.profiles]
            }),
            getProfile: build.query({
                query: function query(_ref2) {
                    var subdomain = _ref2.subdomain, profileId = _ref2.profileId;
                    return {
                        url: "".concat(process.env.PROTOCOL, "://").concat(subdomain, ".").concat(process.env.HOST, "/api/admin/profiles/").concat(profileId),
                        method: 'GET'
                    };
                },
                providesTags: function providesTags(_result, _error, _ref3) {
                    var profileId = _ref3.profileId;
                    return [{
                            type: TAG.profile,
                            id: profileId
                        }];
                }
            }),
            // the same as "getProfile", but for employee
            getProfileEmployee: build.query({
                query: function query(_ref4) {
                    var subdomain = _ref4.subdomain, profileId = _ref4.profileId;
                    return {
                        url: "".concat(process.env.PROTOCOL, "://").concat(subdomain, ".").concat(process.env.HOST, "/api/profiles/").concat(profileId),
                        method: 'GET'
                    };
                },
                providesTags: function providesTags(_result, _error, _ref5) {
                    var profileId = _ref5.profileId;
                    return [{
                            type: TAG.profileEmployee,
                            id: profileId
                        }];
                }
            }),
            createProfile: build.mutation({
                query: function query(_ref6) {
                    var subdomain = _ref6.subdomain, data = _ref6.data;
                    return {
                        url: "".concat(process.env.PROTOCOL, "://").concat(subdomain, ".").concat(process.env.HOST, "/api/admin/profiles"),
                        method: 'POST',
                        body: data
                    };
                },
                invalidatesTags: [TAG.profiles]
            }),
            editProfile: build.mutation({
                query: function query(_ref7) {
                    var subdomain = _ref7.subdomain, data = _ref7.data, profileId = _ref7.profileId;
                    return {
                        url: "".concat(process.env.PROTOCOL, "://").concat(subdomain, ".").concat(process.env.HOST, "/api/admin/profiles/").concat(profileId),
                        method: 'PUT',
                        body: data
                    };
                },
                invalidatesTags: function invalidatesTags(_data, _error, _ref8) {
                    var profileId = _ref8.profileId;
                    return [TAG.profiles, {
                            type: TAG.profile,
                            id: profileId
                        }, {
                            type: TAG.profileEmployee,
                            id: profileId
                        }];
                }
            }),
            deleteProfile: build.mutation({
                query: function query(_ref9) {
                    var subdomain = _ref9.subdomain, profileId = _ref9.profileId;
                    return {
                        url: "".concat(process.env.PROTOCOL, "://").concat(subdomain, ".").concat(process.env.HOST, "/api/admin/profiles/").concat(profileId),
                        method: 'DELETE'
                    };
                },
                invalidatesTags: function invalidatesTags(_data, _error, _ref10) {
                    var profileId = _ref10.profileId;
                    return [TAG.profiles, {
                            type: TAG.profile,
                            id: profileId
                        }, {
                            type: TAG.profileEmployee,
                            id: profileId
                        }];
                }
            }),
            attachKnowledges: build.mutation({
                query: function query(_ref11) {
                    var subdomain = _ref11.subdomain, data = _ref11.data, profileId = _ref11.profileId;
                    return {
                        url: "".concat(process.env.PROTOCOL, "://").concat(subdomain, ".").concat(process.env.HOST, "/api/admin/profiles/").concat(profileId, "/attach-knowledge"),
                        method: 'POST',
                        body: data
                    };
                },
                invalidatesTags: function invalidatesTags(_data, _error, _ref12) {
                    var profileId = _ref12.profileId;
                    return [TAG.profiles, {
                            type: TAG.profile,
                            id: profileId
                        }, {
                            type: TAG.profileEmployee,
                            id: profileId
                        }];
                }
            }),
            detachKnowledges: build.mutation({
                query: function query(_ref13) {
                    var subdomain = _ref13.subdomain, data = _ref13.data, profileId = _ref13.profileId;
                    return {
                        url: "".concat(process.env.PROTOCOL, "://").concat(subdomain, ".").concat(process.env.HOST, "/api/admin/profiles/").concat(profileId, "/detach-knowledge"),
                        method: 'POST',
                        body: data
                    };
                },
                invalidatesTags: function invalidatesTags(_data, _error, _ref14) {
                    var profileId = _ref14.profileId;
                    return [TAG.profiles, {
                            type: TAG.profile,
                            id: profileId
                        }, {
                            type: TAG.profileEmployee,
                            id: profileId
                        }];
                }
            })
        };
    }
});
