var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8;
function _taggedTemplateLiteral(strings, raw) { if (!raw) {
    raw = strings.slice(0);
} return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import styled from 'styled-components';
import { Fonts } from 'types/TextProps';
import { COLORS } from 'constants/colors';
export var SelectWrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  position: relative;\n\n  display: grid;\n  align-items: center;\n\n  width: 100%;\n  margin: 0;\n"])));
export var Wrapper = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  cursor: pointer;\n  user-select: none;\n\n  position: relative;\n\n  display: flex;\n  align-items: center;\n\n  width: 100%;\n  min-width: 0;\n  height: 48px;\n  padding: 12px 12px 12px 16px;\n\n  font-family: ", ";\n  font-size: 16px;\n  font-weight: 400;\n  line-height: 24px;\n\n  background-color: ", ";\n  border-radius: 4px;\n  outline: 1px solid ", ";\n  outline: ", ";\n"])), Fonts.NotoSans, function (_ref) {
    var variant = _ref.variant, disabled = _ref.disabled;
    if (variant === 'secondary') {
        if (disabled) {
            return COLORS.GRAY_500;
        }
        return COLORS.WHITE;
    }
    if (disabled) {
        return COLORS.GRAY_500;
    }
    return COLORS.PURPLE_600;
}, COLORS.PURPLE_400, function (_ref2) {
    var error = _ref2.error;
    return error ? "2px solid ".concat(COLORS.RED_100) : null;
});
export var Text = styled.span(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  overflow: hidden;\n  display: block;\n\n  color: ", ";\n  text-overflow: ellipsis;\n  white-space: nowrap;\n"])), function (_ref3) {
    var disabled = _ref3.disabled;
    return disabled ? COLORS.GRAY_100 : COLORS.DARK_PURPLE_100;
});
export var Placeholder = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  opacity: 0.3;\n"])));
export var ArrowWrapper = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  position: absolute;\n  top: 0;\n  right: 14px;\n  bottom: 0;\n  transform: ", ";\n\n  display: flex;\n  align-items: center;\n\n  transition: 0.8s;\n"])), function (_ref4) {
    var opened = _ref4.opened;
    return opened ? 'rotate(180deg)' : 'unset';
});
export var OverflowWrapper = styled.div(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  overflow: hidden;\n  width: calc(100% - 15px);\n"])));
export var Label = styled.div(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  display: block;\n\n  margin-right: 10px;\n  margin-bottom: 4px;\n\n  font-family: ", ";\n  font-size: 16px;\n  font-weight: 400;\n  font-style: normal;\n  line-height: 24px;\n  color: ", ";\n\n  background: none;\n"])), Fonts.NotoSans, function (_ref5) {
    var disabled = _ref5.disabled;
    return disabled ? COLORS.GRAY_100 : COLORS.DARK_PURPLE_100;
});
export var ErrorMessage = styled.span(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  position: absolute;\n  bottom: -21px;\n\n  padding: 4px 0;\n\n  font-family: ", ";\n  font-size: 12px;\n  font-weight: 700;\n  line-height: 16px;\n  color: ", ";\n"])), Fonts.NotoSans, COLORS.RED_100);
