import { isRejectedWithValue } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { deleteAccessToken } from 'modules';
import { openModal } from 'store/reducers/infoModalSlice';
import { handleErrorMessageByKey } from 'utils/handleErrorMessageByKey';
import { api } from './api';
var DELETE_REQUEST_METHOD = 'DELETE';
var PUT_REQUEST_METHOD = 'PUT';
var POST_REQUEST_METHOD = 'POST';
var GET_REQUEST_METHOD = 'GET';
var INTERNAL_SERVER_ERROR = 500;
var HTTP_STATUS_UNAUTHORIZED = 401;
var ERROR_NOT_FOUND = 404;
var ERROR_UNPROCESSABLE_CONTENT = 422;
var CHANGING_REQUEST_METHODS = [DELETE_REQUEST_METHOD, PUT_REQUEST_METHOD, POST_REQUEST_METHOD];
export var rtkQueryErrorLogger = function rtkQueryErrorLogger(_ref) {
    var dispatch = _ref.dispatch;
    return function (next) {
        return function (action) {
            var _action$meta21, _action$meta21$baseQu, _action$meta21$baseQu2, _action$meta22, _action$meta22$arg, _action$meta23, _action$meta23$arg, _action$meta24, _action$meta24$arg, _action$meta25, _action$meta25$arg, _action$meta26, _action$meta26$arg, _action$meta27, _action$meta27$arg, _action$meta28, _action$meta28$arg, _action$meta29, _action$meta29$arg, _action$meta30, _action$meta30$arg, _action$meta31, _action$meta31$arg;
            if (!isRejectedWithValue(action)) {
                var _action$meta, _action$meta$baseQuer, _action$meta$baseQuer2, _action$meta2, _action$meta2$arg, _action$meta3, _action$meta3$arg, _action$meta4, _action$meta4$arg, _action$meta5, _action$meta5$arg, _action$meta6, _action$meta6$arg, _action$meta7, _action$meta7$arg, _action$meta8, _action$meta8$arg, _action$meta9, _action$meta9$arg, _action$meta10, _action$meta10$arg, _action$meta11, _action$meta11$arg, _action$meta12, _action$meta12$arg, _action$meta13, _action$meta13$arg, _action$meta14, _action$meta14$arg, _action$meta15, _action$meta15$arg, _action$meta16, _action$meta16$arg, _action$meta17, _action$meta17$arg, _action$meta18, _action$meta18$arg, _action$meta19, _action$meta19$arg, _action$meta20, _action$meta20$arg, _action$payload;
                var _requestMethod = action === null || action === void 0 ? void 0 : (_action$meta = action.meta) === null || _action$meta === void 0 ? void 0 : (_action$meta$baseQuer = _action$meta.baseQueryMeta) === null || _action$meta$baseQuer === void 0 ? void 0 : (_action$meta$baseQuer2 = _action$meta$baseQuer.request) === null || _action$meta$baseQuer2 === void 0 ? void 0 : _action$meta$baseQuer2.method;
                var isChangingMethod = CHANGING_REQUEST_METHODS.includes(_requestMethod);
                var isGetMethod = GET_REQUEST_METHOD === _requestMethod;
                CHANGING_REQUEST_METHODS.includes(_requestMethod);
                var isEditProfile = (action === null || action === void 0 ? void 0 : (_action$meta2 = action.meta) === null || _action$meta2 === void 0 ? void 0 : (_action$meta2$arg = _action$meta2.arg) === null || _action$meta2$arg === void 0 ? void 0 : _action$meta2$arg.endpointName) === 'editProfile';
                var _isCreateClient = (action === null || action === void 0 ? void 0 : (_action$meta3 = action.meta) === null || _action$meta3 === void 0 ? void 0 : (_action$meta3$arg = _action$meta3.arg) === null || _action$meta3$arg === void 0 ? void 0 : _action$meta3$arg.endpointName) === 'createClient';
                var _isEditClient = (action === null || action === void 0 ? void 0 : (_action$meta4 = action.meta) === null || _action$meta4 === void 0 ? void 0 : (_action$meta4$arg = _action$meta4.arg) === null || _action$meta4$arg === void 0 ? void 0 : _action$meta4$arg.endpointName) === 'editClient';
                var _isRemoveClient = (action === null || action === void 0 ? void 0 : (_action$meta5 = action.meta) === null || _action$meta5 === void 0 ? void 0 : (_action$meta5$arg = _action$meta5.arg) === null || _action$meta5$arg === void 0 ? void 0 : _action$meta5$arg.endpointName) === 'removeClient';
                var _isCreateEmployee = (action === null || action === void 0 ? void 0 : (_action$meta6 = action.meta) === null || _action$meta6 === void 0 ? void 0 : (_action$meta6$arg = _action$meta6.arg) === null || _action$meta6$arg === void 0 ? void 0 : _action$meta6$arg.endpointName) === 'createEmployee';
                var _isCreateClientEmployee = (action === null || action === void 0 ? void 0 : (_action$meta7 = action.meta) === null || _action$meta7 === void 0 ? void 0 : (_action$meta7$arg = _action$meta7.arg) === null || _action$meta7$arg === void 0 ? void 0 : _action$meta7$arg.endpointName) === 'createClientEmployee';
                var _isEditClientEmployee = (action === null || action === void 0 ? void 0 : (_action$meta8 = action.meta) === null || _action$meta8 === void 0 ? void 0 : (_action$meta8$arg = _action$meta8.arg) === null || _action$meta8$arg === void 0 ? void 0 : _action$meta8$arg.endpointName) === 'editClientEmployee';
                var _isEditEmployee = (action === null || action === void 0 ? void 0 : (_action$meta9 = action.meta) === null || _action$meta9 === void 0 ? void 0 : (_action$meta9$arg = _action$meta9.arg) === null || _action$meta9$arg === void 0 ? void 0 : _action$meta9$arg.endpointName) === 'editEmployee';
                var _isRemoveEmployee = (action === null || action === void 0 ? void 0 : (_action$meta10 = action.meta) === null || _action$meta10 === void 0 ? void 0 : (_action$meta10$arg = _action$meta10.arg) === null || _action$meta10$arg === void 0 ? void 0 : _action$meta10$arg.endpointName) === 'removeEmployee';
                var _isRemoveClientEmployee = (action === null || action === void 0 ? void 0 : (_action$meta11 = action.meta) === null || _action$meta11 === void 0 ? void 0 : (_action$meta11$arg = _action$meta11.arg) === null || _action$meta11$arg === void 0 ? void 0 : _action$meta11$arg.endpointName) === 'removeClientEmployee';
                var isGetSentAdminEmployeeTestLink = (action === null || action === void 0 ? void 0 : (_action$meta12 = action.meta) === null || _action$meta12 === void 0 ? void 0 : (_action$meta12$arg = _action$meta12.arg) === null || _action$meta12$arg === void 0 ? void 0 : _action$meta12$arg.endpointName) === 'getSentAdminEmployeeTestLink';
                var isGetSentEmployeeTestLink = (action === null || action === void 0 ? void 0 : (_action$meta13 = action.meta) === null || _action$meta13 === void 0 ? void 0 : (_action$meta13$arg = _action$meta13.arg) === null || _action$meta13$arg === void 0 ? void 0 : _action$meta13$arg.endpointName) === 'getSentEmployeeTestLink';
                var isGetSentEmployeeLink = (action === null || action === void 0 ? void 0 : (_action$meta14 = action.meta) === null || _action$meta14 === void 0 ? void 0 : (_action$meta14$arg = _action$meta14.arg) === null || _action$meta14$arg === void 0 ? void 0 : _action$meta14$arg.endpointName) === 'getSentEmployeeLink';
                var isGetSentAdminEmployeeLink = (action === null || action === void 0 ? void 0 : (_action$meta15 = action.meta) === null || _action$meta15 === void 0 ? void 0 : (_action$meta15$arg = _action$meta15.arg) === null || _action$meta15$arg === void 0 ? void 0 : _action$meta15$arg.endpointName) === 'getSentAdminEmployeeLink';
                var isResetClientPassword = (action === null || action === void 0 ? void 0 : (_action$meta16 = action.meta) === null || _action$meta16 === void 0 ? void 0 : (_action$meta16$arg = _action$meta16.arg) === null || _action$meta16$arg === void 0 ? void 0 : _action$meta16$arg.endpointName) === 'resetClientPassword';
                var isGenerateQuestions = (action === null || action === void 0 ? void 0 : (_action$meta17 = action.meta) === null || _action$meta17 === void 0 ? void 0 : (_action$meta17$arg = _action$meta17.arg) === null || _action$meta17$arg === void 0 ? void 0 : _action$meta17$arg.endpointName) === 'generateQuestions';
                var isCreateSource = (action === null || action === void 0 ? void 0 : (_action$meta18 = action.meta) === null || _action$meta18 === void 0 ? void 0 : (_action$meta18$arg = _action$meta18.arg) === null || _action$meta18$arg === void 0 ? void 0 : _action$meta18$arg.endpointName) === 'createSource';
                var isCreateQuestion = (action === null || action === void 0 ? void 0 : (_action$meta19 = action.meta) === null || _action$meta19 === void 0 ? void 0 : (_action$meta19$arg = _action$meta19.arg) === null || _action$meta19$arg === void 0 ? void 0 : _action$meta19$arg.endpointName) === 'createQuestion';
                var isСreateEmployee = (action === null || action === void 0 ? void 0 : (_action$meta20 = action.meta) === null || _action$meta20 === void 0 ? void 0 : (_action$meta20$arg = _action$meta20.arg) === null || _action$meta20$arg === void 0 ? void 0 : _action$meta20$arg.endpointName) === 'createEmployee';
                if ((isChangingMethod || isGetMethod && (isGetSentAdminEmployeeTestLink || isGetSentEmployeeTestLink || isGetSentEmployeeLink || isGetSentAdminEmployeeLink || isResetClientPassword)) && !isEditProfile && !_isCreateClient && !_isEditClient && !_isRemoveClient && !_isCreateEmployee && !_isCreateClientEmployee && !_isEditClientEmployee && !_isEditEmployee && !_isRemoveEmployee && !isGenerateQuestions && !_isRemoveClientEmployee) {
                    if (isCreateSource || isCreateQuestion || isСreateEmployee) {
                        toast(action.payload.message);
                    }
                    else {
                        toast.success(action.payload.message);
                    }
                }
                if ((_isCreateClient || _isEditClient || _isRemoveClient || _isCreateEmployee || _isCreateClientEmployee || _isCreateClientEmployee || _isEditClientEmployee || _isEditEmployee || _isRemoveEmployee || _isRemoveClientEmployee) && action !== null && action !== void 0 && (_action$payload = action.payload) !== null && _action$payload !== void 0 && _action$payload.message) {
                    dispatch(openModal({
                        message: action.payload.message
                    }));
                }
                return next(action);
            }
            var requestMethod = action === null || action === void 0 ? void 0 : (_action$meta21 = action.meta) === null || _action$meta21 === void 0 ? void 0 : (_action$meta21$baseQu = _action$meta21.baseQueryMeta) === null || _action$meta21$baseQu === void 0 ? void 0 : (_action$meta21$baseQu2 = _action$meta21$baseQu.request) === null || _action$meta21$baseQu2 === void 0 ? void 0 : _action$meta21$baseQu2.method;
            var isPostMethod = requestMethod === POST_REQUEST_METHOD;
            var _action$payload2 = action.payload, data = _action$payload2.data, status = _action$payload2.status;
            var isCreateClient = (action === null || action === void 0 ? void 0 : (_action$meta22 = action.meta) === null || _action$meta22 === void 0 ? void 0 : (_action$meta22$arg = _action$meta22.arg) === null || _action$meta22$arg === void 0 ? void 0 : _action$meta22$arg.endpointName) === 'createClient';
            var isEditClient = (action === null || action === void 0 ? void 0 : (_action$meta23 = action.meta) === null || _action$meta23 === void 0 ? void 0 : (_action$meta23$arg = _action$meta23.arg) === null || _action$meta23$arg === void 0 ? void 0 : _action$meta23$arg.endpointName) === 'editClient';
            var isRemoveClient = (action === null || action === void 0 ? void 0 : (_action$meta24 = action.meta) === null || _action$meta24 === void 0 ? void 0 : (_action$meta24$arg = _action$meta24.arg) === null || _action$meta24$arg === void 0 ? void 0 : _action$meta24$arg.endpointName) === 'removeClient';
            var isCreateEmployee = (action === null || action === void 0 ? void 0 : (_action$meta25 = action.meta) === null || _action$meta25 === void 0 ? void 0 : (_action$meta25$arg = _action$meta25.arg) === null || _action$meta25$arg === void 0 ? void 0 : _action$meta25$arg.endpointName) === 'createEmployee';
            var isCreateClientEmployee = (action === null || action === void 0 ? void 0 : (_action$meta26 = action.meta) === null || _action$meta26 === void 0 ? void 0 : (_action$meta26$arg = _action$meta26.arg) === null || _action$meta26$arg === void 0 ? void 0 : _action$meta26$arg.endpointName) === 'createClientEmployee';
            var isEditClientEmployee = (action === null || action === void 0 ? void 0 : (_action$meta27 = action.meta) === null || _action$meta27 === void 0 ? void 0 : (_action$meta27$arg = _action$meta27.arg) === null || _action$meta27$arg === void 0 ? void 0 : _action$meta27$arg.endpointName) === 'editClientEmployee';
            var isEditEmployee = (action === null || action === void 0 ? void 0 : (_action$meta28 = action.meta) === null || _action$meta28 === void 0 ? void 0 : (_action$meta28$arg = _action$meta28.arg) === null || _action$meta28$arg === void 0 ? void 0 : _action$meta28$arg.endpointName) === 'editEmployee';
            var isRemoveEmployee = (action === null || action === void 0 ? void 0 : (_action$meta29 = action.meta) === null || _action$meta29 === void 0 ? void 0 : (_action$meta29$arg = _action$meta29.arg) === null || _action$meta29$arg === void 0 ? void 0 : _action$meta29$arg.endpointName) === 'removeEmployee';
            var isRemoveClientEmployee = (action === null || action === void 0 ? void 0 : (_action$meta30 = action.meta) === null || _action$meta30 === void 0 ? void 0 : (_action$meta30$arg = _action$meta30.arg) === null || _action$meta30$arg === void 0 ? void 0 : _action$meta30$arg.endpointName) === 'removeClientEmployee';
            var isDownloadGarant = (action === null || action === void 0 ? void 0 : (_action$meta31 = action.meta) === null || _action$meta31 === void 0 ? void 0 : (_action$meta31$arg = _action$meta31.arg) === null || _action$meta31$arg === void 0 ? void 0 : _action$meta31$arg.endpointName) === 'downloadGarant';
            if (status === INTERNAL_SERVER_ERROR) {
                return toast.error(data.message || data.error);
            }
            if (status === HTTP_STATUS_UNAUTHORIZED) {
                dispatch(api.util.resetApiState());
                deleteAccessToken();
            }
            if (isDownloadGarant && (status === ERROR_NOT_FOUND || status === ERROR_UNPROCESSABLE_CONTENT)) {
                return toast.error(data.error);
            }
            if (isPostMethod) {
                var _data$data;
                toast.error("".concat(data !== null && data !== void 0 && (_data$data = data.data) !== null && _data$data !== void 0 && _data$data.length ? handleErrorMessageByKey(data.data) : data.message));
            }
            if (isCreateClient || isEditClient || isRemoveClient || isEditClient || isCreateEmployee || isCreateClientEmployee || isEditClientEmployee || isEditEmployee || isRemoveEmployee || isRemoveClientEmployee) {
                dispatch(openModal({
                    message: data.data.length ? handleErrorMessageByKey(data.data) : data.message
                }));
            }
            return next(action);
        };
    };
};
